<template>
  <BModal
    :id="nombreModal"
    :title="title"
    centered
    size="lg"
    no-close-on-backdrop
    :hide-footer="true"
    @shown="onModalOpen"
  >
    <div class="d-flex justify-content-between mb-1">
      <div class="mt-50 text-secondary">
      </div>
      <div class="mr-1 mt-50 text-muted small">
        Campos obligatorios <span class="text-danger">*</span>
      </div>
    </div>
    <BOverlay
      :show="configs.cargando"
      spinner-variant="primary"
      variant="semi-dark"
    >
      <BRow>
        
        <BCol id="col-PROFESOR-ESPECIALIZADO" cols="12">
          <BFormGroup label-for="id_persona_rol">
            <template #label>
              Docente <span class="text-danger">*</span>
            </template>
            <v-select
              v-if="configs.loadingDocentes"
              
              placeholder="Cargando docentes..."
              :disabled="true"
            />
            <v-select
              v-else
              v-model="equipo.id_persona_rol"
              placeholder="Seleccione un docente ..."
              
              label="nombre"
              :options="docentesOption"
              :reduce="option => option.id"
              input-id="id"
              @input="changePersonaRol()"
              :class="v$.equipo.id_persona_rol.$error === true
                  ? 'border-danger rounded'
                  : ''"
            />
            <div v-if="v$.equipo.id_persona_rol.$error"
              id="id_persona_rolInfo"
              class="text-danger text-right"
              style="font-size: 0.857rem;"
            >
              <p v-for="error of v$.equipo.id_persona_rol.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
            </div>
          </BFormGroup>
        </BCol>

        <BCol id="col-telefono" cols="12">
          <BFormGroup label-for="telefono">
            <template #label>
              Teléfono <span class="text-danger">*</span>
            </template>
            <BInputGroup size="md" prepend="+"
              :class="v$.equipo.telefono.$error === true
                  ? 'border-danger rounded'
                  : ''"
            >
              <BFormInput
                id="telefono"
                v-model="equipo.telefono"
                placeholder="Ingrese un teléfono"
                @keyup='equipo.telefono = formatSoloNumerosMaxLenght(equipo.telefono, 11)'
              />
            </BInputGroup>
            <div v-if="v$.equipo.telefono.$error"
              id="telefonoInfo"
              class="text-danger text-right"
              style="font-size: 0.857rem;"
            >
              <p v-for="error of v$.equipo.telefono.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
            </div>
          </BFormGroup>
        </BCol>

        <BCol id="col-correo" cols="12">
          <BFormGroup label-for="correo">
            <template #label>
              Correo electrónico <span class="text-danger">*</span>
            </template>
            <BFormInput
              id="correo"
              v-model="equipo.correo"
              placeholder="Ingrese un correo"
              :state="v$.equipo.correo.$error === true
                ? false
                : null"
            />
            <BFormInvalidFeedback v-if="v$.equipo.correo.$error"
              id="correoInfo"
              class="text-right"
            >
              <p v-for="error of v$.equipo.correo.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
            </BFormInvalidFeedback>
          </BFormGroup>
        </BCol>

        <BCol id="col-ASIGNATURAS" cols="12">
          <BFormGroup label-for="id_especialidad">
            <template #label>
              Asignatura <span class="text-danger">*</span>
            </template>
            <v-select
              v-if="configs.loadingAsignaturas"
              
              placeholder="Cargando asignaturas..."
              :disabled="true"
            />
            <v-select
              v-else
              v-model="equipo.id_especialidad"
              placeholder="Seleccione una asignatura ..."
              
              label="nombre_asignatura"
              :options="asignaturasOption"
              :reduce="option => option.id"
              input-id="id"
              @input="changeAsignatura()"
              :class="v$.equipo.id_especialidad.$error === true
                  ? 'border-danger rounded'
                  : ''"
            />
            <div v-if="v$.equipo.id_especialidad.$error"
              id="id_especialidadInfo"
              class="text-danger text-right"
              style="font-size: 0.857rem;"
            >
              <p v-for="error of v$.equipo.id_especialidad.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
            </div>
          </BFormGroup>
        </BCol>

      </BRow>

      <colLinea />

      <div class="text-right">
        <BButton variant="outline-secondary" class="mr-1" @click="closeModal">
          Cancelar
        </BButton>

        <BButton
          variant="primary"
          class="mr-25"
          :disabled="this.v$.equipo.$errors.length > 0"
          @click="submitOption"
        >
          Guardar Docente
        </BButton>
      </div>

    </BOverlay>
  </BModal>
</template>

<script>

// ETIQUETAS
import {
  BRow, BCol, BFormGroup, BFormInput, BFormInvalidFeedback, BMedia, BButton, 
  BAvatar, BOverlay, BFormDatepicker, BFormTimepicker, BFormTextarea, BModal, 
  VBModal, BInputGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import { mapGetters, mapActions } from 'vuex'

// VALIDACIONES
import useVuelidate from '@vuelidate/core'
import { required, maxLength, helpers, email } from '@vuelidate/validators'

// COMPONENTES
import colLinea from '@/views/components/Form/colLinea.vue'
import { formatos } from '@/mixins/formatos'

export default {
  components: {
    BRow, BCol, BFormGroup, BFormInput, BFormInvalidFeedback, BMedia, BButton, 
    BAvatar, BOverlay, BFormDatepicker, BFormTimepicker, BFormTextarea, BModal, 
    VBModal, BInputGroup,
    vSelect,
    
    colLinea,
  },
  mixins: [formatos],
  data() {
    return {
      configs: {
        cargando: false,
        loadingDocentes: true,
        loadingAsignaturas: false,
      },
      equipo: {},
      docentesOption: [],
      asignaturasOption: [],
    }
  },
  computed: {
    ...mapGetters({
      getUser: 'auth/user',
      getLibroSelected: 'libros/getLibroSelected',
    }),
  },
  watch: {
    getLibroSelected(val) {
      this.cargarDocentes()
    },
  },
  props: {
    nombreModal: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions({
      fetchPersonasRolCurso: 'personas/fetchPersonasRolCurso',
      fetchAsignaturasDocente: 'asignaturas/fetchAsignaturasDocente',
    }),
    async onModalOpen () {
      this.initData();
      await Promise.all([
        this.cargarDocentes()
      ])
    },
    initData() {
      this.equipo = { ...this.data }
    },
    async cargarDocentes() {
      const params = {
        id_curso: this.getLibroSelected.id,
        id_rol: 2 // docente
      }
      const { data } = await this.fetchPersonasRolCurso(params)
      this.docentesOption = []
      this.docentesOption = data.map(item => ({
        ...item,
        id: item.id_persona_rol,
        nombre: this.primeraMayus(`${item.nombre} 
          ${item.segundo_nombre} 
          ${item.primer_apellido} 
          ${item.segundo_apellido} 
          ${item.id_persona_rol === item.id_persona_rol_docente_jefe ? ' ★ ': ''}
        `),
        nombre_format: `${item.nombre} ${item.segundo_nombre} ${item.primer_apellido} ${item.segundo_apellido}`,
      }));
      this.configs.loadingDocentes = false
    },
    async cargaAsignaturas() {
      this.loadingAsignaturas = true
      const params = {
        id_curso: this.getLibroSelected.id,
        id_persona_rol: this.equipo.id_persona_rol // docente
      }
      const { data } = await this.fetchAsignaturasDocente(params);
      this.asignaturasOption = []
      this.asignaturasOption = data;
      this.configs.loadingDocentes = false
    },
    changePersonaRol() {
      this.equipo.nombre = '';
      this.equipo.telefono = '';
      this.equipo.correo = '';
      this.equipo.id_especialidad = null;
      if (this.equipo.id_persona_rol) {
        const personaRol = this.docentesOption.find(dp => dp.id_persona_rol === this.equipo.id_persona_rol);
        this.equipo.nombre = personaRol.nombre_format;
        this.equipo.telefono = personaRol.celular || personaRol.telefono || '';
        this.equipo.correo = personaRol.correo;
      }
      this.cargaAsignaturas()
    }, 
    changeAsignatura() {
      this.equipo.especialidad = '';
      if (this.equipo.id_persona_rol) {
        const asignatura = this.asignaturasOption.find(a => a.id === this.equipo.id_especialidad);
        this.equipo.especialidad = asignatura.nombre_asignatura;
      }
    }, 
    submitOption() {
      this.v$.equipo.$touch()
      if (!this.v$.equipo.$invalid) {
        const params = {
          ...this.equipo,
          id_curso: this.getLibroSelected.id
        }
        this.$emit('processForm', params)
        this.v$.equipo.$reset()
      }
    },
    closeModal() {
      this.$bvModal.hide(this.nombreModal);
      this.v$.equipo.$reset();
      if (this.equipo.id === null) {
        this.$emit('resetData');
      }
      this.initData();
    },
  },
  validations() {
    return {
      equipo: {
        id_persona_rol: {
          required: helpers.withMessage('El campo es requerido.', required),
        },
        telefono: {
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener un máximo de 20 caracteres.', maxLength(20)),
        },
        correo: {
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener un máximo de 255 caracteres.', maxLength(255)),
          email: helpers.withMessage('Debe ser un correo valido.', email),
        },
        id_especialidad: {
          required: helpers.withMessage('El campo es requerido.', required),
        },
      }
    }
  },
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
}
</script>