<template>
  <AplicacionForm
    nombreModal="modal-create"
    title="Registro de acciones de aplicación de las estrategias diversificadas planificadas"
    :data.sync="data"
    @processForm="agregar"
    @resetData="resetData"
  />
</template>

<script>
import { mapActions } from 'vuex'
import { toast, swal } from '@/mixins/alerts'
import AplicacionForm from './AplicacionForm.vue'

export default {
  components: {
    AplicacionForm,
  },
  mixins: [toast, swal],
  data() {
    return {
      data: this.getDefaultData(),
    }
  },
  methods: {
    ...mapActions({
      fetchAplicaciones: 'III_1_a_acciones_de_aplicacion/fetchAplicaciones',
      createAplicacion: 'III_1_a_acciones_de_aplicacion/addAplicacion',
    }),
    getDefaultData() {
      return {
        id: null,
        acciones: '',
        evaluacion: '',
        id_detalle_estrategia: '',
      }
    },
    async agregar(aplicacion) {
      const res = await this.createAplicacion(aplicacion)
      if (res.status === 'success') {
        await this.fetchAplicaciones(aplicacion.id_curso)
        this.resetData()
        this.$toastSuccess(
          'Aplicación registrada 👍', 
          'La aplicación fue guardada con éxito!'
        )
        this.$bvModal.hide('modal-create')
      } else {
        this.$swalError('Error al crear la aplicación!', res.message)
      }
    },
    resetData() {
      this.data = this.getDefaultData()
    },
  },
}
</script>
