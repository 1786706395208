<template>
  <ApoyosEstudiantesForm
    :nombreModal="modal"
    title="Editar registro de apoyos para cada estudiante o grupo de estudiantes"
    :data.sync="data.item"
    @processForm="editar"
  />
</template>

<script>

import { mapActions } from 'vuex'
import { toast, swal } from '@/mixins/alerts'
import ApoyosEstudiantesForm from './ApoyosEstudiantesForm.vue'

export default {
  components: {
    ApoyosEstudiantesForm,
  },
  mixins: [toast, swal],
  props: {
    data: {
      type: Object,
      required: true,
    },
    modal: {
      type: String,
      required: true,
    },
  },
  methods: {
    ...mapActions({
      updateRealizada: 'III_1_b_acciones_realizadas/updateRealizada',
      fetchRealizadas: 'III_1_b_acciones_realizadas/fetchRealizadas',
    }),
    async editar(apoyos) {
      const res = await this.updateRealizada(apoyos)
      if (res.status === 'success') {
        const params = {
          idCurso: apoyos.idCurso,
          tipo: apoyos.tipo,
        }
        await this.fetchRealizadas(params)
        
        this.$toastSuccess(
          'Registro de apoyos guardado 👍', 
          'El registro de apoyos fue editado con éxito!'
        )
        this.$bvModal.hide(this.modal)
      } else {
        this.$swalError('Error al editar el registro de apoyo!', res.message)
      }
    },
  },
}
</script>
