<template>
  <BModal
    :id="nombreModal"
    :title="title"
    centered
    size="lg"
    :hide-footer="true"
    :ok-title="title"
    cancel-title="Cancelar"
    cancel-variant="outline-secondary"
    :ok-disabled="this.v$.evaluacion.$errors.length > 0"
    @close="closeModal"
    @ok.prevent="submitOption"
  >
    <BOverlay
      :show="cargando"
      spinner-variant="primary"
      variant="semi-dark"
    >
      <b-form>
        <BRow>
          <!-- col: ASIGNATURA -->
          <BCol
            cols="12"
            md="12"
          >
            <BFormGroup
              label="Asignatura"
              label-for="id_asignatura"
            >
              <v-select
                v-model="evaluacion.id_asignatura"
                placeholder="Seleccione una asignatura..."
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="nombre"
                :options="optionsAsignaturas"
                :reduce="option => option.id"
                input-id="id_asignatura"
                :class="v$.evaluacion.id_asignatura.$error === true
                  ? 'border-danger rounded'
                  : ''"
                :disabled="true"
                :clearable="false"
              />
              <div
                v-if="v$.evaluacion.id_asignatura.$error"
                id="id_asignaturaInfo"
                class="text-danger text-right"
                style="font-size: 0.857rem;"
              >
                <p v-for="error of v$.evaluacion.id_asignatura.$errors" :key="error.$uid">
                  {{ error.$message }}
                </p>
              </div>
            </BFormGroup>
          </BCol>

          <!-- col: PERIODO -->
          <BCol
            cols="12"
            md="12"
          >
            <BFormGroup
              label="Período en que se aplicará *"
              label-for="id_periodo"
            >
              <v-select
                v-model="evaluacion.id_periodo"
                placeholder="Seleccione un periodo..."
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="nombre"
                :options="periodosOption"
                :reduce="option => option.id"
                input-id="id_periodo"
                :class="v$.evaluacion.id_periodo.$error === true
                  ? 'border-danger rounded'
                  : ''"
                :disabled="true"
                :clearable="false"
                @input="changePeriodo()"
              />
              <div
                v-if="v$.evaluacion.id_periodo.$error"
                id="id_periodoInfo"
                class="text-danger text-right"
                style="font-size: 0.857rem;"
              >
                <p v-for="error of v$.evaluacion.id_periodo.$errors" :key="error.$uid">
                  {{ error.$message }}
                </p>
              </div>
            </BFormGroup>
          </BCol>

          <!-- col: NOMBRE -->
          <BCol
            cols="12"
            md="12"
          >
            <BFormGroup
              label="Nombre *"
              label-for="nombre"
            >
              <BFormInput
                id="nombre"
                v-model="evaluacion.nombre"
                placeholder="Ingrese el nombre"
                :state="v$.evaluacion.nombre.$error === true
                  ? false
                  : null"
                @blur.native="v$.evaluacion.nombre.$touch"
              />
              <BFormInvalidFeedback
                v-if="v$.evaluacion.nombre.$error"
                id="nombreInfo"
                class="text-right"
              >
                <p v-for="error of v$.evaluacion.nombre.$errors" :key="error.$uid">
                  {{ error.$message }}
                </p>
              </BFormInvalidFeedback>
            </BFormGroup>
          </BCol>

          <!-- col: Evaluación destino -->
          <BCol
            cols="12"
            md="12"
          >
            <BFormGroup
              label="Evaluación de destino *"
              label-for="id_evaluacion"
            >
              <div
                v-if="this.optionsEvaluaciones.length !== 0"
              >
                <v-select
                  v-model="evaluacion.id_evaluacion"
                  placeholder="Seleccione una evaluación..."
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="nombre"
                  :options="optionsEvaluaciones"
                  :reduce="option => option.id"
                  input-id="id_evaluacion"
                  :class="v$.evaluacion.id_evaluacion.$error === true
                    ? 'border-danger rounded'
                    : ''"
                  :clearable="false"
                />
                <div
                  v-if="v$.evaluacion.id_evaluacion.$error"
                  id="id_evaluacionInfo"
                  class="text-danger text-right"
                  style="font-size: 0.857rem;"
                >
                  <p v-for="error of v$.evaluacion.id_evaluacion.$errors" :key="error.$uid">
                    {{ error.$message }}
                  </p>
                </div>
              </div>
              <b-alert
                v-else
                variant="info"
                show
                class="text-center pb-1 pt-1"
              >
                <feather-icon
                  icon="InfoIcon"
                  class="mr-50"
                />
                ¡Ups! Primero necesitas crear una evaluación con porcentaje en el módulo de notas.
                Así podrás elegirla como evaluación de destino para tus evaluaciones acumulativas. 🤓
              </b-alert>
            </BFormGroup>
          </BCol>

          <!-- col: FECHA -->
          <BCol
            cols="12"
            md="12"
          >
            <BFormGroup
              label="Fecha *"
              label-for="fecha"
            >
              <b-form-datepicker
                v-model="evaluacion.fecha"
                id="fecha"
                placeholder="Abrir calendario"
                hide-header
                :date-format-options="{
                  year: 'numeric',
                  month: 'short',
                  day: '2-digit',
                  weekday: 'short'
                }"
                :min="min"
                :max="max"
                start-weekday="1"
                locale="es-CL"
                :date-disabled-fn="dateDisabledCalendar"
                label-help=""
                :state="v$.evaluacion.fecha.$error === true
                  ? false
                  : null"
                @blur.native="v$.evaluacion.fecha.$touch"
              />
              <!-- Mensajes Error Validación -->
              <BFormInvalidFeedback
                v-if="v$.evaluacion.fecha.$error"
                id="fechaInfo"
                class="text-right"
              >
                <p v-for="error of v$.evaluacion.fecha.$errors" :key="error.$uid">
                  {{ error.$message }}
                </p>
              </BFormInvalidFeedback>
            </BFormGroup>
          </BCol>

          <!-- col: Descripción -->
          <BCol
            cols="12"
            md="12"
          >
            <BFormGroup
              label="Descripción"
              label-for="descripcion"
            >
              <BFormInput
                id="descripcion"
                v-model="evaluacion.descripcion"
                placeholder="Ingrese una descripción como recordatorio"
                :state="v$.evaluacion.descripcion.$error === true
                  ? false
                  : null"
                @blur.native="v$.evaluacion.descripcion.$touch"
              />
              <BFormInvalidFeedback
                v-if="v$.evaluacion.descripcion.$error"
                id="descripcionInfo"
                class="text-right"
              >
                <p v-for="error of v$.evaluacion.descripcion.$errors" :key="error.$uid">
                  {{ error.$message }}
                </p>
              </BFormInvalidFeedback>
            </BFormGroup>
          </BCol>

        </BRow>

        <colLinea />
        <div class="text-right">
          <!-- Action Buttons -->
          <BButton
            v-if="evaluacion.id && $can('delete' , 'evaluaciones')"
            variant="outline-danger"
            class="mr-1"
            @click="removeEvaluacion"
          >
            Eliminar
          </BButton>

          <BButton
            variant="outline-secondary"
            class="mr-1"
            @click="closeModal"
          >
            Cancelar
          </BButton>

          <BButton
            v-if="$can('create' , 'evaluaciones') || $can('update', 'evaluaciones')"
            variant="primary"
            class="mr-25"
            @click="submitOption"
          >
            {{ title }}
          </BButton>
        </div>
      </b-form>
    </BOverlay>
  </BModal>
</template>

<script>

// ETIQUETAS
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BFormInvalidFeedback,
  BMedia, BButton, BAvatar, BOverlay, BFormDatepicker, BFormTimepicker,
  BFormTextarea, BModal, BInputGroup, BInputGroupAppend, BAlert,
  VBModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

import { mapGetters, mapActions } from 'vuex'

// CLEAVE
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

// FORMATOS
import { formatos } from '@/mixins/formatos'

// VALIDACIONES
import useVuelidate from '@vuelidate/core'
import { required, maxLength, email, helpers } from '@vuelidate/validators'

// COMPONENTES RECICLADOS
import colLinea from '../../../../components/Form/colLinea.vue'
import btnSubmit from '../../../../components/Form/btnSubmit.vue'

export default {
  components: {
    // ETIQUETAS
    BRow, BCol, BFormGroup, BFormInput, BForm, BFormInvalidFeedback,
    BMedia, BButton, BAvatar, BOverlay, BFormDatepicker, BFormTimepicker,
    BFormTextarea, BModal, BInputGroup, BInputGroupAppend, BAlert,
    VBModal,
    vSelect,
    Cleave,

    // COMPONENTES RECICLADO
    colLinea,
    btnSubmit,
  },
  directives: {
    'BModal': VBModal,
    Ripple,
  },
  mixins: [formatos],
  data() {
    return {
      cargando: false,
      periodosOption: [],
      optionsAsignaturas: [],
      optionsEvaluaciones: [],
      min: null,
      max: null,
    }
  },
  computed: {
    ...mapGetters({
      getUser: 'auth/user',
      getPeriodosEstablecimiento: 'periodos/getPeriodosEstablecimiento',
      getEvaluacionesParaSumativa: 'evaluaciones/getEvaluacionesParaSumativa',
    }),
  },
  props: {
    asignaturas: {
      type: Array,
      required: true,
    },
    nombreModal: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    evaluacion: {
      type: Object,
      required: true,
    },
    id_periodo_activo: {
      type: Number,
      required: false,
    },
  },
  watch: {
    asignaturas(asignaturas) {
      this.cargaAsignaturas(asignaturas)
    },
    evaluacion(val){
      this.changePeriodo()
    },
    id_periodo_activo(val) {
      this.changePeriodo()
      this.cargaEvaluaciones(this.evaluacion.id_asignatura)
    },
    'evaluacion.id_asignatura'(newVal) {
      this.cargaEvaluaciones(newVal)
    },
  },
  validations() {
    return {
      evaluacion: {
        fecha: {
          required: helpers.withMessage('El campo es requerido.', required),
        },
        nombre: {
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener un máximo de 50 caracteres.', maxLength(50)),
        },
        descripcion: {
          maxLength: helpers.withMessage('Debe tener un máximo de 150 caracteres.', maxLength(150)),
        },
        id_evaluacion: {
          required: helpers.withMessage('El campo es requerido.', required),
        },
        id_asignatura: {
          required: helpers.withMessage('El campo es requerido.', required),
        },
        id_periodo: {
          required: helpers.withMessage('El campo es requerido.', required),
        },
      }
    }
  },
  mounted() {
    this.cargarPeriodos(this.getUser.id_establecimiento)
    this.cargaAsignaturas(this.asignaturas)
    this.changePeriodo()
  },
  methods: {
    ...mapActions({
      fetchPeriodosEstablecimiento: 'periodos/fetchPeriodosEstablecimiento',
      removeEvaluaciones: 'evaluaciones/removeEvaluacionesSumativa',
      fetchEvaluacionesParaSumativa: 'evaluaciones/fetchEvaluacionesParaSumativa',
      fetchNotas: 'notas/fetchNotasSumativa',
    }),
    cargaAsignaturas(asignaturas) {
      this.optionsAsignaturas = []
      this.optionsAsignaturas = asignaturas

    },
    cargaEvaluaciones(id_asignatura) {
      if (id_asignatura) {

        const params = {
          id_asignatura,
          id_periodo: this.evaluacion.id_periodo
        }
        
        this.cargando = true
        this.fetchEvaluacionesParaSumativa(params).then(() => {
          this.optionsEvaluaciones = []
          if (this.getEvaluacionesParaSumativa.length > 0) {
            this.optionsEvaluaciones = this.getEvaluacionesParaSumativa
          }
          this.cargando = false
        })
      }
    },
    cargarPeriodos(id_establecimiento) {
      this.fetchPeriodosEstablecimiento(id_establecimiento).then(() => {
        this.periodosOption = []
        this.periodosOption = this.getPeriodosEstablecimiento
      })
    },
    changePeriodo() {
      // al cambiar periodo mostrar fechas min y max
      if (this.evaluacion.id_periodo !== null && typeof this.evaluacion.id_periodo !== 'undefined') {
        const periodo = this.getPeriodosEstablecimiento.find(pe => pe.id === this.evaluacion.id_periodo)
        this.min = periodo.fecha_inicio
        this.max = periodo.fecha_termino
      } else if (typeof this.id_periodo_activo !== 'undefined' ) {
        const periodo = this.getPeriodosEstablecimiento.find(pe => pe.id === this.id_periodo_activo)
        this.min = periodo.fecha_inicio
        this.max = periodo.fecha_termino
      }
    },
    submitOption() {
      this.v$.evaluacion.$touch()
      if (!this.v$.evaluacion.$invalid) {
        this.$emit('processForm', this.evaluacion)
        this.v$.evaluacion.$reset()
      }
    },
    removeEvaluacion() {
      this.$swal({
        title: 'Eliminar evaluación!',
        text: `Estás seguro que deseas eliminar la evaluación?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, elimínala!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        // this.spinner = true
        if (result.value) {
          if (this.evaluacion.id >= 0) {
            const data = {
              id: this.evaluacion.id,
              id_asignatura: this.evaluacion.id_asignatura,
            }
            this.removeEvaluaciones(data).then(() => {
              this.$swal({
                icon: 'success',
                title: 'Eliminada con éxito!',
                text: `La evaluación ha sido eliminada!`,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.fetchNotas(data.id_asignatura)
            })
            this.closeModal()
          }
        } else {
          this.spinner = false
        }
      })
    },
    closeModal() {
      if (this.evaluacion.id >= 0) {
        this.$bvModal.hide('evaluacion-acumulativa-update')
      } else {
        this.$bvModal.hide('modal-evaluacion-acumulativa-create')
      }
      this.v$.evaluacion.$reset()
      this.$emit('resetData')
    },
    // CALENDARIO
    dateDisabledCalendar(ymd, date) {
      // Disable weekends (Sunday = `0`, Saturday = `6`) and
      // disable days that fall on the 13th of the month
      const weekday = date.getDay()
      const day = date.getDate()
      // Return `true` if the date should be disabled
      // return weekday === 0 || weekday === 6 || day === 1
      return weekday === 0 || weekday === 6
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
