<template>
  <realizadasForm
    :nombreModal="modal"
    title="Editar registro de acciones realizadas por el profesor o profesora de aula"
    :data="data.item"
    @processForm="editar"
  />
</template>

<script>

import { mapActions } from 'vuex'
import { toast, swal } from '@/mixins/alerts'
import realizadasForm from './RealizadasForm.vue'

export default {
  components: {
    realizadasForm,
  },
  mixins: [toast, swal],
  props: {
    data: {
      type: Object,
      required: true,
    },
    modal: {
      type: String,
      required: true,
    },
  },
  methods: {
    ...mapActions({
      updateRealizada: 'III_1_b_acciones_realizadas/updateRealizada',
      fetchRealizadas: 'III_1_b_acciones_realizadas/fetchRealizadas',
    }),
    async editar(apoyos) {
      const res = await this.updateRealizada(apoyos)
      if (res.status === 'success') {
        const params = {
          idCurso: apoyos.idCurso,
          tipo: apoyos.tipo,
        }
        await this.fetchRealizadas(params)
        
        this.$toastSuccess(
          'Registro de acciones realizadas guardado 👍', 
          'El registro de acciones realizadas fue editado con éxito!'
        )
        this.$bvModal.hide(this.modal)
      } else {
        this.$swalError('Error al editar el registro de acción realizadas!', res.message)
      }
    },

    // editar(realizada, acciones) {
    //   let accionesArray = []
    //   acciones.forEach(accion => {
    //     accionesArray.push({
    //       fecha: accion.fecha,
    //       horas: accion.horas,
    //       aula: 1,
    //       actividades: accion.actividades,
    //       firma: 0,
    //     })
    //   })

    //   const dataRealizada = {
    //     id: realizada.id,
    //     observaciones: realizada.observaciones,
    //     id_plan_apoyo: realizada.id_plan_apoyo,
    //     tipo: 1, // tipo profesor de aula
    //     acciones: accionesArray,
    //   }
    //   this.updateRealizada(dataRealizada).then(() => {
    //     const statusRealizadas = store.state.III_1_b_acciones_realizadas.status
    //     if (statusRealizadas === 'success') {
    //       const data = {
    //         idCurso: this.idCurso,
    //         tipo: 1, // tipo profesor de aula
    //       }
    //       this.fetchRealizadas(data)
    //       this.$toast({
    //         component: ToastificationContent,
    //         props: {
    //           title: 'Registro de acciones guardado 👍',
    //           icon: 'CheckIcon',
    //           text: 'El registro de acciones fue editado con éxito!',
    //           variant: 'success',
    //         },
    //       },
    //       {
    //         position: 'bottom-right',
    //         timeout: 4000,
    //       })
    //       this.$bvModal.hide(this.modal)
    //     }
    //     else {
    //       this.$swal({
    //         title: 'Error!',
    //         text: store.state.III_1_b_acciones_realizadas.message,
    //         icon: 'error',
    //         customClass: {
    //           confirmButton: 'btn btn-primary',
    //         },
    //         buttonsStyling: false,
    //       })
    //     }
    //   })
    // },
  },
}
</script>
