var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BModal',{attrs:{"id":_vm.nombreModal,"title":_vm.title,"centered":"","size":"lg","no-close-on-backdrop":"","hide-footer":true},on:{"shown":_vm.onModalOpen}},[_c('div',{staticClass:"d-flex justify-content-between mb-1"},[_c('div',{staticClass:"mt-50 text-secondary"}),_c('div',{staticClass:"mr-1 mt-50 text-muted small"},[_vm._v(" Campos obligatorios "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('BOverlay',{attrs:{"show":_vm.configs.cargando,"spinner-variant":"primary","variant":"semi-dark"}},[_c('BRow',[_c('BCol',{attrs:{"id":"col-PROFESOR-ESPECIALIZADO","cols":"12"}},[_c('BFormGroup',{attrs:{"label-for":"id_persona_rol"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Docente "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[(_vm.configs.loadingDocentes)?_c('v-select',{attrs:{"placeholder":"Cargando docentes...","disabled":true}}):_c('v-select',{class:_vm.v$.equipo.id_persona_rol.$error === true
                ? 'border-danger rounded'
                : '',attrs:{"placeholder":"Seleccione un docente ...","label":"nombre","options":_vm.docentesOption,"reduce":function (option) { return option.id; },"input-id":"id"},on:{"input":function($event){return _vm.changePersonaRol()}},model:{value:(_vm.equipo.id_persona_rol),callback:function ($$v) {_vm.$set(_vm.equipo, "id_persona_rol", $$v)},expression:"equipo.id_persona_rol"}}),(_vm.v$.equipo.id_persona_rol.$error)?_c('div',{staticClass:"text-danger text-right",staticStyle:{"font-size":"0.857rem"},attrs:{"id":"id_persona_rolInfo"}},_vm._l((_vm.v$.equipo.id_persona_rol.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('BCol',{attrs:{"id":"col-telefono","cols":"12"}},[_c('BFormGroup',{attrs:{"label-for":"telefono"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Teléfono "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('BInputGroup',{class:_vm.v$.equipo.telefono.$error === true
                ? 'border-danger rounded'
                : '',attrs:{"size":"md","prepend":"+"}},[_c('BFormInput',{attrs:{"id":"telefono","placeholder":"Ingrese un teléfono"},on:{"keyup":function($event){_vm.equipo.telefono = _vm.formatSoloNumerosMaxLenght(_vm.equipo.telefono, 11)}},model:{value:(_vm.equipo.telefono),callback:function ($$v) {_vm.$set(_vm.equipo, "telefono", $$v)},expression:"equipo.telefono"}})],1),(_vm.v$.equipo.telefono.$error)?_c('div',{staticClass:"text-danger text-right",staticStyle:{"font-size":"0.857rem"},attrs:{"id":"telefonoInfo"}},_vm._l((_vm.v$.equipo.telefono.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('BCol',{attrs:{"id":"col-correo","cols":"12"}},[_c('BFormGroup',{attrs:{"label-for":"correo"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Correo electrónico "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('BFormInput',{attrs:{"id":"correo","placeholder":"Ingrese un correo","state":_vm.v$.equipo.correo.$error === true
              ? false
              : null},model:{value:(_vm.equipo.correo),callback:function ($$v) {_vm.$set(_vm.equipo, "correo", $$v)},expression:"equipo.correo"}}),(_vm.v$.equipo.correo.$error)?_c('BFormInvalidFeedback',{staticClass:"text-right",attrs:{"id":"correoInfo"}},_vm._l((_vm.v$.equipo.correo.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('BCol',{attrs:{"id":"col-ASIGNATURAS","cols":"12"}},[_c('BFormGroup',{attrs:{"label-for":"id_especialidad"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Asignatura "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[(_vm.configs.loadingAsignaturas)?_c('v-select',{attrs:{"placeholder":"Cargando asignaturas...","disabled":true}}):_c('v-select',{class:_vm.v$.equipo.id_especialidad.$error === true
                ? 'border-danger rounded'
                : '',attrs:{"placeholder":"Seleccione una asignatura ...","label":"nombre_asignatura","options":_vm.asignaturasOption,"reduce":function (option) { return option.id; },"input-id":"id"},on:{"input":function($event){return _vm.changeAsignatura()}},model:{value:(_vm.equipo.id_especialidad),callback:function ($$v) {_vm.$set(_vm.equipo, "id_especialidad", $$v)},expression:"equipo.id_especialidad"}}),(_vm.v$.equipo.id_especialidad.$error)?_c('div',{staticClass:"text-danger text-right",staticStyle:{"font-size":"0.857rem"},attrs:{"id":"id_especialidadInfo"}},_vm._l((_vm.v$.equipo.id_especialidad.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1)],1),_c('colLinea'),_c('div',{staticClass:"text-right"},[_c('BButton',{staticClass:"mr-1",attrs:{"variant":"outline-secondary"},on:{"click":_vm.closeModal}},[_vm._v(" Cancelar ")]),_c('BButton',{staticClass:"mr-25",attrs:{"variant":"primary","disabled":this.v$.equipo.$errors.length > 0},on:{"click":_vm.submitOption}},[_vm._v(" Guardar Docente ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }