<template>
  <BModal
    :id="nombreModal"
    :title="title"
    centered
    size="lg"
    :hide-footer="true"
    @shown="onModalOpen"
  >
    <div class="d-flex justify-content-between mb-1">
      <div class="mt-50 text-secondary">
      </div>
      <div class="mr-1 mt-50 text-muted small">
        Campos obligatorios <span class="text-danger">*</span>
      </div>
    </div>
    <BOverlay
      :show="configs.cargando"
      spinner-variant="primary"
      variant="semi-dark"
    >
      <BRow>
        <BCol id="col-PERIODO" cols="12">
          <BFormGroup label-for="estrategia">
            <template #label>
              Estrategia (estrategias del menú 'II 1 b') <span class="text-danger">*</span>
            </template>
            <v-select
              v-if="configs.loadingEstrategias"
              
              placeholder="Cargando estrategias..."
              :disabled="true"
            />
            <v-select
              v-else
              v-model="aplicacion.id_detalle_estrategia"
              :placeholder="estrategiasDisponiblesOption.length === 0
                ? 'No hay estrategias disponibles...'
                : 'Seleccione una estrategia...'"
              
              label="nombre"
              :options="estrategiasDisponiblesOption"
              :reduce="option => option.id"
              input-id="id"
              :class="v$.aplicacion.id_detalle_estrategia.$error === true
                ? 'border-danger rounded'
                : ''"
              :disabled="estrategiasDisponiblesOption.length === 0 || aplicacion.id !== null"
            />
            <BAlert
              v-if="!configs.loadingEstrategias && estrategiasDisponiblesOption.length === 0"
              class="pl-50 pt-25 pb-25 pr-50 mt-50 mb-25"
              variant="primary"
              show
            >
              Para crear una nueva estrategia, dirígase al menú "II 1 b".
            </BAlert>
            <div v-if="v$.aplicacion.id_detalle_estrategia.$error"
              id="asistentesInfo"
              class="text-danger text-right"
              style="font-size: 0.857rem;"
            >
              <p v-for="error of v$.aplicacion.id_detalle_estrategia.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
            </div>
          </BFormGroup>
        </BCol>

        <BCol id="col-ACCION" cols="12">
          <BFormGroup label-for="desarrollo">
            <template #label>
              Acción desarrollada <span class="text-danger">*</span>
            </template>
            <BFormTextarea
              id="desarrollo"
              placeholder="Ingrese la acción desarrollada"
              v-model="aplicacion.acciones"
              rows="6"
              maxlength="550"
              :state="v$.aplicacion.acciones.$error === true
              ? false
              : null"
            />
            <small class="text-muted text-right d-block mt-25">{{ aplicacion.acciones ? aplicacion.acciones.length : 0 }} / 550</small>
            <BFormInvalidFeedback
              v-if="v$.aplicacion.acciones.$error"
              id="accionInfo"
              class="text-right"
            >
              <p v-for="error of v$.aplicacion.acciones.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
            </BFormInvalidFeedback>
          </BFormGroup>
        </BCol>

        <BCol id="col-EVALUACIÓN" cols="12">
          <BFormGroup label-for="evaluacion">
            <template #label>
              Evaluación (resultados) de las estrategias aplicadas <span class="text-danger">*</span>
            </template>
            <BFormTextarea
              id="evaluacion"
              v-model="aplicacion.evaluacion"
              placeholder="Ingrese la evaluación"
              :state="v$.aplicacion.evaluacion.$error === true
                ? false
                : null"
              rows="6"
              maxlength="550"
            />
            <small class="text-muted text-right d-block mt-25">{{ aplicacion.evaluacion ? aplicacion.evaluacion.length : 0 }} / 550</small>
            <BFormInvalidFeedback v-if="v$.aplicacion.evaluacion.$error"
              id="evaluacionInfo"
              class="text-right"
            >
              <p v-for="error of v$.aplicacion.evaluacion.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
            </BFormInvalidFeedback>
          </BFormGroup>
        </BCol>
        
      </BRow>

      <colLinea />

      <div class="text-right">
        <BButton variant="outline-secondary" class="mr-1" @click="closeModal">
          Cancelar
        </BButton>

        <BButton
          variant="primary"
          class="mr-25"
          :disabled="this.v$.aplicacion.$errors.length > 0"
          @click="submitOption"
        >
          Guardar Aplicación
        </BButton>
      </div>

    </BOverlay>
  </BModal>
</template>

<script>

// Etiquetas //
import {
  BRow, BCol, BFormGroup, BFormInvalidFeedback, BButton, BOverlay, BFormTextarea,
  BModal, VBModal, BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

import { mapGetters, mapActions } from 'vuex'

// Validaciones //
import useVuelidate from '@vuelidate/core'
import { required, maxLength, helpers } from '@vuelidate/validators'

import colLinea from '@/views/components/Form/colLinea.vue'

export default {
  components: {
    BRow, BCol, BFormGroup, BFormInvalidFeedback, BButton, BOverlay, BFormTextarea,
    BModal, VBModal, BAlert,
    vSelect,
    colLinea,
  },
  directives: {
    'BModal': VBModal,
    Ripple,
  },
  data() {
    return {
      configs: {
        cargando: false,
        loadingEstrategias: true,
      },
      aplicacion: {},
      estrategiasDisponiblesDisabled: false,
      estrategiasDisponiblesOption: [],
    }
  },
  computed: {
    ...mapGetters({ getLibroSelected: 'libros/getLibroSelected' }),
  },
  props: {
    nombreModal: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions({
      fetchEstrategiasDisponibles: 'II_1_b_estrategias/fetchEstrategiasDisponibles',
    }),
    async onModalOpen() {
      await Promise.all([
        this.cargaEstrategiasDisponibles(),
      ])
      this.aplicacion = { ...this.data }
    },
    async cargaEstrategiasDisponibles() {
      const { data } = await this.fetchEstrategiasDisponibles(this.getLibroSelected.id)
      this.estrategiasDisponiblesOption = []
      this.estrategiasDisponiblesOption = data.map(item => ({
        id: item.id,
        nombre: `${this.cutText(7, item.nombre_periodo)} (${this.cutText(12, item.nombre)}) - ${this.cutText(69, item.estrategia)}`,
      }));
      this.configs.loadingEstrategias = false
    },
    cutText(numberCut, text) {
      let texto = text
      if (text.length >= numberCut) {
        texto = `${text.slice(0, numberCut)}`
      }
      return texto
    },
    submitOption() {
      this.v$.aplicacion.$touch()
      if (!this.v$.aplicacion.$invalid) {
        const params = {
          ...this.aplicacion,
          id_curso: this.getLibroSelected.id
        }
        this.$emit('processForm', params)
        this.v$.aplicacion.$reset()
      }
    },
    closeModal() {
      this.$bvModal.hide(this.nombreModal)
      this.v$.aplicacion.$reset()
      if (this.aplicacion.id === null) {
        this.$emit('resetData')
      }
    },
  },
  validations() {
    return {
      aplicacion: {
        id_detalle_estrategia: {
          required: helpers.withMessage('El campo es requerido.', required),
        },
        acciones: {
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener un máximo de 550 caracteres.', maxLength(550)),
        },
        evaluacion: {
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener un máximo de 550 caracteres.', maxLength(550)),
        },
      }
    }
  },
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
}
</script>
