<template>
  <CoordinacionForm
    :nombreModal="modal"
    title="Editar coordinador del programa al equipo de aula"
    :data="item"
    @processForm="editar"
  />
</template>

<script>

import { mapActions } from 'vuex'
import { toast, swal } from '@/mixins/alerts'
import CoordinacionForm from './CoordinacionForm.vue'

export default {
  components: {
    CoordinacionForm,
  },
  mixins: [toast, swal],
  props: {
    modal: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions({
      fetchEquipo: 'I_1_equipo/fetchEquipo',
      updateEquipo: 'I_1_equipo/updateEquipo',
    }),
    async editar(equipo) {
      const res = await this.updateEquipo(equipo)
      if (res.status === 'success') {
        
        this.$toastSuccess(
          'Coordinador actualizado 👍', 
          'El coordinador fue editado con éxito!'
        )
        this.$bvModal.hide(this.modal)
      } else {
        this.$swalError('Error al editar el coordinador!', res.message)
      }
    },
  },
}
</script>
