<template>
  <LogrosAprendizajeForm
    :nombreModal="modal"
    title="Editar logros de aprendizaje"
    :data.sync="item"
    @processForm="editar"
  />
</template>

<script>

import { mapActions } from 'vuex'
import { toast, swal } from '@/mixins/alerts'
import LogrosAprendizajeForm from './LogrosAprendizajeForm.vue'

export default {
  components: {
    LogrosAprendizajeForm,
  },
  mixins: [toast, swal],
  props: {
    item: {
      type: Object,
      required: true,
    },
    modal: {
      type: String,
      required: true,
    },
  },
  methods: {
    ...mapActions({
      fetchLogros: 'III_3_logros/fetchLogros',
      updateLogro: 'III_3_logros/updateLogro',
    }),
    async editar(logro) {
      const res = await this.updateLogro(logro)
      if (res.status === 'success') {
        await this.fetchLogros(logro.id_curso)
        this.$toastSuccess(
          'Logro de aprendizaje guardado 👍', 
          'El logro de aprendizaje fue editado con éxito!'
        )
        this.$bvModal.hide(this.modal)
      } else {
        this.$swalError('Error al editar el logro de aprendizaje!', res.message)
      }
    },
  },
}
</script>
