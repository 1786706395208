<template>
  <apoyosForm
    nombreModal="modal-create"
    title="Registrar apoyo"
    :data.sync="data"
    @processForm="agregar"
    @resetData="resetData"
  />
</template>

<script>

import { mapActions } from 'vuex'
import { toast, swal } from '@/mixins/alerts'
import apoyosForm from './ApoyosForm.vue'

export default {
  components: {
    apoyosForm,
  },
  mixins: [toast, swal],
  data() {
    return {
      data: this.getDefaultData(),
    }
  },
  methods: {
    ...mapActions({
      fetchApoyos: 'II_2_b_apoyos/fetchApoyos',
      createApoyo: 'II_2_b_apoyos/addApoyo',
    }),
    getDefaultData() {
      return {
        id: null,
        id_asignatura: null,
        horas_aula: 0,
        horas_fuera: 0,
        apoyos: '',
      }
    },
    async agregar(apoyo) {
      const res = await this.createApoyo(apoyo)
      if (res.status === 'success') {
        await this.fetchApoyos(apoyo.id_curso)
        this.resetData()
        this.$toastSuccess(
          'Apoyo registrado 👍', 
          'El apoyo fue guardado con éxito!'
        )
        this.$bvModal.hide('modal-create')
      } else {
        this.$swalError('Error al crear el apoyo!', res.message)
      }
    },
    resetData() {
      this.data = this.getDefaultData()
    }
  },
}
</script>
