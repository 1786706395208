<template>
  <BOverlay
    :show="spinner"
    spinner-variant="primary"
    variant="semi-dark"
  >
    <BRow class="mb-3">
      <BCol
        lg="2"
        md="3"
        sm="3"
        class="my-50"
      >
        <!-- BOTON MOSTRAR -->
        <btnMostrar
          :pageOptions.sync="pageOptions"
          :perPage.sync="perPage"
          :total.sync="items.length"
        />
      </BCol>
      <BCol
        lg="6"
        md="5"
        sm="5"
        class="my-50"
      >
        <!-- FILTRO -->
        <!-- <inputFiltro
          :filter.sync="filter"
        /> -->
      </BCol>
      <BCol
        md="4"
        sm="4"
        class="my-50"
      >
        <!-- CREAR -->
        <AplicacionCreate />
        <!-- BOTON CREAR -->
        <btnCrearModal
          accion="Registrar"
          texto="Acción"
          modal="modal-create"
          :modulo="nombre_permiso"
        />
      </BCol>

      <BCol cols="12" style="min-height: 490px !important;">
        <BTable
          striped
          small
          hover
          noCollapse
          responsive
          bordered
          class="rounded"
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :busy="cargando"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <!-- Cargando -->
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <spinner />
            </div>
          </template>

          <!-- acciones -->
          <template #cell(acciones)="data">
            <VerMas
              :id="`acciones-${data.item.id}`"
              :texto="data.item.acciones"
              :longText="180"
            />
          </template>

          <!-- evaluacion -->
          <template #cell(evaluacion)="data">
            <VerMas
              :id="`evaluacion-${data.item.id}`"
              :texto="data.item.evaluacion"
              :longText="180"
            />
          </template>

          <!-- Column: Action -->
          <template #cell(actions)="data">
            <AplicacionUpdate
              :modal="'modal-update-'+data.item.id"
              :item="data.item"
            />
            <colAccionesBtnes
              :modal="'modal-update-'+data.item.id"
              :data="data"
              :modulo="nombre_permiso"
              @processRemove="remove(data.item)"
            />
          </template>
        </BTable>
        <BAlert
          v-if="!cargando && items.length === 0"
          variant="primary"
          show
          class="text-center pt-25 pb-25"
        >
          <div class="alert-body">
            <span>No existen acciones registradas.</span>
          </div>
        </BAlert>
      </BCol>

      <BCol cols="12" >
        <BPagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </BCol>

    </BRow>
  </BOverlay>
</template>

<script>

// ETIQUETAS
import {
  BTable, BRow, BCol, BPagination, BOverlay, BAlert,
} from 'bootstrap-vue'

// VUEX
import { mapGetters, mapActions } from 'vuex'

// COMPONENTES RECICLADOS
import btnCrearModal from '@/views/components/List/btnCrearModal.vue'
import btnMostrar from '@/views/components/List/btnMostrar.vue'
import colAccionesBtnes from '@/views/components/List/colAccionesBtnes.vue'
import spinner from '@/views/components/spinner.vue'
import VerMas from '@/views/components/List/verMas.vue'

// COMPONENTES HIJOS
import AplicacionCreate from './Aplicacion/AplicacionCreate.vue'
import AplicacionUpdate from './Aplicacion/AplicacionUpdate.vue'

// FORMATOS
import { formatos } from '@/mixins/formatos'
import { toast, swal } from '@/mixins/alerts'

export default {
  components: {
    BTable, BRow, BCol, BPagination, BOverlay, BAlert,

    // COMPONENTES RECICLADOS
    spinner,
    btnMostrar,
    btnCrearModal,
    colAccionesBtnes,
    VerMas,

    // COMPONENTES HIJOS
    AplicacionCreate,
    AplicacionUpdate,
  },
  mixins: [formatos, toast, swal],
  data() {
    return {
      nombre_permiso: 'pieIII1A',
      cargando: true,
      spinner: false,
      items: [],
      selectedchk: [],
      chkTodo: null,
      checked: null,

      perPage: 10,
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: '',
      filterOn: [],
      pageOptions: [10, 25, 50],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'estrategia',
          label: 'Periodo (estrategia)',
          sortable: true,
          thStyle: {
            width: '200px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'acciones',
          label: 'Acciones Desarrolladas',
          sortable: true,
          thStyle: {
            width: '200px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'evaluacion',
          label: 'Evaluación (resultados) de las estrategias aplicadas',
          sortable: true,
          thStyle: {
            width: '200px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        // {
        //   key: 'fecha_creacion',
        //   label: 'Fecha Creación',
        //   sortable: true,
        //   tdClass: 'text-center',
        //   thStyle: {
        //     width: '100px !important',
        //     'text-align': 'center',
        //     display: 'table-cell',
        //     'vertical-align': 'middle',
        //   },
        // },
      ],
      fieldAcciones: [
        {
          key: 'actions',
          label: 'acciones',
          tdClass: 'text-center',
          thStyle: {
            width: '80px !important',
            'text-align': 'center',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      getLibroSelected: 'libros/getLibroSelected',
      getAplicaciones: 'III_1_a_acciones_de_aplicacion/getAplicaciones',
    }),
  },
  watch: {
    getAplicaciones(val) {
      this.totalRows = val.length
      this.items = []
      this.items = this.getAplicaciones
    },
    getLibroSelected(val) {
      this.cargarAplicaciones()
    },
  },
  mounted() {
    this.inicialize()
  },
  methods: {
    ...mapActions({
      fetchAplicaciones: 'III_1_a_acciones_de_aplicacion/fetchAplicaciones',
      removeAplicacion: 'III_1_a_acciones_de_aplicacion/removeAplicacion',
    }),
    async inicialize () {
      await Promise.all([
        this.cargarAplicaciones(),
        this.setTableList()
      ])
    },
    setTableList() {
      if (this.$can('update', this.nombre_permiso)
        || this.$can('delete', this.nombre_permiso)
      ) {
        this.fields.push(this.fieldAcciones)
      }
    },
    async cargarAplicaciones() {
      const { status } = await this.fetchAplicaciones(this.getLibroSelected.id);
      if (status === 'error') {
        this.$toastError('Error al cargar los datos !', res.message);
      }
      this.cargando = false
    },
    async remove(aplicacion) {
      const html = this.formatHTMLSweetEliminar('la aplicacion', aplicacion.acciones);
      
      const result = await this.$swalConfirm(
        'Eliminar aplicacion!', 
        html, 
        'Sí, elimínala!', 
      );
      if (result.value) {
        this.spinner = true;
        const params = {
          id: aplicacion.id,
          id_curso: this.getLibroSelected.id,
        };
        const res = await this.removeAplicacion(params);
        if (res.status === 'success') {
          this.$swalSuccess(
            'Eliminada con éxito!',
            'La aplicacion ha sido eliminada!'
          );
        } else {
          this.$swalError('Error al eliminar la aplicacion', res.message);
        }
        this.spinner = false;
      }
    },

    // Vuexy
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
