<template>
  <observaciones-form
    nombreModal="modal-observacion-create"
    title="Crear observación"
    :estudiantes.sync="estudiantes"
    :asignaturas.sync="asignaturas"
    :asignaturaIndex.sync="asignaturaIndex"
    :tipos.sync="tipos"
    :observacion.sync="data"
    @processForm="agregar"
    @resetData="resetData"
  />
</template>

<script>
import { mapActions } from 'vuex'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

// FORMATOS
import { formatos } from '@/mixins/formatos'

import ObservacionesForm from './ObservacionesForm.vue'

export default {
  components: {
    ObservacionesForm,
  },
  directives: {
    Ripple,
  },
  mixins: [formatos],
  data() {
    return {
      data: {
        fecha: this.formatoFechaHoyCalendar(),
        tipo: null,
        observacion: '',
        id_asignatura: null,
        id_persona_rol_estudiante: null,
      },
    }
  },
  props: {
    estudiantes: {
      type: Array,
      required: true,
    },
    asignaturas: {
      type: Array,
      required: true,
    },
    asignaturaIndex: {
      type: Number,
      required: true,
    },
    tipos: {
      type: Array,
      required: true,
    },
  },
  methods: {
    ...mapActions({
      createObservacion: 'observaciones/addObservacion',
    }),
    agregar(observacion) {
      this.createObservacion(observacion).then(() => {
        const statusObservaciones = store.state.observaciones.status
        if (statusObservaciones === 'success') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Observación guardada 👍',
              icon: 'CheckIcon',
              text: 'La observación fue ingresada con éxito!',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
            timeout: 4000,
          })
          this.$bvModal.hide('modal-observacion-create')
          this.resetData()
        }
        else {
          this.$swal({
            title: 'Error!',
            text: store.state.observaciones.message,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
      })
    },
    resetData() {
      this.data.fecha = this.formatoFechaHoyCalendar()
      this.data.tipo = null
      this.data.observacion = ''
      this.data.id_persona_rol_estudiante = null
    },
  },
}
</script>
