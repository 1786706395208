<template>
  <div>
    <BButton
      v-if="this.getUser.id_rol !== 17
        ? this.id_persona_rol_docente === this.getUser.id_persona_rol
        : true"
      :block="true"
      variant="relief-primary"
      class="mr-0 btn-sm mt-25 ml-0 mr-md-0 mr-50"
      title="Guardar"
      @click="submit('Guardar')"
    >
      <span class="d-inline d-md-none mr-25">
        Guardar
      </span>
      <feather-icon
        icon="SaveIcon"
      />
    </BButton>

      <!-- v-if="!disabled" -->
    <BButton
      v-if="$can('firmar', 'asistencias')"
      variant="relief-success ml-0"
      class="mr-0 btn-sm mt-25"
      :block="true"
      title="Firmar"
      @click="submit('Firmar')"
    >
      <span class="d-inline d-md-none d-sm-inline mr-25">
        Firmar
      </span>
      <feather-icon
        icon="FeatherIcon"
      />
    </BButton>
  </div>
</template>

<script>

import {
  BButton,
} from 'bootstrap-vue'

import { mapGetters } from 'vuex'

export default {
  components: {
    BButton,
  },
  computed: {
    ...mapGetters({
      getUser: 'auth/user',
    }),
  },
  props: {
    id_persona_rol_docente: {
      type: Number,
      required: true,
    },
    num_bloque: {
      type: Number,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    // NEWS
    can: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    submit(boton) {
      const data = {
        num_bloque: this.num_bloque,
        items: this.items,
        boton,
      }
      this.$emit('processBtn', data)
    },
  },
}
</script>
