<template>
  <LogrosAprendizajeForm
    nombreModal="modal-create"
    title="Registrar logros de aprendizaje"
    :data.sync="data"
    @processForm="agregar"
    @resetData="resetData"
  />
</template>

<script>
import { mapActions } from 'vuex'
import { toast, swal } from '@/mixins/alerts'
import LogrosAprendizajeForm from './LogrosAprendizajeForm.vue'

export default {
  components: {
    // COMPONENTES
    LogrosAprendizajeForm,
  },
  mixins: [toast, swal],
  data() {
    return {
      data: this.getDefaultData(),
    }
  },
  methods: {
    ...mapActions({
      createLogro: 'III_3_logros/addLogro',
      fetchLogros: 'III_3_logros/fetchLogros',
    }),
    getDefaultData() {
      return {
        id: null,
        id_persona: '',
        logros: '',
        comentarios: '',
      }
    },
    async agregar(logro) {
      const res = await this.createLogro(logro)
      if (res.status === 'success') {
        await this.fetchLogros(logro.id_curso)
        this.resetData()
        this.$toastSuccess(
          'Logro de aprendizaje registrado 👍', 
          'El logro de aprendizaje fue ingresado con éxito!'
        )
        this.$bvModal.hide('modal-create')
      } else {
        this.$swalError('Error al crear el logro de aprendizaje!', res.message)
      }
    },
    resetData() {
      this.data = this.getDefaultData()
    },
  },
}
</script>
