<template>
  <reunionesForm
    nombreModal="modal-create"
    title="Coordinar reunión"
    :data.sync="data"
    @processForm="agregar"
    @resetData="resetData"
  />
</template>

<script>
import { mapActions } from 'vuex'
import { toast, swal } from '@/mixins/alerts'
import { formatos } from '@/mixins/formatos'
import reunionesForm from './ReunionesForm.vue'

export default {
  components: {
    reunionesForm,
  },
  mixins: [toast, swal, formatos],
  data() {
    return {
      data: this.getDefaultData(),
    }
  },
  methods: {
    ...mapActions({
      fetchReuniones: 'I_2_reuniones/fetchReuniones',
      createReunion: 'I_2_reuniones/addReunion',
    }),
    getDefaultData() {
      return {
        id: null,
        fecha: this.formatDate(new Date()),
        horario: '08:00',
        personas: [],
        acuerdos: '',
        id_periodo: null,
        id_cursos: null,
      }
    },
    async agregar(reunion) {
      const res = await this.createReunion(reunion)
      if (res.status === 'success') {
        this.resetData()
        this.$toastSuccess(
          'Regunión ingresada 👍', 
          'La reunión fue guardado con éxito!'
        )
        this.$bvModal.hide('modal-create')
      } else {
        this.$swalError('Error al crear la reunión!', res.message)
      }
    },
    resetData() {
      this.data = this.getDefaultData()
    },
  },
}
</script>
