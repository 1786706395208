<template>
  <BTabs
    fill
    vertical
    class="mt-2"
    nav-wrapper-class="nav-vertical"
    active-tab-class="font-weight-bold"
  >
    <BTab active title="Docentes" lazy>
      <Docentes />
    </BTab>
    <BTab title="Docentes PIE" lazy>
      <DocentesPie />
    </BTab>
    <BTab title="Especialistas" lazy>
      <Especialistas />
    </BTab>
    <BTab title="Coordinación" lazy>
      <Coordinacion />
    </BTab>
  </BTabs>
</template>

<script>
import { BTabs, BTab, BCardText, BCard } from 'bootstrap-vue'
import Docentes from './IdentificacionEquipo/Docentes.vue'
import Especialistas from './IdentificacionEquipo/Especialistas.vue'
import DocentesPie from './IdentificacionEquipo/DocentesPie.vue'
import Coordinacion from './IdentificacionEquipo/Coordinacion.vue'

export default {
  components: {
    BTabs, BTab, BCardText, BCard,
    Docentes,
    Especialistas,
    DocentesPie,
    Coordinacion,
  },
}
</script>
