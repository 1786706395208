<template>
  <BOverlay
    :show="spinner"
    spinner-variant="primary"
    variant="semi-dark"
  >
    <BRow class="mb-3">
      <BCol
        lg="2"
        md="3"
        sm="3"
        class="my-50"
      >
        <!-- BOTON MOSTRAR -->
        <btnMostrar
          :pageOptions.sync="pageOptions"
          :perPage.sync="perPage"
          :total.sync="items.length"
        />
      </BCol>
      <BCol
        lg="6"
        md="5"
        sm="5"
        class="my-50"
      >
        <!-- FILTRO -->
        <!-- <inputFiltro
          :filter.sync="filter"
        /> -->
      </BCol>
      <BCol
        md="4"
        sm="4"
        class="my-50"
      >
        <!-- CREAR  -->
        <ApoyosCreate />
        <!-- BOTON CREAR -->
        <btnCrearModal
          accion="Registrar"
          texto="Apoyo"
          modal="modal-create"
          :modulo="nombre_permiso"
        />
      </BCol>

      <BCol cols="12" style="min-height: 490px !important;">
        <BTable
          striped
          small
          hover
          noCollapse
          bordered
          class="rounded"
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :busy="cargando"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >

          <!-- Cargando -->
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <spinner />
            </div>
          </template>

          <!-- col HORAS_AULA -->
          <template #cell(horas_aula)="data">
            {{ data.item.horas_aula }} hrs
          </template>
          <!-- col HORAS_FUERA -->
          <template #cell(horas_fuera)="data">
            {{ data.item.horas_fuera }} hrs
          </template>

          <!-- col ACCIONES -->
          <template #cell(acciones)="data">

            <ApoyosUpdate
              :modal="'modal-update-'+data.item.id"
              :item="data.item"
            />

            <colAccionesBtnes
              :modal="'modal-update-'+data.item.id"
              :data="data"
              :modulo="nombre_permiso"
              @processRemove="remove(data.item)"
            />
          </template>
        </BTable>
        <BAlert
          v-if="!cargando && items.length === 0"
          variant="primary"
          show
          class="text-center pt-25 pb-25"
        >
          <div class="alert-body">
            <span>No existen apoyos registrados.</span>
          </div>
        </BAlert>
      </BCol>

      <BCol cols="12" >
        <BPagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </BCol>

    </BRow>
  </BOverlay>
</template>

<script>
// ETIQUETAS
import {
  BTable, BRow, BCol, BPagination, BOverlay, BAlert,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'

// COMPONENTES RECICLADOS
import spinner from '@/views/components/spinner.vue'
import btnMostrar from '@/views/components/List/btnMostrar.vue'
import btnCrearModal from '@/views/components/List/btnCrearModal.vue'
import colAccionesBtnes from '@/views/components/List/colAccionesBtnes.vue'

// HIJOS
import ApoyosCreate from './Apoyos/ApoyosCreate.vue'
import ApoyosUpdate from './Apoyos/ApoyosUpdate.vue'

// FORMATOS
import { formatos } from '@/mixins/formatos'
import { toast, swal } from '@/mixins/alerts'

export default {
  components: {
    BTable, BRow, BCol, BPagination, BOverlay, BAlert,

    // COMPONENTES RECICLADOS
    spinner,
    btnMostrar,
    btnCrearModal,
    colAccionesBtnes,

    // HIJOS
    ApoyosCreate,
    ApoyosUpdate,
  },
  mixins: [formatos, swal, toast],
  data() {
    return {
      nombre_permiso: 'pieII2B',
      cargando: true,
      spinner: false,
      items: [],

      // MODAL
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },

      // TABLE
      perPage: 10,
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: '',
      filterOn: [],
      pageOptions: [10, 25, 50],
      fields: [
        {
          key: 'nombre',
          label: 'Ámbito, asignatura y/o Módulo de Aprendizaje',
          sortable: true,
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '140px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'horas_aula',
          label: 'Horas de apoyo en aula regular',
          sortable: true,
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '100px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'horas_fuera',
          label: 'Horas de apoyo fuera del aula',
          sortable: true,
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '100px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'apoyos',
          label: 'Tipos de apoyo especializado (Recursos materiales y/o profesionales)',
          sortable: true,
          thStyle: {
            'text-align': 'center',
            width: '300px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ],
      fieldAcciones: [
        {
          key: 'acciones',
          label: 'acciones',
          tdClass: 'text-center',
          thStyle: {
            width: '50px !important',
            'text-align': 'center',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      getLibroSelected: 'libros/getLibroSelected',
      getApoyos: 'II_2_b_apoyos/getApoyos',
    }),
  },
  watch: {
    getApoyos(val) {
      this.totalRows = val.length
      this.items = []
      this.items = this.getApoyos
    },
    getLibroSelected(val) {
      this.cargarApoyos()
    },
  },
  mounted() {
    this.inicialize()
  },
  methods: {
    ...mapActions({
      fetchApoyos: 'II_2_b_apoyos/fetchApoyos',
      removeApoyo: 'II_2_b_apoyos/removeApoyo',
    }),
    async inicialize () {
      await Promise.all([
        this.cargarApoyos(),
        this.setTableList()
      ])
    },
    setTableList() {
      if (this.$can('update', this.nombre_permiso)
        || this.$can('delete', this.nombre_permiso)
      ) {
        this.fields.push(this.fieldAcciones)
      }
    },
    async cargarApoyos() {
      const { status } = await this.fetchApoyos(this.getLibroSelected.id);
      if (status === 'error') {
        this.$toastError('Error al cargar los datos !', res.message);
      }
      this.cargando = false
    },
    async remove(apoyo) {
      const html = this.formatHTMLSweetEliminar('el apoyo', '');

      const result = await this.$swalConfirm(
        'Eliminar apoyo!', 
        html, 
        'Sí, elimínalo!', 
      );
      if (result.value) {
        this.spinner = true;
        const params = {
          id: apoyo.id,
          id_curso: this.getLibroSelected.id,
        };
        const res = await this.removeApoyo(params);
        if (res.status === 'success') {
          this.$swalSuccess(
            'Eliminado con éxito!',
            'El apoyo ha sido eliminado!'
          );
        } else {
          this.$swalError('Error al eliminar el apoyo', res.message);
        }
        this.spinner = false;
      }
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>