var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BOverlay',{attrs:{"show":_vm.cargando,"spinner-variant":"primary","variant":"semi-dark"}},[_c('BRow',[_c('BCol',{attrs:{"id":"col-APOYOS-PROFESORES","md":"12"}},[_c('BFormGroup',{attrs:{"label":"Entre profesores/as (educación especial y profesor/a regular) <Co-enseñanza>","label-for":"profesores"}},[_c('BFormTextarea',{attrs:{"id":"profesores","placeholder":_vm.$can('update', _vm.nombre_permiso)
            ? 'Ingrese las estrategias y/o acciones entre profesores'
            : 'Sin información',"rows":"4","maxlength":"550","plaintext":!_vm.$can('update', _vm.nombre_permiso),"state":_vm.v$.acciones.profesores.$error === true
            ? false
            : null},nativeOn:{"blur":function($event){return _vm.v$.acciones.profesores.$touch($event)}},model:{value:(_vm.acciones.profesores),callback:function ($$v) {_vm.$set(_vm.acciones, "profesores", $$v)},expression:"acciones.profesores"}}),_c('small',{staticClass:"text-muted text-right d-block mt-25"},[_vm._v(_vm._s(_vm.acciones.profesores ? _vm.acciones.profesores.length : 0)+" / 550")]),(_vm.v$.acciones.profesores.$error)?_c('BFormInvalidFeedback',{staticClass:"text-right",attrs:{"id":"profesoresInfo"}},_vm._l((_vm.v$.acciones.profesores.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('BCol',{attrs:{"id":"col-APOYOS-PROFESORES-ASISTENTES","md":"12"}},[_c('BFormGroup',{attrs:{"label":"Entre profesores y asistentes de la educación (psicólogos, fonoaudiólogos, auxiliares, intérpretes, etc.)","label-for":"asistentes"}},[_c('BFormTextarea',{attrs:{"id":"asistentes","placeholder":_vm.$can('update', _vm.nombre_permiso)
            ? 'Ingrese las estrategias y/o acciones entre profesores y asistentes de la educación'
            : 'Sin información',"rows":"4","maxlength":"550","plaintext":!_vm.$can('update', _vm.nombre_permiso),"state":_vm.v$.acciones.asistentes.$error === true
            ? false
            : null},nativeOn:{"blur":function($event){return _vm.v$.acciones.asistentes.$touch($event)}},model:{value:(_vm.acciones.asistentes),callback:function ($$v) {_vm.$set(_vm.acciones, "asistentes", $$v)},expression:"acciones.asistentes"}}),_c('small',{staticClass:"text-muted text-right d-block mt-25"},[_vm._v(_vm._s(_vm.acciones.asistentes ? _vm.acciones.asistentes.length : 0)+" / 550")]),(_vm.v$.acciones.asistentes.$error)?_c('BFormInvalidFeedback',{staticClass:"text-right",attrs:{"id":"asistentesInfo"}},_vm._l((_vm.v$.acciones.asistentes.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('BCol',{attrs:{"id":"col-APOYOS-ESTUDIANTES","md":"12"}},[_c('BFormGroup',{attrs:{"label":"Entre estudiantes (Ej: Aprendizaje Colaborativo)","label-for":"estudiantes"}},[_c('BFormTextarea',{attrs:{"id":"estudiantes","placeholder":_vm.$can('update', _vm.nombre_permiso)
            ? 'Ingrese las estrategias y/o acciones entre estudiantes'
            : 'Sin información',"rows":"4","maxlength":"550","plaintext":!_vm.$can('update', _vm.nombre_permiso),"state":_vm.v$.acciones.estudiantes.$error === true
            ? false
            : null},nativeOn:{"blur":function($event){return _vm.v$.acciones.estudiantes.$touch($event)}},model:{value:(_vm.acciones.estudiantes),callback:function ($$v) {_vm.$set(_vm.acciones, "estudiantes", $$v)},expression:"acciones.estudiantes"}}),_c('small',{staticClass:"text-muted text-right d-block mt-25"},[_vm._v(_vm._s(_vm.acciones.estudiantes ? _vm.acciones.estudiantes.length : 0)+" / 550")]),(_vm.v$.acciones.estudiantes.$error)?_c('BFormInvalidFeedback',{staticClass:"text-right",attrs:{"id":"estudiantesInfo"}},_vm._l((_vm.v$.acciones.estudiantes.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('BCol',{attrs:{"id":"col-APOYOS-FAMILIA","md":"12"}},[_c('BFormGroup',{attrs:{"label":"Con la familia y entre familias","label-for":"familia"}},[_c('BFormTextarea',{attrs:{"id":"familia","placeholder":_vm.$can('update', _vm.nombre_permiso)
            ? 'Ingrese las estrategias y/o acciones con la familia y entre familia'
            : 'Sin información',"rows":"4","maxlength":"550","plaintext":!_vm.$can('update', _vm.nombre_permiso),"state":_vm.v$.acciones.familia.$error === true
            ? false
            : null},nativeOn:{"blur":function($event){return _vm.v$.acciones.familia.$touch($event)}},model:{value:(_vm.acciones.familia),callback:function ($$v) {_vm.$set(_vm.acciones, "familia", $$v)},expression:"acciones.familia"}}),_c('small',{staticClass:"text-muted text-right d-block mt-25"},[_vm._v(_vm._s(_vm.acciones.familia ? _vm.acciones.familia.length : 0)+" / 550")]),(_vm.v$.acciones.familia.$error)?_c('BFormInvalidFeedback',{staticClass:"text-right",attrs:{"id":"familiaInfo"}},_vm._l((_vm.v$.acciones.familia.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('BCol',{attrs:{"id":"col-APOYOS-COMUNIDAD","md":"12"}},[_c('BFormGroup',{attrs:{"label":"Con la comunidad (con otras Escuelas, Centros Culturales, Servicios, etc)","label-for":"comunidad"}},[_c('BFormTextarea',{attrs:{"id":"comunidad","placeholder":_vm.$can('update', _vm.nombre_permiso)
            ? 'Ingrese las estrategias y/o acciones con la comunidad'
            : 'Sin información',"rows":"4","maxlength":"550","plaintext":!_vm.$can('update', _vm.nombre_permiso),"state":_vm.v$.acciones.comunidad.$error === true
            ? false
            : null},nativeOn:{"blur":function($event){return _vm.v$.acciones.comunidad.$touch($event)}},model:{value:(_vm.acciones.comunidad),callback:function ($$v) {_vm.$set(_vm.acciones, "comunidad", $$v)},expression:"acciones.comunidad"}}),_c('small',{staticClass:"text-muted text-right d-block mt-25"},[_vm._v(_vm._s(_vm.acciones.comunidad ? _vm.acciones.comunidad.length : 0)+" / 550")]),(_vm.v$.acciones.comunidad.$error)?_c('BFormInvalidFeedback',{staticClass:"text-right",attrs:{"id":"comunidadInfo"}},_vm._l((_vm.v$.acciones.comunidad.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('BCol',{attrs:{"id":"col-OBSERVACIONES","md":"12"}},[_c('BFormGroup',{attrs:{"label":"Observaciones","label-for":"observaciones"}},[_c('BFormTextarea',{attrs:{"id":"observaciones","placeholder":_vm.$can('update', _vm.nombre_permiso)
            ? 'Ingrese las observaciones'
            : 'Sin información',"rows":"4","maxlength":"550","plaintext":!_vm.$can('update', _vm.nombre_permiso),"state":_vm.v$.acciones.observaciones.$error === true
            ? false
            : null},nativeOn:{"blur":function($event){return _vm.v$.acciones.observaciones.$touch($event)}},model:{value:(_vm.acciones.observaciones),callback:function ($$v) {_vm.$set(_vm.acciones, "observaciones", $$v)},expression:"acciones.observaciones"}}),_c('small',{staticClass:"text-muted text-right d-block mt-25"},[_vm._v(_vm._s(_vm.acciones.observaciones ? _vm.acciones.observaciones.length : 0)+" / 550")]),(_vm.v$.acciones.observaciones.$error)?_c('BFormInvalidFeedback',{staticClass:"text-right",attrs:{"id":"observacionesInfo"}},_vm._l((_vm.v$.acciones.observaciones.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1)],1),_c('colLinea'),_c('div',{staticClass:"text-right",staticStyle:{"height":"69px !important"},attrs:{"id":"Action-buttons"}},[(!_vm.cargando)?_c('btnSubmit',{staticClass:"float-right",attrs:{"variant":"primary","modulo":_vm.nombre_permiso,"disabled":this.v$.acciones.$errors.length > 0,"btnText":"Actualizar Acciones"},on:{"processBtn":_vm.submitOption}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }