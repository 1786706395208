<template>
  <CoordinacionForm
    nombreModal="modal-create"
    title="Agregar coordinador del programa al equipo de aula"
    :data.sync="data"
    @processForm="agregar"
    @resetData="resetData"
  />
</template>

<script>
import { mapActions } from 'vuex'
import { toast, swal } from '@/mixins/alerts'
import CoordinacionForm from './CoordinacionForm.vue'

export default {
  components: {
    CoordinacionForm,
  },
  mixins: [toast, swal],
  data() {
    return {
      data: this.getDefaultData(),
    }
  },
  methods: {
    ...mapActions({
      fetchEquipo: 'I_1_equipo/fetchEquipo',
      createEquipo: 'I_1_equipo/addEquipo',
    }),
    getDefaultData() {
      return {
        id: null,
        nombre: '',
        especialidad: '',
        telefono: '',
        correo: '',
        id_curso: null,
        id_persona_rol: null,
        id_especialidad: null,
        id_firma: null,
        tipo: 4, // equipo aula - coordinador
      }
    },
    async agregar(equipo) {
      const res = await this.createEquipo(equipo)
      if (res.status === 'success') {
        this.resetData()
        this.$toastSuccess(
          'Coordinador registrado 👍', 
          'El coordinador fue guardado con éxito!'
        )
        this.$bvModal.hide('modal-create')
      } else {
        this.$swalError('Error al crear el coordinador!', res.message)
      }
    },
    resetData() {
      this.data = this.getDefaultData()
    }
  },
}
</script>
