<template>
  <BTabs
    fill
    vertical
    class="mt-2"
    nav-wrapper-class="nav-vertical"
    active-tab-class="font-weight-bold text-primary"
  >
    <div v-for="(menu, key) in submenus" :key="key" v-if="menu.can" >
      <BTab
        :active="menu.active"
        :title="menu.index.toUpperCase()+`. `+menu.abreviatura"
        lazy
      >
        <!-- ENCABEZADO -->
        <div class="meetup-header d-flex align-items-center">
          <div class="meetup-day">

            <!-- INDEX -->
            <h4 class="mb-0">
              {{ menu.index.toUpperCase() }}
            </h4>
          </div>
          <div class="ml-1">

            <!-- TÍTULO -->
            <BCardTitle
              v-if="menu.titulo !== ''"
              class="mb-25 h4"
              style="margin-top: 4px; "
            >
              {{ menu.titulo }}
            </BCardTitle>

            <!-- TEXTO -->
            <BCardText
              v-if="menu.texto !== ''"
              class="mr-4 mb-50 text-secondary"
            >
              {{ menu.texto }}
            </BCardText>
          </div>
        </div>

        <Panorama v-if="menu.index === 'a'"/>
        <Estrategias v-if="menu.index === 'b'"/>
        
      </BTab>
    </div>
  </BTabs>
</template>

<script>
import {
  BTabs, BTab, BCardText, BCard, BCardTitle,
} from 'bootstrap-vue'

import Panorama from './EstrategiasDiversificadas/Panorama.vue'
import Estrategias from './EstrategiasDiversificadas/Estrategias.vue'

export default {
  components: {
    BTabs, BTab, BCardText, BCard, BCardTitle,
    Panorama,
    Estrategias,
  },
  props: {
    submenus: {
      type: Array,
      required: true,
    }
  },
}
</script>
