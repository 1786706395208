<template>
  <ReunionForm
    nombreModal="modal-create"
    title="Registrar reunión"
    :data.sync="data"
    @processForm="agregar"
    @resetData="resetData"
  />
</template>

<script>
import { mapActions } from 'vuex'
import { toast, swal } from '@/mixins/alerts'
import { formatos } from '@/mixins/formatos'

import ReunionForm from './ReunionForm.vue'

export default {
  components: {
    ReunionForm,
  },
  mixins: [toast, swal, formatos],
  data() {
    return {
      data: this.getDefaultData(),
    }
  },
  methods: {
    ...mapActions({
      createActividad: 'IV_actividades/addActividad',
      fetchActividades: 'IV_actividades/fetchActividades',
    }),
    getDefaultData() {
      return {
        id: null,
        fecha: this.formatDate(new Date()),
        objetivo: '',
        actividad: '',
        acuerdo: '',
        resultado: '',
        participantes: []
      }
    },
    async agregar(actividad) {
      const res = await this.createActividad(actividad)
      if (res.status === 'success') {
        const params = {
          idCurso: actividad.id_curso,
          tipo: actividad.tipo,
        }
        await this.fetchActividades(params)
        this.resetData()
        this.$toastSuccess(
          'Reunión registrada 👍', 
          'La reunión fue guardada con éxito!'
        )
        this.$bvModal.hide('modal-create')
      } else {
        this.$swalError('Error al crear la reunión!', res.message)
      }
    },
    resetData() {
      this.data = this.getDefaultData()
    },
  },
}
</script>
