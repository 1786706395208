<template>
  <div>
    <BTabs
      style="margin: -10px -10px -26px -10px!important;"
      align="center"
      fill
      class="success"
    >
      <BTab
        active
        lazy
        title="Hoy"
      >
        <template
          #title
        >
          <feather-icon
            icon="CalendarIcon"
            size="18"
            class="mr-0 mr-sm-50"
          />
          <span
            class="d-none d-sm-inline"
          >
            {{
              fechaHoy === formatFechaVerActividades(new Date())
                ? 'Hoy'
                : 'Diario'
            }} - {{ fechaHoy }}
          </span>
        </template>

        <colLinea style="margin-top: -15px; margin-bottom: 16px;"/>

        <BRow>
          <BCol
            cols="8"
            xl="9"
            lg="8"
            md="8"
          ></BCol>
          <BCol
            cols="12"
            xl="3"
            lg="4"
            md="4"
            xs="6"
          >
            <calendario
              id="fecha"
              tipo="hastaFechaFinal"
              :fecha="fechaCalendario"
              :state="false"
              @changeCalendario="cambiaDia"
            />
          </BCol>

          <BCol
            cols="12"
            md="12"
          >
            <contenidos-dia
              v-if="cargarContenidosDia"
              :fechaSelected="fechaCalendario"
            />
          </BCol>
        </BRow>

      </BTab>

      <!-- MENSUAL -->
      <!-- <BTab
        lazy
        title="Mensual"
      >
        <template
          #title
        >
          <feather-icon
            icon="CalendarIcon"
            size="18"
            class="mr-0 mr-sm-50"
          />
          <span
            class="d-none d-sm-inline"
          >
            Mensual - {{meses[mes - 1]}}
          </span
          >
        </template>

        <colLinea style="margin-top: -15px; margin-bottom: 16px;"/>

        <BRow>

          <!-- Field: Meses
          <BCol
            cols="12"
            md="3"
          >
            <BFormGroup
              label="Mes"
              label-for="mes"
            >
              <v-select
                v-model="mes"
                placeholder="Seleccione un mes..."
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="texto"
                :options="mesesOption"
                :reduce="option => option.id"
              />
            </BFormGroup>
          </BCol>
          <!-- Field: Meses
          <BCol
            cols="12"
            md="3"
          ></BCol>
          <BCol
            cols="12"
            md="6"
          >
            <b-alert
              show
              variant="primary"
              class="mt-2"
              style="margin-bottom: -1px"
            >
              <div class="alert-body text-center mt-25">
                <feather-icon
                  icon="InfoIcon"
                  class="mr-50"
                />
                Haz click en el día que deseas ver el control de contenido en detalle
              </div>
            </b-alert>
          </BCol>
        </BRow>

        <contenidos-mes
          :mesSelected.sync="mes"
        />

      </BTab> -->
    </BTabs>
  </div>
</template>

<script>

import {
  BRow, BCol, BFormGroup, BTabs, BTab, BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

// MIXINS
import { formatos } from '@/mixins/formatos'


// COMPONENTES HIJOS
import ContenidosDia from './ContenidosDia/ContenidosDia.vue'

import { mapGetters, mapActions } from 'vuex'

import colLinea from '../../../components/Form/colLinea.vue'
import calendario from '../../../components/Form/calendario.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BTabs,
    BTab,
    BAlert,
    vSelect,
    colLinea,
    calendario,

    // COMPONENTES HIJOS
    // ContenidosMes,
    ContenidosDia,
  },
  mixins: [formatos],
  data() {
    return {
      meses: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciciembre',
      ],
      fechaCalendario: '',
      cargarContenidosDia: false,
      fechaHoy: this.formatFechaVerActividades(new Date()),
      hoy: {
        dia: null,
        mes: null,
        year: null,
      },
      mes: new Date().getMonth() + 1,
      mesesOption: [],
      mesesDefault: [
        {
          id: 1,
          texto: 'Ene'
        },
        {
          id: 2,
          texto: 'Febrero'
        },
        {
          id: 3,
          texto: 'Marzo'
        },
        {
          id: 4,
          texto: 'Abril'
        },
        {
          id: 5,
          texto: 'Mayo'
        },
        {
          id: 6,
          texto: 'Junio'
        },
        {
          id: 7,
          texto: 'Julio'
        },
        {
          id: 8,
          texto: 'Agosto'
        },
        {
          id: 9,
          texto: 'Septiembre'
        },
        {
          id: 10,
          texto: 'Octubre'
        },
        {
          id: 11,
          texto: 'Noviembre'
        },
        {
          id: 12,
          texto: 'Diciembre'
        },
      ],
    }
  },
  computed: {
    ...mapGetters({ getLibroSelected: 'libros/getLibroSelected', }),
  },
  watch: {
    getLibroSelected(val) {
      this.setHoy()
      // this.cargarContenidosMensual(this.getLibroSelected.id)
    },
    mes(val) {
      // this.cargarContenidosMensual(this.getLibroSelected.id)
    }
  },
  mounted() {
    this.setHoy()
    // this.cargarContenidosMensual(this.getLibroSelected.id)
    // this.cargarMeses()
  },
  methods: {
    ...mapActions({
      fetchContenidosMensual: 'contenidos/fetchContenidosMensual',
    }),
    cargarMeses() {
      for (let index = 0; index < this.mes; index++) {
        const mes = this.mesesDefault[index];
        this.mesesOption.push({
            id: mes.id,
            texto: mes.texto
        })
      }
    },
    setHoy() {
      this.hoy.dia = null
      this.hoy.mes = null
      const hoy = new Date()
      this.hoy.year = hoy.getFullYear();
      this.hoy.mes = hoy.getMonth() + 1;
      this.hoy.dia = hoy.getDate();
      this.fechaCalendario = this.hoy.year+'-'+this.hoy.mes+'-'+this.hoy.dia
      this.cargarContenidosDia = true
    },
    cargarContenidosMensual(id_curso) {
      const data = {
        id_curso,
        mes: this.mes
      }

      this.fetchContenidosMensual(data).then(() => {})
    },
    cambiaDia(fechaCalendario) {
      this.fechaCalendario = fechaCalendario
      const fechaSeparada = this.fechaCalendario.split('-')

      const fecha = new Date(fechaSeparada)
      this.fechaHoy = this.formatFechaVerActividades(fecha)
      this.hoy.mes = parseInt(fechaSeparada[1])
      this.hoy.dia = parseInt(fechaSeparada[2])
    },
  }
}
</script>
