<template>
  <BOverlay
    :show="configs.cargando"
    spinner-variant="primary"
    variant="semi-dark"
  >
    <BForm
      ref="form"
      :style="{height: trHeight}"
      class="repeater-form mb-2 mt-2"
    >
      <!-- ENCABEZADOS -->
      <BRow
        ref="row"
      >
        <!-- Criterios -->
        <BCol md="3">
          <BFormGroup
            label="Criterios sobre enseñanza y aprendizaje a considerar en la planificación y evaluación en el aula, a saber"
            label-for="observaciones"
            class="text-center"
          />
        </BCol>
        <!-- Estrategias para -->
        <BCol md="3">
          <BFormGroup
            label="Estrategias"
            label-for="observaciones"
            class="text-center"
          />
        </BCol>
        <!-- Como? -->
        <BCol md="3">
          <BFormGroup
            label="¿Cómo?"
            label-for="como"
            class="text-center"
          />
        </BCol>
        <!-- A quienes? -->
        <BCol md="3">
          <BFormGroup
            label="Estudiantes"
            label-for="observaciones"
            class="text-center"
          />
        </BCol>
      </BRow>

      <colLinea class="mt-25 mb-1"/>

      <!-- 1=NORMAL; -->
      <BRow
        v-for="(item_normal, index) in items_normal"
        :id="item_normal.id"
        :key="item_normal.id"
        ref="row"
      >
        <!-- Criterios -->
        <BCol md="3">
          <BFormCheckbox
            v-model="item_normal.criterio_chk"
            value="1"
            :disabled="!$can('update', nombre_permiso)"
            class="mb-1"
          >
            {{ item_normal.criterio }}
          </BFormCheckbox>
        </BCol>

        <!-- Estrategias para -->
        <BCol md="3">

          <BFormCheckbox
            v-model="item_normal.estrategia_1_chk"
            value="1"
            :disabled="!$can('update', nombre_permiso)"
            class="mb-1"
          >
            {{ item_normal.estrategia_1 }}
          </BFormCheckbox>

          <BFormCheckbox
            v-if="item_normal.estrategia_2_chk !== null"
            value="1"
            v-model="item_normal.estrategia_2_chk"
            :disabled="!$can('update', nombre_permiso)"
            class="mb-1"
          >
            {{ item_normal.estrategia_2 }}
          </BFormCheckbox>

          <BFormCheckbox
            v-if="item_normal.estrategia_3_chk !== null"
            value="1"
            v-model="item_normal.estrategia_3_chk"
            :disabled="!$can('update', nombre_permiso)"
          >
           {{ item_normal.estrategia_3 }}
          </BFormCheckbox>

        </BCol>

        <!-- Como? -->
        <BCol md="3">
          <BFormTextarea
            id="como"
            v-model="item_normal.como"
            :placeholder="$can('update', nombre_permiso)
              ? 'Materiales, acciones, formas de agrupamiento, etc'
              : 'Sin información'"
            :plaintext="!$can('update', nombre_permiso)"
            rows="7"
          />
        </BCol>

        <!-- A quienes? -->
        <!-- ESTUDIANTES -->
        <BCol md="3">
          <v-select
            v-if="configs.loadingEstudiantes"
            
            placeholder="Cargando estudiantes..."
            :disabled="true"
          />
          <v-select
            v-else-if="$can('update', nombre_permiso)"
            :id="'estudiantes-'+item_normal.id"
            v-model="item_normal.personas"
            multiple
            
            placeholder="Seleccione un estudiante..."
            label="title"
            :options="alumnosOptions"
            :reduce="option => option.id"
            input-id="id"
          />
          <template v-if="!$can('update', nombre_permiso)">
            <div v-if="item_normal.personas.length !== 0">
              <div
                v-for="(persona, key) in item_normal.personas"
                :key="key"
                class="text-secondary"
              >
                {{ persona.nombre_completo }}
                <hr style="margin-top: 1px; margin-bottom: 5px;">
              </div>
            </div>
            <div v-else>
              <BFormTextarea
                :id="'estudiantes-text-'+item_normal.id"
                placeholder="Sin información"
                :plaintext="!$can('update', nombre_permiso)"
              />
            </div>
          </template>
        </BCol>

        <BCol cols="12">
          <colLinea />
        </BCol>

      </BRow>

      <!-- 2=OTRO; -->
      <BRow>
        <!-- Titulo -->
        <template>
          <BCol md="3">
            <BFormGroup
              label="Otro"
              label-for="otro"
            />
          </BCol>
          <!-- Estrategias para -->
          <BCol md="3">
          </BCol>
          <!-- Como? -->
          <BCol md="3">
          </BCol>
          <!-- A quienes? -->
          <BCol md="3">
          </BCol>
        </template>

        <!-- Criterios -->
        <BCol md="3">
          <BFormTextarea
            id="otro"
            v-model="item_otro.criterio"
            :placeholder="$can('update', nombre_permiso)
              ? 'Ingrese un criterio'
              : 'Sin información'"
            :plaintext="!$can('update', nombre_permiso)"
            rows="3"
          />
        </BCol>

        <!-- Estrategias para -->
        <BCol md="3">
          <BFormTextarea
            id="otro"
            v-model="item_otro.estrategia_1"
            :placeholder="$can('update', nombre_permiso)
              ? 'Ingrese una estrategia'
              : 'Sin información'"
            :plaintext="!$can('update', nombre_permiso)"
            rows="3"
          />
        </BCol>

        <!-- Como? -->
        <BCol md="3">
          <BFormTextarea
            id="como"
            v-model="item_otro.como"
            :placeholder="$can('update', nombre_permiso)
              ? 'Materiales, acciones, formas de agrupamiento, etc'
              : 'Sin información'"
            :plaintext="!$can('update', nombre_permiso)"
            rows="3"
          />
        </BCol>

        <!-- A quienes? -->
        <!-- ESTUDIANTES -->
        <BCol md="3">
          <v-select
            v-if="configs.loadingEstudiantes"
            
            placeholder="Cargando estudiantes..."
            :disabled="true"
          />
          <v-select
            v-else-if="$can('update', nombre_permiso)"
            :id="'estudiantes-'+item_otro.id"
            v-model="item_otro.personas"
            multiple
            
            placeholder="Seleccione un estudiante..."
            label="title"
            :options="alumnosOptions"
            :reduce="option => option.id"
            input-id="id"
          />

          <template v-if="!$can('update', nombre_permiso)">
            <div v-if="item_otro.personas.length !== 0">
              <div
                v-for="(persona, key) in item_otro.personas"
                :key="key"
                class="text-secondary"
              >
                {{ persona.nombre_completo }}
                <hr
                  style="margin-top: 1px; margin-bottom: 5px;"
                >
              </div>
            </div>
            <div v-else>
              <BFormTextarea
                :id="'estudiantes-text-'+item_otro.id"
                placeholder="Sin información"
                :plaintext="!$can('update', nombre_permiso)"
              />
            </div>
          </template>
        </BCol>
      </BRow>

      <colLinea />

      <!-- 3=AJUSTES; -->
      <BRow>
        <!-- Titulo -->
        <template>
          <BCol md="3">
            <BFormGroup
              label="Ajustes"
              label-for="ajustes"
            />
          </BCol>
          <!-- Estrategias para -->
          <BCol md="3">
          </BCol>
          <!-- Como? -->
          <BCol md="3">
          </BCol>
          <!-- A quienes? -->
          <BCol md="3">
          </BCol>
        </template>

        <!-- Criterios -->
        <template
          v-for="(item_ajuste, index) in items_ajustes"
          :id="item_ajuste.id"
        >

          <BCol md="3" >
            <BFormTextarea
              v-if="item_ajuste.criterio_show"
              v-model="item_ajuste.criterio"
              id="ajustes"
              :placeholder="$can('update', nombre_permiso)
                ? 'En qué aspecto o nivel se realizarán ajustes'
                : 'Sin información'"
              :plaintext="!$can('update', nombre_permiso)"
              rows="7"
            />
          </BCol>

          <!-- Estrategias para -->
          <BCol md="9">
            <BRow v-if="item_ajuste.estrategia_1_chk !== null">
              <BCol md="4">
                <BFormCheckbox
                  v-model="item_ajuste.estrategia_1_chk"
                  value="1"
                  :disabled="!$can('update', nombre_permiso)"
                  class="mb-1"
                >
                  {{ item_ajuste.estrategia_1 }}
                </BFormCheckbox>
              </BCol>

              <BCol md="4">
                <BFormTextarea
                  id="como"
                  v-model="item_ajuste.como"
                  :placeholder="$can('update', nombre_permiso)
                    ? 'Materiales, acciones, formas de agrupamiento, etc'
                    : 'Sin información'"
                  :plaintext="!$can('update', nombre_permiso)"
                  rows="3"
                />
              </BCol>

              <!-- ESTUDIANTES -->
              <BCol md="4">
                <v-select
                  v-if="configs.loadingEstudiantes"
                  
                  placeholder="Cargando estudiantes..."
                  :disabled="true"
                />
                <v-select
                  v-else-if="$can('update', nombre_permiso)"
                  :id="'estudiantes-'+item_ajuste.id"
                  v-model="item_ajuste.personas"
                  multiple
                  
                  placeholder="Seleccione un estudiante..."
                  label="title"
                  :options="alumnosOptions"
                  :reduce="option => option.id"
                  input-id="id"
                />

                <template v-if="!$can('update', nombre_permiso)">
                  <div v-if="item_ajuste.personas.length !== 0">
                    <div
                      v-for="(persona, key) in item_ajuste.personas"
                      :key="key"
                      class="text-secondary"
                    >
                      {{ persona.nombre_completo }}
                      <hr
                        style="margin-top: 1px; margin-bottom: 5px;"
                      >
                    </div>
                  </div>
                  <div v-else>
                    <BFormTextarea
                      :id="'estudiantes-text-'+item_ajuste.id"
                      placeholder="Sin información"
                      :plaintext="!$can('update', nombre_permiso)"
                    />
                  </div>
                </template>
              </BCol>
              <BCol md="12">
                <colLinea />
              </BCol>
            </BRow>

            <!-- AJUSTES / OTRO -->
            <BRow v-if="item_ajuste.estrategia_1_chk === null">
                <!-- Criterios -->
              <BCol md="4">
                <BFormGroup
                  label="Otro"
                  label-for="otro"
                />
              </BCol>
              <!-- Estrategias para -->
              <BCol md="4">
              </BCol>
              <!-- Como? -->
              <BCol md="4">
              </BCol>

              <BCol md="4">
                <BFormTextarea
                  id="estrategia"
                  v-model="item_ajuste.estrategia_1"
                  :placeholder="$can('update', nombre_permiso)
                    ? 'Ingrese una estrategia'
                    : 'Sin información'"
                  :plaintext="!$can('update', nombre_permiso)"
                  rows="3"
                />
              </BCol>
              <BCol md="4">
                <BFormTextarea
                  id="como"
                  v-model="item_ajuste.como"
                  :placeholder="$can('update', nombre_permiso)
                    ? 'Materiales, acciones, formas de agrupamiento, etc'
                    : 'Sin información'"
                  :plaintext="!$can('update', nombre_permiso)"
                  rows="3"
                />
              </BCol>

              <!-- ESTUDIANTES -->
              <BCol md="4">
                <v-select
                  v-if="configs.loadingEstudiantes"
                  
                  placeholder="Cargando estudiantes..."
                  :disabled="true"
                />
                <v-select
                  v-else-if="$can('update', nombre_permiso)"
                  :id="'estudiantes-'+item_ajuste.id"
                  v-model="item_ajuste.personas"
                  multiple
                  
                  placeholder="Seleccione un estudiante..."
                  label="title"
                  :options="alumnosOptions"
                  :reduce="option => option.id"
                  input-id="id"
                />

                <template v-if="!$can('update', nombre_permiso)">
                  <div v-if="item_ajuste.personas.length !== 0">
                    <div
                      v-for="(persona, key) in item_ajuste.personas"
                      :key="key"
                      class="text-secondary"
                    >
                      {{ persona.nombre_completo }}
                      <hr
                        style="margin-top: 1px; margin-bottom: 5px;"
                      >
                    </div>
                  </div>
                  <div v-else>
                    <BFormTextarea
                      :id="'estudiantes-text-'+item_ajuste.id"
                      placeholder="Sin información"
                      :plaintext="!$can('update', nombre_permiso)"
                    />
                  </div>
                </template>
              </BCol>
            </BRow>
          </BCol>
        </template>
      </BRow>

      <colLinea />

      <!-- 4=OTRAS; -->
      <BRow>
        <!-- Titulo -->
        <template>
          <BCol md="3">
            <BFormGroup
              label="Otras estrategias y criterios"
              label-for="ajustes"
            />
          </BCol>
          <!-- Estrategias para -->
          <BCol md="3">
          </BCol>
          <!-- Como? -->
          <BCol md="3">
          </BCol>
          <!-- A quienes? -->
          <BCol md="3">
          </BCol>
        </template>

        <!-- Criterios -->
        <BCol md="3">
          <BFormTextarea
            id="otro"
            v-model="item_otras.criterio"
            :placeholder="$can('update', nombre_permiso)
              ? 'Ingrese un criterio'
              : 'Sin información'"
            :plaintext="!$can('update', nombre_permiso)"
            rows="3"
          />
        </BCol>

        <!-- Estrategias para -->
        <BCol md="3">
          <BFormTextarea
            id="otro"
            v-model="item_otras.estrategia_1"
            :placeholder="$can('update', nombre_permiso)
              ? 'Ingrese una estrategia'
              : 'Sin información'"
            :plaintext="!$can('update', nombre_permiso)"
            rows="3"
          />
        </BCol>

        <!-- Como? -->
        <BCol md="3">
          <BFormTextarea
            id="como"
            v-model="item_otras.como"
            :placeholder="$can('update', nombre_permiso)
              ? 'Materiales, acciones, formas de agrupamiento, etc'
              : 'Sin información'"
            :plaintext="!$can('update', nombre_permiso)"
            rows="3"
          />
        </BCol>

        <!-- A quienes? -->
        <!-- ESTUDIANTES -->
        <BCol md="3">
          <v-select
            v-if="configs.loadingEstudiantes"
            
            placeholder="Cargando estudiantes..."
            :disabled="true"
          />
          <v-select
            v-else-if="$can('update', nombre_permiso)"
            :id="'estudiantes-'+item_otras.id"
            v-model="item_otras.personas"
            multiple
            
            placeholder="Seleccione un estudiante..."
            label="title"
            :options="alumnosOptions"
            :reduce="option => option.id"
            input-id="id"
          />

          <template v-if="!$can('update', nombre_permiso)">
            <div v-if="item_otras.personas.length !== 0">
              <div
                v-for="(persona, key) in item_otras.personas"
                :key="key"
                class="text-secondary"
              >
                {{ persona.nombre_completo }}
                <hr
                  style="margin-top: 1px; margin-bottom: 5px;"
                >
              </div>
            </div>
            <div v-else>
              <BFormTextarea
                :id="'estudiantes-text-'+item_otras.id"
                placeholder="Sin información"
                :plaintext="!$can('update', nombre_permiso)"
              />
            </div>
          </template>
        </BCol>
      </BRow>

      <colLinea />

      <!-- 5=OBSERVACIONES; -->
      <BRow>
        <!-- OBSERVACIONES -->
        <BCol md="12">
          <BFormGroup
            label="Observaciones"
            label-for="observaciones"
          >
            <BFormTextarea
              id="observaciones"
              v-model="item_observaciones.observaciones"
              :placeholder="$can('update', nombre_permiso)
                  ? 'Ingrese las observaciones'
                  : 'Sin información'"
                :plaintext="!$can('update', nombre_permiso)"
              rows="4"
            />
          </BFormGroup>
        </BCol>
      </BRow>

      <colLinea />

      <!-- Action Buttons -->
      <BRow
        ref="row"
      >
        <BCol md="8">
        </BCol>

        <BCol md="4">
          <btnSubmit
            class="float-right"
            variant="primary"
            :modulo="nombre_permiso"
            :btnText="btnSubmit"
            @processBtn="submitOption"
          />
        </BCol>

      </BRow>
    </BForm>
  </BOverlay>
</template>

<script>

// ETIQUETAS
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BButton, BOverlay, BCardText,
  BFormTextarea, BFormCheckbox
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import ToastificationContent
from '@core/components/toastification/ToastificationContent.vue'

import { mapGetters, mapActions } from 'vuex'

// FORMATOS
import { formatos } from '@/mixins/formatos'
import { toast } from '@/mixins/alerts'

// COMPONENTES
import colLinea from '@/views/components/Form/colLinea.vue'
import btnSubmit from '@/views/components/Form/btnSubmit.vue'

export default {
  components: {
    BForm, BFormGroup, BFormInput, BRow, BCol, BButton, BOverlay, BCardText,
    BFormTextarea, BFormCheckbox,
    vSelect,

    // COMPONENTS
    colLinea,
    btnSubmit,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition, formatos, toast],
  data() {
    return {
      nombre_permiso: 'pieII3A',
      configs: {
        loadingEstudiantes: true,
        cargando: false,
      },
      items_normal: [],
      item_otro: {},
      items_ajustes: [],
      item_otras: {},
      item_observaciones: {},
      alumnosOptions: [],
      nextTodoId: 2,
    }
  },
  computed: {
    ...mapGetters({
      getDiversidad: 'II_3_a_diversidad/getDiversidad',
      getLibroSelected: 'libros/getLibroSelected',
    }),
  },
  watch: {
    getLibroSelected(getLibroSelected) {
      this.inicialize()
    },
  },
  props: {
    btnSubmit: {
      type: String, // Texto del boton
      default: 'Actualizar Estrategia',
    },
  },
  mounted() {
    this.inicialize()
  },
  methods: {
    ...mapActions({
      // ADECUACIONES
      fetchDiversidad: 'II_3_a_diversidad/fetchDiversidad',
      addDiversidad: 'II_3_a_diversidad/addDiversidad',
      updateDiversidad: 'II_3_a_diversidad/updateDiversidad',

      // OTROS
      fetchAlumnosPie: 'personas/fetchAlumnosPie',
    }),
    cargaEstudiantesPie(idCurso) {
      this.fetchAlumnosPie(idCurso).then(() => {})
    },
    async inicialize() {
      // this.planApoyo = { ...this.data }
      await Promise.all([
        this.cargaEstudiantesPie(),
        this.cargarDiversidades(),
      ])
    },
    async cargaEstudiantesPie() {
      const res = await this.fetchAlumnosPie(this.getLibroSelected.id)
      if (res.state === 'success') {
        this.alumnosOptions = []
        this.alumnosOptions = res.data.map(item => ({
          id: item.id_persona_rol,
          title: `${item.nombre} ${item.primer_apellido} ${item.segundo_apellido}`,
        }));
      } else if (res.state === 'error') {
        this.$toastError('Error al cargar los estudiantes!', res.message);
      }
      this.configs.loadingEstudiantes = false
    },
    async cargarDiversidades() {
      this.configs.cargando = true
      const res = await this.fetchDiversidad(this.getLibroSelected.id);
      if (res.status === 'success') {
        if (res.data.length !== 0) {
          this.vaciarItems()
          res.data.forEach(diversidad => {
            switch (diversidad.tipo) {
              case 1: // Normal
                  this.items_normal.push(diversidad)
                break;
              case 2: // Otro
                  this.item_otro = diversidad
                break;

              case 3: // Ajustes
                  this.items_ajustes.push(diversidad)
                break;

              case 4: // Otras
                  this.item_otras = diversidad
                break;

              case 5: // Observaciones
                  this.item_observaciones = diversidad
                break;

              default:
                break;
            }
          })
        } else {
          this.resetItems()
        }
      } else if (res.state === 'error') {
        this.$toastError('Error al cargar los estudiantes!', res.message);
      }
        
      this.configs.cargando = false
    },
    submitOption() {
      const text = 'las estrategias que se utilizarán para dar respuesta a la diversidad de estudiantes, en el aula común'
      const html = this.formatHTMLSweetInfo(text, '')
      this.$swal({
        title: 'Guardar cambios!',
        html,
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Sí, guardar',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          if (this.getDiversidad.length === 0) {
            this.agregarDiversidades()
          } else {
            this.editarDiversidades()
          }
        } else {
          return false
        }
      })
    },
    agregarDiversidades() {
      this.spinner = true
      this.addNormal(this.items_normal)
      this.addOtro(this.item_otro)
      this.addAjustes(this.items_ajustes)
      this.addOtras(this.item_otras)
      this.addObservaciones(this.item_observaciones)
      
      this.cargarDiversidades()
      this.msjActualizar()
      this.spinner = false
    },

    // * 1=NORMAL
    addNormal(diversidades) {
      diversidades.forEach(diversidad => {
        const data = {
          ...diversidad,
          tipo: 1, // tipo normal
          id_curso: this.getLibroSelected.id,
        }
        this.addDiversidad(data).then((response) => {})
      })
    },
    // * 2=OTRO
    addOtro(diversidad) {

      const data = {
        ...diversidad,
        tipo: 2, // tipo otro
        id_curso: this.getLibroSelected.id,
      }
      this.addDiversidad(data).then((response) => {})
    },
    // * 3=AJUSTES
    addAjustes(diversidades) {
      diversidades.forEach(diversidad => {
        const data = {
          ...diversidad,
          tipo: 3, // tipo ajustes
          id_curso: this.getLibroSelected.id,
        }
        this.addDiversidad(data).then((response) => {})
      })
    },
    // * 4=OTRAS
    addOtras(diversidad) {
      const data = {
        ...diversidad,
        tipo: 4, // tipo otras
        id_curso: this.getLibroSelected.id,
      }
      this.addDiversidad(data).then((response) => {})
    },
    // * 5=OBSERVACIONES
    addObservaciones(diversidad) {
      const data = {
        ...diversidad,
        tipo: 5, // tipo observaciones
        id_curso: this.getLibroSelected.id,
      }
      this.addDiversidad(data).then((response) => {})
    },

    editarDiversidades() {
      this.spinner = true

      this.updateNormal(this.items_normal)
      this.updateOtro(this.item_otro)
      this.updateAjustes(this.items_ajustes)
      this.updateOtras(this.item_otras)
      this.updateObservaciones(this.item_observaciones)

      this.cargarDiversidades()
      this.msjActualizar()
      this.spinner = false
    },
    // * 1=NORMAL
    updateNormal(diversidades) {
      diversidades.forEach(diversidad => {
        let personas = []
        diversidad.personas.forEach(persona => {
          if (typeof persona.id === 'undefined') {
            personas.push(persona)
          } else {
            personas.push(persona.id_persona_rol)
          }
        })
        const data = {
          ...diversidad,
          id_diversidad: diversidad.id,
          tipo: 1, // tipo normal
          personas: personas,
          id_curso: this.getLibroSelected.id,
        }
        this.updateDiversidad(data).then((response) => {})
      })
    },
    // * 2=OTRO
    updateOtro(diversidad) {
      let personas = []
      diversidad.personas.forEach(persona => {
        if (typeof persona.id === 'undefined') {
          personas.push(persona)
        } else {
          personas.push(persona.id_persona_rol)
        }
      })
      const data = {
        ...diversidad,
        id_diversidad: diversidad.id,
        tipo: 2, // tipo otro
        personas: personas,
        id_curso: this.getLibroSelected.id,
      }
      this.updateDiversidad(data).then((response) => {})
    },
    // * 3=AJUSTES
    updateAjustes(diversidades) {
      diversidades.forEach(diversidad => {
        let personas = []
        diversidad.personas.forEach(persona => {
          if (typeof persona.id === 'undefined') {
            personas.push(persona)
          } else {
            personas.push(persona.id_persona_rol)
          }
        })

        const data = {
          ...diversidad,
          id_diversidad: diversidad.id,
          tipo: 3, // tipo ajustes
          personas: personas,
          id_curso: this.getLibroSelected.id,
        }
        this.updateDiversidad(data).then((response) => {})
      })
    },
    // * 4=OTRAS
    updateOtras(diversidad) {
      let personas = []
      diversidad.personas.forEach(persona => {
        if (typeof persona.id === 'undefined') {
          personas.push(persona)
        } else {
          personas.push(persona.id_persona_rol)
        }
      })
      const data = {
        ...diversidad,
        id_diversidad: diversidad.id,
        tipo: 4, // tipo otras
        personas: personas,
        id_curso: this.getLibroSelected.id,
      }
      this.updateDiversidad(data).then((response) => {})
    },
    // * 5=OBSERVACIONES
    updateObservaciones(diversidad) {
      const data = {
        ...diversidad,
        id_diversidad: diversidad.id,
        tipo: 5, // tipo observaciones
        id_curso: this.getLibroSelected.id,
      }
      this.updateDiversidad(data).then((response) => {})
    },

    msjActualizar() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Registro actualizado 👍',
          icon: 'CheckIcon',
          text: `Las estrategias que se utilizarán para dar respuesta a la diversidad de estudiantes, han sido actualizadas con éxito!`,
          variant: 'success',
        },
      },
      {
        position: 'bottom-right',
        timeout: 3000,
      })
    },
    vaciarItems () {
      this.items_normal = []
      this.item_otro = {}
      this.items_ajustes = []
      this.item_otras = {}
      this.item_observaciones = {}
    },
    resetItems() {
      this.vaciarItems()
      this.items_normal = [
        {
          criterio_show: true,
          criterio_chk: false,
          criterio: 'Múltiples medios de presentación y representación',
          estrategia_1_chk: false,
          estrategia_1: 'Favorecer percepción de la información.',
          estrategia_2_chk: false,
          estrategia_2: 'Favorecer la representación de la información.',
          estrategia_3_chk: false,
          estrategia_3: 'Favorecer la comprensión de la información.',
          como: '',
          observaciones: null,
          personas: [],
        },
        {
          criterio_show: true,
          criterio_chk: false,
          criterio: 'Múltiples medios de ejecución y expresión',
          estrategia_1_chk: false,
          estrategia_1: 'Para favorecer la expresión y la fluidez.',
          estrategia_2_chk: false,
          estrategia_2: 'Para favorecer la acción física.',
          estrategia_3_chk: null,
          estrategia_3: null,
          como: '',
          observaciones: null,
          personas: [],
        },
        {
          criterio_show: true,
          criterio_chk: false,
          criterio: 'Múltiples formas de participación',
          estrategia_1_chk: false,
          estrategia_1: 'Para captar la atención y el interés.',
          estrategia_2_chk: false,
          estrategia_2: 'De apoyo al esfuerzo y la persistencia.',
          estrategia_3_chk: false,
          estrategia_3: 'Para el control y regulación de los propios procesos de aprendizaje.',
          como: '',
          observaciones: null,
          personas: [],
        },
      ]

      this.item_otro = {
        criterio_show: true,
        criterio_chk: null,
        criterio: '',
        estrategia_1_chk: null,
        estrategia_1: '',
        estrategia_2_chk: null,
        estrategia_2: null,
        estrategia_3_chk: null,
        estrategia_3: null,
        como: '',
        observaciones: null,
        personas: [],
      }

      this.items_ajustes = [
        {
          criterio_show: true,
          criterio_chk: null,
          criterio: '',
          estrategia_1_chk: false,
          estrategia_1: 'En los materiales de acceso.',
          estrategia_2_chk: null,
          estrategia_2: null,
          estrategia_3_chk: null,
          estrategia_3: null,
          como: '',
          observaciones: null,
          personas: [],
        },
        {
          criterio_show: false,
          criterio_chk: null,
          criterio: '',
          estrategia_1_chk: false,
          estrategia_1: 'En el tiempo.',
          estrategia_2_chk: null,
          estrategia_2: null,
          estrategia_3_chk: null,
          estrategia_3: null,
          como: '',
          observaciones: null,
          personas: [],
        },
        {
          criterio_show: false,
          criterio_chk: null,
          criterio: '',
          estrategia_1_chk: false,
          estrategia_1: 'En la tarea.',
          estrategia_2_chk: null,
          estrategia_2: null,
          estrategia_3_chk: null,
          estrategia_3: null,
          como: '',
          observaciones: null,
          personas: [],
        },
        {
          criterio_show: false,
          criterio_chk: null,
          criterio: '',
          estrategia_1_chk: null,
          estrategia_1: '',
          estrategia_2_chk: null,
          estrategia_2: null,
          estrategia_3_chk: null,
          estrategia_3: null,
          como: '',
          observaciones: null,
          personas: [],
        }
      ]

      this.item_otras = {
        criterio_show: true,
        criterio_chk: null,
        criterio: '',
        estrategia_1_chk: null,
        estrategia_1: '',
        estrategia_2_chk: null,
        estrategia_2: null,
        estrategia_3_chk: null,
        estrategia_3: null,
        como: '',
        observaciones: null,
        personas: [],
      }

      this.item_observaciones = {
        criterio_show: false,
        criterio_chk: null,
        criterio: null,
        estrategia_1_chk: null,
        estrategia_1: null,
        estrategia_2_chk: null,
        estrategia_2: null,
        estrategia_3_chk: null,
        estrategia_3: null,
        como: null,
        observaciones: '',
        personas: [],
      }
    },
  },
}
</script>
