<template>
  <BModal
    :id="nombreModal"
    :title="title"
    centered
    size="xl"
    :hide-footer="true"
    @shown="onModalOpen"
  >
    <BOverlay
      :show="configs.cargando"
      spinner-variant="primary"
      variant="semi-dark"
    >
      <!-- REPEAT -->
      <BFormGroup id="REPEAT" class="mb-75 mt-75">
        
        <template #label class="mb-75 mt-75 h3">
          Registro de participantes
        </template>
        <BForm
          ref="form"
          class="repeater-form overflow-auto border rounded-lg"
          style="min-height: 340px !important; max-height: 340px !important; margin: 0px 0px 0px 0px; padding: 9px 15px 0px 9px;"
          @submit.prevent="repeateAgain"
        >
          <BAlert
            v-if="typeof items === 'undefined' || items.length === 0"
            variant="primary"
            show
            class="text-center"
          >
            <div class="alert-body">
              <span>No existen participantes agregados.</span>
            </div>
          </BAlert>
          <BRow id="HEADER" class="border-bottom mt-0 mb-0 pt-0 pb-50">
            <BCol id="col-PARTICIPANTES" cols="4">
              Participante <span class="text-danger">*</span>
            </BCol>
            <BCol id="col-ROL" cols="3">
              Rol
            </BCol>
            <BCol id="col-RUT" cols="2">
              Rut
            </BCol>
            <BCol id="col-TELEFONO" cols="2">
              Teléfono
            </BCol>
            <BCol id="col-REMOVE-Button" cols="1">
              Eliminar
            </BCol>
          </BRow>
          <BRow
            v-for="(item, index) in items"
            :id="item.id"
            :key="item.id"
            ref="rowParticipantes"
            class="border-bottom pt-75 pb-75"
            >
            <BCol id="col-PARTICIPANTES" cols="4">
              <v-select
                v-if="configs.loadingParticipantes"
                placeholder="Cargando participantes..."
                :disabled="true"
              />
              <v-select
                v-else
                v-model="item.participantes"
                :id="'participantes-'+item.id"
                placeholder="Seleccione un participante..."
                :options="optionsParticipantes"
                label="text"
                :clearable="false"
                :reduce="option => option.id_persona_rol"
                input-id="idParticipante"
                @input="changeParticipante(item)"
                :class="v$.items && v$.items[index] && v$.items[index].participantes && v$.items[index].participantes.$error
                    ? 'border-danger rounded'
                    : ''"
              />
              <div v-if="v$.items && v$.items[index] && v$.items[index].participantes && v$.items[index].participantes.$error"
                id="participantesInfo"
                class="text-danger text-right"
                style="font-size: 0.857rem;"
              >
                <p v-for="error of (v$.items[index] && v$.items[index].participantes ? v$.items[index].participantes.$errors : [])" :key="error.$uid">
                  {{ error.$message }}
                </p>
              </div>
            </BCol>

            <BCol id="col-IDENTIFICACION" cols="3" class="pt-75">
              {{ item.identificacion }}
            </BCol>

            <BCol id="col-RUT" cols="2" class="pt-75">
              {{ item.rut }}
            </BCol>

            <BCol id="col-TELEFONO" cols="2" class="pt-75">
              {{ item.telefono }}
            </BCol>

            <BCol id="col-REMOVE-Button"
              cols="1"
              class="text-center"
            >
              <BButton
                :disabled="index === 0"
                :variant="index === 0 ? 'text-secondary' : 'outline-danger'"
                class=""
                @click="removeItem(index)"
              >
                <feather-icon icon="TrashIcon" />
              </BButton>
            </BCol>

          </BRow>
        </BForm>
      </BFormGroup>

      <BButton
        variant="outline-primary"
        class="mt-25 pl-1 pr-1"
        @click="repeateAgain"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-25"
        />
        <span>Agregar participante</span>
      </BButton>

      <BRow class="mt-2">
          <BCol id="col-FECHA" cols="12">
            <BFormGroup label-for="fecha">
              <template #label>
                Fecha <span class="text-danger">*</span>
              </template>
              <BFormDatepicker
                v-model="actividad.fecha"
                id="fecha"
                placeholder="Abrir calendario"
                hide-header
                :date-format-options="{
                  year: 'numeric',
                  month: 'short',
                  day: '2-digit',
                  weekday: 'short'
                }"
                start-weekday="1"
                locale="es-CL"
                :date-disabled-fn="dateDisabled"
                label-help=""
                :state="v$.actividad.fecha.$error === true
                  ? false
                  : null"
              />
              <BFormInvalidFeedback v-if="v$.actividad.fecha.$error"
                id="fechaInfo"
                class="text-right"
              >
                <p v-for="error of v$.actividad.fecha.$errors" :key="error.$uid">
                  {{ error.$message }}
                </p>
              </BFormInvalidFeedback>
            </BFormGroup>
          </BCol>

          <BCol id="col-MOTIVOS" cols="12">
            <BFormGroup label-for="motivos">
              <template #label>
                Motivo(s) <span class="text-danger">*</span>
              </template>
              <BFormTextarea
                id="motivos"
                placeholder="Ingrese el/los motivo(s)"
                v-model="actividad.objetivo"
                maxlength="500"
                :state="v$.actividad.objetivo.$error === true
                  ? false
                  : null"
              />
              <small class="text-muted text-right d-block mt-25">{{ actividad.objetivo ? actividad.objetivo.length: 0 }} / 500</small>
              <BFormInvalidFeedback v-if="v$.actividad.objetivo.$error"
                id="objetivoInfo"
                class="text-right"
              >
                <p v-for="error of v$.actividad.objetivo.$errors" :key="error.$uid">
                  {{ error.$message }}
                </p>
              </BFormInvalidFeedback>
            </BFormGroup>
          </BCol>

          <BCol id="col-ACUERDOS" cols="12">
            <BFormGroup label-for="acuerdos">
              <template #label>
                Acuerdo(s) <span class="text-danger">*</span> 
              </template>
              <BFormTextarea
                id="acuerdos"
                placeholder="Ingrese el/los acuerdo(s)"
                v-model="actividad.actividad"
                maxlength="500"
                :state="v$.actividad.actividad.$error === true
                  ? false
                  : null"
              />
              <small class="text-muted text-right d-block mt-25">{{ actividad.actividad ? actividad.actividad.length: 0 }} / 500</small>
              <BFormInvalidFeedback v-if="v$.actividad.actividad.$error"
                id="objetivoInfo"
                class="text-right"
              >
                <p v-for="error of v$.actividad.actividad.$errors" :key="error.$uid">
                  {{ error.$message }}
                </p>
              </BFormInvalidFeedback>
            </BFormGroup>
          </BCol>

          <BCol id="col-COMPROMISOS" cols="12">
            <BFormGroup label-for="acuerdo">
              <template #label>
                Compromiso(s) <span class="text-danger">*</span> 
              </template>
              <BFormTextarea
                id="acuerdo"
                placeholder="Ingrese el/los compromiso(s)"
                v-model="actividad.acuerdo"
                maxlength="500"
                :state="v$.actividad.acuerdo.$error === true
                  ? false
                  : null"
              />
              <small class="text-muted text-right d-block mt-25">{{ actividad.acuerdo ? actividad.acuerdo.length: 0 }} / 500</small>
              <BFormInvalidFeedback v-if="v$.actividad.acuerdo.$error"
                id="acuerdoInfo"
                class="text-right"
              >
                <p v-for="error of v$.actividad.acuerdo.$errors" :key="error.$uid">
                  {{ error.$message }}
                </p>
              </BFormInvalidFeedback>
            </BFormGroup>
          </BCol>

      </BRow>

      <colLinea />

      <div class="text-right">
        <BButton variant="outline-secondary" class="mr-1" @click="closeModal">
          Cancelar
        </BButton>

        <BButton
          variant="primary"
          class="mr-25"
          :disabled="this.v$.actividad.$errors.length > 0 || this.v$.items.$errors.length > 0"
          @click="submitOption"
        >
          Guardar Trabajo
        </BButton>
      </div>
    </BOverlay>
  </BModal>
</template>

<script>

// ETIQUETAS
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BFormInvalidFeedback, BButton, 
  BOverlay, BFormDatepicker, BFormTextarea, BAlert, BModal, VBModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { heightTransition } from '@core/mixins/ui/transition'
import { mapGetters, mapActions } from 'vuex'

// VALIDACIONES
import useVuelidate from '@vuelidate/core'
import { required, maxLength, helpers } from '@vuelidate/validators'

// COMPONENTES RECICLADOS
import colLinea from '@/views/components/Form/colLinea.vue'

import { toast, swal } from '@/mixins/alerts'
import { rut } from '@/mixins/rut'
import { formatos } from '@/mixins/formatos'

export default {
  components: {
    BRow, BCol, BFormGroup, BFormInput, BForm, BFormInvalidFeedback, BButton, 
    BOverlay, BFormDatepicker, BFormTextarea, BAlert, BModal, VBModal,
    vSelect,

    // COMPONENTES RECICLADOS
    // firmas,
    colLinea,
  },
  mixins: [heightTransition, toast, swal, rut, formatos],
  data() {
    return {
      nombre_permiso: 'pieIV1',
      configs: {
        cargando: false,
        loadingParticipantes: true,
      },
      actividad: {},
      optionsParticipantes: [],

      // REPEAT
      items: [ this.getDefaultData() ],
      nextTodoId: 0,
    }
  },
  computed: {
    ...mapGetters({
      getLibroSelected: 'libros/getLibroSelected',
    }),
  },
  watch: {
    getLibroSelected(val) {
      this.cargarParticipantes()
    },
  },
  props: {
    nombreModal: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions({
      fetchEquipoAll: 'I_1_equipo/fetchEquipoAll',
    }),
    async onModalOpen () {
      this.actividad = { ...this.data }
      await Promise.all([
        this.cargarParticipantes(),
      ]);
      this.cargarItems();
      this.initTrHeight();
    },
    async cargarParticipantes() {
      this.optionsParticipantes = [];
      this.configs.loadingParticipantes = true; 

      try {
        const equipoPie = await this.cargaEquipo();
        this.optionsParticipantes = [...equipoPie];
      } catch (error) {
        this.$swalError('Error al cargar los participantes!', error.message);
      } finally {
        this.configs.loadingParticipantes = false; 
      }
    },
    async cargaEquipo() {
      const res = await this.fetchEquipoAll(this.getLibroSelected.id);
      if (res.status !== 'success') {
        this.$swalError('Error al cargar los participantes!', res.message);
        return [];
      }
      return res.data.map(item => ({
        id_persona_rol: item.id_persona_rol,
        text: this.primeraMayus(
          `${item.nombre} - ${item.tipo === 1 ? 'Docente' : item.especialidad}`
        ),
        identificacion: item.especialidad,
        rut: this.formatRut(`${item.rut}${item.dv}`),
        telefono: item.telefono,
      }));
    },
    resetItemData() {
      this.items = [ this.getDefaultData() ];
    },
    getDefaultData() {
      return {
        participantes: null,
        identificacion: '',
        rut: '',
        telefono: '',
      }
    },
    cargarItems() {
      if (this.actividad.id !== null) {
        this.items = []
        this.actividad.participantes.forEach(participante => {
          this.items.push({
            id: participante.id_persona_rol,
            tipo: 'select',
            participantes: participante.id_persona_rol,
            rol: participante.nombre_rol,
          });

          this.changeParticipante({participantes: participante.id_persona_rol})
        })
      }
    },
    changeParticipante(persona) {
      if (!persona || !persona.participantes) return;
      const participante = this.optionsParticipantes.find(
        p => p.id_persona_rol === persona.participantes
      );

      const item = this.items.find(i => i.participantes === persona.participantes);
      if (participante && item) {
        const { identificacion, rut, telefono } = participante;
        item.identificacion = identificacion;
        item.rut = rut;
        item.telefono = telefono;
      }
    },
    submitOption() {
      this.items.forEach((_, index) => {
        if (this.v$.items[index]) {
          this.v$.items[index].$touch();
        }
      });
      this.v$.actividad.$touch();

      const itemsValid = this.items.every((_, index) => this.v$.items[index] && !this.v$.items[index].$invalid);
      const actividadValid = !this.v$.actividad.$invalid;

      if (itemsValid && actividadValid) {
        let personas = []
        this.items.forEach(item => {
          personas.push(item.participantes)
        })
        const params = {
          ...this.actividad,
          texto_1: this.actividad.objetivo,
          texto_2: this.actividad.actividad,
          texto_3: this.actividad.acuerdo,
          texto_4: this.actividad.resultado,
          tipo: 3, //Tipo: reunión
          personas,
          id_curso: this.getLibroSelected.id
        }
        this.$emit('processForm', params)
        this.v$.items.$reset()
        this.v$.actividad.$reset()
        this.resetItemData();
      }
    },
    closeModal() {
      this.$bvModal.hide(this.nombreModal)
      this.v$.actividad.$reset()
      if (this.actividad.id === null) {
        this.$emit('resetData');
        this.resetItemData();
      }
    },

    dateDisabled(ymd, date) {
      const weekday = date.getDay()
      const day = date.getDate()
      return weekday === 0 || weekday === 6
    },

    // REPEAT
    repeateAgainDesdeCero() {
      this.items.push({
        id: this.nextTodoId += this.nextTodoId,
        tipo: 'input',
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },

    repeateAgain() {
      this.items.push({
        id: this.nextTodoId += 1,
        tipo: 'select',
      })

      this.$nextTick(() => {
        if (typeof this.$refs.row !== 'undefined') {
          this.trAddHeight(this.$refs.row[0].offsetHeight)
        }
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        if (typeof this.$refs.form !== 'undefined') {
          this.trSetHeight(this.$refs.form.scrollHeight)
        }
      })
    },
  },
  validations() {
    const itemsValidations = {};
    this.items.forEach((_, index) => {
      itemsValidations[index] = {
        participantes: {
          required: helpers.withMessage('El campo es obligatorio.', required),
        },
      };
    });
    return {
      items: itemsValidations,
      actividad: {
        fecha: {
          required: helpers.withMessage('El campo es requerido.', required),
        },
        objetivo: {
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener un máximo de 500 caracteres.', maxLength(500)),
        },
        actividad: {
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener un máximo de 500 caracteres.', maxLength(500)),
        },
        acuerdo: {
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener un máximo de 500 caracteres.', maxLength(500)),
        },
      },
    }
  },

  // REPEATER
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  setup() {
    return {
      v$: useVuelidate(),
    }
  },

}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
