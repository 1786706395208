<template>
  <div>
    {{ formatTamañoTexto(texto, longText) }}
    <template v-if="texto.length > longText">
      <BButton 
        v-BModal="`modal-${id}`" 
        variant="link" 
        class="pl-0"
        :centered="true"
      >
        ver más
      </BButton>
      <BModal :id="`modal-${id}`" title="Acuerdos" size="lg" hide-footer>
        <p>{{texto}}</p>
      </BModal>
    </template>
  </div>
</template>

<script>
import {
  BButton, BModal
} from 'bootstrap-vue'

// FORMATOS
import { formatos } from '@/mixins/formatos'

export default {
  components: {
    BButton, BModal,
  },
  mixins: [formatos],
  props: {
    id: {
      type: String,
      required: true,
    },
    texto: {
      type: String,
      required: true,
    },
    longText: {
      type: Number,
      required: true,
    },
  },
}
</script>
