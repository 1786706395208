<template>
  <BOverlay :show="cargando" spinner-variant="primary" variant="semi-dark" >
    <BRow>

      <BCol id="col-APRENDIZAJES" md="12">
        <BFormGroup
          label="Estilos y modos de aprendizaje del curso"
          label-for="aprendizajes"
        >
          <BFormTextarea
            id="aprendizajes"
            :placeholder="$can('update', nombre_permiso)
              ? 'Ingrese los estilos y modos de aprendizaje'
              : 'Sin información'"
            v-model="panorama.estilo"
            rows="4"
            maxlength="550"
            :plaintext="!$can('update', nombre_permiso)"
            :state="v$.panorama.estilo.$error === true
              ? false
              : null"
            @blur.native="v$.panorama.estilo.$touch"
          />
          <small class="text-muted text-right d-block mt-25">{{ panorama.estilo.length }} / 550</small>
          <!-- Mensajes Error Validación -->
          <BFormInvalidFeedback
            v-if="v$.panorama.estilo.$error"
            id="estiloInfo"
            class="text-right"
          >
            <p v-for="error of v$.panorama.estilo.$errors" :key="error.$uid">
              {{ error.$message }}
            </p>
          </BFormInvalidFeedback>
        </BFormGroup>
      </BCol>

      <BCol id="col-FORTALEZAS" md="12">
        <BFormGroup
          label="Fortalezas del curso"
          label-for="fortalezas"
        >
          <BFormTextarea
            id="fortalezas"
            v-model="panorama.fortaleza"
            :placeholder="$can('update', nombre_permiso)
              ? 'Ingrese las fortalezas'
              : 'Sin información'"
            rows="4"
            maxlength="550"
            :plaintext="!$can('update', nombre_permiso)"
            :state="v$.panorama.fortaleza.$error === true
              ? false
              : null"
            @blur.native="v$.panorama.fortaleza.$touch"
          />
          <small class="text-muted text-right d-block mt-25">{{ panorama.fortaleza.length }} / 550</small>  
          <!-- Mensajes Error Validación -->
          <BFormInvalidFeedback
            v-if="v$.panorama.fortaleza.$error"
            id="fortalezaInfo"
            class="text-right"
          >
            <p v-for="error of v$.panorama.fortaleza.$errors" :key="error.$uid">
              {{ error.$message }}
            </p>
          </BFormInvalidFeedback>
        </BFormGroup>
      </BCol>

      <BCol id="col-NECESIDADES" md="12">
        <BFormGroup
          label="Necesidades de apoyo del curso"
          label-for="necesidades"
        >
          <BFormTextarea
            id="necesidades"
            v-model="panorama.necesidad"
            :placeholder="$can('update', nombre_permiso)
              ? 'Ingrese las necesidades'
              : 'Sin información'"
            rows="4"
            maxlength="550"
            :plaintext="!$can('update', nombre_permiso)"
            :state="v$.panorama.necesidad.$error === true
              ? false
              : null"
            @blur.native="v$.panorama.necesidad.$touch"
          />
          <small class="text-muted text-right d-block mt-25">{{ panorama.necesidad.length }} / 550</small>  
          
          <BFormInvalidFeedback
            v-if="v$.panorama.necesidad.$error"
            id="necesidadInfo"
            class="text-right"
          >
            <p v-for="error of v$.panorama.necesidad.$errors" :key="error.$uid">
              {{ error.$message }}
            </p>
          </BFormInvalidFeedback>
        </BFormGroup>
      </BCol>

    </BRow>

    <colLinea/>

    <div id="Action-buttons" class="text-right" style="height: 69px !important;">
      <btnSubmit
        class="float-right"
        variant="primary"
        :modulo="nombre_permiso"
        :disabled="this.v$.panorama.$errors.length > 0"
        btnText="Actualizar Panorama"
        @processBtn="submitOption"
      />
    </div>
  </BOverlay>
</template>

<script>

// ETIQUETAS
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BButton, BOverlay, BCardText,
  BFormTextarea, BFormInvalidFeedback
} from 'bootstrap-vue'

import { mapGetters, mapActions } from 'vuex'

// VALIDACIONES
import useVuelidate from '@vuelidate/core'
import { maxLength, helpers } from '@vuelidate/validators'

// COMPONENTES RECICLADOS
import colLinea from '@/views/components/Form/colLinea.vue'
import btnSubmit from '@/views/components/Form/btnSubmit.vue'

// FORMATOS
import { formatos } from '@/mixins/formatos'
import { toast } from '@/mixins/alerts'

export default {
  components: {
    BForm, BFormGroup, BFormInput, BRow, BCol, BButton, BOverlay, BCardText,
    BFormTextarea, BFormInvalidFeedback,
    colLinea,
    btnSubmit,
  },
  mixins: [formatos, toast],
  computed: {
    ...mapGetters({ getLibroSelected: 'libros/getLibroSelected' }),
  },
  data() {
    return {
      nombre_permiso: 'pieII1A',
      cargando: true,
      panorama: {
        estilo: '',
        fortaleza: '',
        necesidad: '',
      },
    }
  },
  watch: {
    getLibroSelected(val) {
      this.cargarPanorama()
    }
  },
  mounted() {
    this.cargarPanorama()
  },
  methods: {
    ...mapActions({
      fetchPanorama: 'II_1_a_panorama/fetchPanorama',
      addPanorama: 'II_1_a_panorama/addPanorama',
      updatePanorama: 'II_1_a_panorama/updatePanorama',
    }),
    async cargarPanorama() {
      this.cargando = true
      const res = await this.fetchPanorama(this.getLibroSelected.id)
      if (res.status === 'success') {
        this.panorama = res.data
      } else {
        this.$toastError('Error al cargar los datos !', res.message)
      }
      this.cargando = false
    },
    async actualizarPanorama() {
      this.spinner = true

      const params = { ...this.panorama, idCurso: this.getLibroSelected.id }      
      const res = this.panorama.id === null
        ? await this.addPanorama(params)
        : await this.updatePanorama(params)

      if (res.status === 'success') {
        this.$toastSuccess(
          'Panorama actualizado 👍', 
          'El panorama fue actualizado con éxito!'
        )
        this.cargarPanorama()
      } else {
        this.$toastError('Error al actualizar los datos !', res.message)
      }
      this.spinner = false
    },

    submitOption() {
      this.v$.panorama.$touch()
      if (!this.v$.panorama.$invalid) {
        const text = `El registro de panorama,
          será visible para todos los usuarios que interactuen con este curso.`
        const html = this.formatHTMLSweetInfo('el panorama del curso', text)
        this.$swal({
          title: 'Guardar cambios!',
          html,
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: 'Sí, actualizar',
          cancelButtonText: 'Cancelar',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-secondary ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.actualizarPanorama()
          }
          return false
        })
      }
    },
  },
  validations() {
    return {
      panorama: {
        estilo: {
          $autoDirty: true,
          maxLength: helpers.withMessage(
            'Debe tener un máximo de 550 caracteres.',
            maxLength(550)),
        },
        fortaleza: {
          $autoDirty: true,
          maxLength: helpers.withMessage(
            'Debe tener un máximo de 550 caracteres.',
            maxLength(550)),
        },
        necesidad: {
          $autoDirty: true,
          maxLength: helpers.withMessage(
            'Debe tener un máximo de 550 caracteres.',
            maxLength(550)),
        },
      }
    }
  },
  setup() {
    return { v$: useVuelidate() }
  },
}
</script>
