var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BModal',{attrs:{"id":_vm.nombreModal,"title":_vm.title,"centered":"","size":"xl","hide-footer":true},on:{"shown":_vm.onModalOpen}},[_c('div',{staticClass:"d-flex justify-content-between mb-1"},[_c('div',{staticClass:"mt-50 text-secondary"}),_c('div',{staticClass:"mr-1 mt-50 text-muted small"},[_vm._v(" Campos obligatorios "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('BOverlay',{attrs:{"show":_vm.configs.cargando,"spinner-variant":"primary","variant":"semi-dark"}},[_c('BRow',[_c('BCol',{attrs:{"id":"col-FECHA","cols":"12","md":"6"}},[_c('BFormGroup',{attrs:{"label-for":"fecha"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Fecha "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('BFormDatepicker',{attrs:{"id":"fecha","placeholder":"Abrir calendario","hide-header":"","date-format-options":{
              year: 'numeric',
              month: 'short',
              day: '2-digit',
              weekday: 'short'
            },"start-weekday":"1","locale":"es-CL","date-disabled-fn":_vm.dateDisabled,"label-help":"","state":_vm.v$.reunion.fecha.$error === true
              ? false
              : null},model:{value:(_vm.reunion.fecha),callback:function ($$v) {_vm.$set(_vm.reunion, "fecha", $$v)},expression:"reunion.fecha"}}),(_vm.v$.reunion.fecha.$error)?_c('BFormInvalidFeedback',{staticClass:"text-right",attrs:{"id":"fechaInfo"}},_vm._l((_vm.v$.reunion.fecha.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('BCol',{attrs:{"id":"col-HORA","cols":"12","md":"6"}},[_c('BFormGroup',{attrs:{"label-for":"horario"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Hora "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('BInputGroup',[_c('cleave',{class:_vm.v$.reunion.horario.$error === true
                ? 'form-control border-danger rounded'
                : 'form-control',attrs:{"id":"time","raw":false,"options":_vm.time,"placeholder":"hh:mm"},model:{value:(_vm.reunion.horario),callback:function ($$v) {_vm.$set(_vm.reunion, "horario", $$v)},expression:"reunion.horario"}}),_c('BInputGroupAppend',[_c('BFormTimepicker',{attrs:{"button-only":"","size":"sm","aria-controls":"horario","hide-header":"","locale":"es-CL","variant":"outlined","no-close-button":""},on:{"keyup":function($event){return _vm.formatHoraInput(_vm.reunion.horario)}},model:{value:(_vm.reunion.horario),callback:function ($$v) {_vm.$set(_vm.reunion, "horario", $$v)},expression:"reunion.horario"}})],1)],1),(_vm.v$.reunion.horario.$error)?_c('div',{staticClass:"text-danger text-right",staticStyle:{"font-size":"0.857rem"},attrs:{"id":"diaInfo"}},_vm._l((_vm.v$.reunion.horario.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('BCol',{attrs:{"id":"col-PERIODO","cols":"12","md":"12"}},[_c('BFormGroup',{attrs:{"label-for":"periodo"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Período de tiempo en que se aplicará "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[(_vm.configs.loadingPeriodos)?_c('v-select',{attrs:{"placeholder":"Cargando periodos...","disabled":true}}):_c('v-select',{class:_vm.v$.reunion.id_periodo.$error === true
              ? 'border-danger rounded'
              : '',attrs:{"placeholder":"Seleccione un periodo...","label":"nombre","options":_vm.periodosOption,"reduce":function (option) { return option.id; },"input-id":"id_periodo"},model:{value:(_vm.reunion.id_periodo),callback:function ($$v) {_vm.$set(_vm.reunion, "id_periodo", $$v)},expression:"reunion.id_periodo"}}),(_vm.v$.reunion.id_periodo.$error)?_c('div',{staticClass:"text-danger text-right",staticStyle:{"font-size":"0.857rem"},attrs:{"id":"id_periodoInfo"}},_vm._l((_vm.v$.reunion.id_periodo.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('BCol',{attrs:{"id":"col-Asistentes","cols":"12","md":"12"}},[_c('BFormGroup',{attrs:{"label-for":"asistentes"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Asistentes "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[(_vm.configs.loadingPersonas)?_c('v-select',{attrs:{"placeholder":"Cargando personas...","disabled":true}}):_c('v-select',{class:_vm.v$.reunion.personas.$error === true
              ? 'border-danger rounded'
              : '',attrs:{"multiple":"","placeholder":"Seleccione los asistentes...","label":"nombre","options":_vm.asistentesOptions,"reduce":function (option) { return option.id_persona_rol; },"input-id":"id_persona_rol","closeOnSelect":false},model:{value:(_vm.reunion.personas),callback:function ($$v) {_vm.$set(_vm.reunion, "personas", $$v)},expression:"reunion.personas"}}),(_vm.v$.reunion.personas.$error)?_c('div',{staticClass:"text-danger text-right",staticStyle:{"font-size":"0.857rem"},attrs:{"id":"asistentesInfo"}},_vm._l((_vm.v$.reunion.personas.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('BCol',{attrs:{"id":"col-Acuerdos","cols":"12","md":"12"}},[_c('BFormGroup',{attrs:{"label-for":"acuerdos"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Acuerdos "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('BFormTextarea',{attrs:{"id":"acuerdos","placeholder":"Ingrese los acuerdos","rows":"9","maxlength":"3000","state":_vm.v$.reunion.acuerdos.$error === true
              ? false
              : null},model:{value:(_vm.reunion.acuerdos),callback:function ($$v) {_vm.$set(_vm.reunion, "acuerdos", $$v)},expression:"reunion.acuerdos"}}),_c('small',{staticClass:"text-muted text-right d-block mt-25"},[_vm._v(_vm._s(_vm.reunion.acuerdos ? _vm.reunion.acuerdos.length : 0)+" / 3000")]),(_vm.v$.reunion.acuerdos.$error)?_c('BFormInvalidFeedback',{staticClass:"text-right",attrs:{"id":"acuerdosInfo"}},_vm._l((_vm.v$.reunion.acuerdos.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1)],1),_c('colLinea'),_c('div',{staticClass:"text-right"},[_c('BButton',{staticClass:"mr-1",attrs:{"variant":"outline-secondary"},on:{"click":_vm.closeModal}},[_vm._v(" Cancelar ")]),_c('BButton',{staticClass:"mr-25",attrs:{"variant":"primary","disabled":this.v$.reunion.$errors.length > 0},on:{"click":_vm.submitOption}},[_vm._v(" Guardar Reunión ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }