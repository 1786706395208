<template>
  <BOverlay v-if="$can('read', 'asistencias')"
    :show="cargando"
    spinner-variant="primary"
    variant="semi-dark"
    style="min-height: 640px !important;"
  >
    <b-alert v-if="bloquesAlert"
      variant="info"
      show
      class="text-center mt-25 mr-25 ml-25 pb-2 pt-2"
    >
      {{alertMsg}}
    </b-alert>
    <BTable v-else
      small
      hover
      noCollapse
      bordered
      responsive
      sticky-header
      class="mb-2 rounded"
      :style="cargando
        ? 'min-height: 670px !important;'
        : 'min-height: 670px !important; margin-top: 0px !important;'"
      :fields="fields"
      :items="items"
      foot-clone
    >
      <!-- Cargando -->
      <template #table-busy>
        <div class="text-center text-danger my-1">
          <spinner />
        </div>
      </template>

      <template #cell()="data">
        <div v-if="data.field.key == 'num_lista'">

          <estudiante-retirado
            :rut="data.item.rut"
            :data.sync="data.item.orden.toString()"
            :fecha_retiro.sync="data.item.fecha_retiro"
            :fecha_ingreso.sync="data.item.fecha_ingreso"
            :genero.sync="data.item.genero"
            :mayus="false"
          />

        </div>
        <div v-else-if="data.field.key == 'nombre_completo'" class="divEstudiante">
          <estudiante-retirado
            :rut="data.item.rut"
            :data.sync="data.item.nombre_completo"
            :fecha_retiro.sync="data.item.fecha_retiro"
            :fecha_ingreso.sync="data.item.fecha_ingreso"
            :genero.sync="data.item.genero"
            :mayus="true"
          />
        </div>

        <asistencia-alumno-bloque
          v-else-if="data.field.bloque"
          :item.sync="data.item"
          :field.sync="data.field"
          :tipoAsistencia.sync="tipoAsistencia"
          :asistenciasBloques.sync="asistenciasBloques"
        />
      </template>

      <!-- MODAL FIRMA -->
      <!-- <template #foot(dia)="data">
        DIAAAA -->
        <!-- <btn-guardar-sin-firmar
          v-if="data.field.estado_firma !== 1 || getUser.id_rol === 17"
          :can="true"
          :id_persona_rol_docente="hiddenBloques[0]['id_persona_rol_docente']"
          :num_bloque="1"
          :items="items"
          @processBtn="submit"
        />
        <modal-firma
          :idModal="1"
          :cargar.sync="cargarFirma"
          modulo="asistencias"
          title="Firmar asistencias"
          @processFirma="firmar"
        /> -->
      <!-- </template> -->

      <!-- MODAL FIRMA -->
      <template  #foot(bloque_1)="data">
        <btn-guardar-sin-firmar
          v-if="data.field.estado_firma !== 1 || getUser.id_rol === 17"
          :can="true"
          :id_persona_rol_docente="hiddenBloques[0]['id_persona_rol_docente']"
          :num_bloque="1"
          :items="items"
          @processBtn="submit"
        />
        <modal-firma
          :idModal="1"
          :cargar.sync="cargarFirma"
          modulo="asistencias"
          title="Firmar asistencias"
          @processFirma="firmar"
        />
      </template>

      <template #foot(bloque_2)="data">
        <btn-guardar-sin-firmar
          v-if="data.field.estado_firma !== 1 || getUser.id_rol === 17"
          :can="true"
          :id_persona_rol_docente="hiddenBloques[1]['id_persona_rol_docente']"
          :num_bloque="2"
          :items="items"
          @processBtn="submit"
        />
        <modal-firma
          :idModal="2"
          :cargar.sync="cargarFirma"
          modulo="asistencias"
          title="Firmar asistencias"
          @processFirma="firmar"
        />
      </template>

      <template #foot(bloque_3)="data">
        <btn-guardar-sin-firmar
          v-if="data.field.estado_firma !== 1 || getUser.id_rol === 17"
          :can="true"
          :id_persona_rol_docente="hiddenBloques[2]['id_persona_rol_docente']"
          :num_bloque="3"
          :items="items"
          @processBtn="submit"
        />
        <modal-firma
          :idModal="3"
          :cargar.sync="cargarFirma"
          modulo="asistencias"
          title="Firmar asistencias"
          @processFirma="firmar"
        />
      </template>

      <template #foot(bloque_4)="data">
        <btn-guardar-sin-firmar
          v-if="data.field.estado_firma !== 1 || getUser.id_rol === 17"
          :can="true"
          :id_persona_rol_docente="hiddenBloques[3]['id_persona_rol_docente']"
          :num_bloque="4"
          :items="items"
          @processBtn="submit"
        />
        <modal-firma
          :idModal="4"
          :cargar.sync="cargarFirma"
          modulo="asistencias"
          title="Firmar asistencias"
          @processFirma="firmar"
        />
      </template>

      <template #foot(bloque_5)="data">
        <btn-guardar-sin-firmar
          v-if="data.field.estado_firma !== 1 || getUser.id_rol === 17"
          :can="true"
          :id_persona_rol_docente="hiddenBloques[4]['id_persona_rol_docente']"
          :num_bloque="5"
          :items="items"
          @processBtn="submit"
        />
        <modal-firma
          :idModal="5"
          :cargar.sync="cargarFirma"
          modulo="asistencias"
          title="Firmar asistencias"
          @processFirma="firmar"
        />
      </template>

      <template #foot(bloque_6)="data">
        <btn-guardar-sin-firmar
          v-if="data.field.estado_firma !== 1 || getUser.id_rol === 17"
          :can="true"
          :id_persona_rol_docente="hiddenBloques[5]['id_persona_rol_docente']"
          :num_bloque="6"
          :items="items"
          @processBtn="submit"
        />
        <modal-firma
          :idModal="6"
          :cargar.sync="cargarFirma"
          modulo="asistencias"
          title="Firmar asistencias"
          @processFirma="firmar"
        />
      </template>

      <template #foot(bloque_7)="data">
        <btn-guardar-sin-firmar
          v-if="data.field.estado_firma !== 1 || getUser.id_rol === 17"
          :can="true"
          :id_persona_rol_docente="hiddenBloques[6]['id_persona_rol_docente']"
          :num_bloque="7"
          :items="items"
          @processBtn="submit"
        />
        <modal-firma
          :idModal="7"
          :cargar.sync="cargarFirma"
          modulo="asistencias"
          title="Firmar asistencias"
          @processFirma="firmar"
        />
      </template>

      <template #foot(bloque_8)="data">
        <btn-guardar-sin-firmar
          v-if="data.field.estado_firma !== 1 || getUser.id_rol === 17"
          :can="true"
          :id_persona_rol_docente="hiddenBloques[7]['id_persona_rol_docente']"
          :num_bloque="8"
          :items="items"
          @processBtn="submit"
        />
        <modal-firma
          :idModal="8"
          :cargar.sync="cargarFirma"
          modulo="asistencias"
          title="Firmar asistencias"
          @processFirma="firmar"
        />
      </template>

      <template #foot(bloque_9)="data">
        <btn-guardar-sin-firmar
          v-if="data.field.estado_firma !== 1 || getUser.id_rol === 17"
          :can="true"
          :id_persona_rol_docente="hiddenBloques[8]['id_persona_rol_docente']"
          :num_bloque="9"
          :items="items"
          @processBtn="submit"
        />
        <modal-firma
          :idModal="9"
          :cargar.sync="cargarFirma"
          modulo="asistencias"
          title="Firmar asistencias"
          @processFirma="firmar"
        />
      </template>

      <template #foot(bloque_10)="data">
        <btn-guardar-sin-firmar
          v-if="data.field.estado_firma !== 1 || getUser.id_rol === 17"
          :can="true"
          :id_persona_rol_docente="hiddenBloques[9]['id_persona_rol_docente']"
          :num_bloque="10"
          :items="items"
          @processBtn="submit"
        />
        <modal-firma
          :idModal="10"
          :cargar.sync="cargarFirma"
          modulo="asistencias"
          title="Firmar asistencias"
          @processFirma="firmar"
        />
      </template>

      <template #foot(bloque_11)="data">
        <btn-guardar-sin-firmar
          v-if="data.field.estado_firma !== 1 || getUser.id_rol === 17"
          :can="true"
          :id_persona_rol_docente="hiddenBloques[10]['id_persona_rol_docente']"
          :num_bloque="11"
          :items="items"
          @processBtn="submit"
        />
        <modal-firma
          :idModal="11"
          :cargar.sync="cargarFirma"
          modulo="asistencias"
          title="Firmar asistencias"
          @processFirma="firmar"
        />
      </template>

      <template #foot(bloque_12)="data">
        <btn-guardar-sin-firmar
          v-if="data.field.estado_firma !== 1 || getUser.id_rol === 17"
          :can="true"
          :id_persona_rol_docente="hiddenBloques[11]['id_persona_rol_docente']"
          :num_bloque="12"
          :items="items"
          @processBtn="submit"
        />
        <modal-firma
          :idModal="12"
          :cargar.sync="cargarFirma"
          modulo="asistencias"
          title="Firmar asistencias"
          @processFirma="firmar"
        />
      </template>

      <template #foot(bloque_13)="data">
        <btn-guardar-sin-firmar
          v-if="data.field.estado_firma !== 1 || getUser.id_rol === 17"
          :can="true"
          :id_persona_rol_docente="hiddenBloques[12]['id_persona_rol_docente']"
          :num_bloque="13"
          :items="items"
          @processBtn="submit"
        />
        <modal-firma
          :idModal="13"
          :cargar.sync="cargarFirma"
          modulo="asistencias"
          title="Firmar asistencias"
          @processFirma="firmar"
        />
      </template>

      <template #foot(bloque_14)="data">
        <btn-guardar-sin-firmar
          v-if="data.field.estado_firma !== 1 || getUser.id_rol === 17"
          :can="true"
          :id_persona_rol_docente="hiddenBloques[13]['id_persona_rol_docente']"
          :num_bloque="14"
          :items="items"
          @processBtn="submit"
        />
        <modal-firma
          :idModal="14"
          :cargar.sync="cargarFirma"
          modulo="asistencias"
          title="Firmar asistencias"
          @processFirma="firmar"
        />

        <!-- <btn-guardar-sin-firmar
          class="btn-guardar"
          :id="item.id"
          :item="item"
          :disabled="disabled"
          @processBtn="submit"
        />
        <modal-firma
          v-if="!disabled"
          :idModal="item.id"
          :cargar.sync="cargarFirma"
          modulo="contenidos"
          title="Firmar registro de actividad"
          @processFirma="firmar"
          @processCancel="cargandoSubmit = false"
        /> -->
      </template>
    </BTable>
  </BOverlay>
</template>

<script>

// ETIQUETAS
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BFormInvalidFeedback,
  BMedia, BButton, BButtonGroup, BAvatar, BOverlay, BCard, BTab, BTabs,
  BFormRadioGroup, BTable, BCardText, BModal, BAlert, VBModal,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store/index'

import { mapGetters, mapActions } from 'vuex'

// COMPONENTES RECICLADOS
import spinner from '../../../../components/spinner.vue'
import estudianteRetirado from '../../../../components/List/estudianteRetirado.vue'
import modalFirma from '../../../../components/Firma/modalFirma.vue'

// COMPONENTES HIJOS
import AsistenciaAlumnoBloque from './components/AsistenciaAlumnoBloque.vue'
import btnGuardarSinFirmar from './components/btnGuardarSinFirmar.vue'

// MIXINS
import { formatos } from '@/mixins/formatos'

export default {
  components: {
    BRow, BCol, BFormGroup, BFormInput, BForm, BFormInvalidFeedback,
    BMedia, BButton, BButtonGroup, BAvatar, BOverlay, BCard, BTab, BTabs,
    BFormRadioGroup, BTable, BCardText, BModal, BAlert, VBModal,

    // COMPONENTES RECICLADOS
    spinner,
    estudianteRetirado,
    modalFirma,

    // COMPONENTES HIJOS
    AsistenciaAlumnoBloque,
    btnGuardarSinFirmar,
  },
  mixins: [formatos],
  directives: {
    Ripple,
  },
  data() {
    return {
      canFirma: true,
      cargarFirma: false,
      cargando: true,
      items: [],

      tipoAsistencia: [],

      asistenciasBloques: [],
      asistenciasBloqueUpdate: [],
      bloquesAlert: false,
      alertMsg: '',
      hiddenBloques: [],
      fields: [],
      numBloque: null,
      id_bloque: null,
      id_fecha_curso: null,

      fieldsTabla: [
        {
          key: 'num_lista',
          label: '#',
          variant: 'primary',
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '0% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'nombre_completo',
          stickyColumn: true,
          label: 'Estudiantes',
          thStyle: {
            variant: 'light',
            width: '5% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      getAsistencias: 'asistencias/getAsistencias',
      getTipoAsistencia: 'asistencias/getTipoAsistencia',
      getLibroSelected: 'libros/getLibroSelected',
      getResponseFirma: 'firmas/getResponse',
      getUser: 'auth/user',
    }),
  },
  watch: {
    diaSelected(val) {
      this.cargarAsistencias()
    },
    getLibroSelected(val) {
      this.cargarAsistencias()
    },
  },
  props: {
    diaSelected: {
      type: Number,
      required: false,
      default: null,
    },
    mesSelected: {
      type: Number,
      required: true,
    },
  },
  mounted() {
    this.initialize()
  },
  methods: {
    ...mapActions({
      fetchAsistencias: 'asistencias/fetchAsistencias',
      fetchTipoAsistencia: 'asistencias/fetchTipoAsistencia',
      addAsistencia: 'asistencias/addAsistencia',
      updateAsistencia: 'asistencias/updateAsistencia',
      addFirmaAsistencia: 'firmas/addFirmaAsistencia',
    }),
    async initialize() {
      this.cargando = true
      await Promise.all([
        this.cargarTipoAsistencia(),
        this.cargarAsistencias()
      ])
      this.cargando = false
    },
    async cargarTipoAsistencia() {
      this.tipoAsistencia = []
      this.tipoAsistencia = await this.fetchTipoAsistencia()
    },
    async cargarAsistencias() {
      const year = new Date().getFullYear()
      const fecha = year+'-'+ this.mesSelected +'-'+ this.diaSelected
      const params = {
        id_curso: this.getLibroSelected.id,
        fecha
      }
      const response = await this.fetchAsistencias(params)
      if (response.length !== 0) {
        this.setAlumnosAsistencias(response)
        this.asistenciasBloques = []
        this.asistenciasBloques = response
        this.hiddenBloques = []
        this.setBloques(response)
      } else {
        this.bloquesAlert = true
        this.alertMsg = 'El curso no tiene Estudiantes Matriculados.'
      }
    },
    setAlumnosAsistencias(estudiantes) {
      for (let i = 0; i < estudiantes.length; i++) {
        let estudiante = estudiantes[i];
        estudiante['nombre_completo'] = `${estudiante.primer_apellido}
                  ${estudiante.segundo_apellido}
                  ${estudiante.nombre}
                  ${estudiante.segundo_nombre !== null
                    ? estudiante.segundo_nombre
                    : ''}
                  `
        estudiante['fecha_retiro'] = estudiante.fecha_retiro
      }
      this.items = []
      this.items = estudiantes
    },
    setBloques(val) {
      if (typeof val[0]['bloques'] !== 'undefined') {
        const {bloques} = val.find(b => b.fecha_retiro === null)
        this.hiddenBloques = bloques
        this.cargarFieldsBloques(bloques)

      } else {
        const bloques = []
        this.cargarFieldsBloques(bloques)
      }
        // if (this.getLibroSelected.id_tipo_ensenanza === 10 ) { // parvulo
        //   this.cargarFieldsBloquesParvulo(bloques)
        // } else {
        //   }
      // else if (typeof val[0]['dia'] !== 'undefined') { // parvulo
      //   const {dia} = val.find(b => b.fecha_retiro === null)
      //   this.hiddenBloques = [dia]
      //   this.cargarFieldsBloquesParvulo(dia)
      // }
    },

    cargarFieldsBloques(bloques) {
      this.fields = []
      this.fieldsTabla.forEach(field => {
        this.fields.push(field)
      })

      this.bloquesAlert = bloques.length === 0
      this.alertMsg = 'El día no tiene horas asignadas.'
      bloques.forEach((bloque, index) => {
        const nombreAsignatura = `${bloque.nombre_asignatura}`
        const nombreDocente = `${bloque.nombre} ${bloque.primer_apellido} ${bloque.segundo_apellido}`

        const hora = `» ${this.formatHoraInput(bloque.hora_inicio)} - ${this.formatHoraInput(bloque.hora_termino)} «`
        const detalle = `${nombreDocente}
${nombreAsignatura}`
        const detalleCorto = `${nombreDocente.substring(0, 14)}
${nombreAsignatura.substring(0, 14)}`

        let headerTitle = ''
        let horaBloque = ''
        if (this.getLibroSelected.id_tipo_ensenanza === 10 ) {
          headerTitle = detalle
          horaBloque = detalleCorto
        } else {
          headerTitle = `${hora}
${detalle}`
          horaBloque = `${hora}
${detalleCorto}`
        }

        let tdClass
        let thClass
        if (typeof bloque.id_asistencia !== 'undefined') {
          if (bloque.estado_firma === 1) {
            tdClass = 'bg-light-success bg-lighten-5'
            thClass = 'text-success bg-light-success'
          }  else {
            tdClass = 'bg-light-primary bg-lighten-5'
            thClass = 'text-primary bg-light-primary'
          }
        }

        this.fields.push({
          isRowHeader: true,
          bloque: true,
          // dia: false,

          key: `bloque_${index + 1}`,
          label: horaBloque,
          tdClass: `text-center ${tdClass}`,
          headerTitle,
          // nombreVariant: bloque.isWeek ? 'secondary' : '',
          nombreBloque: `B${bloque.Bloque}`,
          thClass,
          horaBloque,
          estado_firma: bloque.estado_firma,
          id_bloque: bloque.id,
          tipoAsistencia: bloque.id_tipo_asistencia,
          tipo: bloque.tipo,
          thStyle: {
            'text-align': 'center',
            width: '10% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        })
      })
    },

    firmar(codigo) {
      this.cargarFirma = true
      let id_asistencias = []
      if (this.asistenciasBloqueUpdate.length === 0) {
        id_asistencias = this.getIdAsistencias
      } else {
        this.asistenciasBloqueUpdate.forEach(asistencia => {
          id_asistencias.push(asistencia.id_asistencia)
        })
      }

      let response
      let data = {
        id_bloque: this.id_bloque,
        id_fecha_curso: this.id_fecha_curso,
        token: codigo,
      }
      this.addFirmaAsistencia(data).then(() => {
        let title
        let text
        let variant
        let icon
        let estado_firma
        if (this.getResponseFirma.message === 'Registro Guardado. Estado Firma: Valida') {
          title = 'Firma válida 👍'
          text = 'La firma ingresada, ha sido validada con éxito!'
          variant = 'success'
          icon = 'CheckIcon'
          estado_firma = 1
        } else if (this.getResponseFirma.message === 'Registro Guardado. Estado Firma: No valida') {
          title = 'Firma no válida 🚨'
          text = 'La firma ingresada se ha registrado, pero no ha sido validada con éxito!'
          variant = 'warning'
          icon = 'error'
          estado_firma = 0
        } else if (this.getResponseFirma.message === 'Registro Guardado. Estado Firma: No se pudo verificar') {
          title = 'Firma no registrada 🚨'
          text = 'La firma ingresada no se ha registrado!'
          variant = 'danger'
          icon = 'error'
          estado_firma = 3
        }
        this.$toast({
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            variant,
          },
        },
        {
          position: 'bottom-right',
          timeout: 6000,
        })
        this.cargarFirma = false
        this.$bvModal.hide('modal-firma-'+this.numBloque)
        this.pintarBloque(this.id_bloque, estado_firma)
      })

    },

    submit(data) {
      const {
        boton,
        num_bloque,
      } = data

      this.cargando = true
      this.asistenciasBloqueUpdate = []
      const num = num_bloque - 1
      let id_bloque
      let asistenciasBloqueAdd = []

      this.asistenciasBloques.forEach(alumno => {
        id_bloque = alumno.bloques[num].id
        this.id_fecha_curso = alumno.bloques[num].id_fecha_curso

        if (typeof alumno.bloques[num].id_asistencia === 'undefined') {
          asistenciasBloqueAdd.push({
            id_alumno: alumno.id_persona_rol,
            id_bloque: alumno.bloques[num].id,
            tipo_asistencia: alumno.bloques[num].tipoAsistencia,
          })
        } else {
          this.asistenciasBloqueUpdate.push({
            id_alumno: alumno.id_persona_rol,
            id_bloque: alumno.bloques[num].id,
            tipo_asistencia: alumno.bloques[num].tipoAsistencia,
            id_asistencia: alumno.bloques[num].id_asistencia,
          })
        }
      })
      // CREATE
      if (asistenciasBloqueAdd.length > 0) {
        const year = new Date().getFullYear()
        const fecha = year+'-'+ this.mesSelected +'-'+ this.diaSelected
        const datos = {
          fecha,
          id_curso: this.getLibroSelected.id,
          alumnos: asistenciasBloqueAdd
        }
        this.addAsistenciaBloque(datos, id_bloque, num_bloque, boton)
      }
      // UPDATE
      if (this.asistenciasBloqueUpdate.length > 0) {
        if (boton === 'Guardar') {
          this.updateAsistenciaBloque(this.asistenciasBloqueUpdate, id_bloque, num_bloque, boton)
        } else if (boton === 'Firmar') {
          this.firmarAsistencias(data, id_bloque)
        }
      }
    },
    firmarAsistencias(datos, id_bloque) {
      const {
        num_bloque,
        boton,
      } = datos
        this.$swal({
          title: 'Deseas guardar los cambios antes de firmar?',
          text: "El bloque de asistencias que deseas firmar ha sido modificado.",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sí, guardar cambios!',
          cancelButtonText: 'Cancelar',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.updateAsistenciaBloque(this.asistenciasBloqueUpdate, id_bloque, num_bloque, boton)
          } else {
            this.cargando = false
          }
        })
    },

    addAsistenciaBloque(data, id_bloque, numBloque, boton) {
      this.addAsistencia(data).then(() => {
        const statusAsistencia = store.state.asistencias.status
        if (statusAsistencia === 200) {
          const id_fecha_curso = store.state.asistencias.id_fecha_curso
          const text = this.canFirma
            ? 'La asistencia ha sido ingresada con éxito, ahora puedes firmar digitalmente ingresando tu código de authy!'
            : 'La asistencia ha sido ingresada con éxito!'
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Asistencia guardada 👍',
              icon: 'CheckIcon',
              text,
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
            timeout: 10000,
          })
          this.cargando = false
          this.numBloque = numBloque
          this.id_bloque = id_bloque
          this.hiddenBloques[numBloque-1]['id_asistencia'] = 1
          this.id_fecha_curso = id_fecha_curso
          this.pintarBloque(id_bloque, null)
          if (this.canFirma && boton === 'Firmar') {
            this.$bvModal.show('modal-firma-'+numBloque)
          }
        }
        else {
          this.$swal({
            title: 'Error!',
            text: store.state.asistencias.message,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
        this.cargando = false
      })
    },
    updateAsistenciaBloque(asistenciasBloqueUpdate, id_bloque, numBloque, boton) {
      const countAsistencias = asistenciasBloqueUpdate.length
      asistenciasBloqueUpdate.forEach((bloque, index, array) => {
        const data = {
          id_asistencia: bloque.id_asistencia,
          tipo_asistencia: bloque.tipo_asistencia,
        }
        this.updateAsistencia(data).then(() => {
          if (countAsistencias === index + 1) {
            const statusAsistencia = store.state.asistencias.status
            if (statusAsistencia === 200) {
              const text = this.canFirma
                ? 'La asistencia ha sido ingresada con éxito, ahora puedes firmar digitalmente ingresando tu código de authy!'
                : 'La asistencia ha sido ingresada con éxito!'
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Asistencia guardada 👍',
                  icon: 'CheckIcon',
                  text,
                  variant: 'success',
                },
              },
              {
                position: 'bottom-right',
                timeout: 10000,
              })
              this.cargando = false
              this.numBloque = numBloque
              this.id_bloque = id_bloque
              this.pintarBloque(id_bloque, null)
              if (this.canFirma && boton === 'Firmar') {
                this.$bvModal.show('modal-firma-'+numBloque)
              }
            }
            else {
              this.$swal({
                title: 'Error!',
                text: store.state.asistencias,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }
          }
        })
      })
    },
    pintarBloque(id_bloque, estado_firma) {
      let thClass
      let tdClass

      if (estado_firma !== 1) {
        thClass = 'text-primary bg-light-primary'
        tdClass = 'text-center bg-light-primary bg-lighten-5'
      } else if (estado_firma === 1) {
        thClass = 'text-success bg-light-success'
        tdClass = 'text-center bg-light-success bg-lighten-5'
      }
      const field = this.fields.find(f => f.id_bloque === id_bloque)
      field.thClass = thClass
      field.tdClass = tdClass
      field.estado_firma = estado_firma
    },
    fechaActual() {
      const hoy = new Date()
      const dia = hoy.getDate()
      let mes = hoy.getMonth()
      mes = mes + 1
      const year = hoy.getFullYear()

      return year+'-'+mes+'-'+dia
    },
    closeModal() {
      this.initialState()
    },
    initialState() {
      this.items = []
      // this.tipoAsistencia = []
      // this.asistenciasBloques = []
      // this.hiddenBloques = []
      // this.fields = []
    },
  },
}
</script>

<style lang="scss">

.table thead tr th{
  white-space: pre-wrap
}
.table-hover tbody tr {
  cursor: initial !important,
}
</style>
