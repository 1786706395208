<template>
  <div
    class="text-center"
  >
    <spinner
      v-if="showSpinner"
      size="small"
    />
    <BFormInput
      :id="item.id_persona_rol+''+field.numNota"
      type="text"
      v-else-if="$can('update', 'notas') && field.key !== 'nota'"
      v-model="nota"
      placeholder=""
      size="xs"
      style="min-width: 53px !important;"
      :class="textColor+' text-center'"
      :disabled="evaluacionDisabled"
      :title="evaluacionDisabled ? 'Retirado el '+ fecha_retiro : ''"
      @keyup="!evaluacionDisabled ? changeNota() : ''"
      @blur="!evaluacionDisabled ? cambiaNota() : ''"
    />
    <div
      v-else
      :class="textColor"
    >
      {{ nota ? nota : '-' }}
    </div>

  </div>
</template>

<script>
import { BFormInput, VBTooltip } from 'bootstrap-vue'
import { mapActions } from 'vuex'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// FORMATOS
import { formatos } from '@/mixins/formatos'
import { compara } from '@/mixins/compara'

// COMPONENTES RECICLADOS
import spinner from '../../../../components/spinner.vue'

export default {
  components: {
    BFormInput,

    // COMPONENTES RECICLADOS
    spinner,
  },
  data() {
    return {
      showSpinne: false,
      nota: 0,
      id_nota: null,
      color: null,
      textColor: null,
      puntajesOption: [],
      autores: null,
      fecha_evaluacion: null,
      // fecha_retiro: null,
      evaluacionDisabled: false,
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [formatos, compara],
  props: {
    item: {
      type: Object,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    field: {
      type: Object,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
    modulo: {
      type: String,
      required: false,
    },
    fecha_retiro: {
      type: String,
      required: false,
    },
  },
  watch: {
    getNotas(getNotas) {
      this.setNotas(this.item, this.field)
    },
    field() {
      this.setNotas(this.item, this.field)
    },
  },
  computed: {
    showSpinner() {
      return false
    },
  },
  mounted() {
    this.setNotas(this.item, this.field)
  },
  methods: {
    ...mapActions({
      addNota: 'notas/addNota',
      updateNota: 'notas/updateNotaSumativa',
    }),
    setNotas(item, field) {
      if (field.evaluacion && field.key !== 'nota') {
        if (typeof item.notas !== 'undefined') {
          const nota = item.notas.find(n => n.id_evaluacion === Number(field.id_evaluacion))
          this.nota = typeof nota !== 'undefined'
            ? this.formatNota(nota.nota)
            : 0
          this.id_nota = typeof nota !== 'undefined'
            ? nota.id_nota
            : null
          this.fecha_evaluacion = this.formatoDiaMesYear(field.fecha_evaluacion)
          this.evaluacionDisabled = this.comparaFechas(this.fecha_retiro, null, field.fecha_evaluacion)
        } else {
          this.nota = 0
          this.id_nota = 0
          this.fecha_evaluacion = null
          this.evaluacionDisabled = this.comparaFechas(this.fecha_retiro, null, field.fecha_evaluacion)
        }
        this.evaluacionDisabled = field.evaluacion.tipo === 2
        this.id_evaluacion = field.id_evaluacion
        this.formatColor(this.nota)
      } else if (field.key === 'nota') {
        if (typeof item.notas !== 'undefined') {
          const nota = item.notas.find(n => n.id_evaluacion === Number(field.id_evaluacion))
          this.nota = typeof nota !== 'undefined'
            ? this.formatNota(nota.nota)
            : 0
        } else {
          this.nota = 0
          this.id_nota = 0
          this.fecha_evaluacion = null
        }
        this.id_evaluacion = 0
        this.formatColor(this.nota)
      }
    },
    changeNota(event) {
      this.direccionConFlechas(event)
      this.formatColor(this.nota)
      const nota = this.nota.replace('.', '')
      if (nota.length > 2) {
        this.nota = this.nota.replace('0', '')
        this.nota = nota[0]+''+nota[1]
      }
      if (nota[0] === "0") {
        if (typeof nota[1] !== 'undefined') {
          if (typeof nota[2] !== 'undefined') {
            this.nota = nota[1]+''+nota[2]
          } else {
            this.nota = nota[1]
          }
        } else {
          this.nota = '0'
        }
      }
    },
    cambiaNota() {
      this.nota = this.formatNota(this.nota)
      this.formatColor(this.nota)
      let notaSinPunto = this.nota.replace('.', '')
      if (notaSinPunto.substring(0,1) === '0' && notaSinPunto.lenght === 2) {
        notaSinPunto = notaSinPunto.substring(1)
      }
      if (parseInt(notaSinPunto.length) === 1 && parseInt(notaSinPunto) !== 0) {
        const cero = 0
        this.nota = notaSinPunto[0]+'.'+cero
        notaSinPunto = notaSinPunto[0]+cero
        if (parseInt(notaSinPunto) > 70) {
          this.nota = '7.0'
          notaSinPunto = '70'
        }
      }
      this.formatColor(this.nota)
      if (!this.id_nota) { // CREATE
        if (parseInt(notaSinPunto) !== 0) {
          const data = {
            id_nota: 0,
            id_persona_rol_alumno: this.item.id_persona_rol_alumno,
            id_evaluacion: this.id_evaluacion,
            nota: notaSinPunto,
          }
          this.crearNota(data)
        }
      } else { // UPDATE
        const notaCompare = this.item.notas.find(n => n.id_evaluacion === Number(this.field.id_evaluacion))
        if (typeof notaCompare !== 'undefined') {
          if (parseInt(notaSinPunto) !== parseInt(notaCompare.nota)) {
            const data = {
              id_nota: this.id_nota,
              id_persona_rol_alumno: this.item.id_persona_rol_alumno,
              id_evaluacion: this.id_evaluacion,
              nota: notaSinPunto,
            }
            notaCompare.nota = parseInt(notaSinPunto)
            this.editarNota(data)
          }
        }
      }
    },
    crearNota(data) {
      this.addNota(data).then(() => {
        const statusNotas = store.state.notas.status
        if (statusNotas === 'success') {
          this.mensaje('success', '')
          const id_nota = store.state.notas.id_nota
          this.id_nota = id_nota
          const datos = {
            datos_estudiante: store.state.notas.datos_estudiante,
            id_persona_rol_alumno: data.id_persona_rol_alumno,
            tipo: 'create',
            id_evaluacion: data.id_evaluacion,
            id_nota: id_nota,
            nota: data.nota,
          }
          this.$emit('updatePromedio', datos)
        }
        else {
          this.mensaje('error', store.state.notas.message)
        }
      })
    },
    editarNota(data) {
      this.updateNota(data).then(() => {
        const statusNotas = store.state.notas.status
        if (statusNotas === 'success') {
          this.mensaje('success', '')
          const datos = {
            datos_estudiante: store.state.notas.datos_estudiante,
            id_persona_rol_alumno: data.id_persona_rol_alumno,
            tipo: 'update',
          }
          this.$emit('updatePromedio', datos)
        }
        else {
          this.mensaje('error', store.state.notas.message)
        }
      })
    },

    mensaje(tipo, text) {
      if (tipo === 'success') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Nota guardada 👍',
            icon: 'CheckIcon',
            text,
            variant: 'success',
          },
        },
        {
          position: 'top-right',
          timeout: 2000,
        })
      } else if (tipo === 'error'){
        this.$swal({
          title: 'Error!',
          text,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }
    },
    direccionConFlechas() {
      const numNota = this.field.numNota
      let idInputDestino
      if (event.key === 'ArrowUp') {
        const keyItem = this.items.findIndex(i => i.id_persona_rol_alumno === this.item.id_persona_rol_alumno)
        if (keyItem > 0) {
          idInputDestino = this.items[keyItem - 1].id_persona_rol_alumno+''+this.field.numNota
          document.getElementById(idInputDestino).focus()
        }
      }
      if (event.key === 'ArrowDown') {
        const keyItem = this.items.findIndex(i => i.id_persona_rol_alumno === this.item.id_persona_rol_alumno)
        if (keyItem < this.items.length - 1) {
          idInputDestino = this.items[keyItem + 1].id_persona_rol_alumno+''+this.field.numNota
          document.getElementById(idInputDestino).focus()
        }
      }
      if (event.key === 'ArrowLeft') {
        if (numNota > 1) {
          const keyField = this.fields.findIndex(f => f.numNota === this.field.numNota)
          const notaDestino = parseInt(numNota) - 1
          const numNotaFieldDestino = notaDestino
          if (this.fields[keyField - 1].numNota === numNotaFieldDestino) {
            idInputDestino = this.item.id_persona_rol_alumno+''+this.fields[keyField - 1].numNota
            document.getElementById(idInputDestino).focus()
          }
        }
      }
      if (event.key === 'ArrowRight') {
        const keyField = this.fields.findIndex(f => f.numNota === this.field.numNota)
        if (keyField < this.fields.length - 1) {
          const notaDestino = parseInt(numNota) + 1
          const numNotaFieldDestino = notaDestino
          if (this.fields[keyField + 1].numNota === numNotaFieldDestino) {
            idInputDestino = this.item.id_persona_rol_alumno+''+this.fields[keyField + 1].numNota
            document.getElementById(idInputDestino).focus()
          }
        }
      }
    },
  },
}
</script>

<style lang="css">
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
