<template>
  <BOverlay
    :show="cargando"
    spinner-variant="primary"
    variant="semi-dark"
  >
    <BRow class="mt-2">
      <!-- EVALUACION DE PROCESO Y ACANCE -->
      <BCol id="col-EVALUACION-PROCESO" md="12">
        <BFormGroup
          label="Evaluación de proceso y avance"
          label-for="proceso"
        >
          <BFormTextarea
            v-model="evaluacion.estrategia"
            id="proceso"
            :placeholder="$can('update', nombre_permiso)
                ? `Indique estrategias y procedimientos que aplicará para las siguientes evaluaciones:
- Evaluaciones para el aprendizaje, periódicas, se recomiendan quincenales.
- Evaluaciones de resultados; trimestrales, semestrales con informe escrito a la Familia.`
                : 'Sin información'"
            :plaintext="!$can('update', nombre_permiso)"
            rows="4"
            maxlength="550"
            :state="v$.evaluacion.estrategia.$error === true
              ? false
              : null"
            @blur.native="v$.evaluacion.estrategia.$touch"
          />
          <small class="text-muted text-right d-block mt-25">{{ evaluacion.estrategia.length }} / 550</small>
          <BFormInvalidFeedback v-if="v$.evaluacion.estrategia.$error"
            id="evaluacionInfo"
            class="text-right"
          >
            <p v-for="error of v$.evaluacion.estrategia.$errors" :key="error.$uid" >
              {{ error.$message }}
            </p>
          </BFormInvalidFeedback>
        </BFormGroup>
      </BCol>

      <!-- EVALUACIÓN ANUAL -->
      <BCol id="col-EVALUACIÓN-ANUAL" md="12">
        <BFormGroup
          label="Evaluación anual de logros de aprendizaje; de evolución
          del déficit o trastorno, de egreso o de continuidad en el PIE"
          label-for="evaluacionAnual"
        >
          <BFormTextarea
            v-model="evaluacion.evaluacion"
            id="evaluacionAnual"
            :placeholder="$can('update', nombre_permiso)
              ? `Indique estrategias y procedimientos que aplicará para las siguientes evaluaciones:
- Evaluaciones para el aprendizaje, periódicas, se recomiendan quincenales.
- Evaluaciones de resultados; trimestrales, semestrales con informe escrito a la Familia.`
              : 'Sin información'"
            :plaintext="!$can('update', nombre_permiso)"
            rows="4"
            maxlength="550"
            :state="v$.evaluacion.evaluacion.$error === true
              ? false
              : null"
            @blur.native="v$.evaluacion.evaluacion.$touch"
          />
          <small class="text-muted text-right d-block mt-25">{{ evaluacion.evaluacion.length }} / 550</small>
          <BFormInvalidFeedback v-if="v$.evaluacion.evaluacion.$error"
            id="evaluacionAnualInfo"
            class="text-right"
          >
            <p v-for="error of v$.evaluacion.evaluacion.$errors" :key="error.$uid">
              {{ error.$message }}
            </p>
          </BFormInvalidFeedback>
        </BFormGroup>
      </BCol>

      <!-- OBSERVACIONES -->
      <BCol id="col-OBSERVACIONES" md="12">
        <BFormGroup
          label="Observaciones"
          label-for="observaciones"
        >
          <BFormTextarea
            v-model="evaluacion.observaciones"
            id="observaciones"
            placeholder="Indique las observaciones"
            :placeholder="$can('update', nombre_permiso)
              ? 'Indique las observaciones'
              : 'Sin información'"
            :plaintext="!$can('update', nombre_permiso)"
            rows="4"
            maxlength="550"
            :state="v$.evaluacion.observaciones.$error === true
              ? false
              : null"
            @blur.native="v$.evaluacion.observaciones.$touch"
          />
          <small class="text-muted text-right d-block mt-25">{{ evaluacion.observaciones.length }} / 550</small>
          <BFormInvalidFeedback v-if="v$.evaluacion.observaciones.$error"
            id="observacionesInfo"
            class="text-right"
          >
            <p v-for="error of v$.evaluacion.observaciones.$errors" :key="error.$uid">
              {{ error.$message }}
            </p>
          </BFormInvalidFeedback>
        </BFormGroup>
      </BCol>

    </BRow>

    <colLinea />

    <div id="Action-buttons" class="text-right" style="height: 69px !important;">
      <btnSubmit
        class="float-right"
        variant="primary"
        :modulo="nombre_permiso"
        :disabled="this.v$.evaluacion.$errors.length > 0"
        btnText="Actualizar Estrategia"
        @processBtn="submitOption"
      />
    </div>
  </BOverlay>
</template>

<script>

// ETIQUETAS
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BButton, BOverlay, BCardText,
  BFormTextarea, BFormInvalidFeedback
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'

// VALIDACIONES
import useVuelidate from '@vuelidate/core'
import { maxLength, helpers } from '@vuelidate/validators'

// COMPONENTES RECICLADOS
import colLinea from '@/views/components/Form/colLinea.vue'
import btnSubmit from '@/views/components/Form/btnSubmit.vue'

// FORMATOS
import { formatos } from '@/mixins/formatos'
import { toast } from '@/mixins/alerts'

export default {
  components: {
    BForm, BFormGroup, BFormInput, BRow, BCol, BButton, BOverlay, BCardText,
    BFormTextarea, BFormInvalidFeedback,
    colLinea,
    btnSubmit,
  },
  mixins: [formatos, toast],
  data() {
    return {
      nombre_permiso: 'pieII3C',
      evaluacion: {
        estrategia: '',
        evaluacion: '',
        observaciones: '',
      },
      cargando: false,
    }
  },
  computed: {
    ...mapGetters({ getLibroSelected: 'libros/getLibroSelected' }),
  },
  watch: {
    getLibroSelected(val) {
      this.cargarEvaluacion()
    },
  },
  mounted() {
    this.cargarEvaluacion()
  },
  methods: {
    ...mapActions({
      fetchEvaluacion: 'II_3_c_evaluacion/fetchEvaluacion',
      addEvaluacion: 'II_3_c_evaluacion/addEvaluacion',
      updateEvaluacion: 'II_3_c_evaluacion/updateEvaluacion',
    }),
    async cargarEvaluacion() {
      this.cargando = true
      const res = await this.fetchEvaluacion(this.getLibroSelected.id)
      if (res.status === 'success') {
        this.evaluacion = res.data
      } else {
        this.$toastError('Error al cargar los datos !', res.message)
      }
      this.cargando = false
    },
    async actualizarPanorama() {
      this.spinner = true
      const params = { ...this.evaluacion, idCurso: this.getLibroSelected.id } 
      const res = this.evaluacion.id === null
        ? await this.addEvaluacion(params)
        : await this.updateEvaluacion(params)

      if (res.status === 'success') {
        this.$toastSuccess(
          'Registro actualizado 👍', 
          'Las estrategias y procedimientos de evaluación han sido actualizadas con éxito!'
        )
        this.cargarEvaluacion()
      } else {
        this.$toastError('Error al actualizar los datos !', res.message)
      }
      this.spinner = false
    },
    submitOption() {
      this.v$.evaluacion.$touch()
      if (!this.v$.evaluacion.$invalid) {
        const html = this.formatHTMLSweetInfo('las estrategias y procedimientos de evaluación', '')
        this.$swal({
          title: 'Guardar cambios!',
          html,
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: 'Sí, guardar',
          cancelButtonText: 'Cancelar',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-secondary ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.actualizarPanorama()
          }
          return false
        })
      }
    },
  },
  validations() {
    return {
      evaluacion: {
        estrategia: {
          maxLength: helpers.withMessage(
            'Debe tener un máximo de 550 caracteres.',
            maxLength(550)),
        },
        evaluacion: {
          maxLength: helpers.withMessage(
            'Debe tener un máximo de 550 caracteres.',
            maxLength(550)),
        },
        observaciones: {
          maxLength: helpers.withMessage(
            'Debe tener un máximo de 550 caracteres.',
            maxLength(550)),
        },
      }
    }
  },
  setup() {
    return { v$: useVuelidate() }
  },
}
</script>
