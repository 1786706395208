<template>
  <AplicacionForm
    :nombreModal="modal"
    title="Editar la acción de aplicación de las estrategias diversificadas planificadas"
    :data="item"
    @processForm="editar"
  />
</template>

<script>
import { mapActions } from 'vuex'
import { toast, swal } from '@/mixins/alerts'
import AplicacionForm from './AplicacionForm.vue'

export default {
  components: {
    AplicacionForm,
  },
  mixins: [toast, swal],
  props: {
    item: {
      type: Object,
      required: true,
    },
    modal: {
      type: String,
      required: true,
    },
  },
  methods: {
    ...mapActions({
      fetchAplicaciones: 'III_1_a_acciones_de_aplicacion/fetchAplicaciones',
      updateAplicacion: 'III_1_a_acciones_de_aplicacion/updateAplicacion',
    }),
    async editar(aplicacion) {
      const res = await this.updateAplicacion(aplicacion)
      if (res.status === 'success') {
        await this.fetchAplicaciones(aplicacion.id_curso)
        
        this.$toastSuccess(
          'Aplicación guardada 👍', 
          'La aplicación fue editada con éxito!'
        )
        this.$bvModal.hide(this.modal)
      } else {
        this.$swalError('Error al editar la aplicacion!', res.message)
      }
    },
  },
}
</script>
