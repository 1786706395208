<template>
  <BOverlay
    :show="spinner"
    spinner-variant="primary"
    variant="semi-dark"
  >
    <BRow>
      <BCol
        lg="2"
        md="3"
        sm="3"
        class="my-50"
      >
        <!-- BOTON MOSTRAR -->
        <!-- <btnMostrar
          :pageOptions.sync="pageOptions"
          :perPage.sync="perPage"
          :total.sync="items.length"
        /> -->
        <!-- {{ items }} -->
      </BCol>
      <BCol
        lg="6"
        md="5"
        sm="5"
        class="my-50"
      >
        <!-- FILTRO -->
        <!-- <inputFiltro
          :filter.sync="filter"
        /> -->


        <!-- editar debe enviar id y si cambia  -->

      </BCol>
      <BCol
        md="4"
        sm="4"
        class="my-50"
      >
        <!-- CREAR -->
        <PlanApoyoCreate/>

        <!-- BOTON CREAR -->
        <btnCrearModal
          :modulo="nombre_permiso"
          accion="Registrar"
          texto="Plan de Apoyo"
          modal="modal-create"
        />
      </BCol>

      <BCol cols="12" style="min-height: 490px !important;">
        <BTableSimple
          striped
          small
          hover
          noCollapse
          responsive
          bordered
          class="mt-50 rounded"
          :busy="cargando"
          :per-page="perPage"
          :current-page="currentPage"
        >
          <BThead head-variant="light">

            <!-- FILAS -->
            <BTr>
              <b-th
                v-for="(field, key) in fields"
                :key="key"
                :class="field.class"
                :style="field.style"
              >
                {{ field.label }}
              </b-th>
            </BTr>
          </BThead>

          <BTbody
            v-for="(item, key) in items"
            :key="key"
          >
            <!-- DESCRIPCIÓN -->
            <BTr>
              <BTd
                class="text-center bg-light-primary"
                colspan="5"
              >
                <div class="text-center">
                  <b>{{ item.descripcion }}</b>
                </div>
              </BTd>

              <!-- ACCIONES -->
              <BTd
                class="pl-25 pr-25 text-center bg-light-primary"
                :rowspan="item.apoyoEspecializado.length + 3"
              >

                <!-- HORARIOS -->
                <BModal
                  :id="'horarios-'+item.id"
                  title="Horarios del plan de apoyo individual"
                  centered
                  size="xl"
                  :hide-footer="true"
                >
                  <BRow class="text-center mt-50 mb-25">
                    <BCol>
                      <b>Descripción:</b> {{ item.descripcion }}
                    </BCol>
                  </BRow>
                  <BRow class="text-center mb-1">
                    <BCol>
                      <b>Alumno:</b> {{ item.alumno.title }}
                    </BCol>
                  </BRow>

                  <calendario-horarios
                    :idModal="'horarios-'+item.id"
                    :apoyoEspecializado.sync="item.apoyoEspecializado"
                    :idPlanApoyo="item.id"
                    title="Horarios del plan de apoyo individual"
                    :planApoyo="item"
                  />
                </BModal>
                <BButton
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="primary"
                  class="btn-sm mb-25 pl-75 pr-75"
                  @click="goHorario('horarios-'+item.id, item)"
                >
                <!-- v-BModal="" -->
                  <feather-icon
                    icon="CalendarIcon"
                  />
                  Horarios
                </BButton>

                <!-- UPDATE -->
                <PlanApoyoUpdate
                  :modal="'modal-update-'+item.id"
                  :item="item"
                />

                <PlanApoyoClone
                  :modal="'modal-clone-'+item.id"
                  :planApoyo="item"
                  submitTitle="Guardar Plan de Apoyo"
                  title="Registrar plan de apoyo individual"
                />

                <colAccionesBtnes
                  :data="item"
                  :modal="'modal-update-'+item.id"
                  :modulo="nombre_permiso"
                  clone="true"
                  :modalClone="'modal-clone-'+item.id"
                  @processRemove="remove(item)"
                />
              </BTd>
            </BTr>

            <BTr>
              <!-- ALUMNOS -->
              <BTd
                :rowspan="item.apoyoEspecializado.length + 1"
                class="text-center"
              >
                {{ item.alumno.title }}
              </BTd>
              <BTd class="pl-0 pr-0 pt-0 pb-0"></BTd>
              <BTd class="pl-0 pr-0 pt-0 pb-0"></BTd>

              <!-- FECHA INICIO -->
              <BTd
                class="pl-25 pr-25 text-center"
                :rowspan="item.apoyoEspecializado.length + 1"
              >
                {{ formatFechaVer(item.fecha_inicio) }}
              </BTd>

              <!-- FECHA TERMINO -->
              <BTd
                class="pl-25 pr-25 text-center"
                :rowspan="item.apoyoEspecializado.length + 1"
              >
                {{ formatFechaVer(item.fecha_termino) }}
              </BTd>


            </BTr>

            <template
              v-for="(apoyo, key2) in item.apoyoEspecializado"
            >

              <BTr>
                <BTd>
                  <b>{{ apoyo.cargo}}:</b><br>
                  {{ apoyo.nombre}}
                </BTd>
                <BTd v-if="apoyo.horarios.length !== 0">
                  <BRow
                    v-for="(horario, key) in apoyo.horarios"
                    :key="key"
                  >
                    <BCol class="text-right pr-25">{{ horario.dia }}</BCol>
                    <BCol class="text-left pl-25">{{ horario.hora }} hrs.</BCol>
                  </BRow>
                </BTd>
                <BTd v-else class="text-center">
                  Sin horario
                </BTd>
              </BTr>

            </template>

            <!-- OBSERVACIONES -->
            <BTr>
              <BTd
                class="text-left pb-1 pt-1"
                colspan="5"
              >
                <b>Observaciones:</b> 
                <VerMas
                  class="mt-50"
                  :id="`observaciones-${item.id}`"
                  :texto="item.observaciones"
                  :longText="330"
                />
                <!-- {{ item.observaciones }} -->
              </BTd>
            </BTr>
          </BTbody>
        </BTableSimple>
        <BAlert
          v-if="items.length === 0"
          variant="primary"
          show
          class="text-center pt-25 pb-25"
        >
          <div class="alert-body">
            <span>No existen planes de apoyo registrados.</span>
          </div>
        </BAlert>
      </BCol>
    </BRow>
  </BOverlay>
</template>

<script>

// ETIQUETAS
import {
  BTableSimple, BRow, BCol, BPagination, BOverlay, BButton, VBModal, BTd, BTr, 
  BTh, BTbody, BAlert, BThead,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import Ripple from 'vue-ripple-directive'

import calendarStoreModule from './PlanApoyo/Horarios/calendarStoreModule'

// COMPONENTES RECICLADOS
// import inputFiltro from '@/views/components/List/inputFiltro.vue'
import btnCrearModal from '@/views/components/List/btnCrearModal.vue'
import btnMostrar from '@/views/components/List/btnMostrar.vue'
import colPeriodo from '@/views/components/List/colPeriodo.vue'
import colEstado from '@/views/components/List/colEstado.vue'
import spinner from '@/views/components/spinner.vue'
import colNombreImg from '@/views/components/List/colNombreImg.vue'
import colAccionesBtnes from '@/views/components/List/colAccionesBtnes.vue'
// import colAccionesBtnes from '../components/colAccionesBtnes.vue'

// COMPONENTES HIJOS
import PlanApoyoCreate from './PlanApoyo/PlanApoyoCreate.vue'
import PlanApoyoUpdate from './PlanApoyo/PlanApoyoUpdate.vue'
import PlanApoyoClone from './PlanApoyo/PlanApoyoClone.vue'

import calendarioHorarios from './PlanApoyo/Horarios/CalendarioHorarios.vue'

// FORMATOS
import { formatos } from '@/mixins/formatos';
import VerMas from '@/views/components/List/verMas.vue'

export default {
  components: {
    BTableSimple, BRow, BCol, BPagination, BOverlay, BButton, VBModal, BTd, BTr, 
    BTh, BTbody, BAlert, BThead,

    // COMPONENTES RECICLADOS
    // inputFiltro,
    btnCrearModal,
    colAccionesBtnes,
    btnMostrar,
    colPeriodo,
    colEstado,
    spinner,
    colNombreImg,
    VerMas,

    // COMPONENTES HIJOS
    PlanApoyoCreate,
    PlanApoyoUpdate,
    PlanApoyoClone,
    calendarioHorarios,
    calendarStoreModule,
  },
  directives: {
    'BModal': VBModal,
    Ripple,
  },
  mixins: [formatos],
  data() {
    return {
      nombre_permiso: 'pieII4',
      cargando: false,
      spinner: false,
      items: [],
      perPage: 10,
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: '',
      filterOn: [],
      pageOptions: [10, 25, 50],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'alumno',
          label: 'Nombre del estudiante',
          sortable: true,

          style: 'display: table-cell; vertical-align: middle; width: 24%;',
          thStyle: {
            width: '100px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'apoyos',
          label: 'Apoyo especializado requerido',
          sortable: true,
          style: 'display: table-cell; vertical-align: middle; width: 35%;',
          thStyle: {
            width: '150px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'horarios',
          label: 'Horario día/hora',
          style: 'width: 160px !important',
          class: 'text-center',
          sortable: true,
          style: 'display: table-cell; vertical-align: middle; width: 16%;',
          thStyle: {
            width: '60px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'fecha_inicio',
          label: 'Fecha inicio',
          sortable: true,
          class: 'text-center',
          style: 'display: table-cell; vertical-align: middle; width: 10%;',
          thStyle: {
            width: '60px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'fecha_termino',
          label: 'Fecha término',
          sortable: true,
          class: 'text-center',
          style: 'display: table-cell; vertical-align: middle; width: 10%;',
          thStyle: {
            width: '60px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        // {
        //   key: 'acciones',
        //   label: 'acciones',
        //   class: 'text-center',
        //   style: 'display: table-cell; vertical-align: middle; width: 2% !important;',
        //   thStyle: {
        //     width: '80px !important',
        //     'text-align': 'center',
        //     display: 'table-cell',
        //     'vertical-align': 'middle',
        //   },
        // },
        // {
        //   key: 'estado',
        //   label: 'Estado',
        //   sortable: true,
        //   tdClass: 'text-center',
        //   thStyle: {
        //     'text-align': 'center',
        //     width: '100px !important',
        //     display: 'table-cell',
        //     'vertical-align': 'middle',
        //   },
        // },
      ],
      fieldAcciones: {
        key: 'acciones',
        label: 'acciones',
        class: 'text-center',
        style: 'display: table-cell; vertical-align: middle; width: 2% !important;',
        thStyle: {
          width: '80px !important',
          'text-align': 'center',
          display: 'table-cell',
          'vertical-align': 'middle',
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      getPlanApoyos: 'II_4_plan_apoyo/getPlanApoyos',
      getLibroSelected: 'libros/getLibroSelected',
    }),
  },
  watch: {
    getLibroSelected(val) {
      this.setPlanApoyos(this.getLibroSelected.id)
    },
    getPlanApoyos(val) {
      this.cargaPlanApoyos(this.getPlanApoyos)
    },
  },
  mounted() { this.inicialize(); },
  methods: {
    ...mapActions({
      fetchPlanApoyos: 'II_4_plan_apoyo/fetchPlanApoyos',
      removePlanApoyo: 'II_4_plan_apoyo/removePlanApoyo',
    }),
    async inicialize () {
      await Promise.all([
        this.setPlanApoyos(),
        this.setTableList()
      ])
    },
    setTableList() {
      // if (this.$can('update', 'reunionesCoordinaciones')
      //   || this.$can('delete', 'reunionesCoordinaciones')
      // ) {
        this.fields.push(this.fieldAcciones)
      // }
    },
    async setPlanApoyos() {
      await this.fetchPlanApoyos(this.getLibroSelected.id)
    },
    cargaPlanApoyos(planApoyos) {
      this.items = []
      if (planApoyos.status === 'error') {
        this.cargando = false;
        return;
      }
      this.items = planApoyos.map(plan => {
        // Extrae el alumno y su nombre completo
        const alumno = plan.personas.find(p => p.id_rol === 10); // ROL ALUMNO
        const nombreAlumno = `${alumno.nombre} ${alumno.primer_apellido} ${alumno.segundo_apellido}`;

        // Función auxiliar para generar el objeto de apoyo especializado
        const crearApoyoEspecializado = (apoyo) => {
          const nombreApoyo = `${apoyo.nombre} ${apoyo.primer_apellido} ${apoyo.segundo_apellido}`;
          const titleApoyo = `${apoyo.nombre} ${apoyo.primer_apellido} - ${apoyo.nombre_rol}`;
          return {
            value: apoyo.id_persona_rol,
            id_plan_apoyo_persona: apoyo.id,
            title: titleApoyo,
            nombre: nombreApoyo,
            cargo: apoyo.nombre_rol,
            horarios: []
          };
        };

        const apoyoEspecializado = plan.personas
          .filter(p => p.id_rol !== 10) // todos menos estudiantes
          .map(crearApoyoEspecializado);

        return {
          ...plan,
          alumno: {
            value: alumno.id_persona_rol,
            title: nombreAlumno
          },
          apoyoEspecializado
        };
      });
      this.cargando = false
    },

    // HORARIO
    goHorario(nombreModal, planApoyo) {
      this.cargaIdPlanApoyo(planApoyo.id)
      this.cargaIdPersonaRol(1)
      this.$bvModal.show(nombreModal)
    },
    cargaIdPlanApoyo(id_plan_apoyo) {
      calendarStoreModule.state.id_plan_apoyo = id_plan_apoyo
    },
    cargaIdPersonaRol(id_persona_rol) {
      calendarStoreModule.state.id_persona_rol = id_persona_rol
    },
    remove(reunionesCoordinacion) {
      this.$swal({
        title: 'Eliminar reunionesCoordinacion!',
        text: `Estás seguro que deseas eliminar el reunionesCoordinacion
          "${reunionesCoordinacion.nombre}"?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, eliminalo!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        this.spinner = true
        if (result.value) {
          // this.removeReunionesCoordinacions(reunionesCoordinacion.id).then(() => {
          //   this.$swal({
          //     icon: 'success',
          //     title: 'Eliminado con éxito!',
          //     text: `"${reunionesCoordinacion.nombre}" ha sido eliminada!`,
          //     customClass: {
          //       confirmButton: 'btn btn-success',
          //     },
          //   })
          //   this.spinner = false
          // })
        } else {
          this.spinner = false
        }
      })
    },

    // Vuexy
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of
      // buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>

<style lang="css">
  .text-center {
    text-align: center;
  }
</style>
