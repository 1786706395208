<template>
  <BCard class="mb-75 text-white bg-primary">
    <BRow>

      <BCol xs="12" md="5">
        <p v-if="$can('create', 'profesorjefe')"
          class="text-left text-light mb-25 h4 font-weight-normal"
        >
          Libro
        </p>
        <p v-else
          class="text-left text-light mb-25 h4 font-weight-normal"
        >
          Libro
        </p>
        <BOverlay
          :show="cargando"
          spinner-variant="primary"
          variant="semi-dark"
        >
          <v-select
            v-model="idCurso"
            placeholder="Seleccione un libro..."
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :options="cursosOption"
            :reduce="option => option.idCurso"
            input-id="id_curso"
            class="bg-light mb-0 mt-25"
            style="border-radius: 6px;"
            :clearable="false"
            @input="changeLibro(idCurso)"
          />
        </BOverlay>
      </BCol>
      <BCol
        xs="12"
        md="1"
      ></BCol>

      <!-- PROFESOR JEFE -->
      <BCol
        v-if="idCurso"
        xs="12"
        md="5"
        class="mt-2 mt-md-0 ml-75"
      >
        <p v-if="$can('create', 'profesorjefe')"
          class="text-left text-light h4 mb-25 font-weight-normal"
        >
          Profesor jefe ★
        </p>
        <p v-else-if="profesorJefe"
          class="text-left text-center text-light mb-50 h4 font-weight-normal"
        >
          Profesor jefe ★
        </p>

        <BOverlay
          :show="cargandoProfesorJefe"
          spinner-variant="primary"
          variant="semi-dark"
        >
          <v-select
            v-if="$can('create', 'profesorjefe')"
            v-model="id_persona_rol_profesor_jefe"
            placeholder="Seleccione el/la profesor/a jefe..."
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :options="docentesOption"
            :reduce="option => option.id_persona_rol_profesor_jefe"
            input-id="id_curso"
            class="bg-light mb-0"
            style="border-radius: 6px;"
            :clearable="false"
            @input="submitProfesorJefe(id_persona_rol_profesor_jefe)"
          />
          <p v-else class="text-left text-center h5 text-white">
            {{ profesorJefe.nombre }} {{ profesorJefe.segundo_nombre }} {{ profesorJefe.primer_apellido }} {{ profesorJefe.segundo_apellido }}
          </p>
        </BOverlay>
      </BCol>
      <BCol
        xs="12"
        md="1"
      ></BCol>

    </BRow>

    <!-- PANORAMA DEL CURSO -->
    <BTabs
      v-if="panorama.estilo || panorama.fortaleza || panorama.necesidad"
      v-model="tabIndex"
      class="mt-2 bg-primary"
      style="margin-left: -21px;"
    >
      <BTab v-if="panorama.estilo"
        :title-link-class="linkClass(0)" title="Estilos y modos de aprendizaje"
        active
      >
        <BCardText style="margin-left: 17px;">
          {{ panorama.estilo }}
        </BCardText>
      </BTab>
      <BTab v-if="panorama.fortaleza" :title-link-class="linkClass(1)" title="Fortalezas">
        <BCardText style="margin-left: 17px;">
          {{ panorama.fortaleza }}
        </BCardText>
      </BTab>
      <BTab v-if="panorama.necesidad" :title-link-class="linkClass(2)" title="Necesidades de apoyo">
        <BCardText style="margin-left: 17px;">
          {{ panorama.necesidad }}
        </BCardText>
      </BTab>
    </BTabs>
  </BCard>
</template>

<script>

// ETIQUETAS
import {
  BRow, BCol, BOverlay, BCard, BCardText, BCardTitle, BTabs, BTab,
} from 'bootstrap-vue'
import { mapMutations, mapGetters, mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'

import vSelect from 'vue-select'

export default {
  components: {
    BRow, BCol, BOverlay, BCard, BCardText, BCardTitle, BTabs, BTab,
    vSelect,
  },
  data() {
    return {
      collapseType: 'border',
      cargando: true,
      cargandoProfesorJefe: true,
      idCurso: null,
      tabIndex: 0,
      profesorJefe: '',
      id_persona_rol_profesor_jefe: null,
      cursosOption: [],
      docentesOption: [],
      panorama:[],
      libros: [],
    }
  },
  props: {
    libro: {
      type: Object,
      required: false,
    },
  },
  computed: {
    ...mapGetters({
      getLibroSelected: 'libros/getLibroSelected',
      getPanorama: 'II_1_a_panorama/getPanorama',
      getCursosPersonaRol: 'cursos/getCursosPersonaRol',
      getPersonasRol: 'personas/getPersonasRol',
      getUser: 'auth/user',
    }),
  },
  watch: {
    getPanorama(getPanorama) {
      if (getPanorama.message !== 'Registro no existe') {
        this.panorama = getPanorama
      } else {
        this.panorama = {
          'estilo': false,
          'fortaleza': false,
          'necesidad': false,
        }
      }
    },
    getCursosPersonaRol(getCursosPersonaRol) {
      this.libros = getCursosPersonaRol
      this.setCursosOption(getCursosPersonaRol)
    },
    getPersonasRol(getPersonasRol) {
      this.setDocentesOption(getPersonasRol)
    },
  },
  mounted() {
    this.setLibro()
    this.cargaCursosPersonaRol()
    this.cargaDocentes()
  },
  methods: {
    ...mapMutations('libros', ['setLibro']),
    ...mapActions({
      fetchPanorama: 'II_1_a_panorama/fetchPanorama',
      fetchCursosPersonaRol: 'cursos/fetchCursosPersonaRol',
      addProfesorJefe: 'cursos/addProfesorJefe',
      fetchPersonasRol: 'personas/fetchPersonasRol',
    }),
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ['text-white']
      } else {
        return ['text-muted']
      }
    },
    cargaCursosPersonaRol() {
      this.fetchCursosPersonaRol()
    },
    cargaDocentes() {
      const data = {
        id_rol: 2, // Docente
        id_establecimiento: this.getUser.id_establecimiento,
      }
      this.fetchPersonasRol(data)
    },
    cargarPanorama(idCurso) {
      this.cargando = true
      this.fetchPanorama(this.idCurso).then(() => {
        if (this.getPanorama.message !== 'Registro no existe') {
          this.panorama = this.getPanorama
        } else {
          this.panorama = {
            'estilo': false,
            'fortaleza': false,
            'necesidad': false,
          }
        }
        this.cargando = false
      })
    },
    setCursosOption(libros) {
      if (libros.length != 0) {
        this.cargando = true
        this.cursosOption = []
        libros.forEach(libro => {
          let title = this.getUser.id_persona_rol === libro.id_persona_rol_docente_jefe
            ? libro.nombre+' '+libro.letra+' ★ '
            : libro.nombre+' '+libro.letra
          this.cursosOption.push({
            'idCurso': libro.id,
            title,
          })
        })
      }
      this.cargando = false
    },
    setDocentesOption(docentes) {
      this.cargandoProfesorJefe = true
      this.docentesOption = []
      docentes.forEach(docente => {
        const title = `${docente.nombre} ${docente.primer_apellido} ${docente.segundo_apellido}`
        this.docentesOption.push({
          'id_persona_rol_profesor_jefe': docente.id_persona_rol,
          title,
        })
        this.cargandoProfesorJefe = false
      })
    },
    changeLibro(id) {
      this.cargaCursosPersonaRol()
      const libroSelected = this.libros.find(l => l.id === id)
      this.setLibro(libroSelected)
      this.idCurso = this.getLibroSelected.id
      this.cargarPanorama(this.idCurso)
      this.cargandoProfesorJefe = true
      this.id_persona_rol_profesor_jefe = libroSelected.id_persona_rol_docente_jefe
      const profesor_jefe = this.getPersonasRol.find( pr => pr.id_persona_rol === this.id_persona_rol_profesor_jefe)
      if (typeof profesor_jefe !== 'undefined') {
        this.profesorJefe = profesor_jefe
      } else {
        this.profesorJefe = ''
      }
      this.cargandoProfesorJefe = false

    },
    submitProfesorJefe(id_persona_rol_jefe) {
      const data = {
        id_curso: this.idCurso,
        id_persona_rol_jefe,
      }
      this.addProfesorJefe(data).then(() => {
        const statusCursos = store.state.cursos.status
        if (statusCursos === 'success') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Profesor jefe guardado 👍',
              icon: 'CheckIcon',
              text: 'El/la profesor/a jefe ha sido asignado con éxito!',
              variant: 'success',
            },
          },
          {
            position: 'top-right',
            timeout: 4000,
          })
        }
        else {
          this.$swal({
            title: 'Error!',
            text: store.state.evaluaciones.message,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
      })
    },
    submitOption() {
      this.v$.libro.$touch()
      // if (!this.v$.libro.$invalid) {
      //   this.$emit('processForm', this.libro)
      // }
    },
  },
}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>