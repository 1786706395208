<template>
  <BOverlay
    :show="configs.cargando"
    spinner-variant="primary"
    variant="semi-dark"
  >
    <BForm
      ref="form"
      :style="{height: trHeight}"
      class="repeater-form mb-2 mt-2"
    >

      <BRow ref="row" >

        <!-- ADECUACIÓN -->
        <BCol md="3">
          <BFormGroup
            label="Adecuación curricular"
            label-for="observaciones"
            class="text-center"
          />
        </BCol>
        <!-- AMBITOS DE APRENDIZAJE -->
        <BCol md="3">
          <BFormGroup
            label="Ámbito de aprendizaje, asignatura o módulo en que se aplicarán"
            label-for="observaciones"
            class="text-center"
          />
        </BCol>
        <!-- ESTRATEGIAS -->
        <BCol md="3">
          <BFormGroup
            label="Principales estrategias que se utilizarán"
            label-for="observaciones"
            class="text-center"
          />
        </BCol>
        <!-- ESTUDIANTES -->
        <BCol md="3">
          <BFormGroup
            label="Estudiantes"
            label-for="observaciones"
            class="text-center"
          />
        </BCol>
      </BRow>

      <colLinea class="mt-25 mb-1"/>

      <BRow
        v-for="(item, index) in items"
        :id="item.id"
        :key="item.id"
        ref="row"
      >
        <!-- ADECUACIÓN -->
        <BCol md="3">
          <BFormCheckbox
            :id="'adecuacion-'+item.id"
            v-model="item.adecuacion_chk"
            value="1"
            :disabled="!$can('update', nombre_permiso)"
          >
            <b>{{ item.adecuacion }}</b>
          </BFormCheckbox>
        </BCol>

        <!-- ASIGNATURA -->
        <BCol md="3">
          <v-select
            v-if="configs.loadingAsignaturas"
            
            placeholder="Cargando asignaturas..."
            :disabled="true"
          />
          <v-select
            v-else-if="$can('update', nombre_permiso)"
            :id="'id_asignatura-'+item.id"
            v-model="item.id_asignatura"
            placeholder="Seleccione una asignatura..."
            
            label="nombre"
            :options="asignaturasOption"
            :reduce="option => option.id"
            input-id="id"
          />
          <BFormTextarea
            v-else-if="!$can('update', nombre_permiso)"
            v-model="item.id_asignatura"
            :id="'id_asignatura-text-'+item.id"
            placeholder="Sin información"
            :plaintext="!$can('update', nombre_permiso)"
          />
        </BCol>

        <!-- ESTRATEGIAS -->
        <BCol md="3">
          <BFormTextarea
            :id="'estrategias-'+item.id"
            :placeholder="$can('update', nombre_permiso)
                ? 'Ingrese una estrategia'
                : 'Sin información'"
            :plaintext="!$can('update', nombre_permiso)"
            v-model="item.estrategia"
            rows="6"
          />
        </BCol>

        <!-- ESTUDIANTES -->
        <BCol md="3">
          <v-select
            v-if="configs.loadingEstudiantes"
            
            placeholder="Cargando estudiantes..."
            :disabled="true"
          />
          <v-select
            v-else-if="$can('update', nombre_permiso)"
            :id="'estudiantes-'+item.id"
            v-model="item.personas"
            multiple
            
            placeholder="Seleccione un estudiante..."
            label="title"
            :options="alumnosOptions"
            :reduce="option => option.id"
            input-id="id"
          />

          <template v-if="!$can('update', nombre_permiso)">
            <div v-if="item.personas.length !== 0" >
              <div
                v-for="(persona, key) in item.personas"
                :key="key"
                class="text-secondary"
              >
                {{ persona.nombre_completo }}
                <hr style="margin-top: 1px; margin-bottom: 5px;">
              </div>
            </div>
            <div v-else>
              <BFormTextarea
                :id="'estudiantes-text-'+item.id"
                placeholder="Sin información"
                :plaintext="!$can('update', nombre_permiso)"
              />
            </div>
          </template>
        </BCol>
        <BCol cols="12">
          <colLinea />
        </BCol>

      </BRow>

      <!-- Action Buttons -->
      <BRow
        ref="row"
      >
        <BCol md="8">
        </BCol>

        <BCol md="4">
          <btnSubmit
            class="float-right"
            variant="primary"
            :modulo="nombre_permiso"
            :btnText="btnSubmit"
            @processBtn="submitOption"
          />
        </BCol>

      </BRow>
    </BForm>
  </BOverlay>
</template>

<script>

// ETIQUETAS
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BButton, BOverlay, BCardText,
  BFormTextarea, BFormCheckbox
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import ToastificationContent
from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import { mapGetters, mapActions } from 'vuex'

// FORMATOS
import { formatos } from '@/mixins/formatos'

// COMPONENTES
import colLinea from '@/views/components/Form/colLinea.vue'
import btnSubmit from '@/views/components/Form/btnSubmit.vue'

export default {
  components: {
    // ETIQUETAS
    BOverlay,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BCardText,
    BFormTextarea,
    BFormCheckbox,
    vSelect,
    // COMPONENTES
    colLinea,
    btnSubmit,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition, formatos],
  data() {
    return {
      nombre_permiso: 'pieII3B',
      configs: {
        cargando: false,
        loadingEstudiantes: true,
        loadingAsignaturas: true,
      },
      nextTodoId: 2,
      items: [],
      alumnosOptions: [],
      asignaturasOption: [],
    }
  },
  computed: {
    ...mapGetters({
      getLibroSelected: 'libros/getLibroSelected',
      getAdecuacion: 'II_3_b_adecuacion/getAdecuacion',
    }),
  },
  watch: {
    getLibroSelected(getLibroSelected) {
      this.inicialize()
    },
  },
  props: {
    btnSubmit: {
      type: String, // Texto del boton
      default: 'Actualizar Adecuacion',
    },
  },
  mounted() {
    this.inicialize()
  },
  methods: {
    ...mapActions({
      // ADECUACIONES
      fetchAdecuacion: 'II_3_b_adecuacion/fetchAdecuacion',
      addAdecuacion: 'II_3_b_adecuacion/addAdecuacion',
      updateAdecuacion: 'II_3_b_adecuacion/updateAdecuacion',

      // OTROS
      fetchAsignaturasCurso: 'asignaturas/fetchAsignaturasCurso',
      fetchAlumnosPie: 'personas/fetchAlumnosPie',
    }),
    async inicialize() {
      await Promise.all([
        this.cargarAsignaturas(),
        this.cargaEstudiantesPie(),
        this.cargarAdecuaciones(),
        this.resetItems(),
      ])
    },
    async cargarAsignaturas() {
      const res = await this.fetchAsignaturasCurso(this.getLibroSelected.id)
      if (res.status === 'success') {
        this.asignaturasOption = []
        this.asignaturasOption = res.data;
      } else if (res.status === 'error') {
        this.$toastError('Error al cargar las asignaturas!', res.message);
      }
      this.configs.loadingAsignaturas = false
    },
    async cargaEstudiantesPie() {
      const res = await this.fetchAlumnosPie(this.getLibroSelected.id)
      if (res.state === 'success') {
        this.alumnosOptions = []
        this.alumnosOptions = res.data.map(item => ({
          id: item.id_persona_rol,
          title: `${item.nombre} ${item.primer_apellido} ${item.segundo_apellido}`,
        }));
      } else if (res.state === 'error') {
        this.$toastError('Error al cargar los estudiantes!', res.message);
      }
      this.configs.loadingEstudiantes = false
    },
    async cargarAdecuaciones() {
      this.configs.cargando = true
      const res = await this.fetchAdecuacion(this.getLibroSelected.id);
      if (res.status === 'success') {
        if (typeof res.data.message === 'undefined') {
          this.items = []
          this.items = res.data
        } else {
          this.resetForms()
          this.resetItems()
        }
      } else {
        this.$toastError('Error al cargar las adversidades!', res.message);
      }
      this.configs.cargando = false
    },
    submitOption() {
      const text = 'las estrategias que se utilizarán para adecuar o flexibilizar el currículum'
      const html = this.formatHTMLSweetInfo(text, '')
      this.$swal({
        title: 'Guardar cambios!',
        html,
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Sí, guardar',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          if (this.getAdecuacion.message === 'Registro no existe') {
            this.agregarAdecuaciones(this.items)
          } else {
            this.editarAdecuaciones(this.items)
          }
        } else {
          return false
        }
      })
    },

    agregarAdecuaciones(adecuaciones) {
      this.spinner = true
      adecuaciones.forEach(adecuacion => {
        const data = {
          ...adecuacion,
          id_curso: this.getLibroSelected.id,
        }
        this.addAdecuacion(data).then((response) => {
          this.cargarAdecuaciones()
        })
      })
      this.msjActualizar()
      this.spinner = false
    },

    editarAdecuaciones(adecuaciones) {
      this.spinner = true
      adecuaciones.forEach(adecuacion => {
        let personas = []
        adecuacion.personas.forEach(asistente => {
        if (typeof asistente.id === 'undefined') {
          personas.push(asistente)
        } else {
          personas.push(asistente.id_persona_rol)
        }
      })
        const data = {
          ...adecuacion,
          personas: personas,
          id_curso: this.getLibroSelected.id,
        }
        this.updateAdecuacion(data).then((response) => {
          this.cargarAdecuaciones()
        })
      })
      this.msjActualizar()
      this.spinner = false
    },

    msjActualizar() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Registro actualizado 👍',
          icon: 'CheckIcon',
          text: `Las estrategias que se utilizarán para adecuar o flexibilizar el currículum, han sido actualizadas con éxito!`,
          variant: 'success',
        },
      },
      {
        position: 'bottom-right',
        timeout: 3000,
      })
    },

    resetForms() {
      this.items = []
    },
    resetItems() {
      this.items = []
      this.items = [
        {
          id: 1,
          adecuacion_chk: false,
          adecuacion: 'De acceso',
          id_asignatura: null,
          estrategia: '',
          personas: [],
        },
        {
          id: 2,
          adecuacion_chk: false,
          adecuacion: 'A los objetivos de aprendizaje en el caso de la educación básica.',
          id_asignatura: null,
          estrategia: '',
          personas: [],
        },
        {
          id: 3,
          adecuacion_chk: false,
          adecuacion: 'Al plan de estudio (básica)',
          id_asignatura: null,
          estrategia: '',
          personas: [],
        },
        {
          id: 4,
          adecuacion_chk: false,
          adecuacion: 'Plan de adecuación curricular individual (PACI) (Básica)',
          id_asignatura: null,
          estrategia: '',
          personas: [],
        },
      ]
    },
  },
}
</script>
