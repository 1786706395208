<template>
  <BOverlay
    :show="spinner"
    spinner-variant="primary"
    variant="semi-dark"
  >
    <BRow>
      <BCol
        lg="2"
        md="3"
        sm="3"
        class="my-50"
      >
        <!-- BOTON MOSTRAR -->
        <btnMostrar
          :pageOptions.sync="pageOptions"
          :perPage.sync="perPage"
          :total.sync="items.length"
        />
      </BCol>
      <BCol
        lg="6"
        md="5"
        sm="5"
        class="my-50"
      >
        <!-- FILTRO -->
        <!-- <inputFiltro
          :filter.sync="filter"
        /> -->
      </BCol>
      <BCol
        md="4"
        sm="4"
        class="my-50"
      >
        <!-- CREAR -->
        <TrabajoFamiliaCreate/>

        <!-- BOTON CREAR -->
        <btnCrearModal
          accion="Registrar"
          texto="Trabajo"
          modal="modal-create"
          :modulo="nombre_permiso"
        />
      </BCol>

      <BCol cols="12" style="min-height: 403px !important;">
        <BTable
          striped
          small
          hover
          noCollapse
          bordered
          class="rounded"
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :busy="cargando"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >

          <!-- Cargando -->
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <spinner />
            </div>
          </template>

          <!-- FECHA -->
          <template #cell(fecha)="data">
            <div class="mb-25 mt-75">
              {{ formatFechaVer(data.item.fecha) }}
            </div>
          </template>

          <!-- Column: alumnos -->
          <template #cell(participantes)="data">
            <div
              v-for="(participante, key) in data.item.participantes"
              :key="key"
            >
              <b>{{ participante.nombre_rol }}</b><br>

              {{ participante.nombre }} {{ participante.primer_apellido }} {{ participante.segundo_apellido }}
              <hr
                v-if="key + 1 !== data.item.participantes.length"
                style="margin-top: 1px; margin-bottom: 5px;"
              >
            </div>
          </template>

          <!-- objetivo -->
          <template #cell(objetivo)="data">
            <VerMas
              :id="`objetivo-${data.item.id}`"
              :texto="data.item.objetivo"
              :longText="180"
            />
          </template>

          <!-- actividad -->
          <template #cell(actividad)="data">
            <VerMas
              :id="`actividad-${data.item.id}`"
              :texto="data.item.actividad"
              :longText="180"
            />
          </template>

          <!-- acuerdo -->
          <template #cell(acuerdo)="data">
            <VerMas
              :id="`acuerdo-${data.item.id}`"
              :texto="data.item.acuerdo"
              :longText="180"
            />
          </template>

           <!-- resultado -->
           <template #cell(resultado)="data">
            <VerMas
              :id="`resultado-${data.item.id}`"
              :texto="data.item.resultado"
              :longText="180"
            />
          </template>

          <!-- Column: totalFirmas -->
          <!-- <template #cell(totalFirmas)="data">
            <div
              v-for="(asistente, key) in data.item.asistentes"
              :key="key"
            >
              - {{ asistente.nombre }}
            </div>
          </template> -->

          <!-- COLUMNA ESTADO -->
          <!-- <template #cell(estado)="data">
            <colEstado
              :data="data"
              modulo="reunionesCoordinaciones"
              @processUpdateEstado="updateEstado"
            />
          </template> -->

          <!-- Column: Action -->
          <template #cell(acciones)="data">
            <TrabajoFamiliaUpdate
              :modal="'modal-update-'+data.item.id"
              :data="data"
            />
            <colAccionesBtnes
              :modulo="nombre_permiso"
              :modal="`modal-update-${data.item.id}`"
              :data="data"
              @processRemove="remove(data.item)"
            />
          </template>
        </BTable>

        <BAlert
          v-if="!cargando && items.length === 0"
          variant="primary"
          show
          class="text-center pt-25 pb-25"
        >
          <div class="alert-body">
            <span>No existen trabajos registrados.</span>
          </div>
        </BAlert>
      </BCol>

      <BCol
        cols="12"
      >
        <BPagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </BCol>

    </BRow>
  </BOverlay>
</template>

<script>

// ETIQUETAS
import {
  BTable, BRow, BCol, BPagination, BOverlay, BAlert,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'

// FORMATOS
import { formatos } from '@/mixins/formatos'
import { swal } from '@/mixins/alerts'

// COMPONENTES
import spinner from '@/views/components/spinner.vue'
import btnMostrar from '@/views/components/List/btnMostrar.vue'
import btnCrearModal from '@/views/components/List/btnCrearModal.vue'
import colAccionesBtnes from '@/views/components/List/colAccionesBtnes.vue'
import VerMas from '@/views/components/List/verMas.vue'

// HIJOS
import TrabajoFamiliaCreate from './TrabajoFamilia/TrabajoFamiliaCreate.vue'
import TrabajoFamiliaUpdate from './TrabajoFamilia/TrabajoFamiliaUpdate.vue'

export default {
  components: {
    BTable, BRow, BCol, BPagination, BOverlay, BAlert,

    // COMPONENTES
    spinner,
    btnMostrar,
    btnCrearModal,
    colAccionesBtnes,
    VerMas,

    // HIJOS
    TrabajoFamiliaCreate,
    TrabajoFamiliaUpdate,
  },
  mixins: [formatos, swal],
  data() {
    return {
      nombre_permiso: 'pieIV1',
      cargando: true,
      spinner: false,

      items: [],

      perPage: 25,
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: '',
      filterOn: [],
      pageOptions: [25, 50, 100],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'fecha',
          label: 'Fecha',
          sortable: true,
          thStyle: {
            width: '110px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'participantes',
          label: 'Participantes',
          sortable: true,
          thStyle: {
            width: '200px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'objetivo',
          label: 'Objetivo(s)',
          sortable: true,
          thStyle: {
            width: '200px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'actividad',
          label: 'Actividad',
          sortable: true,
          thStyle: {
            width: '200px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'acuerdo',
          label: 'Acuerdo(s) / Compromiso(s)',
          sortable: true,
          thStyle: {
            width: '200px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'resultado',
          label: 'Resultado(s)',
          sortable: true,
          thStyle: {
            width: '200px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        // {
        //   key: 'estado',
        //   label: 'Estado',
        //   sortable: true,
        //   tdClass: 'text-center',
        //   thStyle: {
        //     'text-align': 'center',
        //     width: '100px !important',
        //     display: 'table-cell',
        //     'vertical-align': 'middle',
        //   },
        // },
      ],
      fieldAcciones: [
        {
          key: 'acciones',
          label: 'acciones',
          tdClass: 'text-center',
          thStyle: {
            width: '80px !important',
            'text-align': 'center',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      getActividades: 'IV_actividades/getActividades',
      getLibroSelected: 'libros/getLibroSelected',
    }),
  },
  watch: {
    getActividades(val) {
      this.totalRows = val.length
      this.items = []
      this.items = this.getActividades
    },
    getLibroSelected(val) {
      this.cargarActividades()
    },
  },
  mounted() {
    this.inicialize()
  },
  methods: {
    ...mapActions({
      fetchActividades: 'IV_actividades/fetchActividades',
      removeActividad: 'IV_actividades/removeActividad',
    }),
    async inicialize () {
      await Promise.all([
        this.cargarActividades(),
        this.setTableList()
      ])
    },
    setTableList() {
      if (this.$can('update', this.nombre_permiso)
        || this.$can('delete', this.nombre_permiso)
      ) {
        this.fields.push(this.fieldAcciones)
      }
    },
    async cargarActividades() {
      const data = {
        idCurso: this.getLibroSelected.id,
        tipo: 1, // Tipo Trabajo Familia
      }
      await this.fetchActividades(data);
      this.cargando = false
    },
    async remove(actividad) {
      const fecha = `${this.formatFechaVerCompleto(actividad.fecha)}`
      const html = this.formatHTMLSweetEliminar('el trabajo', fecha);
      
      const result = await this.$swalConfirm(
        'Eliminar trabajo!', 
        html, 
        'Sí, elimínalo!', 
      );
      if (result.value) {
        this.spinner = true;
        const res = await this.removeActividad(actividad.id);
        if (res.status === 'success') {
          this.$swalSuccess(
            'Eliminado con éxito!',
            'El trabajo ha sido eliminado!'
          );
          this.cargarActividades()
        } else {
          this.$swalError('Error al eliminar el trabajo', res.message);
        }
        this.spinner = false;
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>

<style lang="css">
  .text-center {
    text-align: center;
  }
</style>
