var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BModal',{attrs:{"id":_vm.nombreModal,"title":_vm.title,"centered":"","size":"lg","hide-footer":true,"ok-title":_vm.title,"cancel-title":"Cancelar","cancel-variant":"outline-secondary","ok-disabled":this.v$.observacion.$errors.length > 0},on:{"ok":function($event){$event.preventDefault();return _vm.submitOption($event)}}},[_c('BOverlay',{attrs:{"show":_vm.cargando,"spinner-variant":"primary","variant":"semi-dark"}},[_c('b-form',[_c('BRow',[_c('BCol',{attrs:{"cols":"12","md":"12"}},[_c('BFormGroup',{attrs:{"label":"Asignatura","label-for":"id_asignatura"}},[_c('v-select',{class:_vm.v$.observacion.id_asignatura.$error === true
                ? 'border-danger rounded'
                : '',attrs:{"placeholder":"Seleccione una asignatura...","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"nombre","options":_vm.optionsAsignaturas,"reduce":function (option) { return option.id; },"input-id":"id_asignatura","disabled":true,"clearable":false},model:{value:(_vm.observacion.id_asignatura),callback:function ($$v) {_vm.$set(_vm.observacion, "id_asignatura", $$v)},expression:"observacion.id_asignatura"}}),(_vm.v$.observacion.id_asignatura.$error)?_c('div',{staticClass:"text-danger text-right",staticStyle:{"font-size":"0.857rem"},attrs:{"id":"id_asignaturaInfo"}},_vm._l((_vm.v$.observacion.id_asignatura.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('BCol',{attrs:{"cols":"12","md":"12"}},[_c('BFormGroup',{attrs:{"label":"Fecha *","label-for":"fecha"}},[_c('calendario',{attrs:{"id":"fecha","tipo":"hastaFechaActual","fecha":_vm.observacion.fecha,"state":_vm.v$.observacion.fecha.$error},on:{"changeCalendario":_vm.changeCalendario}}),(_vm.v$.observacion.fecha.$error)?_c('BFormInvalidFeedback',{staticClass:"text-right",attrs:{"id":"fechaInfo"}},_vm._l((_vm.v$.observacion.fecha.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('BCol',{attrs:{"cols":typeof _vm.observacion.id === 'undefined' ? '12' : '12',"sm":typeof _vm.observacion.id === 'undefined' ? '12' : '12',"md":typeof _vm.observacion.id === 'undefined' ? '6' : '12',"lg":typeof _vm.observacion.id === 'undefined' ? '8' : '12'}},[_c('BFormGroup',{attrs:{"label":"Estudiante(s) *","label-for":"id_asignatura"}},[_c('v-select',{class:_vm.v$.observacion.id_persona_rol_estudiante.$error === true
                ? 'border-danger rounded'
                : '',attrs:{"multiple":typeof _vm.observacion.id === 'undefined',"disabled":typeof _vm.observacion.id !== 'undefined',"placeholder":"Seleccione uno o varios estudiantes...","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"nombre","options":_vm.optionsEstudiantes,"reduce":function (option) { return option.id_persona_rol_estudiante; },"input-id":"id_persona_rol_estudiante","clearable":false},model:{value:(_vm.observacion.id_persona_rol_estudiante),callback:function ($$v) {_vm.$set(_vm.observacion, "id_persona_rol_estudiante", $$v)},expression:"observacion.id_persona_rol_estudiante"}}),(_vm.v$.observacion.id_persona_rol_estudiante.$error)?_c('div',{staticClass:"text-danger text-right",staticStyle:{"font-size":"0.857rem"},attrs:{"id":"id_persona_rol_estudianteInfo"}},_vm._l((_vm.v$.observacion.id_persona_rol_estudiante.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),(typeof _vm.observacion.id === 'undefined')?_c('BCol',{attrs:{"cols":"6","sm":"6","md":"3","lg":"2"}},[_c('BButton',{staticClass:"mt-0 mt-md-2 mb-1 mb-md-0",attrs:{"size":"sm","block":"","variant":"secondary"},on:{"click":function($event){return _vm.seleccionarTodos()}}},[_vm._v(" Seleccionar todo ")])],1):_vm._e(),(typeof _vm.observacion.id === 'undefined')?_c('BCol',{attrs:{"cols":"6","sm":"6","md":"3","lg":"2"}},[_c('BButton',{staticClass:"mt-0 mt-md-2 mb-1 mb-md-0",attrs:{"size":"sm","block":"","variant":"outline-secondary"},on:{"click":function($event){return _vm.borrarTodos()}}},[_vm._v(" Eliminar todo ")])],1):_vm._e(),_c('BCol',{attrs:{"cols":"12","md":"12"}},[_c('BFormGroup',{attrs:{"label":"Tipo *","label-for":"tipo"}},[_c('v-select',{class:_vm.v$.observacion.tipo.$error === true
                ? 'border-danger rounded'
                : '',attrs:{"placeholder":"Seleccione un tipo de observación...","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"nombre","options":_vm.optionsTiposObservacion,"reduce":function (option) { return option.id; },"input-id":"tipo","clearable":false},model:{value:(_vm.observacion.tipo),callback:function ($$v) {_vm.$set(_vm.observacion, "tipo", $$v)},expression:"observacion.tipo"}}),(_vm.v$.observacion.tipo.$error)?_c('div',{staticClass:"text-danger text-right",staticStyle:{"font-size":"0.857rem"},attrs:{"id":"tipoInfo"}},_vm._l((_vm.v$.observacion.tipo.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('BCol',{attrs:{"cols":"12","md":"12"}},[_c('BFormGroup',{attrs:{"label":"Observaciones u Anotaciones *","label-for":"observacion"}},[_c('b-form-textarea',{attrs:{"id":"observacion","rows":"4","placeholder":"Ingrese una breve descripción de la observación u anotación","state":_vm.v$.observacion.observacion.$error === true
                ? false
                : null},nativeOn:{"blur":function($event){return _vm.v$.observacion.observacion.$touch($event)}},model:{value:(_vm.observacion.observacion),callback:function ($$v) {_vm.$set(_vm.observacion, "observacion", $$v)},expression:"observacion.observacion"}}),(_vm.v$.observacion.observacion.$error)?_c('BFormInvalidFeedback',{staticClass:"text-right",attrs:{"id":"observacionInfo"}},_vm._l((_vm.v$.observacion.observacion.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1)],1),_c('colLinea'),_c('div',{staticClass:"text-right"},[_c('BButton',{staticClass:"mr-1",attrs:{"variant":"outline-secondary"},on:{"click":_vm.closeModal}},[_vm._v(" Cancelar ")]),(_vm.$can('create' , 'hojavida') || _vm.$can('update', 'hojavida'))?_c('BButton',{staticClass:"mr-25",attrs:{"variant":"primary"},on:{"click":_vm.submitOption}},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }