var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BModal',{attrs:{"id":_vm.nombreModal,"title":_vm.title,"centered":"","size":"lg","hide-footer":true},on:{"shown":_vm.onModalOpen}},[_c('div',{staticClass:"d-flex justify-content-between mb-1"},[_c('div',{staticClass:"mt-50 text-secondary"}),_c('div',{staticClass:"mr-1 mt-50 text-muted small"},[_vm._v(" Campos obligatorios "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('BOverlay',{attrs:{"show":_vm.configs.cargando,"spinner-variant":"primary","variant":"semi-dark"}},[_c('BRow',[_c('BCol',{attrs:{"id":"col-PERIODO","cols":"12"}},[_c('BFormGroup',{attrs:{"label-for":"estrategia"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Estrategia (estrategias del menú 'II 1 b') "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[(_vm.configs.loadingEstrategias)?_c('v-select',{attrs:{"placeholder":"Cargando estrategias...","disabled":true}}):_c('v-select',{class:_vm.v$.aplicacion.id_detalle_estrategia.$error === true
              ? 'border-danger rounded'
              : '',attrs:{"placeholder":_vm.estrategiasDisponiblesOption.length === 0
              ? 'No hay estrategias disponibles...'
              : 'Seleccione una estrategia...',"label":"nombre","options":_vm.estrategiasDisponiblesOption,"reduce":function (option) { return option.id; },"input-id":"id","disabled":_vm.estrategiasDisponiblesOption.length === 0 || _vm.aplicacion.id !== null},model:{value:(_vm.aplicacion.id_detalle_estrategia),callback:function ($$v) {_vm.$set(_vm.aplicacion, "id_detalle_estrategia", $$v)},expression:"aplicacion.id_detalle_estrategia"}}),(!_vm.configs.loadingEstrategias && _vm.estrategiasDisponiblesOption.length === 0)?_c('BAlert',{staticClass:"pl-50 pt-25 pb-25 pr-50 mt-50 mb-25",attrs:{"variant":"primary","show":""}},[_vm._v(" Para crear una nueva estrategia, dirígase al menú \"II 1 b\". ")]):_vm._e(),(_vm.v$.aplicacion.id_detalle_estrategia.$error)?_c('div',{staticClass:"text-danger text-right",staticStyle:{"font-size":"0.857rem"},attrs:{"id":"asistentesInfo"}},_vm._l((_vm.v$.aplicacion.id_detalle_estrategia.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('BCol',{attrs:{"id":"col-ACCION","cols":"12"}},[_c('BFormGroup',{attrs:{"label-for":"desarrollo"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Acción desarrollada "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('BFormTextarea',{attrs:{"id":"desarrollo","placeholder":"Ingrese la acción desarrollada","rows":"6","maxlength":"550","state":_vm.v$.aplicacion.acciones.$error === true
            ? false
            : null},model:{value:(_vm.aplicacion.acciones),callback:function ($$v) {_vm.$set(_vm.aplicacion, "acciones", $$v)},expression:"aplicacion.acciones"}}),_c('small',{staticClass:"text-muted text-right d-block mt-25"},[_vm._v(_vm._s(_vm.aplicacion.acciones ? _vm.aplicacion.acciones.length : 0)+" / 550")]),(_vm.v$.aplicacion.acciones.$error)?_c('BFormInvalidFeedback',{staticClass:"text-right",attrs:{"id":"accionInfo"}},_vm._l((_vm.v$.aplicacion.acciones.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('BCol',{attrs:{"id":"col-EVALUACIÓN","cols":"12"}},[_c('BFormGroup',{attrs:{"label-for":"evaluacion"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Evaluación (resultados) de las estrategias aplicadas "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('BFormTextarea',{attrs:{"id":"evaluacion","placeholder":"Ingrese la evaluación","state":_vm.v$.aplicacion.evaluacion.$error === true
              ? false
              : null,"rows":"6","maxlength":"550"},model:{value:(_vm.aplicacion.evaluacion),callback:function ($$v) {_vm.$set(_vm.aplicacion, "evaluacion", $$v)},expression:"aplicacion.evaluacion"}}),_c('small',{staticClass:"text-muted text-right d-block mt-25"},[_vm._v(_vm._s(_vm.aplicacion.evaluacion ? _vm.aplicacion.evaluacion.length : 0)+" / 550")]),(_vm.v$.aplicacion.evaluacion.$error)?_c('BFormInvalidFeedback',{staticClass:"text-right",attrs:{"id":"evaluacionInfo"}},_vm._l((_vm.v$.aplicacion.evaluacion.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1)],1),_c('colLinea'),_c('div',{staticClass:"text-right"},[_c('BButton',{staticClass:"mr-1",attrs:{"variant":"outline-secondary"},on:{"click":_vm.closeModal}},[_vm._v(" Cancelar ")]),_c('BButton',{staticClass:"mr-25",attrs:{"variant":"primary","disabled":this.v$.aplicacion.$errors.length > 0},on:{"click":_vm.submitOption}},[_vm._v(" Guardar Aplicación ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }