<template>
  <BOverlay
    :show="spinner"
    spinner-variant="primary"
    variant="semi-dark"
  >
    <BRow>
      <BCol
        lg="2"
        md="3"
        sm="3"
        class="my-50"
      >
        <!-- BOTON MOSTRAR -->
        <btnMostrar
          :pageOptions.sync="pageOptions"
          :perPage.sync="perPage"
          :total.sync="items.length"
        />
      </BCol>
      <BCol
        lg="6"
        md="5"
        sm="5"
        class="my-50"
      >
        <!-- FILTRO -->
        <!-- <inputFiltro
          :filter.sync="filter"
        /> -->
      </BCol>
      <BCol
        md="4"
        sm="4"
        class="my-50"
      >
        <!-- CREAR -->
        <LogrosAprendizajeCreate />

        <!-- BOTON CREAR -->
        <btnCrearModal
          accion="Registrar"
          texto="Logros"
          modal="modal-create"
          :modulo="nombre_permiso"
        />
      </BCol>

      <BCol cols="12" style="min-height: 424px !important;">
        <BTable
          striped
          small
          hover
          noCollapse
          bordered
          class="rounded"
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :busy="cargando"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >

          <!-- Cargando -->
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <spinner />
            </div>
          </template>

          <!-- Column: alumnos -->
          <template #cell(alumnos)="data">
            <div>
              {{ data.item.nombre }} {{ data.item.primer_apellido }} {{ data.item.segundo_apellido }}
            </div>
          </template>

          <!-- logros -->
          <template #cell(logros)="data">
            <VerMas
              :id="`logros-${data.item.id}`"
              :texto="data.item.logros"
              :longText="180"
            />
          </template>
          
          <!-- comentarios -->
          <template #cell(comentarios)="data">
            <VerMas
              :id="`comentarios-${data.item.id}`"
              :texto="data.item.comentarios"
              :longText="180"
            />
          </template>

          <!-- Column: Action -->
          <template #cell(acciones)="data">

            <LogrosAprendizajeUpdate
              :modal="'modal-update-'+data.item.id"
              :item="data.item"
            />

            <colAccionesBtnes
              :modulo="nombre_permiso"
              :modal="`modal-update-${data.item.id}`"
              :data="data"
              @processRemove="remove(data.item)"
            />
          </template>
        </BTable>
        <BAlert
          v-if="!cargando && items.length === 0"
          variant="primary"
          show
          class="text-center pt-25 pb-25"
        >
          <div class="alert-body">
            <span>No existen logros registrados.</span>
          </div>
        </BAlert>
      </BCol>

      <BCol
        cols="12"
      >
        <BPagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </BCol>

    </BRow>
  </BOverlay>
</template>

<script>

// ETIQUETAS
import {
  BTable, BRow, BCol, BPagination, BOverlay, BAlert,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'

// COMPONENTES RECICLADOS
// import inputFiltro from '@/views/components/List/inputFiltro.vue'
import spinner from '@/views/components/spinner.vue'
import btnMostrar from '@/views/components/List/btnMostrar.vue'
import btnCrearModal from '@/views/components/List/btnCrearModal.vue'
import colAccionesBtnes from '@/views/components/List/colAccionesBtnes.vue'
import VerMas from '@/views/components/List/verMas.vue'

// COMPONENTES HIJOS
import LogrosAprendizajeCreate from './LogrosAprendizaje/LogrosAprendizajeCreate.vue'
import LogrosAprendizajeUpdate from './LogrosAprendizaje/LogrosAprendizajeUpdate.vue'

import { formatos } from '@/mixins/formatos';
import { toast, swal } from '@/mixins/alerts'

export default {
  components: {
    // ETIQUETAS
    BTable, BRow, BCol, BPagination, BOverlay, BAlert,

    // COMPONENTES RECICLADOS
    // inputFiltro,
    spinner,
    btnMostrar,
    btnCrearModal,
    colAccionesBtnes,
    VerMas,

    // COMPONENTES HIJOS
    LogrosAprendizajeCreate,
    LogrosAprendizajeUpdate,
  },
  mixins: [formatos, toast, swal],
  data() {
    return {
      nombre_permiso: 'pieIII3',
      cargando: true,
      spinner: false,
      items: [],

      perPage: 25,
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: '',
      filterOn: [],
      pageOptions: [25, 50, 100],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'alumnos',
          label: 'Estudiante',
          sortable: true,
          thStyle: {
            width: '150px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'logros',
          label: 'Logros más relevantes',
          sortable: true,
          tdClass: 'text-center',
          thStyle: {
            width: '200px !important',
            'text-align': 'center',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'comentarios',
          label: 'Comentarios y sugerencias',
          sortable: true,
          tdClass: 'text-center',
          thStyle: {
            width: '200px !important',
            'text-align': 'center',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ],
      fieldAcciones: [
        {
          key: 'acciones',
          label: 'acciones',
          tdClass: 'text-center',
          thStyle: {
            width: '9px !important',
            'text-align': 'center',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      getLogros: 'III_3_logros/getLogros',
      getLibroSelected: 'libros/getLibroSelected',
    }),
  },
  watch: {
    getLogros(val) {
      this.totalRows = val.length
      this.items = []
      this.items = this.getLogros
    },
    getLibroSelected(val) {
      this.cargarLogros()
    },
  },
  mounted() {
    this.inicialize()
  },
  methods: {
    ...mapActions({
      fetchLogros: 'III_3_logros/fetchLogros',
      removeLogro: 'III_3_logros/removeLogro',
    }),
    async inicialize () {
      await Promise.all([
        this.cargarLogros(),
        this.setTableList()
      ])
    },
    setTableList() {
      if (this.$can('update', this.nombre_permiso)
        || this.$can('delete', this.nombre_permiso)
      ) {
        this.fields.push(this.fieldAcciones)
      }
    },
    async cargarLogros() {
      const { status } = await this.fetchLogros(this.getLibroSelected.id);
      if (status === 'error') {
        this.$toastError('Error al cargar los datos !', res.message);
      }
      this.cargando = false
    },

    async remove(logro) {
      const html = this.formatHTMLSweetEliminar('el logro', logro.logros);
      
      const result = await this.$swalConfirm(
        'Eliminar logro!', 
        html, 
        'Sí, elimínalo!', 
      );
      if (result.value) {
        this.spinner = true;
        const params = {
          id: logro.id,
          id_curso: this.getLibroSelected.id,
        };
        const res = await this.removeLogro(params);
        if (res.status === 'success') {
          this.$swalSuccess(
            'Eliminado con éxito!',
            'El logro de aprendizaje ha sido eliminado!'
          );
        } else {
          this.$swalError('Error al eliminar el logro de aprendizaje', res.message);
        }
        this.spinner = false;
      }
    },

    // Vuexy
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of
      // buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>

<style lang="css">
  .text-center {
    text-align: center;
  }
</style>
