<template>
  <BTabs
    fill
    vertical
    class="mt-2"
    nav-wrapper-class="nav-vertical"
    active-tab-class="font-weight-bold"
  >
    <div
      v-for="(menu, key) in submenus"
      :key="key"
      v-if="menu.can"
    >
      <BTab
        :active="menu.active"
        lazy
        :title="menu.index.toUpperCase()+`. `+menu.abreviatura"
      >
        <!-- ENCABEZADO -->
        <div class="meetup-header d-flex align-items-center">
          <div class="meetup-day">
            <h4 class="mb-0">
              {{ menu.index.toUpperCase() }}
            </h4>
          </div>
          <div class="ml-1">

            <!-- TÍTULO -->
            <BCardTitle
              v-if="menu.titulo !== ''"
              class="mb-25 h4"
              style="margin-top: 4px;"
            >
              {{ menu.titulo }}

              <!-- INFORMATIVO -->
              <popover
                v-if="menu.informacion !== ''"
                class="float-right"
                id="0"
                btnVariant="flat-secondary"
                :texto="menu.informacion"
                direccion="right"
                style="margin-left: -6px; margin-top: -15px; margin-bottom: -10px;"
              />
            </BCardTitle>

            <!-- TEXTO -->
            <BCardText v-if="menu.texto !== ''" class="mr-4 mb-50 text-secondary">
              {{ menu.texto }}
            </BCardText>
          </div>
        </div>

        <acciones v-if="menu.index === 'a'"/>
        <apoyos v-if="menu.index === 'b'"/>
        
      </BTab>
    </div>
  </BTabs>
</template>

<script>
import popover from '@/views/components/Form/popover.vue'
import {
  BTabs, BTab, BCardTitle, BCardText, 
} from 'bootstrap-vue'

import acciones from './TrabajoColaborativo/Acciones.vue'
import apoyos from './TrabajoColaborativo/Apoyos.vue'

export default {
  components: {
    BTabs, BTab, BCardTitle, BCardText, 
    popover,

    // componentes
    acciones,
    apoyos,
  },
  props: {
    submenus: {
      type: Array,
      required: true,
    }
  },
}
</script>
