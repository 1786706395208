<template>
  <TrabajoComunidadForm
    :nombreModal="modal"
    title="Editar registro de trabajo con la comunidad y el entorno escolar"
    :data.sync="data.item"
    @processForm="editar"
  />
</template>

<script>
import { mapActions } from 'vuex'
import { toast, swal } from '@/mixins/alerts'
import TrabajoComunidadForm from './TrabajoComunidadForm.vue'

export default {
  components: {
    TrabajoComunidadForm,
  },
  mixins: [toast, swal],
  props: {
    data: {
      type: Object,
      required: true,
    },
    modal: {
      type: String,
      required: true,
    },
  },
  methods: {
    ...mapActions({
      updateActividad: 'IV_actividades/updateActividad',
      fetchActividades: 'IV_actividades/fetchActividades',
    }),
    async editar(actividad) {
      const res = await this.updateActividad(actividad)
      if (res.status === 'success') {
        const data = {
          idCurso: actividad.id_curso,
          tipo: actividad.tipo,
        }
        await this.fetchActividades(data)
        
        this.$toastSuccess(
          'Trabajo actualizado 👍', 
          'El trabajo fue editado con éxito!'
        )
        this.$bvModal.hide(this.modal)
      } else {
        this.$swalError('Error al editar el trabajo!', res.message)
      }
    },
  },
}
</script>
