<template>
  <BOverlay
    :show="spinner"
    spinner-variant="primary"
    variant="semi-dark"
  >
    <BRow>
      <BCol md="8">
        <BCardText style="margin-top: 15px;" class="h5" >
          Docente(s) de educación regular del curso
        </BCardText>
      </BCol>
      <BCol md="4">
        <!-- CREAR -->
        <DocenteCreate />

        <!-- BOTON CREAR -->
        <btnCrearModal
          accion="Agregar"
          texto="Docente"
          modal="modal-create"
          :modulo="nombre_permiso"
        />
      </BCol>
    </BRow>
    <BRow>

      <BCol cols="12" style="min-height: 490px !important;">
        <BTable
          striped
          small
          hover
          noCollapse
          bordered
          class="mt-1 rounded"
          responsive
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :busy="cargando"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >

          <!-- Cargando -->
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <spinner />
            </div>
          </template>

          <!-- col TELEFONO -->
          <template #cell(telefono)="data">
            +{{ data.item.telefono }}
          </template>

          <!-- col ACCIONES -->
          <template #cell(acciones)="data">
            <DocenteUpdate
              :key="`modal-${data.item.id}`"
              :modal="'modal-update-'+data.item.id"
              :item="data.item"
            />
            <colAccionesBtnes
              :key="`btn-${data.item.id}`"
              :modal="'modal-update-'+data.item.id"
              :data="data"
              :modulo="nombre_permiso"
              @processRemove="remove(data.item)"
            />
          </template>
        </BTable>
        <BAlert
          v-if="!cargando && items.length === 0"
          variant="primary"
          show
          class="text-center pt-25 pb-25"
        >
          <div class="alert-body">
            <span>No existen docente(s) de educación regular del curso registrados en el equipo de aula.</span>
          </div>
        </BAlert>
      </BCol>

    </BRow>
  </BOverlay>
</template>

<script>

// ETIQUETAS
import {
  BTable, BRow, BCol, BPagination, BOverlay, BAlert, BCardText,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'

// COMPONENTES RECICLADOS
import spinner from '@/views/components/spinner.vue'
import btnMostrar from '@/views/components/List/btnMostrar.vue'
import btnCrearModal from '@/views/components/List/btnCrearModal.vue'
import colAccionesBtnes from '@/views/components/List/colAccionesBtnes.vue'

// HIJOS
import DocenteCreate from './Docente/DocenteCreate.vue'
import DocenteUpdate from './Docente/DocenteUpdate.vue'

// FORMATOS
import { formatos } from '@/mixins/formatos'
import { toast, swal } from '@/mixins/alerts'

export default {
  components: {
    BTable, BRow, BCol, BPagination, BOverlay, BAlert, BCardText, 

    // COMPONENTES
    spinner,
    btnMostrar,
    btnCrearModal,
    colAccionesBtnes,

    // HIJOS
    DocenteCreate,
    DocenteUpdate,
  },
  mixins: [formatos, swal, toast],
  data() {
    return {
      nombre_permiso: 'pieI1',
      cargando: true,
      spinner: false,
      items: [],

      // MODAL
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },

      // TABLE
      totalRows: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: '',
      filterOn: [],
      pageOptions: [10, 25, 50],
      fields: [
        {
          key: 'nombre',
          label: 'Nombre',
          sortable: true,
          thStyle: {
            width: '240px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'especialidad',
          // Núcleo, asignatura y/o módulo
          label: 'Asignatura',
          sortable: true,
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '150px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'telefono',
          label: 'Teléfono',
          sortable: true,
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '90px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'correo',
          label: 'Correo electrónico',
          sortable: true,
          thStyle: {
            width: '120px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ],
      fieldAcciones: [
        {
          key: 'acciones',
          label: 'acciones',
          tdClass: 'text-center',
          thStyle: {
            width: '80px !important',
            'text-align': 'center',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ],
    }
  },
  computed: {
    ...mapGetters({ 
      getLibroSelected: 'libros/getLibroSelected',
      getEquipo: 'I_1_equipo/getEquipo'
    }),
  },
  watch: {
    getEquipo(val) {
      this.totalRows = val.length
      this.items = []
      this.items = val
    },
    getLibroSelected(val) {
      this.cargarEquipo()
    },
  },
  mounted() {
    this.inicialize()
  },
  methods: {
    ...mapActions({
      fetchEquipo: 'I_1_equipo/fetchEquipo',
      removeEquipo: 'I_1_equipo/removeEquipo',
    }),
    async inicialize () {
      await Promise.all([
        this.cargarEquipo(),
        this.setTableList()
      ])
    },
    setTableList() {
      if (this.$can('update', this.nombre_permiso)
        || this.$can('delete', this.nombre_permiso)
      ) {
        this.fields.push(this.fieldAcciones)
      }
    },
    async cargarEquipo() {
      const params = {
        id_curso: this.getLibroSelected.id, 
        tipo: 1 //docente pie
      }
      const { status } = await this.fetchEquipo(params);
      if (status === 'error') {
        this.$toastError('Error al cargar los datos !', res.message);
      }
      this.cargando = false
    },
    async remove(equipo){
      const html = this.formatHTMLSweetEliminar('al docente', `${equipo.nombre}, del equipo de aula`);
      
      const result = await this.$swalConfirm(
        'Eliminar docente!', 
        html, 
        'Sí, elimínalo!', 
      );
      if (result.value) {
        this.spinner = true;
        const params = {
          id: equipo.id,
          id_curso: equipo.id_curso,
          tipo: equipo.tipo,
        };
        const res = await this.removeEquipo(params);
        if (res.status === 'success') {
          this.$swalSuccess(
            'Eliminado con éxito!',
            'El docente ha sido eliminado!'
          );
        } else {
          this.$swalError('Error al eliminar el docente', error.message);
        }
        this.spinner = false;
      }
    },
    // Vuexy
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>