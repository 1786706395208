<template>
  <BModal
    :id="nombreModal"
    :title="title"
    centered
    size="lg"
    :hide-footer="true"
    @shown="onModalOpen"
  >
    <div class="d-flex justify-content-between mb-1">
      <div class="mt-50 text-secondary">
      </div>
      <div class="mr-1 mt-50 text-muted small">
        Campos obligatorios <span class="text-danger">*</span>
      </div>
    </div>
    <BOverlay
      :show="configs.cargando"
      spinner-variant="primary"
      variant="semi-dark"
    >
      <BRow>

        <BCol id="col-ESTUDIANTES" cols="12">
          <BFormGroup label-for="estudiante">
            <template #label>
              Estudiante <span class="text-danger">*</span>
            </template>
            <v-select
              v-if="configs.loadingEstudiantes"
              
              placeholder="Cargando estudiantes..."
              :disabled="true"
            />
            <v-select
              v-else
              v-model="logros.id_persona_rol_alumno"
              placeholder="Seleccione un estudiante..."
              
              label="title"
              :options="estudiantesOptions"
              :reduce="option => option.id"
              input-id="id"
              :class="v$.logros.id_persona_rol_alumno.$error === true
                ? 'border-danger rounded'
                : ''"
            />
            <BAlert
              v-if="!configs.loadingEstudiantes && estudiantesOptions.length === 0"
              class="pl-50 pt-25 pb-25 pr-50 mt-50 mb-25"
              variant="primary"
              show
            >
            Para incluir un estudiante en el Programa de Integración Escolar (PIE), 
            es necesario indicarlo en el módulo matrícula.
            </BAlert>
            <div v-if="v$.logros.id_persona_rol_alumno.$error"
              id="id_asignaturaInfo"
              class="text-danger text-right"
              style="font-size: 0.857rem;"
            >
              <p v-for="error of v$.logros.id_persona_rol_alumno.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
            </div>
          </BFormGroup>
        </BCol>

        <BCol id="col-LOGROS" cols="12">
          <BFormGroup label-for="logros">
            <template #label>
              Logros más relevantes <span class="text-danger">*</span>
            </template>
            <BFormTextarea
              id="logros"
              placeholder="Ingrese los logros más relevantes"
              v-model="logros.logros"
              :state="v$.logros.logros.$error === true
                ? false
                : null"
              rows="3"
              maxlength="550"
            />
            <small class="text-muted text-right d-block mt-25">{{ logros.logros ? logros.logros.length: 0 }} / 550</small>
            <BFormInvalidFeedback v-if="v$.logros.logros.$error"
              id="acuerdoInfo"
              class="text-right"
            >
              <p v-for="error of v$.logros.logros.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
            </BFormInvalidFeedback>
          </BFormGroup>
        </BCol>

        <BCol id="col-SUGERENCIAS" cols="12">
          <BFormGroup label-for="comentariosSugerencias">
            <template #label>
              Comentarios y sugerencias <span class="text-danger">*</span>
            </template>
            <BFormTextarea
              id="comentariosSugerencias"
              placeholder="Ingrese los comentarios y sugerencias"
              v-model="logros.comentarios"
              :state="v$.logros.comentarios.$error === true
                ? false
                : null"
              rows="3"
              maxlength="550"
            />
            <small class="text-muted text-right d-block mt-25">{{ logros.comentarios ? logros.comentarios.length: 0 }} / 550</small>
            <BFormInvalidFeedback v-if="v$.logros.comentarios.$error"
              id="acuerdoInfo"
              class="text-right"
            >
              <p v-for="error of v$.logros.comentarios.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
            </BFormInvalidFeedback>
          </BFormGroup>
        </BCol>

      </BRow>

      <colLinea />

      <div class="text-right">
        <BButton variant="outline-secondary" class="mr-1" @click="closeModal">
          Cancelar
        </BButton>

        <BButton
          variant="primary"
          class="mr-25"
          :disabled="this.v$.logros.$errors.length > 0"
          @click="submitOption"
        >
          Guardar Logro
        </BButton>
      </div>

    </BOverlay>
  </BModal>
</template>

<script>

// ETIQUETAS
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BFormInvalidFeedback,
  BMedia, BButton, BAvatar, BOverlay, BFormDatepicker, BFormTimepicker,
  BFormTextarea, BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { heightTransition } from '@core/mixins/ui/transition'

import { mapGetters, mapActions } from 'vuex'

// VALIDACIONES
import useVuelidate from '@vuelidate/core'
import { required, maxLength, helpers } from '@vuelidate/validators'
import colLinea from '@/views/components/Form/colLinea.vue'

export default {
  components: {
    BRow, BCol, BFormGroup, BFormInput, BForm, BFormInvalidFeedback,
    BMedia, BButton, BAvatar, BOverlay, BFormDatepicker, BFormTimepicker,
    BFormTextarea, BAlert,
    vSelect,
    colLinea,
  },
  mixins: [heightTransition],
  data() {
    return {
      nombre_permiso: 'pieIII3',
      configs: {
        cargando: false,
        loadingEstudiantes: true,
      },
      logros: {},
      estudiantesOptions: [],
    }
  },
  props: {
    nombreModal: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({ getLibroSelected: 'libros/getLibroSelected' }),
  },
  methods: {
    ...mapActions({
      fetchAlumnosPie: 'personas/fetchAlumnosPie',
    }),
    async onModalOpen() {
      await Promise.all([
        this.cargaEstudiantesPie(),
      ])
      this.logros = { ...this.data }
    },
    async cargaEstudiantesPie() {
      const res = await this.fetchAlumnosPie(this.getLibroSelected.id)
      if (res.state === 'success') {
        this.estudiantesOptions = []
        this.estudiantesOptions = res.data.map(item => ({
          id: item.id_persona_rol,
          title: `${item.nombre} ${item.primer_apellido} ${item.segundo_apellido}`,
        }));
      } else if (res.state === 'error') {
        this.$toastError('Error al cargar los estudiantes!', res.message);
      }
      this.configs.loadingEstudiantes = false
    },
    submitOption() {
      this.v$.logros.$touch()
      if (!this.v$.logros.$invalid) {
        const params = {
          ...this.logros,
          id_curso: this.getLibroSelected.id
        }
        this.$emit('processForm', params)
        this.v$.logros.$reset()
      }
    },
    closeModal() {
      this.$bvModal.hide(this.nombreModal)
      this.v$.logros.$reset()
      if (this.logros.id === null) {
        this.$emit('resetData')
      }
    },
  },
  validations() {
    return {
      logros: {
        id_persona_rol_alumno: {
          required: helpers.withMessage('El campo es requerido.', required),
        },
        logros: {
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener un máximo de 550 caracteres.', maxLength(550)),
        },
        comentarios: {
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener un máximo de 550 caracteres.', maxLength(550)),
        },
      }
    }
  },
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
