<template>
  <BOverlay
    v-if="$can('read', 'lista')"
    :show="mostrarCargando"
    spinner-variant="primary"
    variant="semi-dark"
    style="min-height: 640px !important;"
  >
    <BRow>
      <BCol
        lg="6"
        md="6"
        sm="6"
        class="mb-25"
      >
        <!-- BOTON Titulo -->
        <h5 class="text-primary mb-25">
          Lista de estudiantes
        </h5>
        <p ><small>Total: {{ this.getAlumnos.length }}</small></p>
      </BCol>
      <BCol
        md="6"
        sm="6"
        class="mt-0"
      >
        <!-- BOTON Actualizar -->
        <!-- <btnSubmit
          class=""
          variant="primary"
          :modulo="nombre_permiso"
          :btnText="btnSubmit"
          @processBtn="submitOption"
        /> -->
      </BCol>
    </BRow>
    <b-alert
      v-if="bloquesAlert"
      variant="info"
      show
      class="text-center mt-25 mr-25 ml-25 pb-2 pt-2"
    >
      {{alertMsg}}
    </b-alert>
    <!-- draggable -->
    <draggable
      v-else
      v-model="items"
      class="list-group list-group-flush cursor-move rounded"
      tag="ul"
      :disabled="disabledList"
      style="min-height: 640px !important;"
    >
      <transition-group
        type="transition"
        name="flip-list"
      >
        <b-list-group-item
          v-for="(item, index) in items"
          :key="index+1"
        >
          <div class="d-flex">
            <div class="ml-25">
              <BCardText class="font-weight-bold mb-0">
                <estudiante-retirado
                  :data="item.nombre_completo"
                  :fecha_retiro.sync="item.fecha_retiro"
                  :mayus="true"
                />
              </BCardText>
            </div>
          </div>
        </b-list-group-item>
      </transition-group>
    </draggable>


    <colLinea v-if="mostrarBtnSubmit" />

    <!-- Action Buttons -->
      <!-- v-if="mostrarBtnSubmit" -->
    <BRow
      v-if="!disabledList"
    >
      <BCol
        cols="12"
        md="9"
      >
      </BCol>
      <BCol
        cols="12"
        md="3"
        class="text-right"
      >
        <btnSubmit
          v-if="!bloquesAlert"
          variant="primary"
          :btnText="btnSubmit"
          :modulo="nombre_permiso"
          @processBtn="submitList()"
        />
      </BCol>
    </BRow>

  </BOverlay>
</template>

<script>
import { BOverlay, BListGroupItem, BCardText, BRow, BCol, BAlert } from 'bootstrap-vue'
import draggable from 'vuedraggable'

import { mapGetters, mapActions } from 'vuex'

import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// MIXINS
import { rut } from '@/mixins/rut'
import { formatos } from '@/mixins/formatos'

import btnSubmit from '../../../components/Form/btnSubmit.vue'
import colLinea from '../../../components/Form/colLinea.vue'
import estudianteRetirado from '../../../components/List/estudianteRetirado.vue'

export default {
  components: {
    BCardText,
    BOverlay,
    draggable,
    BListGroupItem,
    BRow,
    BAlert,
    BCol,
    btnSubmit,
    colLinea,
    estudianteRetirado,
  },
  mixins: [rut, formatos],
  data() {
    return {
      nombre_permiso: 'lista',
      mostrarCargando: true,
      btnSubmit: 'Actualizar orden de Lista',
      items: [],
      bloquesAlert: false,
      alertMsg: 'El curso no tiene Estudiantes Matriculados.',
    }
  },
  computed: {
    ...mapGetters({
      getAlumnos: 'personas/getAlumnos',
      getLibroSelected: 'libros/getLibroSelected',
      getUser: 'auth/user',
    }),
    mostrarBtnSubmit() {
      let retorna = false
      if (this.getLibroSelected.id_persona_rol_docente_jefe !== null) {
        retorna = this.getLibroSelected.id_persona_rol_docente_jefe === this.getUser.id_persona_rol
      }
      return retorna
    },
    disabledList() {
      return window.screen.width < 766
    },

  },
  watch: {
    getAlumnos(val) {
      if (val.length !== 0) {
        this.setEstudiantes(val)
      } else {
        this.bloquesAlert = true
      }
      this.mostrarCargando = false
    },
    getLibroSelected(val) {
      this.mostrarCargando = true
      this.cargaEstudiantes(val.id)
    },
  },
  mounted() {
    this.cargaEstudiantes(this.getLibroSelected.id)
  },
  methods: {
    ...mapActions({
      fetchAlumnos: 'personas/fetchAlumnos',
      ordenarLista: 'cursos/ordenarLista',
    }),
    cargaEstudiantes(id_curso) {
      this.items = []
      this.mostrarCargando = true
      this.fetchAlumnos(id_curso)
    },
    setEstudiantes(estudiantes) {
      for (let i = 0; i < estudiantes.length; i++) {
        let estudiante = estudiantes[i];
        estudiante['nombre_completo'] = `${estudiante.orden} -
                  ${estudiante.primer_apellido}
                  ${estudiante.segundo_apellido}
                  ${estudiante.nombre}
                  ${estudiante.segundo_nombre !== null
                    ? estudiante.segundo_nombre
                    : ''}
                  - rut: ${this.formatRut(estudiante.rut+''+estudiante.dv)}
                  `

        estudiante['fecha_retiro'] = estudiante.fecha_retiro
      }
      this.items = estudiantes
    },
    submitList() {
      this.mostrarCargando = true
      let lista = Array()
      this.items.forEach(item => {
        lista.push(item.id_persona_rol_alumno)
      })

      const data = {
        id_curso: this.getLibroSelected.id,
        alumnos: lista,
      }

      this.ordenarLista(data).then(() => {
        const statusCursos = store.state.cursos.status
        if (statusCursos === 'success') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Lista guardada 👍',
              icon: 'CheckIcon',
              text: 'La lista se actualizó con éxito!',
              variant: 'success',
            },
          },
          {
            position: 'top-right',
            timeout: 4000,
          })
          this.cargaEstudiantes(data.id_curso)
        }
        else {
          this.$swal({
            title: 'Error!',
            text: store.state.evaluaciones.message,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
      })

    },
  },
}

</script>

<style>
/* .list-group-item {
  transition: all 0.03s
} */
</style>