<template>
  <BOverlay
    :show="spinner"
    spinner-variant="primary"
    variant="semi-dark"
  >
    <evaluaciones-form
      nombreModal="evaluacion-update"
      title="Editar evaluación"
      :evaluacion.sync="evaluacion"
      :asignaturas.sync="asignaturas"
      crud="u"
      @processForm="editar"
    />
      <!-- @resetData="resetData" -->
  </BOverlay>
</template>

<script>

import { BOverlay } from 'bootstrap-vue'
import { mapActions, mapState, mapGetters } from 'vuex'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import EvaluacionesForm from './EvaluacionesForm.vue'

export default {
  components: {
    EvaluacionesForm,
    BOverlay,
  },
  data() {
    return {
      spinner: false,
      asignaturas: [],
    }
  },
  computed: {
    ...mapGetters({
      getHorarios: 'horarios/getHorarios',
      getLibroSelected: 'libros/getLibroSelected',
      user: 'auth/user',
    }),
  },
  props: {
    evaluacion: {
      type: Object,
      required: true,
    },
  },
  watch: {
    evaluacion(evaluacion) {
      this.cargaAsignaturas()
    },
  },
  methods: {
    ...mapActions({
      fetchHorarioUsuarioCurso: 'horarios/fetchHorarioUsuarioCurso',
      updateEvaluacion: 'evaluaciones/updateEvaluacion',
      fetchEvaluaciones: 'evaluaciones/fetchEvaluaciones',
    }),
    cargaAsignaturas() {
      this.fetchHorarioUsuarioCurso(this.getLibroSelected.id).then(() => {
        this.asignaturas = []
        this.getHorarios.forEach(horario => {
          const findAsignatura = this.asignaturas.filter(as => as.id === horario.id_asignatura)
          if (findAsignatura.length === 0) {
            this.asignaturas.push({
              id_curso: horario.id_curso,
              id: horario.id_asignatura,
              nombre: horario.nombre_asignatura,
            })
          }
        })
      })
    },
    editar(evaluacion) {
      this.updateEvaluacion(evaluacion).then(() => {
        const statusEvaluaciones = store.state.evaluaciones.status
        if (statusEvaluaciones === 'success') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Evaluación guardada 👍',
              icon: 'CheckIcon',
              text: 'La evaluación fue editada con éxito!',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
            timeout: 4000,
          })
          this.$bvModal.hide('evaluacion-update')
          this.fetchEvaluaciones(evaluacion.id_asignatura).then(() => {
            this.$emit('pintarEvaluacion', evaluacion)
          })
        }
        else {
          this.$swal({
            title: 'Error!',
            text: store.state.evaluaciones.message,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
      })
    },
  },
}
</script>
