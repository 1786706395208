<template>
  <DocenteForm
    nombreModal="modal-create"
    title="Agregar docente al equipo de aula"
    :data.sync="data"
    @processForm="agregar"
    @resetData="resetData"
  />
</template>

<script>
import { mapActions } from 'vuex'
import { toast, swal } from '@/mixins/alerts'
import DocenteForm from './DocenteForm.vue'

export default {
  components: {
    DocenteForm,
  },
  mixins: [toast, swal],
  data() {
    return {
      data: this.getDefaultData(),
    }
  },
  methods: {
    ...mapActions({
      fetchEquipo: 'I_1_equipo/fetchEquipo',
      createEquipo: 'I_1_equipo/addEquipo',
    }),
    getDefaultData() {
      return {
        id: null,
        nombre: '',
        especialidad: null,
        telefono: '',
        correo: '',
        id_curso: null,
        id_persona_rol: null,
        id_especialidad: null,
        id_firma: null,
        tipo: 1, // equipo aula - docente
      }
    },
    async agregar(equipo) {
      const res = await this.createEquipo(equipo)
      if (res.status === 'success') {
        this.resetData()
        this.$toastSuccess(
          'Docente registrado 👍', 
          'El docente fue guardado con éxito!'
        )
        this.$bvModal.hide('modal-create')
      } else {
        this.$swalError('Error al crear al docente!', res.message)
      }
    },
    resetData() {
      this.data = this.getDefaultData()
    }
  },
}
</script>
