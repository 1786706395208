<template>
  <EstrategiasForm
    nombreModal="modal-create"
    title="Registrar estrategia diversificada que aplicará el/la profesor/a de educación regular para el curso"
    :data.sync="data"
    @processForm="agregar"
    @resetData="resetData"
  />
</template>

<script>
import { mapActions } from 'vuex'
import { toast, swal } from '@/mixins/alerts'
import EstrategiasForm from './EstrategiasForm.vue'

export default {
  components: {
    EstrategiasForm,
  },
  mixins: [toast, swal],
  data() {
    return {
      data: this.getDefaultData(),
    }
  },
  methods: {
    ...mapActions({
      fetchEstrategias: 'II_1_b_estrategias/fetchEstrategias',
      createEstrategia: 'II_1_b_estrategias/addEstrategia',
    }),
    getDefaultData() {
      return {
        id: null,
        id_asignatura: null,
        id_periodo: null,
        estrategia: '',
        criterios: '',
      }
    },
    async agregar(estrategia) {
      const res = await this.createEstrategia(estrategia)
      if (res.status === 'success') {
        await this.fetchEstrategias(estrategia.id_curso)
        this.resetData()
        this.$toastSuccess(
          'Estrategia registrada 👍', 
          'La estrategia fue guardada con éxito!'
        )
        this.$bvModal.hide('modal-create')
      } else {
        this.$swalError('Error al crear la estrategia!', res.message)
      }
    },
    resetData() {
      this.data = this.getDefaultData()
    }
  },
}
</script>
