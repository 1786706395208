var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BModal',{attrs:{"id":_vm.nombreModal,"title":_vm.title,"centered":"","size":"lg","hide-footer":true},on:{"shown":_vm.onModalOpen}},[_c('div',{staticClass:"d-flex justify-content-between mb-1"},[_c('div',{staticClass:"mt-50 text-secondary"}),_c('div',{staticClass:"mr-1 mt-50 text-muted small"},[_vm._v(" Campos obligatorios "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('BOverlay',{attrs:{"show":_vm.configs.cargando,"spinner-variant":"primary","variant":"semi-dark"}},[_c('BRow',[_c('BCol',{attrs:{"id":"col-ASIGNATURA","cols":"12","md":"12"}},[_c('BFormGroup',{attrs:{"label-for":"id_asignatura"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Ámbito, asignatura y/o Módulo de Aprendizaje "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[(_vm.configs.loadingAsignaturas)?_c('v-select',{attrs:{"placeholder":"Cargando asignaturas...","disabled":true}}):_c('v-select',{class:_vm.v$.apoyo.id_asignatura.$error === true
              ? 'border-danger rounded'
              : '',attrs:{"placeholder":"Seleccione una asignatura...","label":"nombre","options":_vm.asignaturasOption,"reduce":function (option) { return option.id; },"input-id":"id"},model:{value:(_vm.apoyo.id_asignatura),callback:function ($$v) {_vm.$set(_vm.apoyo, "id_asignatura", $$v)},expression:"apoyo.id_asignatura"}}),(_vm.v$.apoyo.id_asignatura.$error)?_c('div',{staticClass:"text-danger text-right",staticStyle:{"font-size":"0.857rem"},attrs:{"id":"id_asignaturaInfo"}},_vm._l((_vm.v$.apoyo.id_asignatura.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('BCol',{attrs:{"id":"col-HORAS-AULA","cols":"6","md":"6"}},[_c('BFormGroup',{attrs:{"label-for":"horas_aula"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Horas de apoyo en aula regular "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('BInputGroup',{class:_vm.v$.apoyo.horas_aula.$error === true
                ? 'border-danger rounded'
                : 'rounded'},[_c('BFormInput',{attrs:{"id":"horas_aula","type":"number","min":2,"placeholder":"Ingrese las horas de apoyo en aula"},on:{"keyup":function($event){_vm.apoyo.horas_aula = _vm.formatSoloNumerosUnDigito(_vm.apoyo.horas_aula)}},nativeOn:{"blur":function($event){return _vm.v$.apoyo.horas_aula.$touch($event)}},model:{value:(_vm.apoyo.horas_aula),callback:function ($$v) {_vm.$set(_vm.apoyo, "horas_aula", $$v)},expression:"apoyo.horas_aula"}}),_c('BInputGroupAppend',{attrs:{"is-text":""}},[_vm._v(" hrs ")])],1),(_vm.v$.apoyo.horas_aula.$error)?_c('div',{staticClass:"text-right text-danger",staticStyle:{"font-size":"0.857rem"},attrs:{"id":"horas_aulaInfo"}},_vm._l((_vm.v$.apoyo.horas_aula.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('BCol',{attrs:{"id":"col-PERIODO","cols":"6","md":"6"}},[_c('BFormGroup',{attrs:{"label-for":"horas_fuera"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Horas de apoyo fuera del aula "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('BInputGroup',{class:_vm.v$.apoyo.horas_fuera.$error === true
                ? 'border-danger rounded'
                : 'rounded'},[_c('BFormInput',{attrs:{"id":"horas_fuera","type":"number","placeholder":"Ingrese las horas de apoyo fuera del aula"},on:{"keyup":function($event){_vm.apoyo.horas_fuera = _vm.formatSoloNumerosUnDigito(_vm.apoyo.horas_fuera)}},nativeOn:{"blur":function($event){return _vm.v$.apoyo.horas_fuera.$touch($event)}},model:{value:(_vm.apoyo.horas_fuera),callback:function ($$v) {_vm.$set(_vm.apoyo, "horas_fuera", $$v)},expression:"apoyo.horas_fuera"}}),_c('BInputGroupAppend',{attrs:{"is-text":""}},[_vm._v(" hrs ")])],1),(_vm.v$.apoyo.horas_fuera.$error)?_c('div',{staticClass:"text-right text-danger",staticStyle:{"font-size":"0.857rem"},attrs:{"id":"horas_fueraInfo"}},_vm._l((_vm.v$.apoyo.horas_fuera.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('BCol',{attrs:{"id":"col-CRITERIOS","cols":"12","md":"12"}},[_c('BFormGroup',{attrs:{"label-for":"apoyos"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Tipos de apoyo especializado (Recursos materiales y/o profesionales) "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('BFormTextarea',{attrs:{"id":"apoyos","placeholder":"Ingrese los tipos de apoyo especializado","rows":"3","maxlength":"300","state":_vm.v$.apoyo.apoyos.$error === true
              ? false
              : null},nativeOn:{"blur":function($event){return _vm.v$.apoyo.apoyos.$touch($event)}},model:{value:(_vm.apoyo.apoyos),callback:function ($$v) {_vm.$set(_vm.apoyo, "apoyos", $$v)},expression:"apoyo.apoyos"}}),_c('small',{staticClass:"text-muted text-right d-block mt-25"},[_vm._v(_vm._s(_vm.apoyo.apoyo ? _vm.apoyo.apoyo.length : 0)+" / 300")]),(_vm.v$.apoyo.apoyos.$error)?_c('BFormInvalidFeedback',{staticClass:"text-right",attrs:{"id":"apoyosInfo"}},_vm._l((_vm.v$.apoyo.apoyos.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1)],1),_c('colLinea'),_c('div',{staticClass:"text-right"},[_c('BButton',{staticClass:"mr-1",attrs:{"variant":"outline-secondary"},on:{"click":_vm.closeModal}},[_vm._v(" Cancelar ")]),_c('BButton',{staticClass:"mr-25",attrs:{"variant":"primary","disabled":this.v$.apoyo.$errors.length > 0},on:{"click":_vm.submitOption}},[_vm._v(" Guardar Apoyo ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }