var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BOverlay',{attrs:{"show":_vm.cargando,"spinner-variant":"primary","variant":"semi-dark"}},[_c('BRow',{staticClass:"mt-2"},[_c('BCol',{attrs:{"id":"col-EVALUACION-PROCESO","md":"12"}},[_c('BFormGroup',{attrs:{"label":"Evaluación de proceso y avance","label-for":"proceso"}},[_c('BFormTextarea',{attrs:{"id":"proceso","placeholder":_vm.$can('update', _vm.nombre_permiso)
                ? "Indique estrategias y procedimientos que aplicará para las siguientes evaluaciones:\n- Evaluaciones para el aprendizaje, periódicas, se recomiendan quincenales.\n- Evaluaciones de resultados; trimestrales, semestrales con informe escrito a la Familia."
                : 'Sin información',"plaintext":!_vm.$can('update', _vm.nombre_permiso),"rows":"4","maxlength":"550","state":_vm.v$.evaluacion.estrategia.$error === true
              ? false
              : null},nativeOn:{"blur":function($event){return _vm.v$.evaluacion.estrategia.$touch($event)}},model:{value:(_vm.evaluacion.estrategia),callback:function ($$v) {_vm.$set(_vm.evaluacion, "estrategia", $$v)},expression:"evaluacion.estrategia"}}),_c('small',{staticClass:"text-muted text-right d-block mt-25"},[_vm._v(_vm._s(_vm.evaluacion.estrategia.length)+" / 550")]),(_vm.v$.evaluacion.estrategia.$error)?_c('BFormInvalidFeedback',{staticClass:"text-right",attrs:{"id":"evaluacionInfo"}},_vm._l((_vm.v$.evaluacion.estrategia.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('BCol',{attrs:{"id":"col-EVALUACIÓN-ANUAL","md":"12"}},[_c('BFormGroup',{attrs:{"label":"Evaluación anual de logros de aprendizaje; de evolución\n          del déficit o trastorno, de egreso o de continuidad en el PIE","label-for":"evaluacionAnual"}},[_c('BFormTextarea',{attrs:{"id":"evaluacionAnual","placeholder":_vm.$can('update', _vm.nombre_permiso)
              ? "Indique estrategias y procedimientos que aplicará para las siguientes evaluaciones:\n- Evaluaciones para el aprendizaje, periódicas, se recomiendan quincenales.\n- Evaluaciones de resultados; trimestrales, semestrales con informe escrito a la Familia."
              : 'Sin información',"plaintext":!_vm.$can('update', _vm.nombre_permiso),"rows":"4","maxlength":"550","state":_vm.v$.evaluacion.evaluacion.$error === true
              ? false
              : null},nativeOn:{"blur":function($event){return _vm.v$.evaluacion.evaluacion.$touch($event)}},model:{value:(_vm.evaluacion.evaluacion),callback:function ($$v) {_vm.$set(_vm.evaluacion, "evaluacion", $$v)},expression:"evaluacion.evaluacion"}}),_c('small',{staticClass:"text-muted text-right d-block mt-25"},[_vm._v(_vm._s(_vm.evaluacion.evaluacion.length)+" / 550")]),(_vm.v$.evaluacion.evaluacion.$error)?_c('BFormInvalidFeedback',{staticClass:"text-right",attrs:{"id":"evaluacionAnualInfo"}},_vm._l((_vm.v$.evaluacion.evaluacion.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('BCol',{attrs:{"id":"col-OBSERVACIONES","md":"12"}},[_c('BFormGroup',{attrs:{"label":"Observaciones","label-for":"observaciones"}},[_c('BFormTextarea',{attrs:{"id":"observaciones","placeholder":"Indique las observaciones","placeholder":_vm.$can('update', _vm.nombre_permiso)
              ? 'Indique las observaciones'
              : 'Sin información',"plaintext":!_vm.$can('update', _vm.nombre_permiso),"rows":"4","maxlength":"550","state":_vm.v$.evaluacion.observaciones.$error === true
              ? false
              : null},nativeOn:{"blur":function($event){return _vm.v$.evaluacion.observaciones.$touch($event)}},model:{value:(_vm.evaluacion.observaciones),callback:function ($$v) {_vm.$set(_vm.evaluacion, "observaciones", $$v)},expression:"evaluacion.observaciones"}}),_c('small',{staticClass:"text-muted text-right d-block mt-25"},[_vm._v(_vm._s(_vm.evaluacion.observaciones.length)+" / 550")]),(_vm.v$.evaluacion.observaciones.$error)?_c('BFormInvalidFeedback',{staticClass:"text-right",attrs:{"id":"observacionesInfo"}},_vm._l((_vm.v$.evaluacion.observaciones.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1)],1),_c('colLinea'),_c('div',{staticClass:"text-right",staticStyle:{"height":"69px !important"},attrs:{"id":"Action-buttons"}},[_c('btnSubmit',{staticClass:"float-right",attrs:{"variant":"primary","modulo":_vm.nombre_permiso,"disabled":this.v$.evaluacion.$errors.length > 0,"btnText":"Actualizar Estrategia"},on:{"processBtn":_vm.submitOption}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }