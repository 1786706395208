<template>
  <realizadasForm
    nombreModal="modal-create"
    title="Registrar acciones realizadas por el profesor o profesora de aula"
    :data.sync="data"
    @processForm="agregar"
    @resetData="resetData"
  />
</template>

<script>
import { mapActions } from 'vuex'
import { toast, swal } from '@/mixins/alerts'

import realizadasForm from './RealizadasForm.vue'

export default {
  components: {
    realizadasForm,
  },
  mixins: [toast, swal],
  data() {
    return {
      data: this.getDefaultData(),
    }
  },
  methods: {
    ...mapActions({
      createRealizada: 'III_1_b_acciones_realizadas/addRealizada',
      fetchRealizadas: 'III_1_b_acciones_realizadas/fetchRealizadas',
    }),
    getDefaultData() {
      return {
        id: null,
        id_plan_apoyo: null,
        planApoyo: null,
        observaciones: '',
      }
    },
    async agregar(apoyos) {
      const res = await this.createRealizada(apoyos);
      if (res.status === 'success') {
        const params = {
          idCurso: apoyos.idCurso,
          tipo: apoyos.tipo,
        }
        await this.fetchRealizadas(params)
        this.resetData()
        this.$toastSuccess(
          'Registro de acciones realizadas guardado  👍', 
          'El registro de acciones realizadas fue ingresado con éxito!'
        )
        this.$bvModal.hide('modal-create')
      } else {
        this.$swalError('Error al crear el registro de acciones realizadas!', res.message)
      }
    },
    resetData() {
      this.data = this.getDefaultData()
    },
    // agregar(realizada, acciones) {

      // let accionesArray = []
      // acciones.forEach(accion => {
      //   accionesArray.push({
      //     fecha: accion.fecha,
      //     horas: accion.horas,
      //     aula: 1,
      //     actividades: accion.actividades,
      //     firma: 0,
      //   })
      // })
      // const dataRealizada = {
      //   observaciones: realizada.observaciones,
      //   id_plan_apoyo: realizada.id_plan_apoyo,
      //   tipo: 1, // tipo profesor de aula
      //   acciones: accionesArray,
      // }

      // this.createRealizada(dataRealizada).then(() => {
      //   const data = {
      //     idCurso: this.idCurso,
      //     tipo: 1, // tipo profesor de aula
      //   }
      //   this.fetchRealizadas(data)
      // })
    // },
  },
}
</script>
