<template>
  <BModal
    :id="nombreModal"
    :title="title"
    centered
    size="lg"
    :hide-footer="true"
    @shown="onModalOpen"
  >
    <div class="d-flex justify-content-between mb-1">
      <div class="mt-50 text-secondary">
        <!-- Filtro para cargar datos en formulario -->
      </div>
      <div class="mr-1 mt-50 text-muted small">
        Campos obligatorios <span class="text-danger">*</span>
      </div>
    </div>
    <BOverlay
      :show="configs.cargando"
      spinner-variant="primary"
      variant="semi-dark"
    >
      <BRow>
        
        <BCol id="col-especialidad" cols="12" md="12">
          <BFormGroup label-for="especialidad" >
            <template #label>
              Tipo de coordinador <span class="text-danger">*</span>
            </template>
            <v-select
              v-model="equipo.especialidad"
              placeholder="Seleccione el Tipo de Coordinador ..."
              
              label="nombre"
              :options="especialidadesOptions"
              :reduce="option => option.id"
              input-id="id"
            />
            <div v-if="v$.equipo.especialidad.$error"
              id="especialidadInfo"
              class="text-danger text-right"
              style="font-size: 0.857rem;"
            >
              <p v-for="error of v$.equipo.especialidad.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
            </div>
          </BFormGroup>
        </BCol>

        <BCol id="col-nombre" cols="12" md="12">
          <BFormGroup label-for="nombre">
            <template #label>
              Nombre <span class="text-danger">*</span>
            </template>
            <BFormInput
              id="nombre"
              v-model="equipo.nombre"
              placeholder="Ingrese un nombre"
              :state="v$.equipo.nombre.$error === true
                ? false
                : null"
            />
            <BFormInvalidFeedback v-if="v$.equipo.nombre.$error"
              id="nombreInfo"
              class="text-right"
            >
              <p v-for="error of v$.equipo.nombre.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
            </BFormInvalidFeedback>
          </BFormGroup>
        </BCol>

        <BCol id="col-telefono" cols="12" md="12">
          <BFormGroup label-for="telefono">
            <template #label>
              Teléfono <span class="text-danger">*</span>
            </template>
            <BInputGroup size="md" prepend="+">
              <BFormInput
                id="telefono"
                v-model="equipo.telefono"
                placeholder="Ingrese un teléfono"
                :state="v$.equipo.telefono.$error === true
                  ? false
                  : null"
                @keyup='equipo.telefono = formatSoloNumerosMaxLenght(equipo.telefono, 11)'
              />
            </BInputGroup>
            <BFormInvalidFeedback v-if="v$.equipo.telefono.$error"
              id="telefonoInfo"
              class="text-right"
            >
              <p v-for="error of v$.equipo.telefono.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
            </BFormInvalidFeedback>
          </BFormGroup>
        </BCol>

        <BCol id="col-correo" cols="12" md="12">
          <BFormGroup label-for="correo">
            <template #label>
              Correo electrónico <span class="text-danger">*</span>
            </template>
            <BFormInput
              id="correo"
              v-model="equipo.correo"
              placeholder="Ingrese un correo"
              :state="v$.equipo.correo.$error === true
                ? false
                : null"
            />
            <BFormInvalidFeedback v-if="v$.equipo.correo.$error"
              id="correoInfo"
              class="text-right"
            >
              <p v-for="error of v$.equipo.correo.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
            </BFormInvalidFeedback>
          </BFormGroup>
        </BCol>

      </BRow>

      <colLinea />

      <div class="text-right">
        <BButton variant="outline-secondary" class="mr-1" @click="closeModal">
          Cancelar
        </BButton>

        <BButton
          variant="primary"
          class="mr-25"
          :disabled="this.v$.equipo.$errors.length > 0"
          @click="submitOption"
        >
          Guardar Docente PIE
        </BButton>
      </div>

    </BOverlay>
  </BModal>
</template>

<script>

// ETIQUETAS
import {
  BRow, BCol, BFormGroup, BFormInput, BFormInvalidFeedback, BMedia, BButton, 
  BAvatar, BOverlay, BFormDatepicker, BFormTimepicker, BFormTextarea, BModal, 
  VBModal, BInputGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import { mapGetters, mapActions } from 'vuex'

// VALIDACIONES
import useVuelidate from '@vuelidate/core'
import { required, maxLength, helpers, email } from '@vuelidate/validators'

// COMPONENTES
import colLinea from '@/views/components/Form/colLinea.vue'
import equipo from '@/store/pie/I equipo/1 equipo aula/getters'
import { formatos } from '@/mixins/formatos'

export default {
  components: {
    BRow, BCol, BFormGroup, BFormInput, BFormInvalidFeedback, BMedia, BButton, 
    BAvatar, BOverlay, BFormDatepicker, BFormTimepicker, BFormTextarea, BModal, 
    VBModal, BInputGroup,
    vSelect,
    
    colLinea,
  },
  mixins: [formatos],
  data() {
    return {
      configs: {
        cargando: false,
      },
      equipo: {},
      especialidadesOptions: [
        {
          id: 'En el establecimiento',
          nombre: 'En el establecimiento',
        },
        {
          id: 'En el DAEM (si el PIE es comunal)',
          nombre: 'En el DAEM (si el PIE es comunal)',
        },
        {
          id: 'Con Redes de Apoyo',
          nombre: 'Con Redes de Apoyo',
        }
      ],
    }
  },
  computed: {
    ...mapGetters({
      getUser: 'auth/user',
      getLibroSelected: 'libros/getLibroSelected',
    }),
  },
  // watch: {
  //   getLibroSelected(val) {
  //   },
  // },
  props: {
    nombreModal: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions({
      fetchPersonasRol: 'personas/fetchPersonasRol',
    }),
    onModalOpen () {
      this.initData();
    },
    initData() {
      this.equipo = { ...this.data }
    },
    submitOption() {
      this.v$.equipo.$touch()
      if (!this.v$.equipo.$invalid) {
        const params = {
          ...this.equipo,
          id_curso: this.getLibroSelected.id
        }
        this.$emit('processForm', params)
        this.v$.equipo.$reset()
      }
    },
    closeModal() {
      this.$bvModal.hide(this.nombreModal);
      this.v$.equipo.$reset();
      if (this.equipo.id === null) {
        this.$emit('resetData');
      }
      this.initData();
    },
  },
  validations() {
    return {
      equipo: {
        nombre: {
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener un máximo de 255 caracteres.', maxLength(255)),
        },
        especialidad: {
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener un máximo de 255 caracteres.', maxLength(255)),
        },
        telefono: {
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener un máximo de 20 caracteres.', maxLength(20)),
        },
        correo: {
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener un máximo de 255 caracteres.', maxLength(255)),
          email: helpers.withMessage('Debe ser un correo valido.', email),
        },
      }
    }
  },
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
}
</script>