<template>
  <ApoyosEstudiantesForm
    nombreModal="modal-create"
    title="Registrar apoyos para cada estudiante o grupo de estudiantes"
    :data.sync="data"
    @processForm="agregar"
    @resetData="resetData"
  />
</template>

<script>
import { mapActions } from 'vuex'
import { toast, swal } from '@/mixins/alerts'
import ApoyosEstudiantesForm from './ApoyosEstudiantesForm.vue'

export default {
  components: {
    ApoyosEstudiantesForm,
  },
  mixins: [toast, swal],
  data() {
    return {
      data: this.getDefaultData(),
    }
  },
  methods: {
    ...mapActions({
      createRealizada: 'III_1_b_acciones_realizadas/addRealizada',
      fetchRealizadas: 'III_1_b_acciones_realizadas/fetchRealizadas',
    }),
    getDefaultData() {
      return {
        id: null,
        id_plan_apoyo: null,
        planApoyo: null,
        observaciones: '',
      }
    },
    async agregar(apoyos) {
      const res = await this.createRealizada(apoyos);
      if (res.status === 'success') {
        const params = {
          idCurso: apoyos.idCurso,
          tipo: apoyos.tipo,
        }
        await this.fetchRealizadas(params)
        this.resetData()
        this.$toastSuccess(
          'Registro de apoyos guardado  👍', 
          'El registro de apoyos fue ingresado con éxito!'
        )
        this.$bvModal.hide('modal-create')
      } else {
        this.$swalError('Error al crear el registro de apoyos!', res.message)
      }
    },
    resetData() {
      this.data = this.getDefaultData()
    },
  },
}
</script>
