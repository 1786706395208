<template>
  <reunionesForm
    :nombreModal="modal"
    title="Editar reunión"
    :data.sync="item"
    @processForm="editar"
  />
</template>

<script>
import { mapActions } from 'vuex'
import { toast, swal } from '@/mixins/alerts'
import reunionesForm from './ReunionesForm.vue'

export default {
  components: {
    reunionesForm,
  },
  mixins: [toast, swal],
  data() {
    return {
      spinner: false,
    }
  },
  props: {
    modal: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions({
      updateReunion: 'I_2_reuniones/updateReunion',
      fetchReuniones: 'I_2_reuniones/fetchReuniones',
    }),
    async editar(reunion) {
      const params = {
        ...reunion,
        personas: this.setPersonas(reunion.personas),
      }

      const res = await this.updateReunion(params);
      if (res.status === 'success') {        
        this.$toastSuccess(
          'Reunión actualizada 👍', 
          'La reunión fue editada con éxito!'
        );
        this.$bvModal.hide(this.modal);
      } else {
        this.$swalError('Error al editar la reunión!', res.message);
      }
    },
    setPersonas(reunionPersonas) {
      let personas = []
      reunionPersonas.forEach(persona => {
        if (typeof persona.id_persona_rol === 'undefined') {
          personas.push(persona)
        } else {
          personas.push(persona.id_persona_rol)
        }
      })
      return personas;
    },
  },
}
</script>
