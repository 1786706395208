<template>
  <BOverlay
    :show="cargando"
    :class="cargando ? 'mt-4 mb-4' : ''"
    spinner-variant="primary"
    variant="semi-dark"
    style="min-height: 640px !important;"
  >
    <!-- ASIGNATURAS -->
    <BTabs
      v-if="asignaturas.length !== 0"
      v-model="asignaturaIndex"
      class="mb-0"
      style="margin: -10px -10px -26px -10px!important;"
      :align="alignTabs()"
      fills
    >
      <BTab
        v-for="(asignatura, key) in asignaturas"
        :key="key+1"
        :title="asignatura.nombre"
        :active="key === 0"
        lazy
      >
        <colLinea style="margin-top: -15px;" class="mb-0 mb-lg-1"/>

        <!-- ENCABEZADO -->
        <BRow
          v-if="asignaturas.length !== 0"
          style="margin-bottom: 10px;"
        >
          <!-- Informativo -->
          <BCol
            cols="12"
            :lg="$can('create', 'evaluaciones') ? '6' : '8'"
            md="12"
            sm="12"
          >
            <!-- Profesor a cargo de la asignatura -->
            <div
              v-if="user.id_rol === 1 || user.id_rol === 3 || user.id_rol === 4 || user.id_rol === 8"
              class="ml-25 pt-50"
              size="sm"
              horizontal
            >
              <BBadge
                v-for="(docente, index) in asignatura.docentes"
                variant="primary"
                class="pl-50"
                :key="index"
              >
                {{docente.nombre_docente}}
              </BBadge>
            </div>

            <div
              v-else
              class="ml-25 pt-2"
              size="sm"
              horizontal
            >
            </div>

            <b-alert
              show
              variant="primary"
              style="margin-top: -5px"
            >
              <!-- <div
                v-else-if="user.id_rol === 2 || user.id_rol === 6 || user.id_rol === 7"
                class="alert-body text-center mt-25"
              >
                <feather-icon
                  icon="InfoIcon"
                  class="mr-50"
                />
                Para editar una evaluación, haz click en el título de la nota.
              </div> -->
            </b-alert>
          </BCol>

          <!-- PERIODOS -->
          <BCol
            cols="12"
            lg="4"
            md="12"
            sm="12"
            class="text-center text-lg-right mb-1"
          >
            <BButtonGroup class="d-flex">
              <BButton
                v-for="(periodo, key) in getPeriodosEstablecimiento"
                :key="key+1"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                :class="idPeriodoActivo === periodo.id ? 'active' : ''"
                @click="changePeriodo(periodo.id)"
              >
                {{ periodo.nombre }}
              </BButton>
            </BButtonGroup>
          </BCol>

          <!-- CREAR EVALUACIÓN -->
          <BCol
            v-if="$can('create', 'evaluaciones')"
            class="text-right"
            cols="12"
            lg="2"
            md="12"
            sm="12"
          >
            <!-- btn crear evaluación sumativa -->
            <btn-crear-modal
              v-if="!cargando"
              accion="Crear"
              modulo="evaluaciones"
              texto="Formativa"
              modal="modal-evaluacion-formativa-create"
            />
          </BCol>
          <!-- <BCol
            v-if="$can('create', 'evaluaciones')"
            cols="12"
            lg="2"
            md="12"
            sm="12"
          >
            <!-- btn crear evaluacion
            <btn-crear-modal
              v-if="!cargando"
              accion="Crear"
              modulo="evaluaciones"
              texto="Evaluación"
              modal="modal-evaluacion-create"
            />
          </BCol>-->
        </BRow>

        <!-- Listado de alumnos del curso y sus notas por prueba -->
        <tabla-notas
          :id_asignatura="asignatura.id"
          :id_periodo_activo.sync="idPeriodoActivo"
          :asignaturas.sync="asignaturas"
          :asignaturaIndex="asignaturaIndex + 1"
        />

      </BTab>
    </BTabs>
    <!-- SIN ASIGNATURAS -->
    <BRow
      v-else-if="!cargando"
    >
      <BCol
        cols="12"
        md="3"
        class="mt-2"
      ></BCol>
      <BCol
        class="mt-2"
        md="6"
      >
        <b-alert
          show
          variant="primary"
          style="margin-top: -5px"
        >
          <!-- ADMIN, DIRECTOR, JEFE UTP -->
          <div
            v-if="user.id_rol === 1 || user.id_rol === 3 || user.id_rol === 4"
            class="alert-body text-center mt-25"
          >
            <feather-icon
              icon="InfoIcon"
              class="mr-50"
            />
            Ningún docente tiene asignaturas asignadas en este curso.<br>
            Por favor, asegúrese que los docentes ingresen su carga horaria.
          </div>
          <!-- DOCENTES, EDUCADORA DIFERENCIAL, PROFESIONAL DE LA SALUD  -->
          <div
            v-else-if="user.id_rol === 2 || user.id_rol === 6 || user.id_rol === 7"
            class="alert-body text-center mt-25"
          >
            <feather-icon
              icon="InfoIcon"
              class="mr-50"
            />
            La cuenta no posee asignaturas asignadas en este curso.<br>
            Por favor, ingrese su horario en <b-link :to="{ name: 'mis-horarios'}"><u>Mis Horarios</u></b-link>.
          </div>

        </b-alert>
      </BCol>
    </BRow>

  </BOverlay>
</template>

<script>

// ETIQUETAS
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BFormInvalidFeedback, BBadge,
  BMedia, BButton, BButtonGroup, BAvatar, BOverlay, BCard, BTab, BTabs,
  BFormRadioGroup, BTable, BCardText, BAlert, BLink, BListGroup, BListGroupItem,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'

import { mapGetters, mapActions } from 'vuex'

// COMPONENTES RECICLADOS
import btnCrearModal from '../../../components/List/btnCrearModal.vue'
import colLinea from '../../../components/Form/colLinea.vue'

// COMPONENTES HIJOS
import TablaNotas from './TablaNotas.vue'

export default {
  components: {
    BRow, BCol, BFormGroup, BFormInput, BForm, BFormInvalidFeedback, BBadge,
    BMedia, BButton, BButtonGroup, BAvatar, BOverlay, BCard, BTab, BTabs,
    BFormRadioGroup, BTable, BCardText, BAlert, BLink, BListGroup, BListGroupItem,

    // COMPONENTES RECICLADOS
    btnCrearModal,
    colLinea,
    // COMPONENTES HIJOS
    TablaNotas,
    // EvaluacionesCreate,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      cargando: true,
      nombre_permiso: 'notas',

      asignaturaIndex: null,
      idPeriodoActivo: null,
      asignaturas: [],
      selectedRadio: 'active',
      optionsRadio: [
        { text: '1º S', value: 1 },
        { text: '2º S', value: 2 },
        { text: 'Todo', value: 0 },
      ],

      // Align Menu
      pantallaWidth: window.innerWidth,
      sizesWidth: [
        {
          id: 'sm',
          min: 200,
          max: 575,
        },
        {
          id: 'md',
          min: 575,
          max: 992,
        },
        {
          id: 'lg',
          min: 992,
          max: 4900,
        },
      ]
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      getLibroSelected: 'libros/getLibroSelected',
      getHorarios: 'horarios/getHorarios',
      getPeriodosEstablecimiento: 'periodos/getPeriodosEstablecimiento',
    }),
  },
  watch: {
    getLibroSelected(val) {
      this.cargaAsignaturas()
    },
    getPeriodosEstablecimiento(val) {
      this.setPeriodoActivo()
    },
  },
  mounted() {
    this.cargaPeriodos()
    this.cargaAsignaturas()
    // Align Menu
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResizePantalla);
    })
  },
  methods: {
    ...mapActions({
      fetchHorarioUsuarioCurso: 'horarios/fetchHorarioUsuarioCurso',
      fetchPeriodosEstablecimiento: 'periodos/fetchPeriodosEstablecimiento',
    }),
    changePeriodo(idPeriodo) {
      this.idPeriodoActivo = idPeriodo
    },
    cargaPeriodos(){
      this.fetchPeriodosEstablecimiento(this.user.id_establecimiento)
    },
    setPeriodoActivo() {
      const fechaActual = new Date();
      this.getPeriodosEstablecimiento.forEach(periodo => {
        if (fechaActual > new Date(periodo.fecha_inicio) && fechaActual < new Date(periodo.fecha_termino)) {
          this.idPeriodoActivo = periodo.id
        }
      })
      if (this.idPeriodoActivo === null) {
        this.idPeriodoActivo = this.getPeriodosEstablecimiento[0].id
      }
    },
    cargaAsignaturas() {
      this.fetchHorarioUsuarioCurso(this.getLibroSelected.id).then(() => {
        this.asignaturas = []
        this.getHorarios.forEach(horario => {
          const findAsignatura = this.asignaturas.filter(as => as.id === horario.id_asignatura)
          const nombre_docente = `${horario.nombre} ${horario.primer_apellido}`
          if (findAsignatura.length === 0) {
            this.asignaturas.push({
              id_curso: horario.id_curso,
              id: horario.id_asignatura,
              nombre: horario.nombre_asignatura,
              docentes: [
                {
                  id_persona_rol: horario.id_persona_rol,
                  nombre_docente,
                }
              ],
            })
          } else {
            const existeDocente = findAsignatura[0].docentes.find(d => d.id_persona_rol === horario.id_persona_rol)
            // si no existe se inserta
            if (typeof existeDocente === 'undefined') {
              findAsignatura[0].docentes.push({
                id_persona_rol: horario.id_persona_rol,
                nombre_docente,
              })
            }
          }
        })
        this.cargando = false
      })
    },
    // Align Menu
    onResizePantalla() {
      this.pantallaWidth = window.innerWidth
      this.alignTabs()
    },
    alignTabs() {
      let align
      this.sizesWidth.forEach(size => {
        if (this.pantallaWidth >= size.min && this.pantallaWidth <= size.max) {
          if (size.id === 'sm') {
            align = 'center'
          } else if (size.id === 'md') {
            align = 'center'
          } else if (size.id === 'lg') {
            align = 'left'
          }
        }
      })
      return align
    },
  },
  // Align Menu
  beforeDestroy() {
    window.removeEventListener('resize', this.onResizePantalla);
  },
}
</script>
