<template>
  <PlanApoyoForm
    :nombreModal="modal"
    title="Editar plan de apoyo individual"
    :data.sync="item"
    @processForm="editar"
  />
</template>

<script>

import { mapActions } from 'vuex'
import { toast, swal } from '@/mixins/alerts'
import PlanApoyoForm from './PlanApoyoForm.vue'

export default {
  components: {
    PlanApoyoForm,
  },
  mixins: [toast, swal],
  data() {
    return {
      apoyoEspecializado: [],
    }
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    modal: {
      type: String,
      required: true,
    },
  },
  methods: {
    ...mapActions({
      updatePlanApoyo: 'II_4_plan_apoyo/updatePlanApoyo',
      fetchPlanApoyos: 'II_4_plan_apoyo/fetchPlanApoyos',
    }),
    async editar(planApoyo) {
      let personas = []
      personas.push(planApoyo.alumno.value)
      planApoyo.apoyoEspecializado.forEach(apoyo => {
        personas.push(apoyo)
      })

      const params = {
        ...planApoyo,
        personas,
      }
      const res = await this.updatePlanApoyo(params);
      if (res.status === 'success') {        
        this.$toastSuccess(
          'Plan de apoyo actualizado 👍', 
          'El plan de apoyo individual fue editado con éxit!'
        );
        this.$bvModal.hide(this.modal);
      } else {
        this.$swalError('Error al editar el plan de apoyo!', res.message);
      }
    },
  },
}
</script>
