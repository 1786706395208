// We haven't added icon's computed property because it makes this mixin coupled with UI

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export const toast = {
  methods: {
    $toastError(title = 'Error al cargar los datos !', text) {
      this.$toast({
        component: ToastificationContent,
        props: { title, icon: 'XIcon', text, variant: 'danger' },
      },
        {
          position: 'top-center',
          timeout: 3000,
        })
    },
    $toastSuccess(title = 'Actualizado 👍', text) {
      this.$toast({
        component: ToastificationContent,
        props: { title, icon: 'CheckIcon', text, variant: 'success' },
      },
        {
          position: 'top-center',
          timeout: 3000,
        })
    },
  },
}

export const swal = {
  methods: {
    $swalError(title = 'Error !', text) {
      this.$swal({
        title,
        text,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    $swalSuccess(title = 'Actualizado 👍', text) {
      this.$swal({
        icon: 'success',
        title,
        text,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    $swalConfirm(
      title = '¿Está seguro?',
      html = 'Esta acción no se puede deshacer.',
      confirmButtonText = 'Sí, confirmar',
      cancelButtonText = 'Cancelar',
      icon = 'warning'
    ) {
      return this.$swal({
        title,
        html,
        icon,
        showCancelButton: true,
        confirmButtonText,
        cancelButtonText,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      });
    },
  },
}