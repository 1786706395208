<template>
  <BModal
    :id="nombreModal"
    :title="title"
    centered
    size="lg"
    :hide-footer="true"
    @shown="onModalOpen"
  >
    <div class="d-flex justify-content-between mb-1">
      <div class="mt-50 text-secondary">
      </div>
      <div class="mr-1 mt-50 text-muted small">
        Campos obligatorios <span class="text-danger">*</span>
      </div>
    </div>
    <BOverlay
      :show="configs.cargando"
      spinner-variant="primary"
      variant="semi-dark"
    >
      <BRow>

        <BCol id="col-ASIGNATURA" cols="12" md="12">
          <BFormGroup label-for="id_asignatura">
            <template #label>
              Ámbito, asignatura y/o Módulo de Aprendizaje <span class="text-danger">*</span>
            </template>
            <v-select
              v-if="configs.loadingAsignaturas"
              
              placeholder="Cargando asignaturas..."
              :disabled="true"
            />
            <v-select
              v-else
              v-model="apoyo.id_asignatura"
              placeholder="Seleccione una asignatura..."
              
              label="nombre"
              :options="asignaturasOption"
              :reduce="option => option.id"
              input-id="id"
              :class="v$.apoyo.id_asignatura.$error === true
                ? 'border-danger rounded'
                : ''"
            />
            <div v-if="v$.apoyo.id_asignatura.$error"
              id="id_asignaturaInfo"
              class="text-danger text-right"
              style="font-size: 0.857rem;"
            >
              <p v-for="error of v$.apoyo.id_asignatura.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
            </div>

          </BFormGroup>
        </BCol>

        <BCol id="col-HORAS-AULA" cols="6" md="6">
          <BFormGroup label-for="horas_aula">
            <template #label>
              Horas de apoyo en aula regular <span class="text-danger">*</span>
            </template>
            <BInputGroup
              :class="v$.apoyo.horas_aula.$error === true
                  ? 'border-danger rounded'
                  : 'rounded'"
            >
              <BFormInput
                id="horas_aula"
                v-model="apoyo.horas_aula"
                type="number"
                :min="2"
                placeholder="Ingrese las horas de apoyo en aula"
                @keyup='apoyo.horas_aula = formatSoloNumerosUnDigito(apoyo.horas_aula)'
                @blur.native="v$.apoyo.horas_aula.$touch"
              />
                  <!-- :state="v$.apoyo.horas_aula.$error === true
                    ? false
                    : null" -->
              <BInputGroupAppend is-text>
                hrs
              </BInputGroupAppend>
            </BInputGroup>
            <!-- Mensajes Error Validación -->
            <div v-if="v$.apoyo.horas_aula.$error"
              id="horas_aulaInfo"
              class="text-right text-danger"
              style="font-size: 0.857rem;"
            >
              <p v-for="error of v$.apoyo.horas_aula.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
            </div>
          </BFormGroup>
        </BCol>

        <BCol id="col-PERIODO" cols="6" md="6">
          <BFormGroup label-for="horas_fuera">
            <template #label>
              Horas de apoyo fuera del aula <span class="text-danger">*</span>
            </template>
            <BInputGroup
              :class="v$.apoyo.horas_fuera.$error === true
                  ? 'border-danger rounded'
                  : 'rounded'"
            >
              <BFormInput
                id="horas_fuera"
                v-model="apoyo.horas_fuera"
                type="number"
                placeholder="Ingrese las horas de apoyo fuera del aula"
                @keyup='apoyo.horas_fuera = formatSoloNumerosUnDigito(apoyo.horas_fuera)'
                @blur.native="v$.apoyo.horas_fuera.$touch"
              />
              <BInputGroupAppend is-text>
                hrs
              </BInputGroupAppend>
            </BInputGroup>
            <div v-if="v$.apoyo.horas_fuera.$error"
              id="horas_fueraInfo"
              class="text-right text-danger"
              style="font-size: 0.857rem;"
            >
              <p v-for="error of v$.apoyo.horas_fuera.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
            </div>
          </BFormGroup>
        </BCol>

        <BCol id="col-CRITERIOS" cols="12" md="12">
          <BFormGroup label-for="apoyos">
            <template #label>
              Tipos de apoyo especializado (Recursos materiales y/o profesionales) <span class="text-danger">*</span>
            </template>
            <BFormTextarea
              id="apoyos"
              placeholder="Ingrese los tipos de apoyo especializado"
              v-model="apoyo.apoyos"
              rows="3"
              maxlength="300"
              :state="v$.apoyo.apoyos.$error === true
                ? false
                : null"
              @blur.native="v$.apoyo.apoyos.$touch"
            />
            <small class="text-muted text-right d-block mt-25">{{ apoyo.apoyo ? apoyo.apoyo.length : 0 }} / 300</small>
            <BFormInvalidFeedback v-if="v$.apoyo.apoyos.$error"
              id="apoyosInfo"
              class="text-right"
            >
              <p v-for="error of v$.apoyo.apoyos.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
            </BFormInvalidFeedback>
          </BFormGroup>
        </BCol>

      </BRow>

      <colLinea />

      <div class="text-right">
        <BButton variant="outline-secondary" class="mr-1" @click="closeModal">
          Cancelar
        </BButton>

        <BButton
          variant="primary"
          class="mr-25"
          :disabled="this.v$.apoyo.$errors.length > 0"
          @click="submitOption"
        >
          Guardar Apoyo
        </BButton>
      </div>

    </BOverlay>
  </BModal>
</template>

<script>

// ETIQUETAS
import vSelect from 'vue-select'
import {
  BRow, BCol, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BOverlay, 
  BFormTextarea, BModal, VBModal, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { mapGetters, mapActions } from 'vuex'

// VALIDACIONES
import useVuelidate from '@vuelidate/core'
import { required, maxLength, helpers } from '@vuelidate/validators'

import colLinea from '@/views/components/Form/colLinea.vue'

// FORMATOS
import { formatos } from '@/mixins/formatos'

export default {
  components: {
    BRow, BCol, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BOverlay, 
    BFormTextarea, BModal, VBModal, BInputGroup, BInputGroupAppend,
    vSelect,
    colLinea,
  },
  directives: {
    'BModal': VBModal,
    Ripple,
  },
  mixins: [formatos],
  data() {
    return {
      configs: {
        cargando: false,
        loadingAsignaturas: true,
      },
      apoyo: {},
      asignaturasOption: [],
    }
  },
  computed: {
    ...mapGetters({ getLibroSelected: 'libros/getLibroSelected' }),
  },
  watch: {
    getLibroSelected(val) {
      this.cargarAsignaturas()
    },
  },
  props: {
    nombreModal: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.apoyo = { ...this.data }
  },
  methods: {
    ...mapActions({
      fetchAsignaturasCurso: 'asignaturas/fetchAsignaturasCurso',
    }),
    async onModalOpen() {
      await Promise.all([
        this.cargarAsignaturas(),
      ])
    },
    async cargarAsignaturas() {
      const { data } = await this.fetchAsignaturasCurso(this.getLibroSelected.id)
      this.asignaturasOption = []
      this.asignaturasOption = data
      this.configs.loadingAsignaturas = false
    },
    submitOption() {
      this.v$.apoyo.$touch()
      if (!this.v$.apoyo.$invalid) {
        const params = {
          ...this.apoyo,
          id_curso: this.getLibroSelected.id
        }
        this.$emit('processForm', params)
        this.v$.apoyo.$reset()
      }
    },
    closeModal() {
      this.$bvModal.hide(this.nombreModal)
      this.v$.apoyo.$reset()
      if (this.apoyo.id === null) {
        this.$emit('resetData')
      }
    },
  },
  validations() {
    return {
      apoyo: {
        id_asignatura: {
          required: helpers.withMessage('El campo es requerido.', required),
        },
        apoyos: {
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener un máximo de 300 caracteres.', maxLength(300)),
        },
        horas_aula: {
          required: helpers.withMessage('El campo es requerido.', required),
        },
        horas_fuera: {
          required: helpers.withMessage('El campo es requerido.', required),
        },
      }
    }
  },
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
}
</script>
<!-- 
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style> -->
