<template>
  <BModal
    :id="nombreModal"
    :title="title"
    centered
    size="xl"
    :hide-footer="true"
    @shown="onModalOpen"
  >
    <div class="d-flex justify-content-between mb-1">
      <div class="mt-50 text-secondary">
      </div>
      <div class="mr-1 mt-50 text-muted small">
        Campos obligatorios <span class="text-danger">*</span>
      </div>
    </div>
    <BOverlay
      :show="configs.cargando"
      spinner-variant="primary"
      variant="semi-dark"
    >
      <BRow>

        <BCol id="col-FECHA" cols="12" md="6">
          <BFormGroup label-for="fecha">
            <template #label>
              Fecha <span class="text-danger">*</span>
            </template>
            <BFormDatepicker
              v-model="reunion.fecha"
              id="fecha"
              placeholder="Abrir calendario"
              hide-header
              :date-format-options="{
                year: 'numeric',
                month: 'short',
                day: '2-digit',
                weekday: 'short'
              }"
              start-weekday="1"
              locale="es-CL"
              :date-disabled-fn="dateDisabled"
              label-help=""
              :state="v$.reunion.fecha.$error === true
                ? false
                : null"
            />
            <BFormInvalidFeedback v-if="v$.reunion.fecha.$error"
              id="fechaInfo"
              class="text-right"
            >
              <p v-for="error of v$.reunion.fecha.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
            </BFormInvalidFeedback>
          </BFormGroup>
        </BCol>

        <BCol id="col-HORA" cols="12" md="6">
          <BFormGroup label-for="horario">
            <template #label>
              Hora <span class="text-danger">*</span>
            </template>
            <BInputGroup>
              <cleave
                id="time"
                v-model='reunion.horario'
                :raw="false"
                :options="time"
                placeholder="hh:mm"
                :class="v$.reunion.horario.$error === true
                  ? 'form-control border-danger rounded'
                  : 'form-control'"
              />
              <BInputGroupAppend>
                <BFormTimepicker
                  v-model='reunion.horario'
                  button-only
                  size='sm'
                  aria-controls='horario'
                  hide-header
                  locale='es-CL'
                  variant="outlined"
                  no-close-button
                  @keyup='formatHoraInput(reunion.horario)'
                />
              </BInputGroupAppend>
            </BInputGroup>
            <div v-if="v$.reunion.horario.$error"
              id="diaInfo"
              class="text-danger text-right"
              style="font-size: 0.857rem;"
            >
              <p v-for="error of v$.reunion.horario.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
            </div>
          </BFormGroup>
        </BCol>

        <BCol id="col-PERIODO" cols="12" md="12">
          <BFormGroup label-for="periodo">
            <template #label>
              Período de tiempo en que se aplicará 
              <span class="text-danger">*</span>
            </template>
            <v-select
              v-if="configs.loadingPeriodos"
              
              placeholder="Cargando periodos..."
              :disabled="true"
            />
            <v-select
              v-else
              v-model="reunion.id_periodo"
              placeholder="Seleccione un periodo..."
              
              label="nombre"
              :options="periodosOption"
              :reduce="option => option.id"
              input-id="id_periodo"
              :class="v$.reunion.id_periodo.$error === true
                ? 'border-danger rounded'
                : ''"
            />
            <div
              v-if="v$.reunion.id_periodo.$error"
              id="id_periodoInfo"
              class="text-danger text-right"
              style="font-size: 0.857rem;"
            >
              <p v-for="error of v$.reunion.id_periodo.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
            </div>
          </BFormGroup>
        </BCol>

        <BCol id="col-Asistentes" cols="12" md="12">
          <BFormGroup label-for="asistentes" >
            <template #label>
              Asistentes
              <span class="text-danger">*</span>
            </template>
            <v-select
              v-if="configs.loadingPersonas"
              
              placeholder="Cargando personas..."
              :disabled="true"
            />
            <v-select
              v-else
              v-model="reunion.personas"
              multiple
              placeholder="Seleccione los asistentes..."
              
              label="nombre"
              :options="asistentesOptions"
              :reduce="option => option.id_persona_rol"
              input-id="id_persona_rol"
              :class="v$.reunion.personas.$error === true
                ? 'border-danger rounded'
                : ''"
              :closeOnSelect="false"
            />
            <div
              v-if="v$.reunion.personas.$error"
              id="asistentesInfo"
              class="text-danger text-right"
              style="font-size: 0.857rem;"
            >
              <p v-for="error of v$.reunion.personas.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
            </div>
          </BFormGroup>
        </BCol>

        <BCol id="col-Acuerdos" cols="12" md="12">
          <BFormGroup label-for="acuerdos">
            <template #label>
              Acuerdos <span class="text-danger">*</span>
            </template>
            <BFormTextarea
              id="acuerdos"
              placeholder="Ingrese los acuerdos"
              v-model="reunion.acuerdos"
              rows="9"
              maxlength="3000"
              :state="v$.reunion.acuerdos.$error === true
                ? false
                : null"
            />
            <small class="text-muted text-right d-block mt-25">{{ reunion.acuerdos ? reunion.acuerdos.length : 0 }} / 3000</small>
            <BFormInvalidFeedback
              v-if="v$.reunion.acuerdos.$error"
              id="acuerdosInfo"
              class="text-right"
            >
              <p v-for="error of v$.reunion.acuerdos.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
            </BFormInvalidFeedback>
          </BFormGroup>
        </BCol>

      </BRow>

      <colLinea />

      <div class="text-right">
        <BButton variant="outline-secondary" class="mr-1" @click="closeModal">
          Cancelar
        </BButton>

        <BButton
          variant="primary"
          class="mr-25"
          :disabled="this.v$.reunion.$errors.length > 0"
          @click="submitOption"
        >
          Guardar Reunión
        </BButton>
      </div>

    </BOverlay>
  </BModal>
</template>

<script>

// ETIQUETAS
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BFormInvalidFeedback, BOverlay,
  BFormDatepicker, BFormTimepicker, BFormTextarea, BModal, VBModal, BInputGroup, 
  BInputGroupAppend, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

// CLEAVE
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

// VALIDACIONES
import useVuelidate from '@vuelidate/core'
import { required, maxLength, helpers } from '@vuelidate/validators'

import { mapGetters, mapActions } from 'vuex'

// FORMATOS
import colLinea from '@/views/components/Form/colLinea.vue'
import { formatos } from '@/mixins/formatos'
import { swal } from '@/mixins/alerts'

export default {
  components: {
    // ETIQUETAS
    BRow, BCol, BFormGroup, BFormInput, BForm, BFormInvalidFeedback, BOverlay,
    BFormDatepicker, BFormTimepicker, BFormTextarea, BModal, VBModal, BInputGroup, 
    BInputGroupAppend, BButton,
    vSelect,
    Cleave,
    colLinea,
  },
  directives: {
    'BModal': VBModal,
    Ripple,
  },
  mixins: [formatos, swal],
  data() {
    return {
      // datatimer
      time: {
        time: true,
        timePattern: ['h', 'm'],
      },
      configs: {
        cargando: false,
        loadingPersonas: true,
        loadingPeriodos: true,
      },
      reunion: {},
      
      asistentesOptions: [],
      periodosOption: [],
    }
  },
  computed: {
    ...mapGetters({
      getUser: 'auth/user',
      getLibroSelected: 'libros/getLibroSelected',
    }),
  },
  watch: {
    getLibroSelected(val) {
      this.onModalOpen()
    },
  },
  props: {
    nombreModal: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions({
      fetchEquipoAll: 'I_1_equipo/fetchEquipoAll',
      fetchPeriodosEstablecimiento: 'periodos/fetchPeriodosEstablecimiento',
    }),
    async onModalOpen () {
      await Promise.all([
        this.cargarEquipo(),
        this.cargarPeriodos()
      ])
      this.initData();
    },
    initData() {
      let personas
      if (this.data.id !== null) {
        personas = this.data.id !== null 
          ? this.data.asistentes.map(item => item.id_persona_rol)
          : {};
      }
      this.reunion = { ...this.data, personas }
    },
    async cargarEquipo() {
      this.configs.loadingPersonas = true;
      const res = await this.fetchEquipoAll(this.getLibroSelected.id);
      this.asistentesOptions = []
      if (res.status === 'success') {
        this.asistentesOptions = res.data.map(item => ({
          id_persona_rol: item.id_persona_rol,
          nombre: this.primeraMayus(
            `${item.nombre} - ${item.tipo === 1 ? 'Docente' : item.especialidad}`
          ),
        }));
      } else {
        this.$swalError('Error al cargar datos!', res.message)
      }
      this.configs.loadingPersonas = false;
    },
    async cargarPeriodos() {
      this.configs.loadingPeriodos = true;
      const res = await this.fetchPeriodosEstablecimiento(this.getUser.id_establecimiento)
      this.periodosOption = []
      if (res.status === 'success') {
        this.periodosOption = res.data
      } else {
        this.$swalError('Error al cargar datos!', res.message)
      }
      this.configs.loadingPeriodos = false;
    },
    submitOption() {
      this.v$.reunion.$touch()
      if (!this.v$.reunion.$invalid) {
        const params = {
          ...this.reunion,
          id_cursos: this.getLibroSelected.id
        }
        this.$emit('processForm', params)
        this.v$.reunion.$reset()
      }
    },
    closeModal() {
      this.$bvModal.hide(this.nombreModal);
      this.v$.reunion.$reset();
      if (this.reunion.id === null) {
        this.$emit('resetData');
      }
      this.initData();
    },
    dateDisabled(ymd, date) {
      // Disable weekends (Sunday = `0`, Saturday = `6`) and
      // disable days that fall on the 13th of the month
      const weekday = date.getDay()
      const day = date.getDate()
      // Return `true` if the date should be disabled
      // return weekday === 0 || weekday === 6 || day === 1
      return weekday === 0 || weekday === 6
    },
  },
  validations() {
    return {
      reunion: {
        fecha: {
          required: helpers.withMessage('El campo es requerido.', required),
        },
        horario: {
          required: helpers.withMessage('El campo es requerido.', required),
        },
        personas: {
          required: helpers.withMessage('El campo es requerido.', required),
        },
        id_periodo: {
          required: helpers.withMessage('El campo es requerido.', required),
        },
        acuerdos: {
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener un máximo de 3000 caracteres.', maxLength(3000)),
        },
      }
    }
  },
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>
