<template>
  <BOverlay
    :show="spinner"
    spinner-variant="primary"
    variant="semi-dark"
  >
    <BRow class="mb-3">
      <BCol
        lg="2"
        md="3"
        sm="3"
        class="my-50"
      >
        <!-- BOTON MOSTRAR -->
        <btnMostrar
          :pageOptions.sync="pageOptions"
          :perPage.sync="perPage"
          :total.sync="items.length"
        />
      </BCol>
      <BCol
        lg="6"
        md="5"
        sm="5"
        class="my-50"
      >
        <!-- FILTRO -->
        <!-- <inputFiltro
          :filter.sync="filter"
        /> -->
      </BCol>
      <BCol
        md="4"
        sm="4"
        class="my-50"
      >
        <!-- CREAR -->
        <ApoyosEstudiantesCreate />

        <!-- BOTON CREAR -->
        <btnCrearModal
          accion="Registrar"
          texto="Apoyos"
          modal="modal-create"
          :modulo="nombre_permiso"
        />
      </BCol>

      <BCol cols="12" style="min-height: 490px !important;">
        <BTable
          striped
          small
          hover
          noCollapse
          bordered
          class="rounded"
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :busy="cargando"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >

          <!-- Cargando -->
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <spinner />
            </div>
          </template>

          <!-- Column: Descripcion -->
          <template #cell(descripcion)="data">
            {{ data.item.title }}
          </template>

          <!-- Column: Action -->
          <template #cell(acciones)="data">
             <ApoyosEstudiantesUpdate
              :modal="'modal-update-'+data.item.id"
              :data.sync="data"
            />

            <colAccionesBtnes
              v-if="data.item.id_persona_rol === user.id_persona_rol"
              :modulo="nombre_permiso"
              :modal="`modal-update-${data.item.id}`"
              :data="data"
              @processRemove="remove(data.item)"
            />
            <BAlert
              v-else
              class="mt-25 mb-25"
              variant="primary"
              show
            >
              Apoyo creado por otro usuario
            </BAlert>
          </template>
        </BTable>
        <BAlert
          v-if="!cargando && items.length === 0"
          variant="primary"
          show
          class="text-center pt-25 pb-25"
        >
          <div class="alert-body">
            <span>No existen apoyos registrados.</span>
          </div>
        </BAlert>
      </BCol>

      <BCol
        cols="12"
      >
        <BPagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </BCol>

    </BRow>
  </BOverlay>
</template>

<script>

// ETIQUETAS
import {
  BTable, BRow, BCol, BPagination, BOverlay, VBModal, BAlert,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import Ripple from 'vue-ripple-directive'

// COMPONENTES RECICLADOS
// import inputFiltro from '@/views/components/List/inputFiltro.vue'
import btnCrearModal from '@/views/components/List/btnCrearModal.vue'
import btnMostrar from '@/views/components/List/btnMostrar.vue'
import colAccionesBtnes from '@/views/components/List/colAccionesBtnes.vue'
import spinner from '@/views/components/spinner.vue'

// FORMATOS
import { formatos } from '@/mixins/formatos'

// COMPONENTES HIJOS
import ApoyosEstudiantesCreate from './ApoyosEstudiantes/ApoyosEstudiantesCreate.vue'
import ApoyosEstudiantesUpdate from './ApoyosEstudiantes/ApoyosEstudiantesUpdate.vue'

export default {
  components: {
    BTable, BRow, BCol, BPagination, BOverlay, VBModal, BAlert,

    // COMPONENTES RECICLADOS
    spinner,
    btnMostrar,
    btnCrearModal,
    colAccionesBtnes,

    // COMPONENTES HIJOS
    ApoyosEstudiantesCreate,
    ApoyosEstudiantesUpdate,
  },
  directives: {
    'BModal': VBModal,
    Ripple,
  },
  mixins: [formatos],
  data() {
    return {
      nombre_permiso: 'pieIII2',
      cargando: true,
      spinner: false,
      // chk
      items: [],
      selectedchk: [],
      chkTodo: null,
      checked: null,

      perPage: 25,
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: '',
      filterOn: [],
      pageOptions: [25, 50, 100],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'descripcion',
          label: 'Plan de apoyo',
          sortable: true,
          thStyle: {
            width: '200px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'observaciones',
          label: 'Objetivos de aprendizaje',
          sortable: true,
          thStyle: {
            width: '350px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'totalAcciones',
          label: 'Nº Apoyos',
          sortable: true,
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '60px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        // {
        //   key: 'totalFirmados',
        //   label: 'Nº Firmas',
        //   sortable: true,
        //   thStyle: {
        //     width: '60px !important',
        //     display: 'table-cell',
        //     'vertical-align': 'middle',
        //   },
        // },
      ],
      fieldAcciones: [
        {
          key: 'acciones',
          label: 'acciones',
          tdClass: 'text-center',
          thStyle: {
            width: '9px !important',
            'text-align': 'center',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      getRealizadas: 'III_1_b_acciones_realizadas/getRealizadas',
      getLibroSelected: 'libros/getLibroSelected',
      user: 'auth/user',
    }),
    // Vuexy
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    getRealizadas(val) {
      this.totalRows = val.length
      this.items = []
      this.items = this.getRealizadas
    },
    getLibroSelected(val) {
      this.cargarRealizadas(this.getLibroSelected.id)
    },
  },
  mounted() {
    this.cargarRealizadas(this.getLibroSelected.id)
    this.setTableList()
  },
  methods: {
    ...mapActions({
      fetchRealizadas: 'III_1_b_acciones_realizadas/fetchRealizadas',
      removeRealizada: 'III_1_b_acciones_realizadas/removeRealizada',
    }),
    setTableList() {
      if (this.$can('update', this.nombre_permiso)
        || this.$can('delete', this.nombre_permiso)
      ) {
        this.fields.push(this.fieldAcciones)
      }
    },
    cargarRealizadas(idCurso) {
      const data = {
        idCurso,
        tipo: 2, // Tipo Profesional de la salud
      }
      this.fetchRealizadas(data).then(() => {
        this.cargando = false
      })
    },
    remove(realizada) {
      const html = this.formatHTMLSweetEliminar('el registro de apoyo', realizada.observaciones)
      this.$swal({
        title: 'Eliminar registro de apoyos!',
        html,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, elimínalo!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        this.spinner = true
        if (result.value) {
          const data = {
            id: realizada.id,
            id_curso: this.getLibroSelected.id,
          }
          this.removeRealizada(data).then(() => {
            this.$swal({
              icon: 'success',
              title: 'Eliminado con éxito!',
              text: `El registro de apoyos ha sido eliminado!`,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })

            this.cargarRealizadas(this.getLibroSelected.id)
            this.spinner = false
          })
        } else {
          this.spinner = false
        }
      })
    },

    // Vuexy
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of
      // buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>

<style lang="css">
  .text-center {
    text-align: center;
  }
</style>
