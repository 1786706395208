<template>
  <BTabs
    fill
    vertical
    class="mt-2"
    nav-wrapper-class="nav-vertical"
    active-tab-class="font-weight-bold text-primary"
  >
    <div
      v-for="(menu, key) in submenus"
      :key="key"
      v-if="menu.can"
    >
      <BTab
        :active="menu.active"
        lazy
        :disabled="menu.disabled"
        :title="menu.index.toUpperCase()+`. `+menu.abreviatura"
      >
        <!-- ENCABEZADO -->
        <div class="meetup-header d-flex align-items-center">
          <div class="meetup-day">

            <!-- INDEX -->
            <h4 class="mb-0">
              {{ menu.index.toUpperCase() }}
            </h4>
          </div>
          <div class="ml-1">

            <!-- TÍTULO -->
            <BCardTitle
              v-if="menu.titulo !== ''"
              class="mb-25 h4"
              style="margin-top: 4px;"
            >
              {{ menu.titulo }}
              <!-- INFORMATIVO -->
              <popover
                v-if="menu.informacion !== ''"
                class="float-right"
                id="0"
                btnVariant="flat-secondary"
                :texto="menu.informacion"
                direccion="right"
                style="margin-left: -6px; margin-top: -15px; margin-bottom: -10px;"
              />
            </BCardTitle>

            <!-- TEXTO -->
            <BCardText
              v-if="menu.texto !== ''"
              class="mr-4 mb-50 text-secondary"
            >
              {{ menu.texto }}
            </BCardText>
          </div>
        </div>

        <diversidad
          v-if="menu.index === 'a'"
          btnSubmit="Actualizar Estrategias"
        />

        <adecuacion
          v-if="menu.index === 'b'"
          btnSubmit="Actualizar Estrategias"
        />
        <evaluacion v-if="menu.index === 'c'" />
      </BTab>
    </div>
  </BTabs>
</template>

<script>
import popover from '@/views/components/Form/popover.vue'
import {
  BTabs, BTab, BCardText, BCard, BCardTitle,
} from 'bootstrap-vue'

import diversidad from './DiversidadAdecuacion/Diversidad.vue'
import adecuacion from './DiversidadAdecuacion/Adecuacion.vue'
import evaluacion from './DiversidadAdecuacion/Evaluacion.vue'

export default {
  components: {
    popover,
    BCardText,
    BTabs,
    BTab,
    BCard,
    BCardTitle,

    // componentes
    diversidad,
    adecuacion,
    evaluacion,
  },
  props: {
    submenus: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
    }
  },
  methods: {
  },
}
</script>
