<template>
  <EstrategiasForm
    :nombreModal="modal"
    title="Editar estrategia diversificada que aplicará el/la profesor/a de educación regular para el curso"
    :data="item"
    @processForm="editar"
  />
</template>

<script>

import { mapActions } from 'vuex'
import { toast, swal } from '@/mixins/alerts'
import EstrategiasForm from './EstrategiasForm.vue'

export default {
  components: {
    EstrategiasForm,
  },
  mixins: [toast, swal],
  props: {
    modal: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions({
      updateEstrategia: 'II_1_b_estrategias/updateEstrategia',
      fetchEstrategias: 'II_1_b_estrategias/fetchEstrategias',
    }),
    async editar(estrategia) {
      const res = await this.updateEstrategia(estrategia)
      if (res.status === 'success') {
        await this.fetchEstrategias(estrategia.id_curso)
        
        this.$toastSuccess(
          'Estrategia actualizada 👍', 
          'La estrategia fue editada con éxito!'
        )
        this.$bvModal.hide(this.modal)
      } else {
        this.$swalError('Error al editar la estrategia!', res.message)
      }
    },
  },
}
</script>
