<template>
  <BModal
    :id="nombreModal"
    :title="title"
    centered
    size="lg"
    :hide-footer="true"
    @shown="onModalOpen"
  >
    <div class="d-flex justify-content-between mb-1">
      <div class="mt-50 text-secondary">
      </div>
      <div class="mr-1 mt-50 text-muted small">
        Campos obligatorios <span class="text-danger">*</span>
      </div>
    </div>
    <BOverlay
      :show="configs.cargando"
      spinner-variant="primary"
      variant="semi-dark"
    >
      <BRow>
        <BCol id="col-PROFESOR-ESPECIALIZADO" cols="12" md="12">
          <BFormGroup label-for="id_persona_rol">
            <template #label>
              Docente PIE <span class="text-danger">*</span>
            </template>
            <v-select
              v-if="configs.loadingProfesorEspecializado"
              
              placeholder="Cargando Docente PIE..."
              :disabled="true"
            />
            <v-select
              v-else
              v-model="equipo.id_persona_rol"
              placeholder="Seleccione un Docente PIE ..."
              
              label="nombre"
              :options="docentesPieOption"
              :reduce="option => option.id"
              input-id="id"
              @input="changePersonaRol()"
              :class="v$.equipo.id_persona_rol.$error === true
                  ? 'border-danger rounded'
                  : ''"
            />
            <div v-if="v$.equipo.id_persona_rol.$error"
              id="asistentesInfo"
              class="text-danger text-right"
              style="font-size: 0.857rem;"
            >
              <p v-for="error of v$.equipo.id_persona_rol.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
            </div>
          </BFormGroup>

        </BCol>

        <BCol id="col-telefono" cols="12" md="12">
          <BFormGroup label-for="telefono">
            <template #label>
              Teléfono <span class="text-danger">*</span>
            </template>
            <BInputGroup size="md" prepend="+"
              :class="v$.equipo.telefono.$error === true
                ? 'border-danger rounded'
                : ''"
            >
              <BFormInput
                id="telefono"
                v-model="equipo.telefono"
                placeholder="Ingrese un teléfono"
                @keyup='equipo.telefono = formatSoloNumerosMaxLenght(equipo.telefono, 11)'
              />
            </BInputGroup>
            <div v-if="v$.equipo.telefono.$error"
              id="telefonoInfo"
              class="text-danger text-right"
              style="font-size: 0.857rem;"
            >
              <p v-for="error of v$.equipo.telefono.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
            </div>
          </BFormGroup>
        </BCol>

        <BCol id="col-correo" cols="12" md="12">
          <BFormGroup label-for="correo">
            <template #label>
              Correo electrónico <span class="text-danger">*</span>
            </template>
            <BFormInput
              id="correo"
              v-model="equipo.correo"
              placeholder="Ingrese un correo"
              :state="v$.equipo.correo.$error === true
                ? false
                : null"
            />
            <BFormInvalidFeedback v-if="v$.equipo.correo.$error"
              id="correoInfo"
              class="text-right"
            >
              <p v-for="error of v$.equipo.correo.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
            </BFormInvalidFeedback>
          </BFormGroup>
        </BCol>

        <BCol id="col-especialidad" cols="12" md="12">
          <BFormGroup label-for="especialidad" >
            <template #label>
              Especialidad <span class="text-danger">*</span>
            </template>
            <BFormInput
              id="especialidad"
              v-model="equipo.especialidad"
              placeholder="Ingrese una especialidad"
              :state="v$.equipo.especialidad.$error === true
                ? false
                : null"
            />
            <BFormInvalidFeedback v-if="v$.equipo.especialidad.$error"
              id="especialidadInfo"
              class="text-right"
            >
              <p v-for="error of v$.equipo.especialidad.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
            </BFormInvalidFeedback>
          </BFormGroup>
        </BCol>

      </BRow>

      <colLinea />

      <div class="text-right">
        <BButton variant="outline-secondary" class="mr-1" @click="closeModal">
          Cancelar
        </BButton>

        <BButton
          variant="primary"
          class="mr-25"
          :disabled="this.v$.equipo.$errors.length > 0"
          @click="submitOption"
        >
          Guardar Docente PIE
        </BButton>
      </div>

    </BOverlay>
  </BModal>
</template>

<script>

// ETIQUETAS
import {
  BRow, BCol, BFormGroup, BFormInput, BFormInvalidFeedback, BMedia, BButton, 
  BAvatar, BOverlay, BFormDatepicker, BFormTimepicker, BFormTextarea, BModal, 
  VBModal, BInputGroup, 
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import { mapGetters, mapActions } from 'vuex'

// VALIDACIONES
import useVuelidate from '@vuelidate/core'
import { required, maxLength, helpers, email } from '@vuelidate/validators'

// COMPONENTES
import colLinea from '@/views/components/Form/colLinea.vue'
import equipo from '@/store/pie/I equipo/1 equipo aula/getters'
import { formatos } from '@/mixins/formatos'

export default {
  components: {
    BRow, BCol, BFormGroup, BFormInput, BFormInvalidFeedback, BMedia, BButton, 
    BAvatar, BOverlay, BFormDatepicker, BFormTimepicker, BFormTextarea, BModal, 
    VBModal, BInputGroup,
    vSelect,
    
    colLinea,
  },
  mixins: [formatos],
  data() {
    return {
      configs: {
        cargando: false,
        loadingProfesorEspecializado: true,
      },
      equipo: {},
      docentesPieOption: [],
    }
  },
  computed: {
    ...mapGetters({
      getUser: 'auth/user',
      getLibroSelected: 'libros/getLibroSelected',
    }),
  },
  watch: {
    getLibroSelected(val) {
      this.cargarDocentesPie()
    },
  },
  props: {
    nombreModal: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions({
      fetchPersonasRol: 'personas/fetchPersonasRol',
    }),
    async onModalOpen () {
      this.initData();
      await Promise.all([
        this.cargarDocentesPie()
      ])
    },
    initData() {
      this.equipo = { ...this.data }
    },
    async cargarDocentesPie() {
      const params = {
        id_establecimiento: this.getUser.id_establecimiento,
        id_rol: 6 // docente pie
      }
      const { data } = await this.fetchPersonasRol(params)
      this.docentesPieOption = []
      this.docentesPieOption = data.map(item => ({
        ...item,
        id: item.id_persona_rol,
        nombre: `${item.nombre} ${item.segundo_nombre} ${item.primer_apellido} ${item.segundo_apellido}`,
      }));
      this.configs.loadingProfesorEspecializado = false
    },
    changePersonaRol() {
      this.equipo.nombre = '';
      this.equipo.telefono = '';
      this.equipo.correo = '';
      if (this.equipo.id_persona_rol) {
        const personaRol = this.docentesPieOption.find(dp => dp.id_persona_rol === this.equipo.id_persona_rol);
        this.equipo.nombre = personaRol.nombre;
        this.equipo.telefono = personaRol.celular || personaRol.telefono || '';
        this.equipo.correo = personaRol.correo;
      }
    }, 
    submitOption() {
      this.v$.equipo.$touch()
      if (!this.v$.equipo.$invalid) {
        const params = {
          ...this.equipo,
          id_curso: this.getLibroSelected.id
        }
        this.$emit('processForm', params)
        this.v$.equipo.$reset()
      }
    },
    closeModal() {
      this.$bvModal.hide(this.nombreModal);
      this.v$.equipo.$reset();
      if (this.equipo.id === null) {
        this.$emit('resetData');
      }
      this.initData();
    },
  },
  validations() {
    return {
      equipo: {
        id_persona_rol: {
          required: helpers.withMessage('El campo es requerido.', required),
        },
        especialidad: {
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener un máximo de 255 caracteres.', maxLength(255)),
        },
        telefono: {
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener un máximo de 20 caracteres.', maxLength(20)),
        },
        correo: {
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener un máximo de 255 caracteres.', maxLength(255)),
          email: helpers.withMessage('Debe ser un correo valido.', email),
        },
      }
    }
  },
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
}
</script>