<template>
  <BOverlay :show="cargando" spinner-variant="primary" variant="semi-dark" >
    <BRow>

      <BCol id="col-APOYOS-PROFESORES" md="12">
        <BFormGroup
          label="Entre profesores/as (educación especial y profesor/a regular) <Co-enseñanza>"
          label-for="profesores"
        >
          <BFormTextarea
            id="profesores"
            :placeholder="$can('update', nombre_permiso)
              ? 'Ingrese las estrategias y/o acciones entre profesores'
              : 'Sin información'"
            v-model="acciones.profesores"
            rows="4"
            maxlength="550"
            :plaintext="!$can('update', nombre_permiso)"
            :state="v$.acciones.profesores.$error === true
              ? false
              : null"
            @blur.native="v$.acciones.profesores.$touch"
          />
          <small class="text-muted text-right d-block mt-25">{{ acciones.profesores ? acciones.profesores.length : 0 }} / 550</small>
          <BFormInvalidFeedback v-if="v$.acciones.profesores.$error"
            id="profesoresInfo"
            class="text-right"
          >
            <p v-for="error of v$.acciones.profesores.$errors" :key="error.$uid">
              {{ error.$message }}
            </p>
          </BFormInvalidFeedback>
        </BFormGroup>
      </BCol>

      <BCol id="col-APOYOS-PROFESORES-ASISTENTES" md="12">
        <BFormGroup
          label="Entre profesores y asistentes de la educación (psicólogos, fonoaudiólogos, auxiliares, intérpretes, etc.)"
          label-for="asistentes"
        >
          <BFormTextarea
            id="asistentes"
            :placeholder="$can('update', nombre_permiso)
              ? 'Ingrese las estrategias y/o acciones entre profesores y asistentes de la educación'
              : 'Sin información'"
            v-model="acciones.asistentes"
            rows="4"
            maxlength="550"
            :plaintext="!$can('update', nombre_permiso)"
            :state="v$.acciones.asistentes.$error === true
              ? false
              : null"
            @blur.native="v$.acciones.asistentes.$touch"
          />
          <small class="text-muted text-right d-block mt-25">{{ acciones.asistentes ? acciones.asistentes.length : 0 }} / 550</small>
          <BFormInvalidFeedback v-if="v$.acciones.asistentes.$error"
            id="asistentesInfo"
            class="text-right"
          >
            <p v-for="error of v$.acciones.asistentes.$errors" :key="error.$uid">
              {{ error.$message }}
            </p>
          </BFormInvalidFeedback>
        </BFormGroup>
      </BCol>

      <BCol id="col-APOYOS-ESTUDIANTES" md="12">
        <BFormGroup
          label="Entre estudiantes (Ej: Aprendizaje Colaborativo)"
          label-for="estudiantes"
        >
          <BFormTextarea
            id="estudiantes"
            :placeholder="$can('update', nombre_permiso)
              ? 'Ingrese las estrategias y/o acciones entre estudiantes'
              : 'Sin información'"
            v-model="acciones.estudiantes"
            rows="4"
            maxlength="550"
            :plaintext="!$can('update', nombre_permiso)"
            :state="v$.acciones.estudiantes.$error === true
              ? false
              : null"
            @blur.native="v$.acciones.estudiantes.$touch"
          />
          <small class="text-muted text-right d-block mt-25">{{ acciones.estudiantes ? acciones.estudiantes.length : 0 }} / 550</small>
          <BFormInvalidFeedback v-if="v$.acciones.estudiantes.$error"
            id="estudiantesInfo"
            class="text-right"
          >
            <p v-for="error of v$.acciones.estudiantes.$errors" :key="error.$uid">
              {{ error.$message }}
            </p>
          </BFormInvalidFeedback>
        </BFormGroup>
      </BCol>

      <BCol id="col-APOYOS-FAMILIA" md="12">
        <BFormGroup
          label="Con la familia y entre familias"
          label-for="familia"
        >
          <BFormTextarea
            id="familia"
            :placeholder="$can('update', nombre_permiso)
              ? 'Ingrese las estrategias y/o acciones con la familia y entre familia'
              : 'Sin información'"
            v-model="acciones.familia"
            rows="4"
            maxlength="550"
            :plaintext="!$can('update', nombre_permiso)"
            :state="v$.acciones.familia.$error === true
              ? false
              : null"
            @blur.native="v$.acciones.familia.$touch"
          />
          <small class="text-muted text-right d-block mt-25">{{ acciones.familia ? acciones.familia.length : 0 }} / 550</small>
          <BFormInvalidFeedback v-if="v$.acciones.familia.$error"
            id="familiaInfo"
            class="text-right"
          >
            <p v-for="error of v$.acciones.familia.$errors" :key="error.$uid">
              {{ error.$message }}
            </p>
          </BFormInvalidFeedback>
        </BFormGroup>
      </BCol>

      <BCol id="col-APOYOS-COMUNIDAD" md="12">
        <BFormGroup
          label="Con la comunidad (con otras Escuelas, Centros Culturales, Servicios, etc)"
          label-for="comunidad"
        >
          <BFormTextarea
            id="comunidad"
            :placeholder="$can('update', nombre_permiso)
              ? 'Ingrese las estrategias y/o acciones con la comunidad'
              : 'Sin información'"
            v-model="acciones.comunidad"
            rows="4"
            maxlength="550"
            :plaintext="!$can('update', nombre_permiso)"
            :state="v$.acciones.comunidad.$error === true
              ? false
              : null"
            @blur.native="v$.acciones.comunidad.$touch"
          />
          <small class="text-muted text-right d-block mt-25">{{ acciones.comunidad ? acciones.comunidad.length : 0 }} / 550</small>
          <BFormInvalidFeedback v-if="v$.acciones.comunidad.$error"
            id="comunidadInfo"
            class="text-right"
          >
            <p v-for="error of v$.acciones.comunidad.$errors" :key="error.$uid">
              {{ error.$message }}
            </p>
          </BFormInvalidFeedback>
        </BFormGroup>
      </BCol>

      <BCol id="col-OBSERVACIONES" md="12">
        <BFormGroup
          label="Observaciones"
          label-for="observaciones"
        >
          <BFormTextarea
            id="observaciones"
            :placeholder="$can('update', nombre_permiso)
              ? 'Ingrese las observaciones'
              : 'Sin información'"
            v-model="acciones.observaciones"
            rows="4"
            maxlength="550"
            :plaintext="!$can('update', nombre_permiso)"
            :state="v$.acciones.observaciones.$error === true
              ? false
              : null"
            @blur.native="v$.acciones.observaciones.$touch"
          />
          <small class="text-muted text-right d-block mt-25">{{ acciones.observaciones ? acciones.observaciones.length : 0 }} / 550</small>
          <BFormInvalidFeedback v-if="v$.acciones.observaciones.$error"
            id="observacionesInfo"
            class="text-right"
          >
            <p v-for="error of v$.acciones.observaciones.$errors" :key="error.$uid">
              {{ error.$message }}
            </p>
          </BFormInvalidFeedback>
        </BFormGroup>
      </BCol>
    </BRow>

    <colLinea />

    <div id="Action-buttons" class="text-right" style="height: 69px !important;">
      <btnSubmit
        v-if="!cargando"
        class="float-right"
        variant="primary"
        :modulo="nombre_permiso"
        :disabled="this.v$.acciones.$errors.length > 0"
        btnText="Actualizar Acciones"
        @processBtn="submitOption"
      />
    </div>
  </BOverlay>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BButton, BOverlay, BCardText,
  BFormTextarea, BFormInvalidFeedback
} from 'bootstrap-vue'

import { mapGetters, mapActions } from 'vuex'

// VALIDACIONES
import useVuelidate from '@vuelidate/core'
import { maxLength, helpers } from '@vuelidate/validators'

// COMPONENTES
import colLinea from '@/views/components/Form/colLinea.vue'
import btnSubmit from '@/views/components/Form/btnSubmit.vue'

// FORMATOS
import { formatos } from '@/mixins/formatos'
import { toast } from '@/mixins/alerts'

export default {
  components: {
    BForm, BFormGroup, BFormInput, BRow, BCol, BButton, BOverlay, BCardText,
    BFormTextarea, BFormInvalidFeedback,

    // COMPONENTES
    colLinea,
    btnSubmit,
  },
  mixins: [formatos, toast],
  data() {
    return {
      nombre_permiso: 'pieII2A',
      cargando: false,
      acciones: {
        profesores: '',
        asistentes: '',
        estudiantes: '',
        familia: '',
        comunidad: '',
        observaciones: '',
      },
    }
  },
  computed: {
    ...mapGetters({ getLibroSelected: 'libros/getLibroSelected' }),
  },
  watch: {
    getLibroSelected(getLibroSelected) {
      this.cargarAcciones(getLibroSelected.id)
    }
  },
  mounted() {
    this.cargarAcciones()
  },
  methods: {
    ...mapActions({
      fetchAcciones: 'II_2_a_acciones/fetchAcciones',
      addAccion: 'II_2_a_acciones/addAccion',
      updateAccion: 'II_2_a_acciones/updateAccion',
    }),

    async cargarAcciones() {
      this.cargando = true
      const res = await this.fetchAcciones(this.getLibroSelected.id)
      
      if (res.status === 'success') {
        this.acciones = res.data
      } else {
        this.$toastError('Error al cargar los datos !', res.message)
      }
      this.cargando = false
    },
    async actualizarAccion() {
      this.spinner = true

      const params = { ...this.acciones, idCurso: this.getLibroSelected.id }      
      const res = this.acciones.id === null
        ? await this.addAccion(params)
        : await this.updateAccion(params)

      if (res.status === 'success') {
        this.$toastSuccess(
          'Acciones actualizadas 👍', 
          'Las acciones fueron actualizadas con éxito!'
        )
        this.cargarAcciones()
      } else {
        this.$toastError('Error al actualizar los datos !', res.message)
      }
      this.spinner = false
    },

    submitOption() {
      this.v$.acciones.$touch()
      if (!this.v$.acciones.$invalid) {
        const html = this.formatHTMLSweetInfo('las acciones que se implementarán', '')
        this.$swal({
          title: 'Guardar cambios!',
          html,
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: 'Sí, guardar',
          cancelButtonText: 'Cancelar',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-secondary ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.actualizarAccion()
          }
          return false
        })
      }
    },

  },
  validations() {
    return {
      acciones: {
        profesores: {
          maxLength: helpers.withMessage('Debe tener un máximo de 550 caracteres.'
            , maxLength(550)),
        },
        asistentes: {
          maxLength: helpers.withMessage('Debe tener un máximo de 550 caracteres.'
            , maxLength(550)),
        },
        estudiantes: {
          maxLength: helpers.withMessage('Debe tener un máximo de 550 caracteres.'
            , maxLength(550)),
        },
        familia: {
          maxLength: helpers.withMessage('Debe tener un máximo de 550 caracteres.'
            , maxLength(550)),
        },
        comunidad: {
          maxLength: helpers.withMessage('Debe tener un máximo de 550 caracteres.'
            , maxLength(550)),
        },
        observaciones: {
          maxLength: helpers.withMessage('Debe tener un máximo de 550 caracteres.'
            , maxLength(550)),
        },
      }
    }
  },
  setup() {
    return { v$: useVuelidate() }
  },
}
</script>
