var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BModal',{attrs:{"id":_vm.nombreModal,"title":_vm.title,"centered":"","size":"lg","hide-footer":true},on:{"shown":_vm.onModalOpen}},[_c('div',{staticClass:"d-flex justify-content-between mb-1"},[_c('div',{staticClass:"mt-50 text-secondary"}),_c('div',{staticClass:"mr-1 mt-50 text-muted small"},[_vm._v(" Campos obligatorios "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('BOverlay',{attrs:{"show":_vm.configs.cargando,"spinner-variant":"primary","variant":"semi-dark"}},[_c('BRow',[_c('BCol',{attrs:{"id":"col-DESCRIPCION","cols":"12"}},[_c('BFormGroup',{attrs:{"label-for":"descripcion"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Descripción "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('BFormInput',{attrs:{"id":"descripcion","placeholder":"Ingrese la descripción del plan de apoyo individual","rows":"2","state":_vm.v$.planApoyo.descripcion.$error === true
              ? false
              : null},model:{value:(_vm.planApoyo.descripcion),callback:function ($$v) {_vm.$set(_vm.planApoyo, "descripcion", $$v)},expression:"planApoyo.descripcion"}}),(_vm.v$.planApoyo.descripcion.$error)?_c('BFormInvalidFeedback',{staticClass:"text-right",attrs:{"id":"observacionesInfo"}},_vm._l((_vm.v$.planApoyo.descripcion.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('BCol',{attrs:{"id":"col-ALUMNO","cols":"12","md":"6"}},[_c('BFormGroup',{attrs:{"label-for":"alumno"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Estudiante "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[(_vm.configs.loadingEstudiantes)?_c('v-select',{attrs:{"placeholder":"Cargando estudiantes...","disabled":true}}):_c('v-select',{class:_vm.v$.planApoyo.alumno.$error === true
                ? 'border-danger rounded'
                : '',attrs:{"placeholder":"Seleccione un estudiante...","label":"title","options":_vm.alumnosOptions,"reduce":function (option) { return option.id; },"input-id":"id"},model:{value:(_vm.planApoyo.alumno),callback:function ($$v) {_vm.$set(_vm.planApoyo, "alumno", $$v)},expression:"planApoyo.alumno"}}),(!_vm.configs.loadingEstudiantes && _vm.alumnosOptions.length === 0)?_c('BAlert',{staticClass:"pl-50 pt-25 pb-25 pr-50 mt-50 mb-25",attrs:{"variant":"primary","show":""}},[_vm._v(" Para incluir un estudiante en el Programa de Integración Escolar (PIE), es necesario indicarlo en el módulo matrícula. ")]):_vm._e(),(_vm.v$.planApoyo.alumno.$error)?_c('div',{staticClass:"text-danger text-right",staticStyle:{"font-size":"0.857rem"},attrs:{"id":"alumnoInfo"}},_vm._l((_vm.v$.planApoyo.alumno.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('BCol',{attrs:{"id":"col-ESPECIALISTA","cols":"12","md":"6"}},[_c('BFormGroup',{attrs:{"label-for":"apoyoEspecializado"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Apoyo(s) especializado(s) requerido(s) "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[(_vm.configs.loadingApoyosEspecializados)?_c('v-select',{attrs:{"placeholder":"Cargando apoyos especializados...","disabled":true}}):_c('v-select',{class:_vm.v$.planApoyo.apoyoEspecializado.$error === true
                ? 'border-danger rounded'
                : '',attrs:{"placeholder":"Seleccione los apoyos especializados...","multiple":"","label":"nombre","options":_vm.especialistasOptions,"reduce":function (option) { return option.value; },"input-id":"value","closeOnSelect":false},model:{value:(_vm.planApoyo.apoyoEspecializado),callback:function ($$v) {_vm.$set(_vm.planApoyo, "apoyoEspecializado", $$v)},expression:"planApoyo.apoyoEspecializado"}}),(_vm.v$.planApoyo.apoyoEspecializado.$error)?_c('div',{staticClass:"text-danger text-right",staticStyle:{"font-size":"0.857rem"},attrs:{"id":"alumnoInfo"}},_vm._l((_vm.v$.planApoyo.apoyoEspecializado.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('BCol',{attrs:{"id":"col-FECHA-INICIO","cols":"12","md":"6"}},[_c('BFormGroup',{attrs:{"label-for":"fecha_inicio"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Fecha inicio "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('BFormDatepicker',{attrs:{"id":"fecha_inicio","placeholder":"Abrir calendario","hide-header":"","date-format-options":{
              year: 'numeric',
              month: 'short',
              day: '2-digit',
              weekday: 'short'
            },"start-weekday":"1","locale":"es-CL","date-disabled-fn":_vm.dateDisabled,"label-help":"","state":_vm.v$.planApoyo.fecha_inicio.$error === true
              ? false
              : null},model:{value:(_vm.planApoyo.fecha_inicio),callback:function ($$v) {_vm.$set(_vm.planApoyo, "fecha_inicio", $$v)},expression:"planApoyo.fecha_inicio"}}),(_vm.v$.planApoyo.fecha_inicio.$error)?_c('BFormInvalidFeedback',{staticClass:"text-right",attrs:{"id":"fecha_inicioInfo"}},_vm._l((_vm.v$.planApoyo.fecha_inicio.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('BCol',{attrs:{"id":"col-FECHA-TERMINO","cols":"12","md":"6"}},[_c('BFormGroup',{attrs:{"label-for":"fecha_termino"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Fecha termino "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('BFormDatepicker',{attrs:{"id":"fecha_termino","placeholder":"Abrir calendario","hide-header":"","date-format-options":{
              year: 'numeric',
              month: 'short',
              day: '2-digit',
              weekday: 'short'
            },"start-weekday":"1","locale":"es-CL","date-disabled-fn":_vm.dateDisabled,"label-help":"","state":_vm.v$.planApoyo.fecha_termino.$error === true
              ? false
              : null},model:{value:(_vm.planApoyo.fecha_termino),callback:function ($$v) {_vm.$set(_vm.planApoyo, "fecha_termino", $$v)},expression:"planApoyo.fecha_termino"}}),(_vm.v$.planApoyo.fecha_termino.$error)?_c('BFormInvalidFeedback',{staticClass:"text-right",attrs:{"id":"fecha_terminoInfo"}},_vm._l((_vm.v$.planApoyo.fecha_termino.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('BCol',{attrs:{"id":"col-OBSERVACIONES","cols":"12"}},[_c('BFormGroup',{attrs:{"label-for":"observaciones"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Observaciones "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('BFormTextarea',{attrs:{"id":"observaciones","placeholder":"Ingrese las observaciones","rows":"6","maxlength":"900","state":_vm.v$.planApoyo.observaciones.$error === true
              ? false
              : null},model:{value:(_vm.planApoyo.observaciones),callback:function ($$v) {_vm.$set(_vm.planApoyo, "observaciones", $$v)},expression:"planApoyo.observaciones"}}),_c('small',{staticClass:"text-muted text-right d-block mt-25"},[_vm._v(_vm._s(_vm.planApoyo.observaciones ? _vm.planApoyo.observaciones.length : 0)+" / 900")]),(_vm.v$.planApoyo.observaciones.$error)?_c('BFormInvalidFeedback',{staticClass:"text-right",attrs:{"id":"observacionesInfo"}},_vm._l((_vm.v$.planApoyo.observaciones.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1)],1),_c('colLinea'),_c('div',{staticClass:"text-right"},[_c('BButton',{staticClass:"mr-1",attrs:{"variant":"outline-secondary"},on:{"click":_vm.closeModal}},[_vm._v(" Cancelar ")]),_c('BButton',{staticClass:"mr-25",attrs:{"variant":"primary","disabled":this.v$.planApoyo.$errors.length > 0},on:{"click":_vm.submitOption}},[_vm._v(" Guardar Plan de Apoyo ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }