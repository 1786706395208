var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BOverlay',{attrs:{"show":_vm.cargando,"spinner-variant":"primary","variant":"semi-dark"}},[_c('BRow',[_c('BCol',{attrs:{"id":"col-APRENDIZAJES","md":"12"}},[_c('BFormGroup',{attrs:{"label":"Estilos y modos de aprendizaje del curso","label-for":"aprendizajes"}},[_c('BFormTextarea',{attrs:{"id":"aprendizajes","placeholder":_vm.$can('update', _vm.nombre_permiso)
            ? 'Ingrese los estilos y modos de aprendizaje'
            : 'Sin información',"rows":"4","maxlength":"550","plaintext":!_vm.$can('update', _vm.nombre_permiso),"state":_vm.v$.panorama.estilo.$error === true
            ? false
            : null},nativeOn:{"blur":function($event){return _vm.v$.panorama.estilo.$touch($event)}},model:{value:(_vm.panorama.estilo),callback:function ($$v) {_vm.$set(_vm.panorama, "estilo", $$v)},expression:"panorama.estilo"}}),_c('small',{staticClass:"text-muted text-right d-block mt-25"},[_vm._v(_vm._s(_vm.panorama.estilo.length)+" / 550")]),(_vm.v$.panorama.estilo.$error)?_c('BFormInvalidFeedback',{staticClass:"text-right",attrs:{"id":"estiloInfo"}},_vm._l((_vm.v$.panorama.estilo.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('BCol',{attrs:{"id":"col-FORTALEZAS","md":"12"}},[_c('BFormGroup',{attrs:{"label":"Fortalezas del curso","label-for":"fortalezas"}},[_c('BFormTextarea',{attrs:{"id":"fortalezas","placeholder":_vm.$can('update', _vm.nombre_permiso)
            ? 'Ingrese las fortalezas'
            : 'Sin información',"rows":"4","maxlength":"550","plaintext":!_vm.$can('update', _vm.nombre_permiso),"state":_vm.v$.panorama.fortaleza.$error === true
            ? false
            : null},nativeOn:{"blur":function($event){return _vm.v$.panorama.fortaleza.$touch($event)}},model:{value:(_vm.panorama.fortaleza),callback:function ($$v) {_vm.$set(_vm.panorama, "fortaleza", $$v)},expression:"panorama.fortaleza"}}),_c('small',{staticClass:"text-muted text-right d-block mt-25"},[_vm._v(_vm._s(_vm.panorama.fortaleza.length)+" / 550")]),(_vm.v$.panorama.fortaleza.$error)?_c('BFormInvalidFeedback',{staticClass:"text-right",attrs:{"id":"fortalezaInfo"}},_vm._l((_vm.v$.panorama.fortaleza.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('BCol',{attrs:{"id":"col-NECESIDADES","md":"12"}},[_c('BFormGroup',{attrs:{"label":"Necesidades de apoyo del curso","label-for":"necesidades"}},[_c('BFormTextarea',{attrs:{"id":"necesidades","placeholder":_vm.$can('update', _vm.nombre_permiso)
            ? 'Ingrese las necesidades'
            : 'Sin información',"rows":"4","maxlength":"550","plaintext":!_vm.$can('update', _vm.nombre_permiso),"state":_vm.v$.panorama.necesidad.$error === true
            ? false
            : null},nativeOn:{"blur":function($event){return _vm.v$.panorama.necesidad.$touch($event)}},model:{value:(_vm.panorama.necesidad),callback:function ($$v) {_vm.$set(_vm.panorama, "necesidad", $$v)},expression:"panorama.necesidad"}}),_c('small',{staticClass:"text-muted text-right d-block mt-25"},[_vm._v(_vm._s(_vm.panorama.necesidad.length)+" / 550")]),(_vm.v$.panorama.necesidad.$error)?_c('BFormInvalidFeedback',{staticClass:"text-right",attrs:{"id":"necesidadInfo"}},_vm._l((_vm.v$.panorama.necesidad.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1)],1),_c('colLinea'),_c('div',{staticClass:"text-right",staticStyle:{"height":"69px !important"},attrs:{"id":"Action-buttons"}},[_c('btnSubmit',{staticClass:"float-right",attrs:{"variant":"primary","modulo":_vm.nombre_permiso,"disabled":this.v$.panorama.$errors.length > 0,"btnText":"Actualizar Panorama"},on:{"processBtn":_vm.submitOption}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }