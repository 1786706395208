<template>
  <BModal
    :id="nombreModal"
    :title="title"
    centered
    size="lg"
    :hide-footer="true"
    @shown="onModalOpen"
  >
    <div class="d-flex justify-content-between mb-1">
      <div class="mt-50 text-secondary">
      </div>
      <div class="mr-1 mt-50 text-muted small">
        Campos obligatorios <span class="text-danger">*</span>
      </div>
    </div>
    <BOverlay
      :show="configs.cargando"
      spinner-variant="primary"
      variant="semi-dark"
    >
      <BRow>
        
        <BCol id="col-ASIGNATURA" cols="12" md="12">
          <BFormGroup label-for="id_asignatura">
            <template #label>
              Ámbito o asignatura donde se aplicará <span class="text-danger">*</span>
            </template>
            <v-select
              v-if="configs.loadingAsignaturas"
              placeholder="Cargando asignaturas..."
              :disabled="true"
            />
            <v-select
              v-else
              v-model="estrategia.id_asignatura"
              placeholder="Seleccione una asignatura..."
              :options="asignaturasOption"
              :reduce="option => option.id"
              label="nombre"
              input-id="id"
              :class="v$.estrategia.id_asignatura.$error === true
                ? 'border-danger rounded'
                : ''"
            />
            <div v-if="v$.estrategia.id_asignatura.$error"
              id="id_asignaturaInfo"
              class="text-danger text-right"
              style="font-size: 0.857rem;"
            >
              <p v-for="error of v$.estrategia.id_asignatura.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
            </div>
          </BFormGroup>

        </BCol>

        <BCol id="coL-PERIODO" cols="12" md="12">
          <BFormGroup label-for="periodo">
            <template #label>
              Período de tiempo en que se aplicará <span class="text-danger">*</span>
            </template>
            <v-select
              v-if="configs.loadingPeriodos"
              placeholder="Cargando periodos..."
              :disabled="true"
            />
            <v-select
              v-else
              v-model="estrategia.id_periodo"
              placeholder="Seleccione un periodo..."
              label="nombre"
              :options="periodosOption"
              :reduce="option => option.id"
              input-id="id_periodo"
              :class="v$.estrategia.id_periodo.$error === true
                ? 'border-danger rounded'
                : ''"
            />
            <div v-if="v$.estrategia.id_periodo.$error"
              id="id_periodoInfo"
              class="text-danger text-right"
              style="font-size: 0.857rem;"
            >
              <p v-for="error of v$.estrategia.id_periodo.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
            </div>
          </BFormGroup>
        </BCol>

        <BCol id="col-ESTRATEGIA" cols="12" md="12">
          <BFormGroup label-for="estrategia">
            <template #label>
              Estrategia <span class="text-danger">*</span>
            </template>
            <BFormTextarea
              id="estrategia"
              placeholder="Ingrese la estrategia"
              v-model="estrategia.estrategia"
              rows="2"
              maxlength="200"
              :state="v$.estrategia.estrategia.$error === true
                ? false
                : null"
            />
            <small class="text-muted text-right d-block mt-25">{{ estrategia.estrategia ? estrategia.estrategia.length : 0 }} / 200</small>
            <BFormInvalidFeedback v-if="v$.estrategia.estrategia.$error"
              id="estrategiaInfo"
              class="text-right"
            >
              <p v-for="error of v$.estrategia.estrategia.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
            </BFormInvalidFeedback>
          </BFormGroup>
        </BCol>

        <BCol id="col-CRITERIOS" cols="12" md="12">
          
          <!-- INFORMATIVO -->
          <popover
            class="float-right"
            id="0"
            btnVariant="flat-secondary"
            :texto="informacion"
            direccion="right"
            style="margin-right: -4px; margin-top: -13px; margin-bottom: -8px;"
          />

          <BFormGroup label-for="criterios">
            <template #label>
              Criterios para la evaluación de la estrategia <span class="text-danger">*</span>
            </template>
            <BFormTextarea
              id="criterios"
              placeholder="Ingrese los criterios"
              v-model="estrategia.criterios"
              rows="2"
              maxlength="200"
              :state="v$.estrategia.criterios.$error === true
                ? false
                : null"
            />
            <small class="text-muted text-right d-block mt-25">{{ estrategia.criterios ? estrategia.criterios.length : 0 }} / 200</small>
            <BFormInvalidFeedback v-if="v$.estrategia.criterios.$error"
              id="criteriosInfo"
              class="text-right"
            >
              <p v-for="error of v$.estrategia.criterios.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
            </BFormInvalidFeedback>
          </BFormGroup>
        </BCol>

      </BRow>

      <colLinea />

      <div class="text-right">
        <BButton variant="outline-secondary" class="mr-1" @click="closeModal">
          Cancelar
        </BButton>

        <BButton
          variant="primary"
          class="mr-25"
          :disabled="this.v$.estrategia.$errors.length > 0"
          @click="submitOption"
        >
          Guardar Estrategia
        </BButton>
      </div>

    </BOverlay>
  </BModal>
</template>

<script>

// ETIQUETAS
import {
  BRow, BCol, BFormGroup, BFormInput, BFormInvalidFeedback, BMedia, BButton, 
  BAvatar, BOverlay, BFormDatepicker, BFormTimepicker, BFormTextarea, BModal, 
  VBModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import { mapGetters, mapActions } from 'vuex'

// VALIDACIONES
import useVuelidate from '@vuelidate/core'
import { required, maxLength, helpers } from '@vuelidate/validators'

import { toast } from '@/mixins/alerts'

// COMPONENTES
import popover from '@/views/components/Form/popover.vue'
import colLinea from '@/views/components/Form/colLinea.vue'

export default {
  components: {
    BRow, BCol, BFormGroup, BFormInput, BFormInvalidFeedback, BMedia, BButton, 
    BAvatar, BOverlay, BFormDatepicker, BFormTimepicker, BFormTextarea, BModal, 
    VBModal,
    vSelect,
    
    popover,
    colLinea,
  },
  mixins: [toast],
  data() {
    return {
      configs: {
        cargando: false,
        loadingPeriodos: true,
        loadingAsignaturas: true,
      },
      estrategia: {},
      informacion: 'Sugerencias de criterios para la evaluación de la estrategia: Recursos educativos responden a los distintos estilos de aprendizaje; Participación de todos los estudiantes, especialmente aquellos que presentan NEE; Logros de los objetivos planteados para la clase; Interacción entre los estudiantes; entre otros.',
      periodosOption: [],
      asignaturasOption: [],
    }
  },
  computed: {
    ...mapGetters({
      getUser: 'auth/user',
      getLibroSelected: 'libros/getLibroSelected',
    }),
  },
  watch: {
    getLibroSelected(val) {
      this.cargarAsignaturas()
    },
  },
  props: {
    nombreModal: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions({
      fetchAsignaturasCurso: 'asignaturas/fetchAsignaturasCurso',
      fetchPeriodosEstablecimiento: 'periodos/fetchPeriodosEstablecimiento',
    }),
    async onModalOpen() {
      await Promise.all([
        this.cargarAsignaturas(),
        this.cargarPeriodos(),
      ]);
      this.estrategia = { ...this.data }
    },
    async cargarAsignaturas() {
      const res = await this.fetchAsignaturasCurso(this.getLibroSelected.id)
      if (res.status === 'success') {
        this.asignaturasOption = []
        this.asignaturasOption = res.data
      } else if (res.status === 'error') {
        this.$toastError('Error al cargar las asignaturas!', res.message);
      }
      this.configs.loadingAsignaturas = false
    },
    async cargarPeriodos() {
      const res = await this.fetchPeriodosEstablecimiento(this.getUser.id_establecimiento)
      if (res.status === 'success') {
        this.periodosOption = []
        this.periodosOption = res.data
      } else if (res.status === 'error') {
        this.$toastError('Error al cargar los periodos!', res.message);
      }
      this.configs.loadingPeriodos = false
    },
    submitOption() {
      this.v$.estrategia.$touch()
      if (!this.v$.estrategia.$invalid) {
        const params = {
          ...this.estrategia,
          id_curso: this.getLibroSelected.id
        }
        this.$emit('processForm', params)
        this.v$.estrategia.$reset()
      }
    },
    closeModal() {
      this.$bvModal.hide(this.nombreModal)
      this.v$.estrategia.$reset()
      if (this.estrategia.id === null) {
        this.$emit('resetData')
      }
    },
  },
  validations() {
    return {
      estrategia: {
        id_asignatura: {
          required: helpers.withMessage('El campo es requerido.', required),
        },
        id_periodo: {
          required: helpers.withMessage('El campo es requerido.', required),
        },
        estrategia: {
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener un máximo de 200 caracteres.', maxLength(200)),
        },
        criterios: {
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener un máximo de 200 caracteres.', maxLength(200)),
        },
      }
    }
  },
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
