<template>
  <BModal
    id="asistencia_dias"
    :title="'Asistencias del día '+ diaSelected.label"
    centered
    size="xl"
    cancel-title="Cancelar"
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
    body-class="p-0"
    @close="closeModal()"
    @show="openModal()"
  >
    <BOverlay
      :show="cargando"
      spinner-variant="primary"
      variant="semi-dark"
      v-if="$can('read', 'asistencias')"
      style="min-height: 605px !important;"
    >
      <b-alert
        v-if="bloquesAlert"
        variant="info"
        show
        class="text-center mt-25 mr-25 ml-25 pb-2 pt-2"
      >
        {{ alertMsg }}
      </b-alert>
        <!-- :sticky-header="true" -->
      <BTable
        v-else-if="!cargando"
        small
        hover
        noCollapse
        bordered
        :style="cargando
          ? 'min-height: 605px !important;'
          : 'min-height: 605px !important;'
        "
        class="mt-0"
        responsive
        sticky-header
        :fields="fields"
        :items="items"
        foot-clone
      >
        <!-- Cargando -->
        <template #table-busy>
          <div class="text-center text-danger my-1">
            <spinner />
          </div>
        </template>

        <template
          #cell()="data"
        >
          <!-- col: NUMLISTA -->
          <div
            v-if="data.field.key == 'num_lista'"
          >
            <estudiante-retirado
              :rut="data.item.rut"
              :data.sync="data.item.orden.toString()"
              :fecha_retiro.sync="data.item.fecha_retiro"
              :fecha_ingreso.sync="data.item.fecha_ingreso"
              :genero.sync="data.item.genero"
              :mayus="false"
            />
          </div>

          <!-- col: NOMBRE ESTUDIANTE -->
          <div
            class="divEstudiante"
            v-else-if="data.field.key == 'nombre_completo'"
          >
            <estudiante-retirado
              :rut="data.item.rut"
              :data.sync="data.item.nombre_completo"
              :fecha_retiro.sync="data.item.fecha_retiro"
              :fecha_ingreso.sync="data.item.fecha_ingreso"
              :genero.sync="data.item.genero"
              :mayus="true"
            />
          </div>

          <!-- col: BLOQUES -->
          <asistencia-alumno-bloque
            v-else-if="data.field.bloque"
            :item.sync="data.item"
            :field.sync="data.field"
            :tipoAsistencia.sync="tipoAsistencia"
            :asistenciasBloques.sync="asistenciasBloques"
          />

        </template>

        <template #foot(bloque_1)="data">
          <btn-guardar-sin-firmar
            v-if="hiddenBloques.length >= 1"
            :id_persona_rol_docente.sync="hiddenBloques[0]['id_persona_rol_docente']"
            :btnText="'Guardar'"
            @processBtn="submit(1)"
          />
          <btn-guardar-firmar
            class="mt-50"
            :idModal="1"
            v-if="hiddenBloques.length >= 1"
            :id_persona_rol_docente.sync="hiddenBloques[0]['id_persona_rol_docente']"
            :btnText="'Firmar y guardar'"
            :text="'Ingrese su firma digital'"
            @processBtn="submitFirma(1)"
          />
        </template>

        <template #foot(bloque_2)="data">
          <btn-guardar-sin-firmar
            v-if="hiddenBloques.length >= 2"
            :id_persona_rol_docente.sync="hiddenBloques[1]['id_persona_rol_docente']"
            :btnText="'Guardar'"
            @processBtn="submit(2)"
          />
          <btn-guardar-firmar
            class="mt-50"
            :idModal="2"
            v-if="hiddenBloques.length >= 2"
            :id_persona_rol_docente.sync="hiddenBloques[1]['id_persona_rol_docente']"
            :btnText="'Firmar y guardar'"
            :text="'Ingrese su firma digital'"
            @processBtn="submitFirma(2)"
          />
        </template>

        <template #foot(bloque_3)="data">
          <btn-guardar-sin-firmar
            v-if="hiddenBloques.length >= 3"
            :id_persona_rol_docente.sync="hiddenBloques[2]['id_persona_rol_docente']"
            :btnText="'Guardar'"
            @processBtn="submit(3)"
          />
          <btn-guardar-firmar
            class="mt-50"
            :idModal="3"
            v-if="hiddenBloques.length >= 3"
            :id_persona_rol_docente.sync="hiddenBloques[2]['id_persona_rol_docente']"
            :btnText="'Firmar y guardar'"
            :text="'Ingrese su firma digital'"
            @processBtn="submitFirma(3)"
          />
        </template>

        <template #foot(bloque_4)="data">
          <btn-guardar-sin-firmar
            v-if="hiddenBloques.length >= 4"
            :id_persona_rol_docente.sync="hiddenBloques[3]['id_persona_rol_docente']"
            :btnText="'Guardar'"
            @processBtn="submit(4)"
          />
          <btn-guardar-firmar
            class="mt-50"
            :idModal="4"
            v-if="hiddenBloques.length >= 4"
            :id_persona_rol_docente.sync="hiddenBloques[3]['id_persona_rol_docente']"
            :btnText="'Firmar y guardar'"
            :text="'Ingrese su firma digital'"
            @processBtn="submitFirma(4)"
          />
        </template>

        <template #foot(bloque_5)="data">
          <btn-guardar-sin-firmar
            v-if="hiddenBloques.length >= 5"
            :id_persona_rol_docente.sync="hiddenBloques[4]['id_persona_rol_docente']"
            :btnText="'Guardar'"
            @processBtn="submit(5)"
          />
          <btn-guardar-firmar
            class="mt-50"
            :idModal="5"
            v-if="hiddenBloques.length >= 5"
            :id_persona_rol_docente.sync="hiddenBloques[4]['id_persona_rol_docente']"
            :btnText="'Firmar y guardar'"
            :text="'Ingrese su firma digital'"
            @processBtn="submitFirma(5)"
          />
        </template>

        <template #foot(bloque_6)="data">
          <btn-guardar-sin-firmar
            v-if="hiddenBloques.length >= 6"
            :id_persona_rol_docente.sync="hiddenBloques[5]['id_persona_rol_docente']"
            :btnText="'Guardar'"
            @processBtn="submit(6)"
          />
          <btn-guardar-firmar
            class="mt-50"
            :idModal="6"
            v-if="hiddenBloques.length >= 6"
            :id_persona_rol_docente.sync="hiddenBloques[5]['id_persona_rol_docente']"
            :btnText="'Firmar y guardar'"
            :text="'Ingrese su firma digital'"
            @processBtn="submitFirma(6)"
          />
        </template>

        <template #foot(bloque_7)="data">
          <btn-guardar-sin-firmar
            v-if="hiddenBloques.length >= 7"
            :id_persona_rol_docente.sync="hiddenBloques[6]['id_persona_rol_docente']"
            :btnText="'Guardar'"
            @processBtn="submit(7)"
          />
          <btn-guardar-firmar
            class="mt-50"
            :idModal="7"
            v-if="hiddenBloques.length >= 7"
            :id_persona_rol_docente.sync="hiddenBloques[6]['id_persona_rol_docente']"
            :btnText="'Firmar y guardar'"
            :text="'Ingrese su firma digital'"
            @processBtn="submitFirma(7)"
          />
        </template>

        <template #foot(bloque_8)="data">
          <btn-guardar-sin-firmar
            v-if="hiddenBloques.length >= 8"
            :id_persona_rol_docente.sync="hiddenBloques[7]['id_persona_rol_docente']"
            :btnText="'Guardar'"
            @processBtn="submit(8)"
          />
          <btn-guardar-firmar
            class="mt-50"
            :idModal="8"
            v-if="hiddenBloques.length >= 8"
            :id_persona_rol_docente.sync="hiddenBloques[7]['id_persona_rol_docente']"
            :btnText="'Firmar y guardar'"
            :text="'Ingrese su firma digital'"
            @processBtn="submitFirma(8)"
          />
        </template>

        <template #foot(bloque_9)="data">
          <btn-guardar-sin-firmar
            v-if="hiddenBloques.length >= 9"
            :id_persona_rol_docente.sync="hiddenBloques[8]['id_persona_rol_docente']"
            :btnText="'Guardar'"
            @processBtn="submit(9)"
          />
          <btn-guardar-firmar
            class="mt-50"
            :idModal="9"
            v-if="hiddenBloques.length >= 9"
            :id_persona_rol_docente.sync="hiddenBloques[8]['id_persona_rol_docente']"
            :btnText="'Firmar y guardar'"
            :text="'Ingrese su firma digital'"
            @processBtn="submitFirma(9)"
          />
        </template>

        <template #foot(bloque_10)="data">
          <btn-guardar-sin-firmar
            v-if="hiddenBloques.length >= 10"
            :id_persona_rol_docente.sync="hiddenBloques[9]['id_persona_rol_docente']"
            :btnText="'Guardar'"
            @processBtn="submit(10)"
          />
          <btn-guardar-firmar
            class="mt-50"
            :idModal="10"
            v-if="hiddenBloques.length >= 10"
            :id_persona_rol_docente.sync="hiddenBloques[9]['id_persona_rol_docente']"
            :btnText="'Firmar y guardar'"
            :text="'Ingrese su firma digital'"
            @processBtn="submitFirma(10)"
          />
        </template>

        <template #foot(bloque_11)="data">
          <btn-guardar-sin-firmar
            v-if="hiddenBloques.length >= 11"
            :id_persona_rol_docente.sync="hiddenBloques[10]['id_persona_rol_docente']"
            :btnText="'Guardar'"
            @processBtn="submit(11)"
          />
          <btn-guardar-firmar
            class="mt-50"
            :idModal="11"
            v-if="hiddenBloques.length >= 11"
            :id_persona_rol_docente.sync="hiddenBloques[10]['id_persona_rol_docente']"
            :btnText="'Firmar y guardar'"
            :text="'Ingrese su firma digital'"
            @processBtn="submitFirma(11)"
          />
        </template>

        <template #foot(bloque_12)="data">
          <btn-guardar-sin-firmar
            v-if="hiddenBloques.length >= 12"
            :id_persona_rol_docente.sync="hiddenBloques[11]['id_persona_rol_docente']"
            :btnText="'Guardar'"
            @processBtn="submit(12)"
          />
          <btn-guardar-firmar
            class="mt-50"
            :idModal="12"
            v-if="hiddenBloques.length >= 12"
            :id_persona_rol_docente.sync="hiddenBloques[11]['id_persona_rol_docente']"
            :btnText="'Firmar y guardar'"
            :text="'Ingrese su firma digital'"
            @processBtn="submitFirma(12)"
          />
        </template>

        <template #foot(bloque_13)="data">
          <btn-guardar-sin-firmar
            v-if="hiddenBloques.length >= 13"
            :id_persona_rol_docente.sync="hiddenBloques[12]['id_persona_rol_docente']"
            :btnText="'Guardar'"
            @processBtn="submit(13)"
          />
          <btn-guardar-firmar
            class="mt-50"
            :idModal="13"
            v-if="hiddenBloques.length >= 13"
            :id_persona_rol_docente.sync="hiddenBloques[12]['id_persona_rol_docente']"
            :btnText="'Firmar y guardar'"
            :text="'Ingrese su firma digital'"
            @processBtn="submitFirma(13)"
          />
        </template>

        <template #foot(bloque_14)="data">
          <btn-guardar-sin-firmar
            v-if="hiddenBloques.length >= 14"
            :id_persona_rol_docente.sync="hiddenBloques[13]['id_persona_rol_docente']"
            :btnText="'Guardar'"
            @processBtn="submit(14)"
          />
          <btn-guardar-firmar
            class="mt-50"
            :idModal="14"
            v-if="hiddenBloques.length >= 14"
            :id_persona_rol_docente.sync="hiddenBloques[13]['id_persona_rol_docente']"
            :btnText="'Firmar y guardar'"
            :text="'Ingrese su firma digital'"
            @processBtn="submitFirma(14)"
          />
        </template>
      </BTable>
    </BOverlay>

  </BModal>
</template>

<script>

// ETIQUETAS
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BFormInvalidFeedback,
  BMedia, BButton, BButtonGroup, BAvatar, BOverlay, BCard, BTab, BTabs,
  BFormRadioGroup, BTable, BCardText, BModal, VBModal, BAlert
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store/index'

import { mapGetters, mapActions } from 'vuex'

// FORMATOS
import { formatos } from '@/mixins/formatos'

// COMPONENTES RECICLADOS
import spinner from '../../../../components/spinner.vue'

// COMPONENTES HIJOS
import AsistenciaAlumnoBloque from './components/AsistenciaAlumnoBloque.vue'
import btnGuardarSinFirmar from './components/btnGuardarSinFirmar.vue'
import btnGuardarFirmar from './components/btnGuardarFirmar.vue'
import estudianteRetirado from '../../../../components/List/estudianteRetirado.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    BMedia,
    BButton,
    BButtonGroup,
    BFormRadioGroup,
    BAvatar,
    BOverlay,
    BCard,
    BTab,
    BTabs,
    BTable,
    BCardText,
    BModal,
    VBModal,
    BAlert,

    // COMPONENTES RECICLADOS
    estudianteRetirado,
    spinner,
    AsistenciaAlumnoBloque,
    btnGuardarSinFirmar,
    btnGuardarFirmar,
  },
  directives: {
    Ripple,
  },
  mixins: [formatos],
  data() {
    return {
      cargando: true,


      items: [],

      tipoAsistencia: [],

      asistenciasBloques: [],

      bloquesAlert: false,
      alertMsg: '',
      hiddenBloques: [],
      fields: [],

      fieldsTabla: [
        {
          key: 'num_lista',
          // stickyColumn: true,
          label: '#',
          // sortable: true,
          variant: 'primary',
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '1% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'nombre_completo',
          stickyColumn: true,
          label: 'Estudiantes',
          variant: 'light',
          // sortable: true,
          thStyle: {
            width: '30% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      getUser: 'auth/user',
      getAsistencias: 'asistencias/getAsistencias',
      getTipoAsistencia: 'asistencias/getTipoAsistencia',
      getLibroSelected: 'libros/getLibroSelected',
    }),
  },
  watch: {
    getAsistencias(val) {
      if (val.length !== 0) {
        this.setAlumnosAsistencias(val)

        this.asistenciasBloques = []
        this.asistenciasBloques = val

        this.hiddenBloques = []
        if (typeof val[0]['bloques'] !== 'undefined') {
          this.hiddenBloques = val[0]['bloques']
          this.cargarFieldsBloques(val[0]['bloques'])
        } else {
          const bloques = []
          this.cargarFieldsBloques(bloques)
        }
      } else {
        this.cargando = false
        this.bloquesAlert = true
        this.alertMsg = 'El curso no tiene Estudiantes Matriculados.'
      }
    },
    diaSelected(diaSelected) {
      if (diaSelected.state) {
        this.cargarAsistencias(this.getLibroSelected.id)
      }
    },
    getTipoAsistencia(val) {
      this.tipoAsistencia = val
    },
  },
  props: {
    diaSelected: {
      type: Object,
      required: false,
      default: null,
    },
    mesSelected: {
      type: Number,
      required: true,
    },
  },
  methods: {
    ...mapActions({
      fetchBloques: 'bloques/fetchBloques',
      fetchAsistencias: 'asistencias/fetchAsistencias',
      fetchTipoAsistencia: 'asistencias/fetchTipoAsistencia',

      addAsistencia: 'asistencias/addAsistencia',
      updateAsistencia: 'asistencias/updateAsistencia',
    }),
    async openModal() {
      await this.cargarTipoAsistencia()
    },
    closeModal() {
      this.initialState()
      this.$emit('cargarDatosMensual', this.getLibroSelected.id)
    },
    async cargarTipoAsistencia() {
      await this.fetchTipoAsistencia()
    },

    setAlumnosAsistencias(estudiantes) {
      for (let i = 0; i < estudiantes.length; i++) {
        let estudiante = estudiantes[i];
        estudiante['nombre_completo'] = `${estudiante.primer_apellido}
                  ${estudiante.segundo_apellido}
                  ${estudiante.nombre}
                  ${estudiante.segundo_nombre !== null
                    ? estudiante.segundo_nombre
                    : ''}
                  `
        estudiante['fecha_retiro'] = estudiante.fecha_retiro
      }
      this.items = []
      this.items = estudiantes
    },
    cargarFieldsBloques(bloques) {
      this.fields = []
      this.fieldsTabla.forEach(field => {
        this.fields.push(field)
      })

      bloques.forEach((bloque, index) => {
        const num = index + 1
        const nombreBloque = 'Bloque'+bloque.Bloque
        const nombreAsignatura = bloque.nombre_asignatura
        const horaInicio = this.formatHoraInput(bloque.hora_inicio)
        const horaTermino = this.formatHoraInput(bloque.hora_termino)
        const horaBloque = ' » '+horaInicio+' - '+horaTermino+' « '+nombreAsignatura.substring(0, 14)
        const hora = horaInicio+' - '+horaTermino+'\n'+nombreAsignatura

        let thClass = typeof bloque.id_asistencia === 'undefined'
          ? null
          : 'text-primary'
        let background = typeof bloque.id_asistencia === 'undefined'
          ? null
          : '#e4f2fb !important'

        let tdClass = typeof bloque.id_asistencia === 'undefined'
          ? 'text-center'
          : 'text-center bg-light-primary bg-lighten-5'

        this.fields.push(
          {
            key: 'bloque_'+num,
            label: horaBloque,
            tdClass,
            headerTitle: hora,
            bloque: true,
            nombreBloque: nombreBloque,
            horaBloque: horaBloque,
            id_bloque: bloque.id,
            tipoAsistencia: bloque.id_tipo_asistencia,
            tipo: bloque.tipo,
            thClass,
            thStyle: {
              'text-align': 'center',
              width: '15% !important',
              display: 'table-cell',
              'vertical-align': 'middle',
              background,
            },
          },
        )
      })
      this.bloquesAlert = bloques.length === 0
      this.alertMsg = 'El día no tiene horas asignadas.'
      this.cargando = false
    },
    cargarAsistencias(id_curso) {
      this.cargando = true
      const year = new Date().getFullYear()
      const fecha = year+'-'+ this.mesSelected +'-'+ this.diaSelected.numeroDia

      const data = {
        id_curso,
        fecha
      }
      this.fetchAsistencias(data)
    },
    pushFirmas(bloques) {
      let bloquesFirma = []
      bloques.forEach(bloque => {
        bloquesFirma.push({
          firma: true,
          id: 0,
        })
      })
      this.items.push({
        id: null,
        nombre: null,
        primer_apellido: null,
        segundo_apellido: null,
        bloques: bloquesFirma,
      })
    },
    fechaActual() {
      const hoy = new Date()
      const dia = hoy.getDate()
      let mes = hoy.getMonth()
      mes = mes + 1
      const year = hoy.getFullYear()

      return year+'-'+mes+'-'+dia
    },
    submit(numBloque) {
      const num = numBloque - 1

      let asistenciasBloqueAdd = []
      let asistenciasBloqueUpdate = []
      let id_bloque = ''
      this.asistenciasBloques.forEach(alumno => {
        id_bloque = alumno.bloques[num].id
        if (typeof alumno.bloques[num].id_asistencia === 'undefined') {
          asistenciasBloqueAdd.push({
            id_alumno: alumno.id_persona_rol,
            id_bloque: alumno.bloques[num].id,
            tipo_asistencia: alumno.bloques[num].tipoAsistencia,
          })
        } else {
          asistenciasBloqueUpdate.push({
            id_alumno: alumno.id_persona_rol,
            id_bloque: alumno.bloques[num].id,
            tipo_asistencia: alumno.bloques[num].tipoAsistencia,
            id_asistencia: alumno.bloques[num].id_asistencia,
          })
        }
      });
      const year = new Date().getFullYear()
      const fecha = year+'-'+ this.mesSelected +'-'+ this.diaSelected.numeroDia

      if (asistenciasBloqueAdd.length > 0) {
        // CREAR
        const data = {
          fecha,
          id_curso: this.getLibroSelected.id,
          alumnos: asistenciasBloqueAdd
        }
        this.addAsistenciaBloque(data, id_bloque)
      }

      if (asistenciasBloqueUpdate.length > 0) {
        // UPDATE CON FOREACH POR CADA ID_ASISTENCIA
        this.updateAsistenciaBloque(asistenciasBloqueUpdate, id_bloque)
      }
    },

    addAsistenciaBloque(data, id_bloque) {
      this.addAsistencia(data).then(() => {
        const statusAsistencia = store.state.asistencias.status
        if (statusAsistencia === 200) {

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Asistencia guardada 👍',
              icon: 'CheckIcon',
              text: 'La asistencia fue ingresada con éxito!',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
            timeout: 4000,
          })
          this.pintarBloque(id_bloque)
          this.cargando = false
        }
        else {
          this.$swal({
            title: 'Error!',
            text: store.state.asistencias.message,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
      })
    },
    updateAsistenciaBloque(asistenciasBloqueUpdate, id_bloque) {
      const countAsistencias = asistenciasBloqueUpdate.length
      asistenciasBloqueUpdate.forEach((bloque, index, array) => {
        const data = {
          id_asistencia: bloque.id_asistencia,
          tipo_asistencia: bloque.tipo_asistencia,
        }
        this.updateAsistencia(data).then(() => {
          if (countAsistencias === index + 1) {
            const statusAsistencia = store.state.asistencias.status
            if (statusAsistencia === 200) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Asistencias guardadas 👍',
                  icon: 'CheckIcon',
                  text: 'Las asistencias del bloque han sido ingresadas con éxito!',
                  variant: 'success',
                },
              },
              {
                position: 'bottom-right',
                timeout: 4000,
              })
              this.pintarBloque(id_bloque)
              this.cargando = false
            }
            else {
              this.$swal({
                title: 'Error!',
                text: store.state.asistencias.message,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }
          }
        })
      })
    },

    pintarBloque(id_bloque) {
      const thClass = 'text-primary'
      const tdClass = 'text-center bg-light-primary bg-lighten-5'
      const background = '#e4f2fb !important'
      const field = this.fields.find(f => f.id_bloque === id_bloque)
      field.thClass = thClass
      field.tdClass = tdClass
      field.thStyle.background = background
    },


    initialState() {
      this.cargando = true,
      this.items = [],
      this.tipoAsistencia = [],
      this.asistenciasBloques = [],
      this.bloquesAlert = false,
      this.hiddenBloques = [],
      this.$emit('initialState')
    },
  },
}
</script>

<style lang="scss">
  .table-hover tbody tr {
    cursor: initial !important;
  }
  .break-page {
    page-break-after: always;
  }
</style>
