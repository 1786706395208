<template>
  <PlanApoyoForm
    nombreModal="modal-create"
    title="Registrar plan de apoyo individual"
    :data.sync="data"
    @processForm="agregar"
    @resetData="resetData"
  />
</template>

<script>
import { mapActions } from 'vuex'
import store from '@/store/index'
import { toast, swal } from '@/mixins/alerts'
import PlanApoyoForm from './PlanApoyoForm.vue'

export default {
  components: {
    PlanApoyoForm,
  },
  mixins: [toast, swal],
  data() {
    return {
      data: this.getDefaultData(),
    }
  },
  methods: {
    ...mapActions({
      fetchPlanApoyos: 'II_4_plan_apoyo/fetchPlanApoyos',
      createPlanApoyo: 'II_4_plan_apoyo/addPlanApoyo',
      createPlanApoyoHorario: 'II_4_plan_apoyo/addPlanApoyoHorario',
    }),
    getDefaultData() {
      return {
        id: null,
        descripcion: '',
        fecha_inicio: null,
        fecha_termino: null,
        alumno: null,
        observaciones: '',
        apoyoEspecializado: [],
        id_periodo: null,
        id_curso: null,
      }
    },
    resetData() {
      this.data = this.getDefaultData()
    },
    async agregar(planApoyo) {

      this.personas = [planApoyo.alumno]
      planApoyo.apoyoEspecializado.forEach(especialista => {
        this.personas.push(especialista)
      })

      const data = {
        ...planApoyo,
        personas: this.personas,
      }

      const res = await this.createPlanApoyo(data)
        if (res.status === 'success') {
          const dataResponse = store.state.II_4_plan_apoyo.dataResponse
          
          // consulta horarios registrado y compara id_persona_rol del horario.apoyoEspecializado,
          // con los id_persona_rol de la respuesta del dataResponse
          // para obtener el id_plan_apoyo_persona
          let responseStatus = 'Success'
          planApoyo.horarios.forEach(horario => {
            dataResponse.forEach(plan_apoyo_persona => {
              if (plan_apoyo_persona.id_persona_rol === horario.apoyoEspecializado.value) {
                let dataHorario = {}
                dataHorario = {
                  dia: horario.dia.id,
                  hora_inicio: horario.hora_inicio,
                  hora_termino: horario.hora_termino,
                  id_plan_apoyo_persona: plan_apoyo_persona.id
                }
                this.createPlanApoyoHorario(dataHorario).then(() => {
                  const statusHorario = store.state.II_4_plan_apoyo.statusHorario
                  if (statusHorario !== 'Success') {
                    responseStatus = statusHorario
                  }
                });
              }
            });
          });

          if (responseStatus === 'Success') {
            this.$toastSuccess(
              'Plan de apoyo individual registrado 👍', 
              'La plan de apoyo fue guardado con éxito!'
            );
            this.resetData()
            this.$bvModal.hide('modal-create')
          } else {
            this.$swalError('Error al crear el plan de apoyo!', responseStatus);
          }
        } else if (res.status === 'error') {
          this.$swalError('Error al crear el plan de apoyo!', statusPlanApoyos);
        }
    },
  },
}
</script>
