var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BModal',{attrs:{"id":_vm.nombreModal,"title":_vm.title,"centered":"","size":"lg","hide-footer":true},on:{"shown":_vm.onModalOpen}},[_c('div',{staticClass:"d-flex justify-content-between mb-1"},[_c('div',{staticClass:"mt-50 text-secondary"}),_c('div',{staticClass:"mr-1 mt-50 text-muted small"},[_vm._v(" Campos obligatorios "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('BOverlay',{attrs:{"show":_vm.configs.cargando,"spinner-variant":"primary","variant":"semi-dark"}},[_c('BRow',[_c('BCol',{attrs:{"id":"col-ASIGNATURA","cols":"12","md":"12"}},[_c('BFormGroup',{attrs:{"label-for":"id_asignatura"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Ámbito o asignatura donde se aplicará "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[(_vm.configs.loadingAsignaturas)?_c('v-select',{attrs:{"placeholder":"Cargando asignaturas...","disabled":true}}):_c('v-select',{class:_vm.v$.estrategia.id_asignatura.$error === true
              ? 'border-danger rounded'
              : '',attrs:{"placeholder":"Seleccione una asignatura...","options":_vm.asignaturasOption,"reduce":function (option) { return option.id; },"label":"nombre","input-id":"id"},model:{value:(_vm.estrategia.id_asignatura),callback:function ($$v) {_vm.$set(_vm.estrategia, "id_asignatura", $$v)},expression:"estrategia.id_asignatura"}}),(_vm.v$.estrategia.id_asignatura.$error)?_c('div',{staticClass:"text-danger text-right",staticStyle:{"font-size":"0.857rem"},attrs:{"id":"id_asignaturaInfo"}},_vm._l((_vm.v$.estrategia.id_asignatura.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('BCol',{attrs:{"id":"coL-PERIODO","cols":"12","md":"12"}},[_c('BFormGroup',{attrs:{"label-for":"periodo"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Período de tiempo en que se aplicará "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[(_vm.configs.loadingPeriodos)?_c('v-select',{attrs:{"placeholder":"Cargando periodos...","disabled":true}}):_c('v-select',{class:_vm.v$.estrategia.id_periodo.$error === true
              ? 'border-danger rounded'
              : '',attrs:{"placeholder":"Seleccione un periodo...","label":"nombre","options":_vm.periodosOption,"reduce":function (option) { return option.id; },"input-id":"id_periodo"},model:{value:(_vm.estrategia.id_periodo),callback:function ($$v) {_vm.$set(_vm.estrategia, "id_periodo", $$v)},expression:"estrategia.id_periodo"}}),(_vm.v$.estrategia.id_periodo.$error)?_c('div',{staticClass:"text-danger text-right",staticStyle:{"font-size":"0.857rem"},attrs:{"id":"id_periodoInfo"}},_vm._l((_vm.v$.estrategia.id_periodo.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('BCol',{attrs:{"id":"col-ESTRATEGIA","cols":"12","md":"12"}},[_c('BFormGroup',{attrs:{"label-for":"estrategia"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Estrategia "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('BFormTextarea',{attrs:{"id":"estrategia","placeholder":"Ingrese la estrategia","rows":"2","maxlength":"200","state":_vm.v$.estrategia.estrategia.$error === true
              ? false
              : null},model:{value:(_vm.estrategia.estrategia),callback:function ($$v) {_vm.$set(_vm.estrategia, "estrategia", $$v)},expression:"estrategia.estrategia"}}),_c('small',{staticClass:"text-muted text-right d-block mt-25"},[_vm._v(_vm._s(_vm.estrategia.estrategia ? _vm.estrategia.estrategia.length : 0)+" / 200")]),(_vm.v$.estrategia.estrategia.$error)?_c('BFormInvalidFeedback',{staticClass:"text-right",attrs:{"id":"estrategiaInfo"}},_vm._l((_vm.v$.estrategia.estrategia.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1),_c('BCol',{attrs:{"id":"col-CRITERIOS","cols":"12","md":"12"}},[_c('popover',{staticClass:"float-right",staticStyle:{"margin-right":"-4px","margin-top":"-13px","margin-bottom":"-8px"},attrs:{"id":"0","btnVariant":"flat-secondary","texto":_vm.informacion,"direccion":"right"}}),_c('BFormGroup',{attrs:{"label-for":"criterios"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Criterios para la evaluación de la estrategia "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('BFormTextarea',{attrs:{"id":"criterios","placeholder":"Ingrese los criterios","rows":"2","maxlength":"200","state":_vm.v$.estrategia.criterios.$error === true
              ? false
              : null},model:{value:(_vm.estrategia.criterios),callback:function ($$v) {_vm.$set(_vm.estrategia, "criterios", $$v)},expression:"estrategia.criterios"}}),_c('small',{staticClass:"text-muted text-right d-block mt-25"},[_vm._v(_vm._s(_vm.estrategia.criterios ? _vm.estrategia.criterios.length : 0)+" / 200")]),(_vm.v$.estrategia.criterios.$error)?_c('BFormInvalidFeedback',{staticClass:"text-right",attrs:{"id":"criteriosInfo"}},_vm._l((_vm.v$.estrategia.criterios.$errors),function(error){return _c('p',{key:error.$uid},[_vm._v(" "+_vm._s(error.$message)+" ")])}),0):_vm._e()],1)],1)],1),_c('colLinea'),_c('div',{staticClass:"text-right"},[_c('BButton',{staticClass:"mr-1",attrs:{"variant":"outline-secondary"},on:{"click":_vm.closeModal}},[_vm._v(" Cancelar ")]),_c('BButton',{staticClass:"mr-25",attrs:{"variant":"primary","disabled":this.v$.estrategia.$errors.length > 0},on:{"click":_vm.submitOption}},[_vm._v(" Guardar Estrategia ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }