<template>
  <BOverlay
    :show="spinner"
    spinner-variant="primary"
    variant="semi-dark"
  >
    <BRow class="mb-3">
      <BCol
        lg="2"
        md="3"
        sm="3"
        class="my-50"
      >
        <!-- BOTON MOSTRAR -->
        <btnMostrar
          :pageOptions.sync="pageOptions"
          :perPage.sync="perPage"
          :total.sync="items.length"
        />
      </BCol>
      <BCol
        lg="6"
        md="5"
        sm="5"
        class="my-50"
      >
        <!-- FILTRO -->
        <!-- <inputFiltro
          :filter.sync="filter"
        /> -->
      </BCol>
      <BCol
        md="4"
        sm="4"
        class="my-50"
      >
        <!-- CREAR -->
        <EstrategiasCreate />

        <!-- BOTON CREAR -->
        <btnCrearModal
          accion="Registrar"
          texto="Estrategia"
          modal="modal-create"
          :modulo="nombre_permiso"
        />
      </BCol>

      <BCol cols="12" style="min-height: 490px !important;">
        <BTable
          striped
          small
          hover
          noCollapse
          bordered
          class="rounded"
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :busy="cargando"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >

          <!-- Cargando -->
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <spinner />
            </div>
          </template>

          <!-- col ACCIONES -->
          <template #cell(acciones)="data">
            <EstrategiasUpdate
              :modal="'modal-update-'+data.item.id"
              :item="data.item"
            />
            <colAccionesBtnes
              :modal="'modal-update-'+data.item.id"
              :data="data"
              :modulo="nombre_permiso"
              @processRemove="remove(data.item)"
            />
          </template>
        </BTable>
        <BAlert
          v-if="!cargando && items.length === 0"
          variant="primary"
          show
          class="text-center pt-25 pb-25"
        >
          <div class="alert-body">
            <span>No existen estrategias registradas.</span>
          </div>
        </BAlert>
      </BCol>

      <BCol cols="12" >
        <BPagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </BCol>

    </BRow>
  </BOverlay>
</template>

<script>

// ETIQUETAS
import {
  BTable, BRow, BCol, BPagination, BOverlay, BAlert,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'

// COMPONENTES RECICLADOS
import spinner from '@/views/components/spinner.vue'
import btnMostrar from '@/views/components/List/btnMostrar.vue'
import btnCrearModal from '@/views/components/List/btnCrearModal.vue'
import colAccionesBtnes from '@/views/components/List/colAccionesBtnes.vue'

// HIJOS
import EstrategiasCreate from './Estrategias/EstrategiasCreate.vue'
import EstrategiasUpdate from './Estrategias/EstrategiasUpdate.vue'

// FORMATOS
import { formatos } from '@/mixins/formatos'
import { toast, swal } from '@/mixins/alerts'

export default {
  components: {
    BTable, BRow, BCol, BPagination, BOverlay, BAlert,

    // COMPONENTES
    spinner,
    btnMostrar,
    btnCrearModal,
    colAccionesBtnes,

    // HIJOS
    EstrategiasCreate,
    EstrategiasUpdate,
  },
  mixins: [formatos, swal, toast],
  data() {
    return {
      nombre_permiso: 'pieII1B',
      cargando: true,
      spinner: false,
      items: [],

      // MODAL
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },

      // TABLE
      perPage: 10,
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: '',
      filterOn: [],
      pageOptions: [10, 25, 50],
      fields: [
        {
          key: 'estrategia',
          label: 'Estrategia',
          sortable: true,
          thStyle: {
            width: '250px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'nombre',
          label: 'Ámbito o asignatura donde se aplicará',
          sortable: true,
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '100px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'nombre_periodo',
          label: 'Período de tiempo en que se aplicará',
          sortable: true,
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '120px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'criterios',
          label: 'Criterios para la evaluación de la estrategia',
          sortable: true,
          thStyle: {
            width: '250px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ],
      fieldAcciones: [
        {
          key: 'acciones',
          label: 'acciones',
          tdClass: 'text-center',
          thStyle: {
            width: '80px !important',
            'text-align': 'center',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ],
    }
  },
  computed: {
    ...mapGetters({ 
      getLibroSelected: 'libros/getLibroSelected',
      getEstrategias: 'II_1_b_estrategias/getEstrategias'
    }),
  },
  watch: {
    getEstrategias(val) {
      this.totalRows = val.length
      this.items = []
      this.items = val
    },
    getLibroSelected(val) {
      this.cargarEstrategias()
    },
  },
  mounted() {
    this.inicialize()
  },
  methods: {
    ...mapActions({
      fetchEstrategias: 'II_1_b_estrategias/fetchEstrategias',
      removeEstrategia: 'II_1_b_estrategias/removeEstrategia',
    }),
    async inicialize () {
      await Promise.all([
        this.cargarEstrategias(),
        this.setTableList()
      ])
    },
    setTableList() {
      if (this.$can('update', this.nombre_permiso)
        || this.$can('delete', this.nombre_permiso)
      ) {
        this.fields.push(this.fieldAcciones)
      }
    },
    async cargarEstrategias() {
      const { status } = await this.fetchEstrategias(this.getLibroSelected.id);
      if (status === 'error') {
        this.$toastError('Error al cargar los datos !', res.message);
      }
      this.cargando = false
    },
    async remove(estrategia) {
      const html = this.formatHTMLSweetEliminar('la estrategia', estrategia.estrategia);
      
      const result = await this.$swalConfirm(
        'Eliminar estrategia!', 
        html, 
        'Sí, elimínala!', 
      );
      if (result.value) {
        this.spinner = true;
        const params = {
          id: estrategia.id,
          id_curso: this.getLibroSelected.id,
        };
        const res = await this.removeEstrategia(params);
        if (res.status === 'success') {
          this.$swalSuccess(
            'Eliminada con éxito!',
            'La estrategia ha sido eliminada!'
          );
        } else {
          this.$swalError('Error al eliminar la estrategia', res.message);
        }
        this.spinner = false;
      }
    },
    // Vuexy
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>