<template>
  <BModal
    :id="nombreModal"
    :title="title"
    centered
    size="lg"
    :hide-footer="true"
    @shown="onModalOpen"
  >
    <div class="d-flex justify-content-between mb-1">
      <div class="mt-50 text-secondary">
      </div>
      <div class="mr-1 mt-50 text-muted small">
        Campos obligatorios <span class="text-danger">*</span>
      </div>
    </div>
    <BOverlay
      :show="configs.cargando"
      spinner-variant="primary"
      variant="semi-dark"
    >
      <BRow>

        <BCol id="col-DESCRIPCION" cols="12" >
          <BFormGroup label-for="descripcion">
            <template #label>
              Descripción <span class="text-danger">*</span>
            </template>
            <BFormInput
              id="descripcion"
              placeholder="Ingrese la descripción del plan de apoyo individual"
              v-model="planApoyo.descripcion"
              rows="2"
              :state="v$.planApoyo.descripcion.$error === true
                ? false
                : null"
            />
            <BFormInvalidFeedback v-if="v$.planApoyo.descripcion.$error"
              id="observacionesInfo"
              class="text-right"
            >
              <p v-for="error of v$.planApoyo.descripcion.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
            </BFormInvalidFeedback>
          </BFormGroup>
        </BCol>

        <BCol id="col-ALUMNO" cols="12" md="6" >
          <BFormGroup label-for="alumno">
            <template #label>
              Estudiante <span class="text-danger">*</span>
            </template>
            <v-select
              v-if="configs.loadingEstudiantes"
              placeholder="Cargando estudiantes..."
              :disabled="true"
            />
            <v-select
              v-else
              v-model="planApoyo.alumno"
              placeholder="Seleccione un estudiante..."
              label="title"
              :options="alumnosOptions"
              :reduce="option => option.id"
              input-id="id"
              :class="v$.planApoyo.alumno.$error === true
                  ? 'border-danger rounded'
                  : ''"
            />
            <BAlert
              v-if="!configs.loadingEstudiantes && alumnosOptions.length === 0"
              class="pl-50 pt-25 pb-25 pr-50 mt-50 mb-25"
              variant="primary"
              show
            >
            Para incluir un estudiante en el Programa de Integración Escolar (PIE), 
            es necesario indicarlo en el módulo matrícula.
            </BAlert>

            <div v-if="v$.planApoyo.alumno.$error"
              id="alumnoInfo"
              class="text-danger text-right"
              style="font-size: 0.857rem;"
            >
              <p v-for="error of v$.planApoyo.alumno.$errors" :key="error.$uid" >
                {{ error.$message }}
              </p>
            </div>

          </BFormGroup>
        </BCol>

        <BCol id="col-ESPECIALISTA" cols="12" md="6">
          <BFormGroup label-for="apoyoEspecializado">
            <template #label>
              Apoyo(s) especializado(s) requerido(s) <span class="text-danger">*</span>
            </template>
            <v-select
              v-if="configs.loadingApoyosEspecializados"
              placeholder="Cargando apoyos especializados..."
              :disabled="true"
            />
            <v-select
              v-else
              v-model="planApoyo.apoyoEspecializado"
              placeholder="Seleccione los apoyos especializados..."
              multiple
              label="nombre"
              :options="especialistasOptions"
              :reduce="option => option.value"
              input-id="value"
              :class="v$.planApoyo.apoyoEspecializado.$error === true
                  ? 'border-danger rounded'
                  : ''"
              :closeOnSelect="false"
            />
            <div v-if="v$.planApoyo.apoyoEspecializado.$error"
              id="alumnoInfo"
              class="text-danger text-right"
              style="font-size: 0.857rem;"
            >
              <p v-for="error of v$.planApoyo.apoyoEspecializado.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
            </div>
          </BFormGroup>
        </BCol>

        <BCol id="col-FECHA-INICIO" cols="12" md="6" >
          <BFormGroup label-for="fecha_inicio">
            <template #label>
              Fecha inicio <span class="text-danger">*</span>
            </template>
            <BFormDatepicker
              v-model="planApoyo.fecha_inicio"
              id="fecha_inicio"
              placeholder="Abrir calendario"
              hide-header
              :date-format-options="{
                year: 'numeric',
                month: 'short',
                day: '2-digit',
                weekday: 'short'
              }"
              start-weekday="1"
              locale="es-CL"
              :date-disabled-fn="dateDisabled"
              label-help=""
              :state="v$.planApoyo.fecha_inicio.$error === true
                ? false
                : null"
            />
            <BFormInvalidFeedback v-if="v$.planApoyo.fecha_inicio.$error"
              id="fecha_inicioInfo"
              class="text-right"
            >
              <p v-for="error of v$.planApoyo.fecha_inicio.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
            </BFormInvalidFeedback>
          </BFormGroup>
        </BCol>

        <BCol id="col-FECHA-TERMINO" cols="12" md="6">
          <BFormGroup label-for="fecha_termino">
            <template #label>
              Fecha termino <span class="text-danger">*</span>
            </template>
            <BFormDatepicker
              v-model="planApoyo.fecha_termino"
              id="fecha_termino"
              placeholder="Abrir calendario"
              hide-header
              :date-format-options="{
                year: 'numeric',
                month: 'short',
                day: '2-digit',
                weekday: 'short'
              }"
              start-weekday="1"
              locale="es-CL"
              :date-disabled-fn="dateDisabled"
              label-help=""
              :state="v$.planApoyo.fecha_termino.$error === true
                ? false
                : null"
            />
            <BFormInvalidFeedback v-if="v$.planApoyo.fecha_termino.$error"
              id="fecha_terminoInfo"
              class="text-right"
            >
              <p v-for="error of v$.planApoyo.fecha_termino.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
            </BFormInvalidFeedback>
          </BFormGroup>
        </BCol>

        <BCol id="col-OBSERVACIONES" cols="12" >
          <BFormGroup label-for="observaciones">
            <template #label>
              Observaciones <span class="text-danger">*</span>
            </template>
            <BFormTextarea
              id="observaciones"
              placeholder="Ingrese las observaciones"
              v-model="planApoyo.observaciones"
              rows="6"
              maxlength="900"
              :state="v$.planApoyo.observaciones.$error === true
                ? false
                : null"
            />
            <small class="text-muted text-right d-block mt-25">{{ planApoyo.observaciones ? planApoyo.observaciones.length : 0 }} / 900</small>
            <BFormInvalidFeedback v-if="v$.planApoyo.observaciones.$error"
              id="observacionesInfo"
              class="text-right"
            >
              <p v-for="error of v$.planApoyo.observaciones.$errors" :key="error.$uid" >
                {{ error.$message }}
              </p>
            </BFormInvalidFeedback>
          </BFormGroup>
        </BCol>

      </BRow>

      <colLinea />

      <div class="text-right">
        <BButton variant="outline-secondary" class="mr-1" @click="closeModal">
          Cancelar
        </BButton>

        <BButton
          variant="primary"
          class="mr-25"
          :disabled="this.v$.planApoyo.$errors.length > 0"
          @click="submitOption"
        >
          Guardar Plan de Apoyo
        </BButton>
      </div>
    </BOverlay>
  </BModal>
</template>

<script>
// ETIQUETAS
import {
  BRow, BCol, BFormGroup, BFormInvalidFeedback, BOverlay, BFormTextarea, 
  BModal, VBModal, BFormDatepicker, BFormInput, BAlert, BButton,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

import { mapGetters, mapActions } from 'vuex'

// VALIDACIONES
import useVuelidate from '@vuelidate/core'
import { required, maxLength, helpers } from '@vuelidate/validators'

import colLinea from '@/views/components/Form/colLinea.vue'

import { toast } from '@/mixins/alerts'
import { formatos } from '@/mixins/formatos'

export default {
  components: {
    BRow, BCol, BFormGroup, BFormInvalidFeedback, BOverlay, BFormTextarea, 
    BModal, VBModal, BFormDatepicker, BFormInput, BAlert, BButton,
    vSelect,
    colLinea,
  },
  mixins: [toast],
  directives: {
    'BModal': VBModal,
    Ripple,
  },
  mixins: [formatos],
  data() {
    return {
      configs: {
        cargando: false,
        loadingEstudiantes: true,
        loadingApoyosEspecializados: true,
      },
      id_periodo: null,
      planApoyo: {},
      horarios: [],
      alumnosOptions: [],
      especialistasOptions: [],
    }
  },
  computed: {
    ...mapGetters({
      getLibroSelected: 'libros/getLibroSelected',
      getUser: 'auth/user',
      // getPlanApoyos: 'II_4_plan_apoyo/getPlanApoyos',
    }),
  },
  watch: {
    getLibroSelected() {
      this.onModalOpen()
    },
  },
  props: {
    nombreModal: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions({
      fetchEquipoAll: 'I_1_equipo/fetchEquipoAll',
      fetchAlumnosPie: 'personas/fetchAlumnosPie',
      fetchPeriodosEstablecimiento: 'periodos/fetchPeriodosEstablecimiento',
    }),
    async onModalOpen() {
      
      await Promise.all([
        this.cargaEstudiantesPie(),
        this.cargarApoyoEspecializado(),
        this.cargarPeriodos()
      ]);
      this.initData();
    },
    initData() {
      let apoyoEspecializado
      if (this.data.id !== null) {
        apoyoEspecializado = this.data.id !== null 
          ? this.data.apoyoEspecializado.map(item => item.value) 
          : {};
      }
      this.planApoyo = { ...this.data, apoyoEspecializado }
    },
    async cargaEstudiantesPie() {
      const res = await this.fetchAlumnosPie(this.getLibroSelected.id)
      if (res.state === 'success') {
        this.alumnosOptions = []
        this.alumnosOptions = res.data.map(item => ({
          id: item.id_persona_rol,
          title: `${item.nombre} ${item.primer_apellido} ${item.segundo_apellido}`,
        }));
      } else if (res.state === 'error') {
        this.$toastError('Error al cargar los estudiantes!', res.message);
      }
      this.configs.loadingEstudiantes = false
    },
    async cargarApoyoEspecializado() {
      this.configs.loadingApoyosEspecializados = true
      const res = await this.fetchEquipoAll(this.getLibroSelected.id);
      this.especialistasOptions = []
      if (res.status === 'success') {
        this.especialistasOptions = res.data.map(item => ({
          value: item.id_persona_rol,
          nombre: this.primeraMayus(
            `${item.nombre} - ${item.tipo === 1 ? 'Docente' : item.especialidad}`
          ),
        }));
      } else {
        this.$swalError('Error al cargar datos!', res.message)
      }
      this.configs.loadingApoyosEspecializados = false
    },
    async cargarPeriodos() {
      const { data } = await this.fetchPeriodosEstablecimiento(this.getUser.id_establecimiento)
      this.id_periodo = data[0].id;
    },
    submitOption() {
      this.v$.planApoyo.$touch()
      if (!this.v$.planApoyo.$invalid) {
        const params = {
          ...this.planApoyo,
          horarios: this.horarios,
          id_curso: this.getLibroSelected.id,
          id_periodo: this.id_periodo,
          personas: this.planApoyo.apoyoEspecializado
        }
        this.$emit('processForm', params)
        this.v$.planApoyo.$reset()
      }
    },
    closeModal() {
      this.$bvModal.hide(this.nombreModal)
      this.v$.planApoyo.$reset()
      if (this.planApoyo.id === null) {
        this.$emit('resetData')
      }
    },
    dateDisabled(ymd, date) {
      const weekday = date.getDay()
      const day = date.getDate()
      return weekday === 0 || weekday === 6
    },
  },
  validations() {
    return {
      planApoyo: {
        descripcion: {
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener un máximo de 50 caracteres.'
            , maxLength(50)),
        },
        fecha_inicio: {
          required: helpers.withMessage('El campo es requerido.', required),
        },
        fecha_termino: {
          required: helpers.withMessage('El campo es requerido.', required),
        },
        alumno: {
          required: helpers.withMessage('El campo es requerido.', required),
        },
        apoyoEspecializado: {
          required: helpers.withMessage('El campo es requerido.', required),
        },
        observaciones: {
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener un máximo de 900 caracteres.'
            , maxLength(900)),
        },

      }
    }
  },
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
