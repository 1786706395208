<template>
  <BModal
    :id="nombreModal"
    :title="title"
    centered
    size="xl"
    :hide-footer="true"
    @shown="onModalOpen"
  >
    <div class="d-flex justify-content-between mb-1">
      <div class="mt-50 text-secondary">
      </div>
      <div class="mr-1 mt-50 text-muted small">
        Campos obligatorios <span class="text-danger">*</span>
      </div>
    </div>
    <BOverlay
      :show="configs.cargando"
      spinner-variant="primary"
      variant="semi-dark"
    >
      <BRow>

        <BCol id="col-ALUMNOS" cols="12">
          <BFormGroup label-for="plan">
            <template #label>
              Plan de apoyo <span class="text-danger">*</span>
            </template>
            <v-select
              v-if="configs.loadingPlanApoyo"
              
              placeholder="Cargando planes de apoyo..."
              :disabled="true"
            />
            <v-select
              v-else
              v-model="realizadas.id_plan_apoyo"
              :placeholder="planApoyoOptions.length === 0
                ? 'No hay planes de apoyo disponibles...'
                : 'Seleccione una plan de apoyo...'"
              
              label="title"
              :reduce="option => option.id_plan_apoyo"
              :options="planApoyoOptions"
              :class="v$.realizadas.id_plan_apoyo.$error === true
                ? 'border-danger rounded'
                : ''"
              :disabled="planApoyoOptions.length === 0 || realizadas.id !== null"
            />
            
            <BAlert 
              v-if="!configs.loadingPlanApoyo && planApoyoOptions.length === 0"
              class="pl-50 pt-25 pb-25 pr-50 mt-50 mb-25"
              variant="primary"
              show
            >
              Para crear un nuevo plan de apoyo, dirígase al menú "II 4".
            </BAlert>
            <div v-if="v$.realizadas.id_plan_apoyo.$error"
              id="asistentesInfo"
              class="text-danger text-right"
              style="font-size: 0.857rem;"
            >
              <p v-for="error of v$.realizadas.id_plan_apoyo.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
            </div>
          </BFormGroup>
        </BCol>

        <BCol id="col-OBSERVACIONES" cols="12">
          <BFormGroup label-for="observaciones">
            <template #label>
              Observaciones <span class="text-danger">*</span>
            </template>
            <BFormTextarea
              id="observaciones"
              placeholder="Ingrese las observaciones"
              v-model="realizadas.observaciones"
              rows="2"
              maxlength="250"
              :state="v$.realizadas.observaciones.$error === true
              ? false
              : null"
            />
            <small class="text-muted text-right d-block mt-25">{{ realizadas.observaciones ? realizadas.observaciones.length: 0 }} / 250</small>
            <BFormInvalidFeedback v-if="v$.realizadas.observaciones.$error"
              id="acuerdosInfo"
              class="text-right"
            >
              <p v-for="error of v$.realizadas.observaciones.$errors" :key="error.$uid">
                {{ error.$message }}
              </p>
            </BFormInvalidFeedback>
          </BFormGroup>
        </BCol>

      </BRow>

      <!-- REPEAT -->
      <BFormGroup id="REPEAT">
        <template #label class="mb-50 mt-50 h3">
          Registro de acciones <span class="text-danger">*</span>
        </template>
        <BForm
          ref="form"
          class="repeater-form overflow-auto border rounded-lg"
          style="min-height: 399px !important; max-height: 399px !important; margin: 0px 0px 0px 0px; padding: 9px 15px 0px 9px;"
          @submit.prevent="repeateAgain"
        >

        <BAlert
          v-if="typeof items === 'undefined' || items.length === 0"
          :variant="configs.variantApoyos"
          show
          class="text-center"
        >
          <div class="alert-body">
            <span>No existen acciones agregadas.</span>
          </div>
        </BAlert>
        <BRow
          v-for="(item, index) in items"
          :id="item.id"
          :key="item.id"
          ref="row"
        >

          <BCol id="col-FECHA" md="3">
            <BFormGroup
              label="Fecha"
              :label-for="'fecha-'+item.id"
            >
              <BFormDatepicker
                :id="'fecha-'+item.id"
                v-model="item.fecha"
                placeholder="Abrir calendario"
                hide-header
                :date-format-options="{
                  year: 'numeric',
                  month: 'short',
                  day: '2-digit',
                  weekday: 'short'
                }"
                start-weekday="1"
                locale="es-CL"
                :date-disabled-fn="dateDisabled"
                label-help=""
              />
            </BFormGroup>
          </BCol>

          <BCol id="col-HORAS" md="2">
            <BFormGroup
              label="Horas pedagógicas"
              :label-for="'horas-'+item.id"
            >
              <BFormInput
                :id="'horas-'+item.id"
                type="number"
                v-model="item.horas"
                placeholder="Ingrese las horas"
                @keyup='item.horas = formatSoloNumerosUnDigito(item.horas)'
              />
            </BFormGroup>
          </BCol>

          <BCol id="col-ACTIVIDADES" lg="6" md="6">
            <BFormGroup
              label="Actividades desarrolladas"
              :label-for="'actividades-'+item.id"
            >
              <BFormTextarea
                :id="'actividades-'+item.id"
                v-model="item.actividades"
                placeholder="Ingrese las actividades desarrolladas"
                rows="2"
                maxlength="550"
              />
              <small class="text-muted text-right d-block mt-25">{{ item.actividades ? item.actividades.length: 0 }} / 550</small>
            </BFormGroup>
          </BCol>

          <BCol id="col-REMOVE-BTN"
            lg="1"
            md="1"
            class="mb-50 text-right"
          >
            <BButton
              :disabled="index === 0"
              :variant="index === 0 ? 'text-secondary' : 'outline-danger'"
              class="mt-md-2 mr-1 pr-1 pl-1"
              @click="removeItem(index)"
            >
              <feather-icon icon="TrashIcon"/>
            </BButton>
          </BCol>

          <BCol id="cols-hr" cols="12">
            <hr class="mt-0">
          </BCol>
        </BRow>
        </BForm>
      </BFormGroup>

      <BButton
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="outline-primary"
        class="mt-25 mb-25 pl-1 pr-1"
        @click="repeateAgain"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-25"
        />
        <span>Agregar acción</span>
      </BButton>

      <colLinea />

      <div class="text-right">
        <BButton variant="outline-secondary" class="mr-1" @click="closeModal">
          Cancelar
        </BButton>

        <BButton
          variant="primary"
          class="mr-25"
          :disabled="this.v$.realizadas.$errors.length > 0"
          @click="submitOption"
        >
          Guardar Registro
        </BButton>

      </div>
    </BOverlay>
  </BModal>
</template>

<script>

// ETIQUETAS
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BFormInvalidFeedback, BButton, 
  BOverlay, BFormDatepicker, BFormTextarea, BAlert, BModal, VBModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'

import { mapGetters, mapActions } from 'vuex'

// VALIDACIONES
import useVuelidate from '@vuelidate/core'
import { required, maxLength, helpers } from '@vuelidate/validators'

// FORMATOS
import { formatos } from '@/mixins/formatos'
import { swal } from '@/mixins/alerts'

// COMPONENTES RECICLADOS
import colLinea from '@/views/components/Form/colLinea.vue'

export default {
  components: {
    BRow, BCol, BFormGroup, BFormInput, BForm, BFormInvalidFeedback, BButton, 
    BOverlay, BFormDatepicker, BFormTextarea, BAlert, BModal, VBModal,
    vSelect,

    colLinea,
  },
  directives: {
    'BModal': VBModal,
    Ripple,
  },
  mixins: [heightTransition, formatos, swal],
  data() {
    return {
      nombre_permiso: 'pieIII1B',
      configs: {
        loadingPlanApoyo: true,
        cargando: false,
        variantApoyos: 'primary',
      },
      planApoyoOptions: [],
      realizadas: {},
      // REPEAT
      items: [ this.getDefaultData() ],
      nextTodoId: 1,
    }
  },
  props: {
    nombreModal: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  
  computed: {
    ...mapGetters({
      getLibroSelected: 'libros/getLibroSelected',
      getPlanApoyosAlumnos: 'II_4_plan_apoyo/getPlanApoyosAlumnos',
    }),
  },
  watch: {
    getPlanApoyosAlumnos(val) {
      this.planApoyoOptions = []
      this.planApoyoOptions = this.getPlanApoyosAlumnos
    },
  },
  methods: {
    ...mapActions({
      fetchPlanApoyosAlumnos: 'II_4_plan_apoyo/fetchPlanApoyosAlumnos',
    }),
    async onModalOpen() {
      this.realizadas = JSON.parse(JSON.stringify(this.data));
      await this.cargarPlanApoyo()
      this.cargarRelizada()
      this.initTrHeight()
    },
    async cargarPlanApoyo() {
      const res = await this.fetchPlanApoyosAlumnos(this.getLibroSelected.id)
      this.planApoyoOptions = []
      if (res.status === 'success') {
        this.planApoyoOptions = res.data
      } else {
        this.$swalError('Error al cargar datos!', res.message)
      }
      this.configs.loadingPlanApoyo = false
    },
    resetItemData() {
      this.items = [ this.getDefaultData() ];
    },
    getDefaultData() {
      return {
        fecha: this.formatDate(new Date()),
        horas: 0,
        actividades: '',
      }
    },
    cargarRelizada() {
      if (this.realizadas.id_plan_apoyo !== null) {
        this.items = this.realizadas.acciones
      } 
    },
    submitOption() {
      this.v$.realizadas.$touch()
      if (!this.v$.realizadas.$invalid) {
        if (typeof this.items === 'undefined' || this.items.length === 0) {
          this.configs.variantApoyos = 'danger';
          alert('Debe agregar al menos una acción.');
        } else {
          let acciones = []
          this.items.forEach(item => {
            acciones.push({
              ...item,
              firma: 0,
              aula: 1,
            })
          })
          const params = {
            ...this.realizadas,
            tipo: 1, // tipo Profesional de la salud
            idCurso: this.getLibroSelected.id,
            acciones
          }
          this.$emit('processForm', params)
          this.v$.realizadas.$reset()
          this.resetItemData();
        }
      }
    },
    closeModal() {
      this.resetItemData();
      this.$bvModal.hide(this.nombreModal)
      if (this.realizadas.id === null) {
        this.$emit('resetData')
      }
      this.v$.realizadas.$reset();
    },
    dateDisabled(ymd, date) {
      // Disable weekends (Sunday = `0`, Saturday = `6`) and
      // disable days that fall on the 13th of the month
      const weekday = date.getDay()
      const day = date.getDate()
      // Return `true` if the date should be disabled
      // return weekday === 0 || weekday === 6 || day === 1
      return weekday === 0 || weekday === 6
    },
    
    // REPEAT
    repeateAgain() {
      if (typeof this.items === 'undefined') {
        this.items = []
      }
      this.items.push({
        id: this.nextTodoId += 1,
        fecha: this.formatDate(new Date()),
        horas: 0,
        actividades: '',
      })

      this.$nextTick(() => {
        if (typeof this.$refs.row !== 'undefined') {
          this.trAddHeight(this.$refs.row[0].offsetHeight)
        }
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        if (typeof this.$refs.form !== 'undefined') {
          this.trSetHeight(this.$refs.form.scrollHeight)
        }
      })
    },
  },
  validations() {
    return {
      realizadas: {
        id_plan_apoyo: {
          required: helpers.withMessage('El campo es requerido.', required),
        },
        observaciones: {
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener un máximo de 250 caracteres.', maxLength(250)),
        },
      }
    }
  },
  setup() {
    return {
      v$: useVuelidate(),
    }
  },

  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
